define("in4m-web/controllers/component/manage/api-interface/detail/component-certificate", ["exports", "in4m-web/utils/alerts", "ember-cli-file-saver/mixins/file-saver"], function (_exports, _alerts, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _Ember = Ember,
      get = _Ember.get,
      set = _Ember.set;

  var _default = Ember.Controller.extend(_fileSaver.default, {
    startDate: moment().format('MM/DD/YYYY'),
    endDate: moment().add(10, 'years').format('MM/DD/YYYY'),
    selectedDate: '',
    selectedTimeZone: '',
    runImmediately: false,
    initializeDatePicker: false,
    fmt: 'MM/DD/YYYY h:mm:ss A',
    isServerAuthentication: true,
    isClientAuthentication: true,
    certificateFile: '',
    keyFile: '',
    embedKey: true,
    disableUpload: true,
    uploader: Ember.inject.service('file-queue'),
    useCaCert: false,
    certPassword: '',
    listOfCertificatesGenerated: '',
    ajax: Ember.inject.service(),
    watchIsServerAuthentication: Ember.observer('isServerAuthentication', function () {
      this.get('componentCertificate').set('isServerAuthentication', this.isServerAuthentication);

      if (this.isServerAuthentication) {
        this.get('componentCertificate').set('certificateIssuanceType', 2);
      }
    }),
    watchIsClientAuthentication: Ember.observer('isClientAuthentication', function () {
      this.get('componentCertificate').set('isClientAuthentication', this.isClientAuthentication);

      if (this.isClientAuthentication) {
        this.get('componentCertificate').set('certificateIssuanceType', 2);
      }
    }),
    actions: {
      loadCertificate: function loadCertificate() {
        $('#loadCertificateModal').modal('show');
      },
      selectCertificate: function selectCertificate(file) {
        var _this = this;

        _this.set('certificateFile', file);

        file.readAsText().then(function (data) {
          //check to determine if the certificate has an embed key
          if (data.indexOf('-----BEGIN RSA PRIVATE KEY-----') != -1 || file.extension == "x-pkcs12") {
            _this.set('embedKey', true);

            _this.set('disableUpload', false);
          } else {
            _this.set('embedKey', false);

            _this.set('disableUpload', true);
          }
        });
      },
      selectKey: function selectKey(file) {
        var _this = this;

        _this.set('keyFile', file);

        _this.set('disableUpload', false);
      },
      closeLoadCertificateModal: function closeLoadCertificateModal() {
        $('#loadCertificateModal').modal('hide');
        $('#progressHudOverlay').hide();
        this.set('certificateFile', '');
        this.set('keyFile', '');
        this.set('embedKey', true);
        this.set('disableUpload', true);
        this.set('certPassword', '');
        var certQueue = this.get('uploader').find("certificates");
        var keyQueue = this.get('uploader').find("keys");

        if (certQueue) {
          get(certQueue, 'files').forEach(function (file) {
            return set(file, 'queue', null);
          });
          set(certQueue, 'files', Ember.A());
        }

        if (keyQueue) {
          get(keyQueue, 'files').forEach(function (file) {
            return set(file, 'queue', null);
          });
          set(keyQueue, 'files', Ember.A());
        }

        this.set('useCaCert', false);
      },
      uploadCertificate: function uploadCertificate() {
        var _this = this;

        if ($('#loadCertificateForm').valid()) {
          var validateCertificate = function validateCertificate() {
            var validationRequest = _this.store.createRecord('validationRequest', {
              'password': password,
              'componentId': componentId,
              'certificateGuid': certificateGuid,
              'businessEntityId': parentBusinessEntityId
            });

            return validationRequest.save().then(function (valResponse) {
              if (valResponse.result) {
                var keyFile = _this.get('keyFile');

                if (keyFile) {
                  var certificateKeyUploadUrl = commonUrl + keyFile.name;
                  return keyFile.upload(certificateKeyUploadUrl, {
                    'headers': {
                      'Authorization': accessToken
                    }
                  }).then(function (keyResponse) {
                    $('#loadCertificateModal').modal('hide');
                    $('#progressHudOverlay').hide();

                    _this.set('useCaCert', true);
                  }, function () {
                    //add upload key file failure message
                    $('#loadCertificateModal').modal('hide');

                    _this.send('closeLoadCertificateModal');
                  });
                } else {
                  $('#loadCertificateModal').modal('hide');
                  $('#progressHudOverlay').hide();

                  _this.set('useCaCert', true);
                }
              } else {
                _alerts.default._warn('The password provided is incorrect');

                _this.set('certPassword', '');

                $('#loadCertificateForm').validate().showErrors({
                  'certPassword': 'The password provided is incorrect'
                });

                var certQueue = _this.get('uploader').find("certificates");

                if (certQueue) {
                  get(certQueue, 'files').forEach(function (file) {
                    return set(file, 'queue', null);
                  });
                  set(certQueue, 'files', Ember.A());
                }

                $('#progressHudOverlay').hide();
              }
            }, function (err) {
              _this.get('errorhandler').handleError(err, 'Unable to validate the certificate.');

              _this.send('closeLoadCertificateModal');

              $('#progressHudOverlay').hide();
            });
          };

          $('#progressHudOverlay').show();
          var certificateFile = this.get('certificateFile');
          var apiPath = this.get('apiPath');
          var accessToken = 'Token token="' + this.get('session.sessionObject.accessToken') + '"';
          var componentId = this.get('componentCertificate').get('component.id');

          var parentBusinessEntityId = _this.get('session.selectedBusinessEntity').get('id');

          var certificateGuid = _this.get('componentCertificate').get('certificateGuid');

          var commonUrl = apiPath.in4mApiUrl + '/' + apiPath.in4mApiPath + '/businessentities/' + parentBusinessEntityId + '/components/' + componentId + '/componentcertificates/' + certificateGuid + '/';
          var certificateUploadUrl = commonUrl + certificateFile.name + '?iscacert=true';
          var headers = {
            'Authorization': accessToken
          };
          var settings = {
            'headers': headers
          };

          var password = _this.get('certPassword').split('');

          var component = this.get('componentCertificate').get('component');

          if (certificateFile.state == 'uploaded') {
            return validateCertificate();
          } else {
            return certificateFile.upload(certificateUploadUrl, settings).then(function (certResponse) {
              return validateCertificate();
            }, function (err) {
              _this.get('errorhandler').handleError(err, 'Unable to upload the certificate.');

              _this.send('closeLoadCertificateModal');

              $('#progressHudOverlay').hide();
            });
          }
        }
      },
      changed: function changed(selectedValue) {
        if (selectedValue != 2) {
          this.set('isServerAuthentication', false);
          this.set('isClientAuthentication', false);
        }
      },
      back: function back() {
        this.get('componentCertificate').deleteRecord();
        history.back();
      },
      cancelGenerateCertificate: function cancelGenerateCertificate() {
        this.get('componentCertificate').destroyRecord().then(function () {
          history.back();
        });
      },
      generateCertificateFinished: function generateCertificateFinished() {
        if (this.get('componentCertificate.certificateIssuanceType') == 2) {
          //load cert reset
          this.set('certificateFile', '');
          this.set('keyFile', '');
          this.set('embedKey', true);
          this.set('disableUpload', true);
          this.set('certPassword', '');
        }

        this.set('selectedInterface.certFile', '');
        this.set('selectedInterface.certPassword', '');
        this.set('selectedInterface.keyFile', '');
        this.set('selectedInterface.keyPassword', '');
      },
      generateCertificate: function generateCertificate() {
        var _this = this;

        if ($('#generateCertificate').valid()) {
          $('#progressHudOverlay').show();

          _this.store.query('componentCertificate', {
            businessEntityId: _this.get('session.selectedBusinessEntity').get('id'),
            componentId: _this.get('componentCertificate').get('component.id')
          }).then(function (componentCertificates) {
            var foundCertificates = componentCertificates.filter(function (componentCertificate) {
              return _this.get('selectedInterface.certFile').indexOf(componentCertificate.certificateGuid) != -1;
            });

            if (foundCertificates.length > 0) {
              foundCertificates[0].destroyRecord();
            }
          });

          this.get('componentCertificate').set('startDate', new moment(_this.get('startDate')).utc());
          this.get('componentCertificate').set('endDate', new moment(_this.get('endDate')).utc());
          this.get('componentCertificate').set('isServerAuthentication', _this.isServerAuthentication);
          this.get('componentCertificate').set('isClientAuthentication', _this.isClientAuthentication);
          this.get('componentCertificate').set('useCaCert', _this.useCaCert);
          this.get('componentCertificate').set('caCertPassword', _this.certPassword);
          var password = this.get('componentCertificate.password');

          if (this.get('componentCertificate').get('id') && this.get('componentCertificate').get('certificateIssuanceType') == 2) {
            var oldCertificate = this.get('componentCertificate').toJSON();
            delete oldCertificate.filename;
            var newComponentCertificate = this.store.createRecord('componentCertificate', oldCertificate);
            newComponentCertificate.setProperties({
              parentBusinessEntity: _this.get('session').get('selectedBusinessEntity'),
              component: _this.get('componentCertificate').get('component')
            }); // _this.set('componentCertificate', newComponentCertificate);

            newComponentCertificate.save().then(function (response) {
              postGenerateCertificate(response);
            }, function (error) {
              $('#progressHudOverlay').hide();

              _this.get('errorhandler').handleError(error, 'Unable to generate certificate.');
            });
          } else {
            this.get('componentCertificate').save().then(function (response) {
              postGenerateCertificate(response);
            }, function (error) {
              $('#progressHudOverlay').hide();

              _this.get('errorhandler').handleError(error, 'Unable to generate certificate.');
            });
          }
        }

        function postGenerateCertificate(response) {
          $('#progressHudOverlay').hide();

          _alerts.default._success('Certitificate has been generated.');

          _this.send('generateCertificateFinished');

          if (response.certificateFilePath) {
            _this.set('selectedInterface.certFile', response.certificateFilePath);

            _this.set('selectedInterface.certPassword', password);
          }

          if (response.keyFilePath) {
            _this.set('selectedInterface.keyFile', response.keyFilePath);

            _this.set('selectedInterface.keyPassword', password);
          }

          _this.send('downloadCertificate', response);

          var defer = Ember.RSVP.defer();

          _this.send('saveComponentConfig', defer);

          defer.promise.then(function () {
            if (response.certificateIssuanceType == 2) {
              scheduleTasks();
            }
          }, function () {
            $('#progressHudOverlay').hide();

            _this.get('errorhandler').handleError(error, 'Unable to update UTG.');
          });
        }

        function scheduleTasks() {
          var selectedBusinessEntity = _this.get('session.selectedBusinessEntity');

          var user = _this.get('session.userObject');

          var hasPermissionToScheduleTasks = user.hasPermission('isEnabled', 'in4mUtgScheduleTasks', selectedBusinessEntity);

          if (hasPermissionToScheduleTasks) {
            _this.set('initializeDatePicker', true);

            $('#scheduleTaskCertificateModal').modal('show');
          } else {
            $('#noPermissionToGenerateCertModal').modal('show');
          }
        }
      },
      downloadCertificate: function downloadCertificate(componentCertificate) {
        var _this = this;

        var apiPath = _this.get('apiPath');

        var accessToken = 'Token token="' + _this.get('session.sessionObject.accessToken') + '"';

        var parentBusinessEntityId = _this.get('session.selectedBusinessEntity').get('id');

        var componentId = componentCertificate.component.id;
        var certificateGuid = componentCertificate.certificateGuid;
        var certificateFilePath = componentCertificate.certificateFilePath;
        var keyFilePath = componentCertificate.keyFilePath;

        if (certificateFilePath) {
          var filePaths = certificateFilePath.split('\\');
          var certificateFile = filePaths.pop();

          if (certificateFile) {
            var downloadCrt = apiPath.in4mApiUrl + '/' + apiPath.in4mApiPath + '/businessentities/' + parentBusinessEntityId + '/components/' + componentId + '/componentcertificates/' + certificateGuid + '/' + certificateFile;

            _this.get('ajax').request(downloadCrt, {
              method: 'GET',
              dataType: 'arraybuffer',
              // or 'blob'
              processData: false,
              headers: {
                Authorization: accessToken
              }
            }).then(function (content) {
              _this.saveFileAs(certificateFile, content, 'application/pkix-cert');
            });
          }
        }

        if (keyFilePath) {
          var _filePaths = keyFilePath.split('\\');

          var keyFile = _filePaths.pop();

          if (keyFile) {
            var downloadKey = apiPath.in4mApiUrl + '/' + apiPath.in4mApiPath + '/businessentities/' + parentBusinessEntityId + '/components/' + componentId + '/componentcertificates/' + certificateGuid + '/' + keyFile;

            _this.get('ajax').request(downloadKey, {
              method: 'GET',
              dataType: 'arraybuffer',
              // or 'blob'
              processData: false,
              headers: {
                Authorization: accessToken
              }
            }).then(function (content) {
              _this.saveFileAs(keyFile, content, 'application/pkix-cert');
            });
          }
        }
      },
      selectedDate: function selectedDate(date) {
        var formattedDate = date.format(this.fmt);
        this.set('selectedDate', formattedDate);
      },
      scheduleTask: function scheduleTask() {
        if ($('#scheduleTaskCertificate').valid()) {
          $('#progressHudOverlay').show();

          var _this = this;

          var runAfter;

          if (!_this.get('runImmediately')) {
            //set runAfter only if run immediately not checked
            runAfter = moment(_this.get('selectedDate')).tz(_this.get('selectedTimeZone')).utc().format(_this.get('fmt'));
          } else {
            runAfter = moment().toISOString(); //setting runAfter to current date and time to avoid sorting on null record
          }

          var component = this.get('componentCertificate').get('component');

          var task = _this.store.createRecord('task', {
            componentId: component.id,
            type: 4,
            businessEntityId: component.get('parentBusinessEntity.id'),
            machinePermanentId: component.get('machine.permanentId'),
            runAfter: runAfter
          });

          task.save().then(function (task) {
            component.set('hasConfigChanges', false);
            task.set('component', component);

            _alerts.default._success('Task scheduled for UTG.');

            _this.transitionToRoute('component.manage.api-interface.detail', _this.get('selectedInterface.id'));

            $('#progressHudOverlay').hide();

            _this.send('closeScheduleTasksModal');
          }, function () {
            _alerts.default._error('Failed to schedule task for UTG(s).');

            $('#progressHudOverlay').hide();

            _this.send('closeScheduleTasksModal');

            _this.transitionToRoute('component.manage.api-interface.detail', _this.get('selectedInterface.id'));
          });
        }
      },
      closeScheduleTasksModal: function closeScheduleTasksModal() {
        $('#daterangepicker').val(''); //resetting the daterangepicker value to default value

        this.set('selectedDate', '');
        this.set('selectedTimeZone', '');
        this.set('runImmediately', false);
        $('#scheduleTaskCertificateModal').modal('hide');
        this.set('initializeDatePicker', false);
        $("#scheduleTaskCertificate").data('validator').resetForm();
        this.transitionToRoute('component.manage.api-interface.detail', this.get('selectedInterface.id'));
      },
      closeNoPermissionModal: function closeNoPermissionModal() {
        $('#noPermissionToGenerateCertModal').modal('hide');
        this.transitionToRoute('component.manage.api-interface.detail', this.get('selectedInterface.id'));
      }
    }
  });

  _exports.default = _default;
});
define("in4m-web/templates/custom/profile-version", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AD9vJEXA",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[4,\"if\",[[25,[\"record\",\"ComponentProfileVersion\"]]],null,{\"statements\":[[0,\"  \"],[1,[25,[\"record\",\"ComponentProfileVersion\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  -\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/custom/profile-version.hbs"
    }
  });

  _exports.default = _default;
});
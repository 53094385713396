define("in4m-web/controllers/component-profiles/component-profile/api-interfaces/new", ["exports", "in4m-web/helpers/cd-type-to-model-type", "in4m-web/utils/alerts", "in4m-web/utils/scroll-to-validate", "in4m-web/helpers/check-duplicates-in-array", "in4m-web/utils/drop-down-list"], function (_exports, _cdTypeToModelType, _alerts, _scrollToValidate, _checkDuplicatesInArray, _dropDownList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    componentProfile: Ember.inject.controller('component-profiles.component-profile'),
    selectedInterfaceType: '',
    supportedApiInterfaces: [{
      cdType: 30
    }, {
      cdType: 111
    }, {
      cdType: 110
    }, {
      cdType: 112
    }, {
      cdType: 154
    }, {
      cdType: 109
    }, {
      cdType: 137
    }, {
      cdType: 148
    }, {
      cdType: 158
    } //{cdType:114},
    //{cdType:115},
    //{cdType:116},
    //{cdType:117},
    //{cdType:120},
    //{cdType:121},
    ],
    dropdownListValues: Ember.computed('dropdownList', function () {
      return (0, _dropDownList.default)();
    }),
    selectedInterfaceModel: Ember.computed('selectedInterfaceType', function () {
      var selectedInterfaceType = this.selectedInterfaceType;
      var apiInterfaceConfig = this.componentProfile.get('model.config.apiInterfaceConfig');

      if (apiInterfaceConfig) {
        // for removing apiInterfaces that have not been saved
        var removeApiInterfaces = []; // rollback any other unsaved api interfaces that may have been created from this function before

        this.componentProfile.get('model.config.apiInterfaceConfig').get('apiInterfaces').forEach(function (apiInterface) {
          if (apiInterface.get('isNew')) {
            //rollback attributes for model which is in New state
            apiInterface.rollbackAttributes();
            removeApiInterfaces.push(apiInterface);
          }
        }); //remove unsaved api interfaces the user clicked through

        apiInterfaceConfig.get('apiInterfaces').removeObjects(removeApiInterfaces);
      } // check if selectedInterfaceType has been selected yet


      if (selectedInterfaceType) {
        var modelName = 'apiInterface/' + Ember.String.camelize((0, _cdTypeToModelType.cdTypeToModelType)(selectedInterfaceType));
        var selectedInterfaceModel = this.store.createRecord(modelName, {
          // assigns the selectedInterfaceModel to the config for the profile
          apiInterfaceConfig: apiInterfaceConfig
        }); //add the new api interface

        apiInterfaceConfig.get('apiInterfaces').addObject(selectedInterfaceModel); // set model to selectedInterfaceModel

        this.set('model', selectedInterfaceModel); // for form binding and reloading when form is changed

        return selectedInterfaceModel;
      } else {
        // hides form
        return false;
      }
    }),
    "connect": [true, false],
    "step": 1,
    "range": {
      "min": 1,
      "max": 4
    },
    "format": [{
      "displayName": "SSL 3.0",
      "value": 1
    }, {
      "displayName": "TLS 1.0",
      "value": 2
    }, {
      "displayName": "TLS 1.1",
      "value": 3
    }, {
      "displayName": "TLS 1.2",
      "value": 4
    }],
    actions: {
      saveProfile: function saveProfile() {
        var _this = this;

        if ($('form').valid()) {
          var model = this.componentProfile.get('model');
          var apiInterfaces = model.get('config').get('apiInterfaceConfig').get('apiInterfaces'); //check duplicates for a combination of port number and ipAddressType

          var hasDuplicateCombinationOfAPIPortsAndIp = (0, _checkDuplicatesInArray.checkDuplicatesInArray)(apiInterfaces, 'listenPort', 'ipAddressType');

          if (hasDuplicateCombinationOfAPIPortsAndIp) {
            _scrollToValidate.default.begin('custom-scroll', 'listenPort', 'apiInterface', 'Port Must be Unique per IP Address.');
          } else {
            var hasDuplicateAPIInterfaceNames = (0, _checkDuplicatesInArray.checkDuplicatesInArray)(apiInterfaces, 'taskDesc');

            if (hasDuplicateAPIInterfaceNames) {
              _scrollToValidate.default.begin('custom-scroll', 'taskDesc', 'apiInterface', 'API Interface description must be unique.');
            } else {
              var profileName = model.get('name');
              $('#progressHudOverlay').show();
              model.save().then(function () {
                _alerts.default._success(profileName + ' has been successfully updated.');

                _this.transitionToRoute('componentProfiles.componentProfile.apiInterfaces');

                $('#progressHudOverlay').hide();
              }, function (e) {
                _alerts.default._error('There was a problem updating your profile.');

                $('#progressHudOverlay').hide();
              });
            }
          }
        }
      }
    }
  });

  _exports.default = _default;
});
define("in4m-web/components/bootstrap-tool-tip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'i',
    attributeBindings: ['data-toggle', 'aria-hidden', 'data-placement', 'title'],
    didInsertElement: function didInsertElement() {
      $('[data-toggle="tooltip"]').tooltip();
    }
  });

  _exports.default = _default;
});
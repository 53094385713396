define("in4m-web/templates/components/s4-dropdown-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LqavDfzu",
    "block": "{\"symbols\":[\"s4dropdown\"],\"statements\":[[7,\"select\"],[12,\"disabled\",[23,\"disabled\"]],[12,\"name\",[23,\"name\"]],[12,\"class\",[25,[\"property\",\"s4-validations\",\"class\"]]],[12,\"onchange\",[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"selectedValue\"]]],null]],[[\"value\"],[\"target.value\"]]]],[12,\"required\",[25,[\"property\",\"s4-validations\",\"required\"]]],[9],[0,\"\\n\\t\"],[7,\"option\"],[11,\"value\",\"\"],[12,\"selected\",[29,\"is-equal\",[[25,[\"selectedValue\"]],\"\"],null]],[9],[0,\"Please select\"],[10],[0,\"\\n\"],[4,\"each\",[[25,[\"property\",\"s4-dropdown\"]]],null,{\"statements\":[[4,\"if\",[[24,1,[\"displayName\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"option\"],[12,\"value\",[24,1,[\"value\"]]],[12,\"selected\",[29,\"is-equal\",[[29,\"mut\",[[25,[\"selectedValue\"]]],null],[24,1,[\"value\"]]],null]],[9],[1,[24,1,[\"displayName\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"option\"],[12,\"value\",[24,1,[]]],[12,\"selected\",[29,\"is-equal\",[[29,\"mut\",[[25,[\"selectedValue\"]]],null],[24,1,[]]],null]],[9],[1,[24,1,[]],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/components/s4-dropdown-type.hbs"
    }
  });

  _exports.default = _default;
});
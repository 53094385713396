define("in4m-web/models/component-profile-assignment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    componentId: attr(),
    componentBusinessEntityId: attr(),
    profileId: attr(),
    profileBusinessEntityId: attr(),
    profileVersion: attr(),
    componentProfileAssignmentCollection: belongsTo('componentProfileAssignmentCollection')
  });

  _exports.default = _default;
});
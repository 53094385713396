define("in4m-web/helpers/seconds-to-days-verbose", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.secondsToDays = secondsToDays;
  _exports.default = void 0;

  function secondsToDays(params
  /*, hash*/
  ) {
    var totalNumberOfSeconds = params[0];

    if (totalNumberOfSeconds) {
      var seconds = parseInt(totalNumberOfSeconds);
      var days = Math.floor(seconds / (3600 * 24));
      seconds -= days * 3600 * 24;
      var hours = Math.floor(seconds / 3600);
      seconds -= hours * 3600;
      var minutes = Math.floor(seconds / 60);
      seconds -= minutes * 60; //append zero if the number is less than 10 to display same as windows task manager UI

      if (hours < 10) {
        hours = "0" + hours;
      }

      if (minutes < 10) {
        minutes = "0" + minutes;
      }

      if (seconds < 10) {
        seconds = "0" + seconds;
      }

      return days + ' days, ' + hours + ' hours, ' + minutes + ' minutes, and ' + seconds + ' seconds';
    }
  }

  var _default = Ember.Helper.helper(secondsToDays);

  _exports.default = _default;
});
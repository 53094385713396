define("in4m-web/adapters/component-config", ["exports", "ember-inflector", "in4m-web/adapters/application"], function (_exports, _emberInflector, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    host: Ember.computed('apiPath', function () {
      return this.get('apiPath.in4mApiUrl');
    }),
    namespace: Ember.computed('apiPath', function () {
      return this.get('apiPath.in4mApiPath');
    }),
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var url = [];
      var host = Ember.get(this, 'host');
      var prefix = this.urlPrefix();
      var path;

      if (requestType === 'queryRecord') {
        var businessEntityId = query.businessEntityId;
        var machinePermanentId = query.machinePermanentId;
        var componentPermanentId = query.componentPermanentId;
        var revisionId = query.revisionId;
        delete query.businessEntityId;
        delete query.machinePermanentId;
        delete query.componentPermanentId;
        delete query.revisionId;

        if (modelName) {
          path = this.pathForType(modelName);

          if (path) {
            url.push('businessEntities' + '/' + businessEntityId + '/' + 'machines' + '/' + machinePermanentId + '/' + 'components' + '/' + componentPermanentId + '/' + path + '/' + revisionId);
          }
        }
      } else if (requestType === 'updateRecord') {
        if (modelName) {
          path = this.pathForType(modelName);

          if (path) {
            url.push('businessEntities' + '/' + snapshot.record.get('component.parentBusinessEntity.id') + '/' + 'machines' + '/' + snapshot.record.get('component.machine.permanentId') + '/' + 'components' + '/' + snapshot.record.get('component.permanentId') + '/' + path);
          }
        }
      } else {
        return this._super(modelName, id, snapshot, requestType, query);
      }

      if (id) {
        url.push(encodeURIComponent(id));
      }

      if (prefix) {
        url.unshift(prefix);
      }

      url = url.join('/');

      if (!host && url && url.charAt(0) !== '/') {
        url = '/' + url;
      }

      return url;
    },
    pathForType: function pathForType(modelName) {
      var camelized = Ember.String.camelize(modelName);
      var pluralized = (0, _emberInflector.pluralize)(camelized);
      return pluralized;
    }
  });

  _exports.default = _default;
});
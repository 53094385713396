define("in4m-web/helpers/digital-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.digitalClock = digitalClock;
  _exports.default = void 0;

  function digitalClock(params
  /*, hash*/
  ) {
    var seconds = params[0];
    var h = Math.floor(seconds / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    var addZero = function addZero(number) {
      return number < 10 ? '0' + number : '' + number;
    };

    var formatHMS = function formatHMS(h, m, s) {
      if (h > 0) {
        return "".concat(h, ":").concat(addZero(m), ":").concat(addZero(s));
      }

      return "".concat(m, ":").concat(addZero(s));
    };

    return formatHMS(h, m, s);
  }

  var _default = Ember.Helper.helper(digitalClock);

  _exports.default = _default;
});
define("in4m-web/adapters/access-token", ["exports", "in4m-web/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: Ember.computed('apiPath', function () {
      return this.get('apiPath.coreSsoApiPath');
    })
  });

  _exports.default = _default;
});
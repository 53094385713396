define("in4m-web/templates/custom/pretty-json", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FuMTTYjC",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"pretty-json\",[[25,[\"record\"]]],null],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/custom/pretty-json.hbs"
    }
  });

  _exports.default = _default;
});
define("in4m-web/services/rest-query", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    componentsFilter: null,
    tasksFilter: null,
    devicesFilter: null,
    components: null,
    tasks: null,
    devices: null,
    componentsFilterCount: 0,
    tasksFilterCount: 0,
    devicesFilterCount: 0,
    // create objects and arrays in the init hook
    init: function init() {
      this._super.apply(this, arguments);

      this.set('componentsFilter', {
        "Predicates": []
      });
      this.set('tasksFilter', {
        "Predicates": []
      });
      this.set('devicesFilter', {
        "Predicates": []
      });
      this.set('components', []);
      this.set('tasks', []);
      this.set('devices', []);
    },
    setComponentsFilter: function setComponentsFilter(filter) {
      this.set('componentsFilter', filter);
      this.set('componentsFilterCount', filter.Predicates.length);
    },
    setTasksFilter: function setTasksFilter(filter) {
      this.set('tasksFilter', filter);
      this.set('tasksFilterCount', filter.Predicates.length);
    },
    setDevicesFilter: function setDevicesFilter(filter) {
      this.set('devicesFilter', filter);
      this.set('devicesFilterCount', filter.Predicates.length);
    },
    encodeFilter: function encodeFilter(filter) {
      // The REST API requires that the filter payload package be encoded as a string with special characters escaped or removed.
      // Rules:
      // Surrounding quotes ""
      // Escape in-line quotes \"
      // Escape in-line backslash \\
      // And remove newlines
      return JSON.stringify(filter).replace("\n", "");
    },
    queryComponents: function queryComponents(beId, filter) {
      var _this = this;

      var _filter = filter || this.get('componentsFilter');

      var url = "".concat(_this.get('apiPath.in4mApiUrl'), "/").concat(_this.get('apiPath.in4mApiPath'), "/businessentities/").concat(beId, "/components/query");

      var accessToken = _this.get('session.sessionObject.accessToken');

      return this.queryEntities(url, accessToken, _filter, 'components');
    },
    queryTasks: function queryTasks(beId, filter) {
      var _this = this;

      var _filter = filter || this.get('tasksFilter');

      var url = "".concat(_this.get('apiPath.in4mApiUrl'), "/").concat(_this.get('apiPath.in4mApiPath'), "/businessentities/").concat(beId, "/tasks/query");

      var accessToken = _this.get('session.sessionObject.accessToken');

      return this.queryEntities(url, accessToken, _filter, 'tasks');
    },
    queryDevices: function queryDevices(beId, filter) {
      var _this = this;

      var _filter = filter || this.get('devicesFilter');

      var url = "".concat(_this.get('apiPath.in4mApiUrl'), "/").concat(_this.get('apiPath.in4mApiPath'), "/businessentities/").concat(beId, "/devices/query");

      var accessToken = _this.get('session.sessionObject.accessToken');

      return this.queryEntities(url, accessToken, _filter, 'devices');
    },
    queryEntities: function queryEntities(url, accessToken, filter, entityType) {
      var _this = this;

      var _filter = filter;
      return new Promise(function (resolve, reject) {
        $.ajax({
          url: url,
          method: 'POST',
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify(_this.encodeFilter(_filter)),
          headers: {
            'Authorization': "Token token=\"".concat(accessToken, "\"")
          }
        }).done(function (data) {
          _this.set(entityType, _this.mapEntity(data, entityType));

          return resolve(data);
        }).fail(function (jqXHR, textStatus, errorThrown) {
          return reject(errorThrown);
        });
      });
    },
    mapEntity: function mapEntity(data, entityType) {
      var _this2 = this;

      // iterates over data and remap/add field names if needed. A lowercase id field is required to link to detail pages.
      var parentName = this.get('session.selectedBusinessEntity.name');
      var today = new Date();
      var latestAgentVersion = this.get('session.latestAgentVersion');
      data.forEach(function (c) {
        // map any fields that apply to all entities
        c.ParentName = parentName !== null && parentName !== void 0 ? parentName : 'Unknown';
        c.MachineStatusEntityLastKnownCommunication = c.MachineStatusEntityLastKnownCommunication ? new Date(parseInt(c.MachineStatusEntityLastKnownCommunication.substr(6))) : '';
        c.MachineStatusEntityNextPredictedCommunication = c.MachineStatusEntityNextPredictedCommunication ? new Date(parseInt(c.MachineStatusEntityNextPredictedCommunication.substr(6))) : ''; //less than today will always be accurate, rather than comparing to last communication. However, browser timezone could affect this logic. Eventually, we will want to investigate this further.

        c.failedToCheckIn = new Date(c.MachineStatusEntityNextPredictedCommunication).getTime() < today.getTime();
        c.isAgentVersionLatest = c.AgentVersion == latestAgentVersion; // now map any entity specific fields

        switch (entityType) {
          case 'components':
            c.id = c.ComponentId;
            break;

          case 'tasks':
            c.id = c.TaskId;
            c.TaskLastUpdatedAt = c.TaskLastUpdatedAt ? new Date(parseInt(c.TaskLastUpdatedAt.substr(6))) : '';
            c.TaskRunAfter = c.TaskRunAfter ? new Date(parseInt(c.TaskRunAfter.substr(6))) : '';
            c.TaskTypeString = _this2.mapTaskType(c.TaskType, c);
            c.TaskStatusString = _this2.mapTaskState(c.TaskStatus);
            break;

          case 'devices':
            break;
        }
      });
      return data;
    },
    mapTaskState: function mapTaskState(status) {
      if (status === -10) {
        return "Rejected";
      } else if (status === -5) {
        return "Controller Failed";
      } else if (status >= 0 && status < 15) {
        return "Scheduled";
      } else if (status === 15) {
        return "Running";
      } else if (status === 20) {
        return "Completed";
      } else if (status === 30) {
        return "Failed";
      } else {
        return "Unknown";
      }
    },
    mapTaskType: function mapTaskType(taskType, task) {
      // Need to look into instructions to determine UTG running mode and applied configuration status
      var instructions = task.TaskInstructions && task.TaskInstructions !== 'NULL' ? JSON.parse(task.TaskInstructions) : null;
      var s = 'Unknown';

      switch (taskType) {
        case 1:
          if (instructions) {
            if (instructions.ComponentRunningMode == 1) {
              s = "Start UTG as a service";
            } else if (instructions.ComponentRunningMode == 2) {
              s = "Start UTG as stand alone";
            }
          }

          break;

        case 2:
          s = 'Stop UTG';
          break;

        case 3:
          s = 'Restart UTG';
          break;

        case 4:
          s = 'Update UTG(s) to latest configuration';
          break;

        case 5:
          if (instructions) {
            if (instructions.Destination == 5) {
              s = "Revert UTG to last successfully applied configuration";
            } else if (instructions.Destination == 10) {
              s = "Update Shift4 Command Center with UTG's local configuration";
            }
          }

          break;

        case 6:
          s = 'Retrieve latest component information';
          break;

        case 7:
          s = 'Reset UTG Slot';
          break;

        case 8:
          s = 'Change UTG Version';
          break;

        case 9:
          s = 'Change Agent Version';
          break;

        case 10:
          s = 'Push Forms';
          break;

        case 11:
          s = 'Update Firmware';
          break;

        case 13:
          s = 'Set Offline';
          break;

        case 15:
          s = 'Upload Logs';
          break;

        default:
          s = 'Unknown';
      }

      return s;
    }
  });

  _exports.default = _default;
});
define("in4m-web/templates/component-profiles/component-profile/api-interfaces/api-interface", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VTuCOtvj",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[16,[29,\"concat\",[\"api-interfaces/\",[29,\"dasherize-helper\",[[29,\"cd-type-to-model-type\",[[25,[\"model\",\"cdType\"]]],null]],null]],null],[]],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"componentProfile\",\"canEditSelectedProfile\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"footer\"],[11,\"class\",\"panel-footer\"],[11,\"style\",\"background-color:transparent\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\"],[11,\"style\",\"float:right\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"button\"],[11,\"class\",\"btn btn-primary\"],[9],[0,\"Apply\"],[3,\"action\",[[24,0,[]],\"saveProfile\"]],[10],[0,\"\\t\\t\\n\\t\\t\\t\"],[10],[0,\"\\n\\t\\t\"],[10],[0,\"\\n\\t\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":true}",
    "meta": {
      "moduleName": "in4m-web/templates/component-profiles/component-profile/api-interfaces/api-interface.hbs"
    }
  });

  _exports.default = _default;
});
define("in4m-web/routes/tasks/error", ["exports", "in4m-web/mixins/error-substate"], function (_exports, _errorSubstate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_errorSubstate.default);

  _exports.default = _default;
});
define("in4m-web/controllers/machine/manage", ["exports", "in4m-web/utils/alerts"], function (_exports, _alerts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    selectedComponents: null,
    selectedStatus: '',
    selectedDate: '',
    selectedTimeZone: '',
    runImmediately: false,
    casenumber: '',
    fromdaterangepicker: '',
    todaterangepicker: '',
    initializeDatePicker: false,
    fmt: 'MM/DD/YYYY h:mm:ss A',
    watchCheckbox: Ember.observer('runImmediately', function () {
      $("#scheduleTasks").data('validator').resetForm();
    }),
    isCogHidden: Ember.computed('session.selectedBusinessEntity', function () {
      var hasPermissionToViewCog = true;
      var selectedBusinessEntity = this.get('session.selectedBusinessEntity');
      var user = this.get('session.userObject');
      var hasPermissionToEditMachines = user.hasPermission('canEdit', 'in4mMachines', selectedBusinessEntity);
      var hasPermissionToScheduleTasks = user.hasPermission('isEnabled', 'in4mUtgScheduleTasks', selectedBusinessEntity);
      var hasPermissionToSetupPackages = user.hasPermission('isEnabled', 'in4mUtgSetupPackages', selectedBusinessEntity);

      if (hasPermissionToEditMachines || hasPermissionToScheduleTasks || hasPermissionToSetupPackages) {
        //checking if the user has atleast one of the above permissions
        hasPermissionToViewCog = false;
      }

      return hasPermissionToViewCog;
    }),
    canEditMachines: Ember.computed('session.selectedBusinessEntity', function () {
      var selectedBusinessEntity = this.get('session.selectedBusinessEntity');
      var user = this.get('session.userObject');
      var hasPermissionToEditMachines = user.hasPermission('canEdit', 'in4mMachines', selectedBusinessEntity);
      return hasPermissionToEditMachines;
    }),
    columns: function () {
      return [{
        "template": "custom/select-box",
        "useFilter": false,
        "templateForSortCell": 'custom/select-box-all'
      }, {
        "template": "custom/machineComponentGear",
        "isHidden": this.isCogHidden
      }, {
        "propertyName": "nickname",
        "title": "Component Nickname"
      }, {
        "propertyName": "serviceType",
        "title": "Service Type"
      }, {
        "propertyName": "status",
        "title": "Service Status"
      }, {
        "template": "custom/dateFormatter",
        "propertyName": "componentStatus.machineStatus.lastUpdatedAt",
        "title": "Last Known Communication",
        "sortedBy": "componentStatus.machineStatus.lastUpdatedAt"
      }, {
        "template": "custom/nextKnownCommunication",
        "title": "Next Predicted Communication",
        "sortedBy": "componentStatus.machineStatus.nextPredictedUpdateAt"
      }, {
        "propertyName": "scheduledTasks",
        "title": "Scheduled Tasks"
      }, {
        "propertyName": "softwareVersionFromAws",
        "title": "UTG Version"
      }, {
        "template": "custom/configurationVersion",
        "title": "Configuration Status"
      }, {
        "propertyName": "parentName",
        "title": "Parent Account"
      }];
    }.property('isCogHidden'),
    actions: {
      updateStatusInstructions: function updateStatusInstructions(value) {
        this.set('selectedStatus', value.selectedStatus);
        this.set('instructions', value.instructions);
      },
      displayDataChanged: function displayDataChanged(settings) {
        this.set('selectedComponents', settings.selectedItems);
      },
      scheduleTask: function scheduleTask(record) {
        if (this.selectedComponents.length === 0) {
          var tempArray = [];
          tempArray.pushObject(record);
          this.set('selectedComponents', tempArray);
        }

        this.set('initializeDatePicker', true);
        $('#scheduleTaskModal').modal('show');
      },
      editParent: function editParent(record) {},
      editProfile: function editProfile(record) {},
      selectedDate: function selectedDate(date) {
        var formattedDate = date.format(this.fmt);
        this.set('selectedDate', formattedDate);
      },
      closeModal: function closeModal() {
        $('#daterangepicker').val(''); //resetting the daterangepicker value to default value

        $('#fromdaterangepicker').val('');
        $('#todaterangepicker').val('');
        this.set('selectedStatus', '');
        this.set('selectedDate', '');
        this.set('selectedTimeZone', '');
        this.set('fromdaterangepicker', '');
        this.set('todaterangepicker', '');
        this.set('casenumber', '');
        this.set('runImmediately', false);
        this.selectedComponents.clear();
        $('#scheduleTaskModal').modal('hide');
        this.set('initializeDatePicker', false);
        this.set('selectedComponentType', '');
        this.set('selectedTaskType', '');
        this.set('instructions', '');
        $("#scheduleTasks").data('validator').resetForm(); //resetting the form to remove error classes if any on modal close

        $('#scheduleTaskModal').modal('hide');
      },
      changeStatus: function changeStatus(value) {
        if ($('#scheduleTasks').valid()) {
          var createRecord = function createRecord(components) {
            if (components.length > 0) {
              components.forEach(function (eachComponent) {
                var task = _this.store.createRecord('task', {
                  componentId: eachComponent.id,
                  type: parseInt(selectedStatus),
                  businessEntityId: eachComponent.get('parentBusinessEntity.id'),
                  machinePermanentId: eachComponent.get('machine.permanentId'),
                  runAfter: runAfter,
                  instructions: instructions
                });

                task.save().then(function (task) {
                  if (_this.get('selectedStatus') == 4) {
                    eachComponent.set('hasConfigChanges', false); //adding this to make sure the component has no config changes when user updates UTG to latest configuration
                  }

                  task.set('component', eachComponent);
                  noOfUpdatesSucceeded = noOfUpdatesSucceeded + 1;
                  showAlert(noOfUpdatesSucceeded, noOfUpdatesFailed, components);
                }, function () {
                  noOfUpdatesFailed = noOfUpdatesFailed + 1;
                  showAlert(noOfUpdatesSucceeded, noOfUpdatesFailed, components);
                });
              });
            }
          };

          var showAlert = function showAlert(noOfUpdatesSucceeded, noOfUpdatesFailed, components) {
            //passing filtered components instead of selected components
            if (noOfUpdatesSucceeded + noOfUpdatesFailed === components.length) {
              //checking components length instead of all selected components length because schedule tasks can be done for only components that have hasConfigChanges or all selected components
              if (noOfUpdatesSucceeded > 0) {
                _alerts.default._success('Task scheduled for ' + noOfUpdatesSucceeded + ' UTG(s).');
              }

              if (noOfUpdatesFailed > 0) {
                _alerts.default._error('Failed to schedule task for ' + noOfUpdatesFailed + ' UTG(s).');
              }

              $('#progressHudOverlay').hide();

              _this.send('closeModal');
            }
          };

          $('#progressHudMessage').html('Updating...');
          $('#progressHudOverlay').show();

          var _this = this;

          var noOfUpdatesSucceeded = 0;
          var noOfUpdatesFailed = 0;
          var runAfter;

          if (!_this.get('runImmediately')) {
            //set runAfter only if run immediately not checked
            runAfter = moment(_this.get('selectedDate')).tz(_this.get('selectedTimeZone')).utc().format(_this.get('fmt'));
          } else {
            runAfter = moment().toISOString(); //setting runAfter to current date and time to avoid sorting on null record
          }

          var selectedStatus = _this.get('selectedStatus');

          var instructions = _this.get('instructions');

          if (value && value.selectedStatus == '15') {
            // override instructions for upload log and trace
            instructions = value.instructions;
          }

          if (_this.get('selectedStatus')) {
            createRecord(_this.get('selectedComponents'));
          }
        }
      },
      updateMachine: function updateMachine() {
        if ($('#manageMachine').valid()) {
          var _this = this;

          $('#progressHudMessage').html('Updating...');
          $('#progressHudOverlay').show();
          this.model.save().then(function () {
            $('#progressHudOverlay').hide();

            _alerts.default._success('Machine has been updated.');
          }, function (error) {
            $('#progressHudOverlay').hide();

            _this.get('errorhandler').handleError(error, 'Unable to update machine.');
          });
        }
      }
    }
  });

  _exports.default = _default;
});
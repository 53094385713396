define("in4m-web/services/clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    logout: Ember.inject.service(),
    pulse: Ember.computed.oneWay('_seconds').readOnly(),
    tick: Ember.on('init', Ember.observer('_seconds', function () {
      var _this = this;

      if (this.get('session.sessionObject') && this.get('session.sessionObject.expires')) {
        if (_this.get('_seconds').gte(Big(1))) {
          Ember.run.later(function () {
            _this.set('_seconds', Big(Big((_this.get('session.sessionObject.expires').getTime() - Date.now()) / 1000).toFixed(0)));
          }, 1000);
        } else {
          _this.get('logout').logout();
        }
      } else {
        Ember.run.later(function () {
          _this.set('_seconds', Big(1200));
        }, 1000);
      }
    })),
    _seconds: Big(1200)
  });

  _exports.default = _default;
});
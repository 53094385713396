define("in4m-web/controllers/users/user", ["exports", "shift4tress/utils/utils"], function (_exports, utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({});

  _exports.default = _default;
});
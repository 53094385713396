define("in4m-web/helpers/check-for-s4-directive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.checkForS4Directive = checkForS4Directive;
  _exports.default = void 0;

  function checkForS4Directive(params
  /*, hash*/
  ) {
    var property = params[0];

    if (property['s4-label'] || property['s4-password'] || property['s4-ipaddress'] || property['s4-email'] || property['s4-radio'] || property['s4-dropdown'] || property['s4-alphanumeric'] || property['s4-currency'] || property['s4-complex-array'] || property['s4-slider']) {
      return true;
    } else {
      return false;
    }
  }

  var _default = Ember.Helper.helper(checkForS4Directive);

  _exports.default = _default;
});
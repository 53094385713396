define("in4m-web/templates/component-profiles/component-profile/advanced", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UQA7pn4O",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"advanced-template\",null,[[\"model\",\"canEdit\",\"dropdownListValues\",\"save\"],[[25,[\"model\"]],[25,[\"componentProfile\",\"canEditSelectedProfile\"]],[25,[\"dropdownListValues\"]],\"saveProfile\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/component-profiles/component-profile/advanced.hbs"
    }
  });

  _exports.default = _default;
});
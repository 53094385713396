define("in4m-web/components/radio-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'input',
    name: 'radioButton',
    attributeBindings: ['required', 'formValue', 'name', 'propertyValue', 'type', 'checked', 'disabled'],
    type: 'radio',
    checked: function () {
      return this.formValue == this.propertyValue;
    }.property('value', 'propertyValue'),
    click: function click() {
      this.set('propertyValue', this.formValue);
    },
    change: function change() {
      var value = this.get('propertyValue');
      var changedAction = this.get('changed');

      if (typeof changedAction === 'string') {
        this.sendAction('changed', value);
        return;
      }

      if (changedAction) {
        changedAction(value);
      }
    }
  });

  _exports.default = _default;
});
define("in4m-web/routes/installations/select-install-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    renderTemplate: function renderTemplate() {
      this.render('installations/selectInstallType', {
        into: 'installations',
        outlet: 'modal'
      });
      this.render('installations/index', {
        into: 'installations',
        controller: 'installations/index',
        model: this.modelFor('installations')
      });
    }
  });

  _exports.default = _default;
});
define("in4m-web/components/date-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.run.schedule('afterRender', this, function () {
        $('#datecalendar').click(function () {
          $('#daterangepicker').click();
        });
        $('#fromdatecalendar').click(function () {
          $('#fromdaterangepicker').click();
        });
        $('#todatecalendar').click(function () {
          $('#todaterangepicker').click();
        });
        $('#daterangepicker').daterangepicker({
          "opens": "left",
          "minDate": moment().subtract(24, 'hours'),
          "singleDatePicker": true,
          "timePicker": true,
          "autoUpdateInput": false,
          "applyClass": "btn-primary",
          "drops": _this.get('drops')
        });
        $('#daterangepicker').on('apply.daterangepicker', function (ev, picker) {
          $('#daterangepickerDiv').removeClass('has-error'); //fix for removing error classes since jquery's key up events are not triggered

          $('#daterangepicker-error').hide();

          _this.sendAction('selectedDate', picker.endDate);

          $(this).val(picker.endDate.format('MM/DD/YYYY h:mm:ss A'));
        });
        $('#daterangepicker').on('cancel.daterangepicker', function (ev, picker) {
          $(this).val('');
        });
        $('#fromdaterangepicker').daterangepicker({
          "opens": "left",
          "singleDatePicker": true,
          "timePicker": true,
          "autoUpdateInput": false,
          "applyClass": "btn-primary",
          "drops": _this.get('drops')
        });
        $('#fromdaterangepicker').on('apply.daterangepicker', function (ev, picker) {
          $('#fromDaterangepickerDiv').removeClass('has-error'); //fix for removing error classes since jquery's key up events are not triggered

          $('#daterangepicker-error').hide();

          _this.sendAction('fromdaterangepicker', picker.endDate);

          $(this).val(picker.endDate.format('MM/DD/YYYY h:mm:ss A'));
        });
        $('#fromdaterangepicker').on('cancel.daterangepicker', function (ev, picker) {
          $(this).val('');
        });
        $('#todaterangepicker').daterangepicker({
          "opens": "left",
          "singleDatePicker": true,
          "timePicker": true,
          "autoUpdateInput": false,
          "applyClass": "btn-primary",
          "drops": _this.get('drops')
        });
        $('#todaterangepicker').on('apply.daterangepicker', function (ev, picker) {
          $('#toDaterangepickerDiv').removeClass('has-error'); //fix for removing error classes since jquery's key up events are not triggered

          $('#daterangepicker-error').hide();

          _this.sendAction('todaterangepicker', picker.endDate);

          $(this).val(picker.endDate.format('MM/DD/YYYY h:mm:ss A'));
        });
        $('#todaterangepicker').on('cancel.daterangepicker', function (ev, picker) {
          $(this).val('');
        });
      });
    }
  });

  _exports.default = _default;
});
define("in4m-web/routes/component-profiles/component-profile/api-interfaces/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting) {
        var dirtyRecord = controller.selectedInterfaceModel;

        if (dirtyRecord) {
          dirtyRecord.rollbackAttributes(); //remove unsaved api interfaces the user clicked through

          controller.componentProfile.get('model.config.apiInterfaceConfig.apiInterfaces').removeObject(dirtyRecord);
        }

        controller.set('selectedInterfaceType', ''); //resetting the selected interface on the controller to empty
      }
    }
  });

  _exports.default = _default;
});
define("in4m-web/routes/component/manage/general", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    pollingInterval: null,
    model: function model() {
      if (this.modelFor('component.manage')) {
        pollComponentStatus(this);
        this.store.query('machine-status', {
          parentBusinessEntityId: this.get('session.selectedBusinessEntity.id'),
          machinePermanentIds: this.modelFor('component.manage').get('component.machine.permanentId')
        }).then(function (machineStatuses) {// results are now in store
        });
        return this.modelFor('component.manage').get('component');
      } else {
        return '';
      }

      function pollComponentStatus(context) {
        var _this = context;

        if (_this.get('pollingInterval')) {
          Ember.run.cancel(_this.get('pollingInterval')); //for cancelling the previous lined up pollComponentStatus call
        }

        var interval = 1000 * 15; // setting the time interval to 15 seconds

        _this.set('pollingInterval', Ember.run.later(_this, function () {
          _this.store.queryRecord('component', {
            businessEntityId: _this.currentModel.get('parentBusinessEntity.id'),
            machinePermanentId: _this.currentModel.get('machine.permanentId'),
            componentPermanentId: _this.currentModel.get('permanentId')
          }).then(function () {//do nothing
          }, function (error) {
            _this.get('errorhandler').handleError(error, 'Error while making a request.');
          });

          pollComponentStatus(context);
        }, interval));
      }
    },
    afterModel: function afterModel() {
      $('#progressHudOverlay').hide();
    },
    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting) {
        var pollingInterval = this.pollingInterval;

        if (pollingInterval) {
          Ember.run.cancel(pollingInterval);
        }

        this.set('pollingInterval', null);
      }
    }
  });

  _exports.default = _default;
});
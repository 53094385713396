define("in4m-web/controllers/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['includealldescendants', 'businessentityid'],
    showChildren: Ember.computed.alias('session.showChildren'),
    updateChildren: function () {
      var navigation = this.navigation;
      var hasPermission = navigation.checkPermissions(this.get('session.selectedBusinessEntity'));

      if (hasPermission) {
        $('#progressHudOverlay').show();
        this.send('refresh');
      }
    }.observes('showChildren'),
    ParentBusinessEntities: Ember.computed('model.@each.parentBusinessEntity', function () {
      //method to retrieve parentBusinessEntity Ids for the current selected business entity
      var parentBusinessEntityArr = [];
      var components = this.model;
      components.forEach(function (eachComponent) {
        var parentBusinessEntity = eachComponent.get('parentBusinessEntity.id');

        if (parentBusinessEntityArr.indexOf(parentBusinessEntity) === -1) {
          parentBusinessEntityArr.push(parentBusinessEntity);
        }
      });
      return parentBusinessEntityArr;
    })
  });

  _exports.default = _default;
});
define("in4m-web/routes/application", ["exports", "in4m-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var apiPath = this.apiPath;
      apiPath.setAppConfiguration();
    },
    model: function model() {
      $('#progressHudOverlay').show();

      var _this = this;

      var agentManifestLocation = this.get('apiPath.agentManifestLocation');
      Ember.$.get(agentManifestLocation).then(function (data) {
        var latestAgentVersion;

        for (var i = 0; i < data.product.length; i++) {
          var agent = data.product[i];

          if (agent.generalAvailability) {
            latestAgentVersion = agent.version;
            break;
          }
        }

        _this.set('session.latestAgentVersion', latestAgentVersion);
      }); // Initialize the proxy.html iframes and check the status of required services.

      var proxyPromises = []; //core API status

      proxyPromises.push($.get(coreApiStatusUrl)); //in4m API status

      proxyPromises.push($.get(in4mApiStatusUrl)); //handles resolving the proxy promises

      var proxyHandler = Ember.RSVP.all(proxyPromises); //proxyDefer gives us a deferred object we can reject if xdomain timesout

      var proxyDefer = $.Deferred();
      xdomain.on('timeout', function () {
        $('#progressHudOverlay').hide();
        $('#services-unavailable').modal().toggle(); //reject the deferred promise

        proxyDefer.reject();
      }); // Create a hidden listener.

      var _hide = $.fn.hide;

      $.fn.hide = function (speed, callback) {
        $(this).trigger('hidden');
        return _hide.apply(this, arguments);
      }; // TODO Build a progressHudOverlay component that is smart/elegant


      $('#progressHudOverlay').on('hidden', function () {
        // always default back to "Loading..."
        $('#progressHudMessage').html('Loading...');
      });
      proxyHandler.then(function () {
        //resolve the deferred promise
        proxyDefer.resolve();
      }, function () {
        //reject the deferred promise
        proxyDefer.reject();
      });
      return proxyDefer.then(function () {
        return getSessionDetails();
      }, function (e) {
        return $.Deferred().resolve({
          error: true
        });
      });

      function getQueryVariable(variable) {
        var query = window.location.search.substring(1);
        var vars = query.split("&");

        for (var i = 0; i < vars.length; i++) {
          var pair = vars[i].split("=");

          if (pair[0] == variable) {
            return pair[1].replace(/[^a-z0-9-._@]/gi, "");
          }
        }

        return false;
      }

      function getSessionDetails() {
        var applicationId = getQueryVariable('clientid');
        var authenticationCode = getQueryVariable('authcode');
        var accessToken = localStorage.getItem('accessToken');
        var invalidAccessToken = !accessToken || accessToken === '' || accessToken === 'undefined' || accessToken === 'null';

        if (authenticationCode) {
          history.replaceState({}, '', '/');
          return _this.store.createRecord('accessToken', {
            'applicationId': applicationId,
            'authenticationCode': authenticationCode
          }).save().then(function (data) {
            _this.get('session').setSessionObject(data);

            localStorage.setItem('accessToken', data.get('accessToken'));
            return _this.store.query('userManifest', {
              accesstoken: data.get('accessToken')
            }).then(function (loggedInUser) {
              var userObject = loggedInUser.get('firstObject'); //case where you get only one element in array all the time

              _this.get('session').setUserObject(userObject);

              return finishLoadingSession();
            }, function (error) {
              return _this.get('errorhandler').handleError(error, 'There was a problem trying to process your request. Please try again in a few minutes. If the error persists, contact your administrator.');
            });
          }, function (error) {
            return _this.get('errorhandler').handleError(error, 'There was a problem trying to process your request. Please try again in a few minutes. If the error persists, contact your administrator.');
          });
        } else if (invalidAccessToken) {
          if (_environment.default.isDev) {
            window.location.href = _this.get('apiPath.ssoUrl') + '/?clientid=4&redirectToReferer=true';
          } else {
            window.location.href = _this.get('apiPath.ssoUrl') + '/?clientid=4';
          }
        } else if (!authenticationCode) {
          if (invalidAccessToken) {
            console.log('invalidAccessToken');
          } else {
            return _this.store.findRecord('accessToken', accessToken, {
              applicationId: applicationId
            }).then(function (data) {
              _this.get('session').setSessionObject(data);

              return _this.store.query('userManifest', {
                accesstoken: data.get('accessToken')
              }).then(function (loggedInUser) {
                var userObject = loggedInUser.get('firstObject'); //case where you get only one element in array all the time

                _this.get('session').setUserObject(userObject);

                return finishLoadingSession();
              }, function (error) {
                return _this.get('errorhandler').handleError(error, 'There was a problem trying to process your request. Please try again in a few minutes. If the error persists, contact your administrator.');
              });
            }, function (error) {
              return _this.get('errorhandler').handleError(error, 'There was a problem trying to process your request. Please try again in a few minutes. If the error persists, contact your administrator.');
            });
          }
        }
      }
      /* Load data to complete application start-up */


      function finishLoadingSession() {
        var promises = [];
        promises.push(_this.store.findAll('permission'));
        promises.push(_this.store.findAll('permissionMask').then(function (permissionMasks) {
          _this.get('session.userObject').set('permissionMasks', permissionMasks);
        }));
        promises.push(_this.store.findAll('applicationGroup').then(function (applicationGroups) {
          _this.get('session').setApplicationGroups(applicationGroups);
        }));
        promises.push(_this.store.query('businessEntityTheme', {
          parentBusinessEntityId: _this.get('session.userObject.parentBusinessEntity.id'),
          applicationGroupId: 1
        }).then(function (themes) {
          _this.get('session').setBusinessEntityThemes(themes);
        }));
        promises.push(_this.store.query('businessEntity', {
          parentBusinessEntityId: _this.get('session.userObject.parentBusinessEntity.id'),
          includeChildren: true,
          includeParent: true
        }).then(function () {
          _this.set('businessEntity', _this.get('session.userObject.parentBusinessEntity'));

          _this.set('businessEntity.isSelected', true);
        }, function (error) {
          return _this.get('errorhandler').handleError(error, 'No permissions to access Shift4 Command Center account tree', true);
        }));
        return Ember.RSVP.all(promises).then(function () {
          //no need to do a transition from here
          _this.get('session.userObject').hasAccessCheck();
        }, function (error) {
          return _this.get('errorhandler').handleError(error, 'Unable to retrieve user information.');
        });
      }
    },
    afterModel: function afterModel() {
      var _this = this;

      _this.get('session').sessionTimeoutInterval = setInterval(function () {
        if (_this.get('session.sessionObject')) {
          if (_this.get('session.sessionObject.expires')) {
            if (Big(Big((_this.get('session.sessionObject.expires').getTime() - Date.now()) / 1000).toFixed(0)) <= 300) {
              clearInterval(_this.get('session.sessionTimeoutInterval'));
              $('#inactivityModal').on('show.bs.modal', function () {
                clearInterval(_this.get('session.sessionTimeoutInterval'));
              });
              $('#inactivityModal').modal('show');
            }
          }
        }
      }, 1000);
    },
    redirect: function redirect(model, transition) {
      if (!this.get('errorhandler.didFail')) {
        var accessToken = localStorage.getItem('accessToken');
        var invalidAccessToken = !accessToken || accessToken === '' || accessToken === 'undefined';

        if (!invalidAccessToken) {
          this.navigation.setSelectedBE();
          var targetName = transition.targetName;

          if (targetName.indexOf('.') > -1) {
            this.controllerFor('application').set('selectedRoute', targetName.substring(0, targetName.indexOf('.')));
          } else {
            this.controllerFor('application').set('selectedRoute', targetName);

            if (targetName === 'index') {
              this.controllerFor('application').set('selectedRoute', 'dashboard'); //this condition is for handling the very first time when the application route loads
            }
          }

          if (targetName === 'dashboard.index' || targetName === 'index') {
            //added this check not to transit to component on page refresh
            this.transitionTo('dashboard');
          }
        }
      }
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('businessEntity', this.businessEntity);
    },
    actions: {
      BESelected: function BESelected(businessEntity) {
        if (this.get('session.selectedBusinessEntity.id') != businessEntity.id) {
          //added this check because when a BE is selected twice spinner is remaining on the screen
          this.navigation.selectBE(businessEntity);
        }
      },
      logout: function logout() {
        $('#progressHudMessage').html('Signing Out...');
        $('#progressHudOverlay').show();
        this.logout.logout();
      },
      refreshSession: function refreshSession() {
        this.refreshSession.refresh();
      },
      handleBusinessEntityChange: function handleBusinessEntityChange() {}
    }
  });

  _exports.default = _default;
  Ember.Route.reopen({
    routeError: false,
    beforeModel: function beforeModel(transition) {
      var navigation = this.navigation;
      var permKey = this.permKey;
      var permAction = this.permAction;
      var showChildrenAllowed = this.showChildrenAllowed;

      if (showChildrenAllowed) {
        navigation.set('showChildrenAllowed', showChildrenAllowed);
      } else {
        navigation.set('showChildrenAllowed', false);
      }

      if (permKey !== 'None' && permAction !== 'None') {
        if (permKey && permAction) {
          var targetName = transition.targetName;
          var routeName = targetName.substring(0, targetName.indexOf('.'));
          var selectedBusinessEntity = this.get('session.selectedBusinessEntity');

          if (selectedBusinessEntity) {
            navigation.set('permKey', permKey);
            navigation.set('permAction', permAction);

            if (navigation.checkPermissions(selectedBusinessEntity)) {
              this.set('routeError', false);
            } else {
              this.set('routeError', true);
              $('#progressHudOverlay').hide();
              this.transitionTo(routeName + '.error');
            }
          } else {
            this.set('routeError', true);
            $('#progressHudOverlay').hide();
            this.transitionTo(routeName + '.error');
          }
        }
      } else {
        $('#progressHudOverlay').hide();
      }
    },
    actions: {
      handleBusinessEntityChange: function handleBusinessEntityChange() {
        this.refresh();
        return true;
      }
    }
  });
});
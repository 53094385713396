define("in4m-web/models/component-profile", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    config: belongsTo('componentProfile/config', {
      async: false
    }),
    name: attr(),
    version: attr(),
    lastUpdatedAt: attr(),
    lastAppliedAt: attr(),
    createdAt: attr(),
    componentProfileAssociations: attr(),
    isDeviceOverrideEnabled: attr(),
    isApiInterfaceOverrideEnabled: attr(),
    parentBusinessEntity: belongsTo('businessEntity', {
      async: false
    }),
    parentBusinessEntityId: attr(),
    type: attr()
  });

  _exports.default = _default;
});
define("in4m-web/models/api-interface/simphony-api", ["exports", "ember-data", "in4m-web/models/api-interface"], function (_exports, _emberData, _apiInterface) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _apiInterface.default.extend({
    taskDesc: attr('', {
      defaultValue: 'Simphony'
    }),
    listenPort: attr('', {
      defaultValue: '5008'
    })
  });

  _exports.default = _default;
});
define("in4m-web/serializers/permission", ["exports", "in4m-web/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      applicationGroup: 'applicationGroupId',
      canView: 'view',
      canEdit: 'edit',
      isEnabled: 'enabled',
      canDelete: 'delete',
      canGrant: 'grant'
    }
  });

  _exports.default = _default;
});
define("in4m-web/components/s4-alpha-numeric", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend({
    keyPress: function keyPress(event) {
      // Firefox: 0 is needed for arrow keys and shift to highlight. 8 is for backspace.
      if (event.which === 0 || event.which === 8 || event.which >= 48 && event.which <= 57 || event.which >= 65 && event.which <= 90 || event.which >= 97 && event.which <= 122) {
        return;
      } else {
        event.preventDefault();
      }
    },
    paste: function paste(event) {
      var pastedText = event.originalEvent.clipboardData.getData('Text');
      var pattern = /^[A-Za-z0-9_]+$/; //regex pattern for alpha numeric which includes valid alphabets and numbers

      if (pattern.test(pastedText)) {
        return;
      } else {
        event.preventDefault();
      }
    }
  });

  _exports.default = _default;
});
define("in4m-web/services/api-path", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ssoUrl: null,
    fourTressUrl: null,
    vt4WebUrl: null,
    vt4MobileUrl: null,
    in4mUrl: null,
    coreApiUrl: null,
    coreSsoApiPath: null,
    coreSecurityApiPath: null,
    in4mApiUrl: null,
    in4mApiPath: null,
    xdomainSlaves: null,
    version: null,
    iOS: /(iPad|iPhone|iPod)/g.test(navigator.userAgent),
    referenceDocumentUrl: null,
    defaultUtgLocation: null,
    defaultAgentLocation: null,
    setAppConfiguration: function setAppConfiguration() {
      this.setProperties(window.config);
    }
  });

  _exports.default = _default;
});
define("in4m-web/helpers/schema-check-enum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.schemaCheckEnum = schemaCheckEnum;
  _exports.default = void 0;

  function schemaCheckEnum(params) {
    var schema = params[0];
    var field = params[1];
    var value = params[2];

    if (typeof schema == 'undefined' || typeof schema[field] == 'undefined' || typeof schema[field]['enum'] == 'undefined') {
      return true;
    } else {
      return schema[field]['enum'].includes(value);
    }
  }

  var _default = Ember.Helper.helper(schemaCheckEnum);

  _exports.default = _default;
});
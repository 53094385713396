define("in4m-web/components/s4-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['col-xs-12', 'col-sm-12', 'col-md-12', 'verifyPassword']
  });

  _exports.default = _default;
});
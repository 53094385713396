define("in4m-web/controllers/component-profiles/component-profile/api-interfaces/api-interface", ["exports", "in4m-web/utils/drop-down-list"], function (_exports, _dropDownList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    componentProfile: Ember.inject.controller('componentProfiles.componentProfile'),
    "connect": [true, false],
    "step": 1,
    "range": {
      "min": 1,
      "max": 4
    },
    "format": [{
      "displayName": "SSL 3.0",
      "value": 1
    }, {
      "displayName": "TLS 1.0",
      "value": 2
    }, {
      "displayName": "TLS 1.1",
      "value": 3
    }, {
      "displayName": "TLS 1.2",
      "value": 4
    }],
    dropdownListValues: Ember.computed('dropdownList', function () {
      return (0, _dropDownList.default)();
    })
  });

  _exports.default = _default;
});
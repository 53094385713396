define("in4m-web/serializers/device-status", ["exports", "ember-data", "in4m-web/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      component: 'componentId',
      parentBusinessEntityId: {
        key: 'businessEntityId'
      }
    },
    normalize: function normalize(modelClass, resourceHash) {
      resourceHash.id = resourceHash.componentId + '-' + resourceHash.machineId + '-' + resourceHash.deviceSerialNumber;
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});
define("in4m-web/helpers/remove-properties-from-model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.removePropertiesFromModel = removePropertiesFromModel;
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function removePropertiesFromModel(param1, param2) {
    if (param1 && param2) {
      //function that loops through recursively and removes the property anywhere in the json object
      var removeProps = function removeProps(obj) {
        for (var key in obj) {
          if (obj instanceof Array) {
            obj.forEach(function (item) {
              removeProps(item);
            });
          } else if (_typeof(obj) === 'object') {
            removeProps(obj[key]);
          }

          if (key === keyToRemove) {
            delete obj[key];
          }
        }
      };

      var obj = param1;
      var keyToRemove = param2; //function to remove properties that are not needed

      removeProps(obj);
      return obj;
    }
  }

  var _default = Ember.Helper.helper(removePropertiesFromModel);

  _exports.default = _default;
});
define("in4m-web/controllers/component-profiles/index", ["exports", "in4m-web/utils/default-profile-config", "in4m-web/helpers/cd-type-to-model-type", "in4m-web/helpers/remove-properties-from-model", "in4m-web/utils/alerts"], function (_exports, _defaultProfileConfig, _cdTypeToModelType, _removePropertiesFromModel, _alerts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Controller.extend({
    profileName: '',
    selectedProfile: '',
    userEnteredProfileName: '',
    isCloning: false,
    reactProfileListUrl: Ember.computed('session.selectedBusinessEntity', function () {
      return "/react/#/be/".concat(this.get('session.selectedBusinessEntity.id'), "/profiles");
    }),
    isCogHidden: Ember.computed('session.selectedBusinessEntity', function () {
      var hasPermissionToin4mUtgProfiles = true;
      var selectedBusinessEntity = this.get('session.selectedBusinessEntity');
      var user = this.get('session.userObject');
      hasPermissionToin4mUtgProfiles = user.hasPermission('canEdit', 'in4mUtgProfiles', selectedBusinessEntity);
      return !hasPermissionToin4mUtgProfiles;
    }),
    columns: function () {
      return [{
        "template": "custom/profileGear",
        "isHidden": this.get('isCogHidden')
      }, {
        "propertyName": "name",
        "title": "Profile Name",
        "routeName": "componentProfiles.componentProfile"
      }, {
        "propertyName": "parentBusinessEntity.name",
        "title": "Parent Account"
      }, {
        "propertyName": "version",
        "title": "Profile Version"
      }, {
        "template": "custom/profileCreatedAt",
        "title": "Creation Date",
        "propertyName": "createdAt"
      }, {
        "template": "custom/lastUpdated",
        "title": "Last Updated",
        "propertyName": "lastUpdatedAt"
      }];
    }.property('isCogHidden'),
    config: Ember.computed('defaultProfileConfig', function () {
      return (0, _defaultProfileConfig.default)().config;
    }),
    actions: {
      newProfile: function newProfile() {
        $('#newProfile').modal('show');
      },
      addProfile: function addProfile() {
        if ($('#applyProfile').valid()) {
          var _this = this;

          var profileName = _this.get('profileName');

          if (profileName.trim().length > 0) {
            var profileNameExists = _this.get('model').getEach('name').indexOf(profileName); //profileName does not exists in the local store. show a spinner and make api call


            if (profileNameExists === -1) {
              if (!_this.get('isCloning')) {
                //create a profile from the default config
                _this.send('createProfile', _this.get('config'));
              } else {
                var selectedProfile = this.get('model').findBy("id", this.get('selectedProfileId'));
                var selectedProfileConfig = selectedProfile.serialize().config;
                var selectedProfileConfigWithNoIds = (0, _removePropertiesFromModel.removePropertiesFromModel)(selectedProfileConfig, 'id');

                _this.send('createProfile', selectedProfileConfigWithNoIds);
              }
            } else {
              var validator = $('#applyProfile').validate();
              validator.showErrors({
                'profileName': profileName + ' is already assigned to a profile. Please specify a different name.'
              });
            }
          } else {
            var validator = $('#applyProfile').validate();
            validator.showErrors({
              'profileName': 'Profile Name cannot be empty.'
            });
          }
        }
      },
      //function to create a profile based on the config passed.
      createProfile: function createProfile(profileConfig) {
        var _this$store$createRec;

        var _this = this;

        $('#progressHudOverlay').show(); //apiInterfaceConfig hasMany relationship with polymorphic true

        var apiInterfaceConfig = _this.store.createRecord('componentProfile/config/apiInterfaceConfig', profileConfig.apiInterfaceConfig);

        var apiInterfaces = profileConfig.apiInterfaceConfig.apiInterface;
        apiInterfaces.forEach(function (apiInterface) {
          var modelName = 'api-interface/' + Ember.String.dasherize((0, _cdTypeToModelType.cdTypeToModelType)(apiInterface.cdType));

          var newApiInterface = _this.store.createRecord(modelName, {
            apiInterfaceConfig: apiInterfaceConfig,
            cdType: apiInterface.cdType
          });

          newApiInterface.setProperties(apiInterface);
          apiInterfaceConfig.get('apiInterfaces').addObject(newApiInterface);
        });

        var deviceConfig = _this.store.createRecord('componentProfile/config/deviceConfig', profileConfig.deviceConfig);

        var devices = profileConfig.deviceConfig.device;
        devices.forEach(function (device) {
          var componentDevice = _this.store.createRecord('componentDevice', {
            deviceConfig: deviceConfig,
            cdType: device.cdType
          });

          componentDevice.setProperties(device);
          deviceConfig.get('devices').addObject(componentDevice);
        }); //create a new profile from the default config json

        var componentProfile = _this.store.createRecord('componentProfile', {
          name: _this.get('profileName').trim(),
          type: 1,
          parentBusinessEntityId: _this.get('session.selectedBusinessEntity').get('id'),
          parentBusinessEntity: _this.get('session.selectedBusinessEntity'),
          config: _this.store.createRecord('componentProfile/config', {
            apiInterfaceConfig: apiInterfaceConfig,
            coreGlobal: profileConfig.coreGlobal,
            deviceConfig: deviceConfig,
            expressManager: profileConfig.expressManager,
            mailServer: _this.store.createRecord('componentProfile/config/mailServer', profileConfig.mailServer),
            utgGlobal: _this.store.createRecord('componentProfile/config/utgGlobal', (_this$store$createRec = {
              "4Go": _this.store.createRecord('componentProfile/config/utgGlobal/4go', {
                enabled: profileConfig.utgGlobal['4Go'].enabled,
                fauxGenerationMethod: profileConfig.utgGlobal['4Go'].fauxGenerationMethod,
                normalRetentionTimeMinutes: profileConfig.utgGlobal['4Go'].normalRetentionTimeMinutes,
                offlineRetentionTimeMinutes: profileConfig.utgGlobal['4Go'].offlineRetentionTimeMinutes,
                updates: profileConfig.utgGlobal['4Go'].updates,
                updateAddress: profileConfig.utgGlobal['4Go'].updateAddress,
                updatePort: profileConfig.utgGlobal['4Go'].updatePort,
                broadcastList: profileConfig.utgGlobal['4Go'].broadcastList
              }),
              offline: _this.store.createRecord('componentProfile/config/utgGlobal/offline', {
                enabled: profileConfig.utgGlobal['offline'].enabled,
                MaxInterfaceofflineHours: profileConfig.utgGlobal['offline'].MaxInterfaceofflineHours,
                standinEnabled: profileConfig.utgGlobal['offline'].standinEnabled,
                floor: profileConfig.utgGlobal['offline'].floor,
                returnVoidErrorIfOffline: profileConfig.utgGlobal['offline'].returnVoidErrorIfOffline,
                declineAllNonBankCards: profileConfig.utgGlobal['offline'].declineAllNonBankCards,
                transactionStoragePeriod: profileConfig.utgGlobal['offline'].transactionStoragePeriod,
                maxUploadAttempts: profileConfig.utgGlobal['offline'].maxUploadAttempts
              }),
              cloudTimeoutOverride: profileConfig.utgGlobal.cloudTimeoutOverride,
              convertErrorsToReferrals: profileConfig.utgGlobal.convertErrorsToReferrals,
              corruptDataMarkTrackDataFix1: profileConfig.utgGlobal.corruptDataMarkTrackDataFix1,
              lowDiskSpaceThresholdMB: profileConfig.utgGlobal.lowDiskSpaceThresholdMB,
              maxCircuitElements: profileConfig.utgGlobal.maxCircuitElements,
              maxThreads: profileConfig.utgGlobal.maxThreads
            }, _defineProperty(_this$store$createRec, "offline", profileConfig.utgGlobal.offline), _defineProperty(_this$store$createRec, "sendAlertMessagesToShift4", profileConfig.utgGlobal.sendAlertMessagesToShift4), _defineProperty(_this$store$createRec, "signature", profileConfig.utgGlobal.signature), _defineProperty(_this$store$createRec, "transactionCacheEnabled", profileConfig.utgGlobal.transactionCacheEnabled), _defineProperty(_this$store$createRec, "utgId", profileConfig.utgGlobal.utgId), _defineProperty(_this$store$createRec, "workerThreads", profileConfig.utgGlobal.workerThreads), _defineProperty(_this$store$createRec, "maxTransmits", profileConfig.utgGlobal.maxTransmits), _defineProperty(_this$store$createRec, "pinPadPushSlotCount", profileConfig.utgGlobal.pinPadPushSlotCount), _defineProperty(_this$store$createRec, "importManager", profileConfig.utgGlobal.importManager), _defineProperty(_this$store$createRec, "cdType", profileConfig.utgGlobal.cdType), _this$store$createRec)),
            webServer: profileConfig.webServer,
            microsBridge: profileConfig.microsBridge
          })
        });

        componentProfile.save().then(function (componentProfile) {
          //this is for temporarily adding the row to the table to make sure the table gets updated since getComponentProfiles is a query request instead of findAll
          _this.get('model').get('content').pushObject(componentProfile.get('_internalModel'));

          if (_this.get('isCloning')) {
            _alerts.default._success(_this.get('profileName') + ' has been successfully created as a clone of ' + _this.get('selectedProfile.name') + '.', 'Profile Cloned');
          } else {
            _alerts.default._success(_this.get('profileName') + ' has been successfully created.', 'Profile Added');
          }

          _this.send('cancelAdd');

          _this.transitionToRoute('componentProfiles.componentProfile', componentProfile.get('id'));
        }, function (error) {
          $('#progressHudOverlay').hide();

          _this.get('errorhandler').handleError(error, 'Profile Application Failed.');
        });
      },
      cancelAdd: function cancelAdd() {
        var _this = this;

        $('#newProfile').modal('hide');
        $('#cloneProfileModal').modal('hide');
        $('#progressHudOverlay').hide();

        _this.set('profileName', '');

        _this.set('selectedTemplate', 'Default');

        _this.set('selectedProfileId', '');

        _this.set('isCloning', false);

        $("#applyProfile").data('validator').resetForm();
      },
      showDeleteProfileWarningModal: function showDeleteProfileWarningModal(record) {
        this.set('selectedProfile', record);
        $('#deleteProfileWarningModal').modal('show');
      },
      showCloneProfileModal: function showCloneProfileModal(record) {
        this.set('selectedProfile', record);
        $('#cloneProfileModal').modal('show');
      },
      dismissDeleteProfileModal: function dismissDeleteProfileModal() {
        $('#deleteProfileWarningModal').modal('hide');
        $('#deleteProfileConfirmationModal').modal('hide');

        if ($("#deleteProfileForm2").data('validator')) {
          $("#deleteProfileForm2").data('validator').resetForm();
        }

        this.set('userEnteredProfileName', '');
      },
      continueToDeleteProfile: function continueToDeleteProfile() {
        this.send('dismissDeleteProfileModal');
        $('#deleteProfileConfirmationModal').modal('show');
      },
      deleteProfile: function deleteProfile() {
        var _this = this;

        var record = this.get('selectedProfile');

        if (record) {
          var selectedProfileName = record.get('name');

          if (selectedProfileName !== this.get('userEnteredProfileName')) {
            var validator = $('#deleteProfileForm2').validate();
            validator.showErrors({
              'userEnteredProfileName': 'Profile name was not entered correctly.'
            });
          } else {
            $('#progressHudOverlay').show();
            record.destroyRecord().then(function () {
              // $('#progressHudOverlay').hide();
              _alerts.default._success(selectedProfileName + ' has been successfully deleted.', 'Profile Deleted');

              _this.get('target.targetState.router').refresh(); //adding this fix temporarily - need to remove in later release when #5006 is resloved in ember data github repo

            }, function (error) {
              $('#progressHudOverlay').hide();

              _this.get('errorhandler').handleError(error, 'Profile Deletion Failed.');
            });

            _this.send('dismissDeleteProfileModal');
          }
        }
      },
      cloneProfile: function cloneProfile() {
        if ($('#cloneProfileForm').valid()) {
          var _this = this;

          var cloneProfileName = _this.get('profileName');

          if (cloneProfileName.trim().length > 0) {
            var profileNameExists = _this.get('model').getEach('name').indexOf(cloneProfileName); //profileName does not exists in the local store. show a spinner and make api call


            if (profileNameExists === -1) {
              var selectedProfileConfig = this.get('selectedProfile').serialize().config;
              var selectedProfileConfigWithNoIds = (0, _removePropertiesFromModel.removePropertiesFromModel)(selectedProfileConfig, 'id');

              _this.set('isCloning', true);

              _this.send('createProfile', selectedProfileConfigWithNoIds);
            } else {
              var validator = $('#cloneProfileForm').validate();
              validator.showErrors({
                'profileName': cloneProfileName + ' is already assigned to a profile. Please specify a different name.'
              });
            }
          } else {
            var validator = $('#cloneProfileForm').validate();
            validator.showErrors({
              'profileName': 'Profile Name cannot be empty.'
            });
          }
        }
      },
      //reset the cloneProfileName so that user dont see the last entered name when modal opens
      cancelCloneProfile: function cancelCloneProfile() {
        $('#cloneProfileModal').modal('hide');
        this.set('profileName', '');
        this.set('isCloning', false);
        $("#cloneProfileForm").data('validator').resetForm();
      },
      componentProfilesInfo: function componentProfilesInfo() {
        $('#componentProfilesInfoModal').modal('show');
      },
      showComponentProfilesInfoDoc: function showComponentProfilesInfoDoc() {
        var _this = this;

        window.open(_this.get('apiPath.referenceDocumentUrl'), '_blank');
      }
    }
  });

  _exports.default = _default;
});
define("in4m-web/templates/custom/display-seconds-in-days", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cpQOLF/y",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"seconds-to-days\",[[25,[\"record\",\"machineStatus\",\"uptime\"]]],null],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/custom/display-seconds-in-days.hbs"
    }
  });

  _exports.default = _default;
});
define("in4m-web/serializers/component-device-schema", ["exports", "in4m-web/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.response) {
        delete payload.response;
      }

      payload.componentDeviceSchemas = payload.componentDevice;
      delete payload.componentDevice;
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});
define("in4m-web/controllers/component/manage/telemetry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isEditDisabled: Ember.computed('model', function () {
      var selectedBusinessEntity = this.get('session.selectedBusinessEntity');
      var user = this.get('session.userObject');
      var hasPermissionToEditAPIInterfaces = user.hasPermission('canEdit', 'in4mUtgTelemetry', selectedBusinessEntity);
      return !hasPermissionToEditAPIInterfaces;
    })
  });

  _exports.default = _default;
});
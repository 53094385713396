define("in4m-web/components/expandable-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['disabled:disabled', 'isComplexType:col-md-12:col-md-6', 'isComplexType::col-md-offset-4'],
    disabled: false,
    isComplexType: Ember.computed('property.s4-complex-array', function () {
      if (this.get('property.s4-complex-array')) {
        return true;
      }

      return false;
    }),
    actions: {
      addItem: function addItem(propertyName) {
        var items = this.model.get(propertyName);

        if (!items) {
          //for new models when an api interface is created
          this.model.set(propertyName, []);
          items = this.model.get(propertyName);
        }

        if (this.get('model.schema.properties')[propertyName].items.type === 'object') {
          //for complex objects
          var tempJson = {};

          for (var propKey in this.get('model.schema.properties')[propertyName].items.properties) {
            tempJson[propKey] = '';
          }

          items.pushObject(Ember.Object.create({
            key: tempJson
          }));
        } else {
          //for simple objects eg: mail server tab
          items.pushObject(Ember.Object.create({
            key: ''
          }));
        }
      },
      removeItem: function removeItem(propertyName, index) {
        var items = this.model.get(propertyName);
        items.removeAt(index);
      }
    }
  });

  _exports.default = _default;
});
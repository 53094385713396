define("in4m-web/services/utg-component-config-retrieve-model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    modelWithTab: function modelWithTab(componentConfig, tab) {
      var model = Ember.Object.create();

      if (componentConfig) {
        var getTabProperties = function getTabProperties(properties, parentKey) {
          for (var key in properties) {
            if (properties[key].type == 'object') {
              getTabProperties(properties[key].properties, key);
            } else {
              if (properties[key]['s4-tab-id'] === tab) {
                _tabProperties[key.camelize()] = properties[key];

                if (parentKey) {
                  var parentKeyModified;

                  if (parentKey[0] === '_') {
                    //handling underscores
                    parentKeyModified = parentKey.slice(1);
                  } else {
                    parentKeyModified = parentKey;
                  }

                  _tabProperties[key.camelize()].parentKey = parentKeyModified;
                }
              }
            }
          }
        };

        var utgGlobal = componentConfig.get('config').get('utgGlobal');
        var coreGlobal = componentConfig.get('config').get('coreGlobal'); // //filtering s4-groups related to the specific tab

        var utgGlobalGroups = utgGlobal.schema['s4-groups'].filterBy('tab', tab);
        var coreGlobalGroups = coreGlobal.schema['s4-groups'].filterBy('tab', tab);

        var _tabGroups = utgGlobalGroups.concat(coreGlobalGroups);

        var _tabschema = {};
        var _tabProperties = {};
        getTabProperties(utgGlobal.schema.properties); //default parent is added so that while checking 

        getTabProperties(coreGlobal.schema.properties);
        _tabschema['s4-groups'] = _tabGroups;
        _tabschema.properties = _tabProperties;
        return _tabschema;
      }

      return model;
    }
  });

  _exports.default = _default;
});
define("in4m-web/controllers/component-device-forms/index", ["exports", "in4m-web/utils/alerts", "ember-cli-file-saver/mixins/file-saver", "ember-concurrency"], function (_exports, _alerts, _fileSaver, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _Ember = Ember,
      get = _Ember.get,
      set = _Ember.set;

  var _default = Ember.Controller.extend(_fileSaver.default, {
    userEnteredFormName: '',
    selectedDeviceType: '',
    ajax: Ember.inject.service(),
    selectedForm: '',
    deviceTypes: '',
    diskFileName: '',
    uploader: Ember.inject.service('file-queue'),
    isCogHidden: Ember.computed('session.selectedBusinessEntity', function () {
      var hasPermissionToViewCog = true;
      var selectedBusinessEntity = this.get('session.selectedBusinessEntity');
      var user = this.get('session.userObject');
      return !user.hasPermission('canEdit', 'in4mDeviceForms', selectedBusinessEntity);
    }),
    disableUpload: true,
    columns: function () {
      return [{
        "template": "custom/formGear",
        "isHidden": this.isCogHidden
      }, {
        "propertyName": "name",
        "title": "Name"
      }, {
        // "template":"custom/deviceType",
        "propertyName": "deviceType",
        "title": "Device Type"
      }, {
        "template": "custom/lastUpdated",
        "propertyName": "lastUpdatedAt",
        "title": "Upload Date",
        "sortedBy": "lastUpdatedAt",
        "sortDirection": "desc",
        "sortPrecedence": "1"
      }];
    }.property('isCogHidden'),
    getDeviceTypes: function () {
      var _this = this;

      return this.store.findAll('componentDeviceSchema').then(function (deviceTypes) {
        _this.set('deviceTypes', deviceTypes);
      });
    }.observes('model'),
    actions: {
      showDeleteModal: function showDeleteModal(record) {
        this.set('selectedForm', record);
        $('#deleteFormModal').modal('show');
      },
      dismissDeleteModal: function dismissDeleteModal(record) {
        $('#deleteFormModal').modal('hide');
      },
      deleteRecord: function deleteRecord() {
        var record = this.get('selectedForm');
        var selectedFormName = record.get('name');

        var _this = this;

        if (record) {
          $('#progressHudOverlay').show();
          record.destroyRecord().then(function () {
            _this.get('model').removeObject(record);

            $('#progressHudOverlay').hide();

            _alerts.default._success(selectedFormName + ' was deleted successfully.', 'Form Deleted');
          }, function (error) {
            $('#progressHudOverlay').hide();

            _alerts.default._error('The deletion of the form was unsuccessful, please verify you have permission to delete the form with your account administrator.');
          });
        }

        $('#deleteFormModal').modal('hide');
      },
      downloadForm: function downloadForm(record) {
        $('#progressHudMessage').html('Downloading...');
        $('#progressHudOverlay').show();

        var _this = this;

        var apiPath = this.get('apiPath');
        var accessToken = 'Token token="' + this.get('session.sessionObject.accessToken') + '"';
        var downloadUrl = apiPath.in4mApiUrl + '/' + apiPath.in4mApiPath + '/businessentities/' + record.get('parentBusinessEntityId') + '/componentdeviceforms/' + record.get('id').toString().split("-")[1] + '/forms.tgz';
        var filename = record.get('name');
        this.get('ajax').request(downloadUrl, {
          method: 'GET',
          dataType: 'arraybuffer',
          // or 'blob'
          processData: false,
          headers: {
            Authorization: accessToken
          }
        }).then(function (content) {
          var saveFileNameAs = "";

          if (filename.indexOf('.tgz') == -1) {
            saveFileNameAs = filename + '.tgz';
          } else {
            saveFileNameAs = filename;
          }

          _this.saveFileAs(saveFileNameAs, content, 'application/x-gzip');

          $('#progressHudOverlay').hide();
        });
      },
      showUploadFormModal: function showUploadFormModal() {
        $('#uploadFormModal').modal('show');
      },
      closeUploadFormModal: function closeUploadFormModal() {
        this.set('userEnteredFormName', '');
        this.set('selectedDeviceType', '');
        this.set('fileToUpload', '');
        this.set('diskFileName', '');
        var queue = this.get('uploader').find("forms");
        get(queue, 'files').forEach(function (file) {
          return set(file, 'queue', null);
        });
        set(queue, 'files', Ember.A());
        $('#uploadFormModal').modal('hide');
        $("#uploadForm").data('validator').resetForm(); //resetting the form to remove error classes if any on modal close

        this.set('disableUpload', true);
      },
      selectForm: function selectForm(file) {
        this.set('fileToUpload', file);
        this.set('diskFileName', file.name);
        this.set('disableUpload', false);
      },
      uploadForm: function uploadForm() {
        if ($('form').valid()) {
          $('#progressHudMessage').html('Uploading...');
          $('#progressHudOverlay').show();
          var file = this.get('fileToUpload');

          var _this = this;

          var form = this.store.createRecord('componentDeviceForm', {
            parentBusinessEntityId: _this.get('session.selectedBusinessEntity').get('id'),
            parentBusinessEntity: _this.get('session.selectedBusinessEntity'),
            description: "",
            deviceType: _this.get('selectedDeviceType'),
            name: _this.get('userEnteredFormName')
          });
          var apiPath = this.get('apiPath');
          var accessToken = 'Token token="' + this.get('session.sessionObject.accessToken') + '"';
          form.save().then(function (formData) {
            //this is for temporarily adding the row to the table to make sure the table gets updated since getComponentProfiles is a query request instead of findAll
            _this.get('model').get('content').pushObject(formData.get('_internalModel'));

            var uploadUrl = apiPath.in4mApiUrl + '/' + apiPath.in4mApiPath + '/businessentities/' + formData.get('parentBusinessEntityId') + '/componentdeviceforms/' + formData.get('id').toString().split("-")[1] + '/' + file.name;
            var headers = {
              'Authorization': accessToken
            };
            var settings = {
              'headers': headers
            };
            return file.upload(uploadUrl, settings).then(function (response) {
              _alerts.default._success(file.name + ' has successfully been uploaded.', 'Form Uploaded');

              _this.send('closeUploadFormModal');

              $('#progressHudOverlay').hide();
            }, function (err) {
              _alerts.default._error('Form ' + file.name + ' upload was unsuccessful.');

              _this.send('closeUploadFormModal');

              $('#progressHudOverlay').hide();
            });
          });
        }
      }
    }
  });

  _exports.default = _default;
});
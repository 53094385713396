define("in4m-web/models/component", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    parentBusinessEntity: belongsTo('businessEntity'),
    connectionString: attr('string'),
    currentTaskHash: attr('string'),
    downloadLocation: attr(),
    hasConfigChanges: attr('boolean'),
    lastTaskHash: attr('string'),
    lastUpdatedAt: attr('string'),
    lastUpdatedBy: attr('number'),
    localConfigRevisionId: attr('number'),
    remoteConfigRevisionId: attr('number'),
    machine: attr(),
    machineId: attr('number'),
    metadata: attr('string'),
    operatingSystem: attr('string'),
    permanentId: attr('string'),
    profile: belongsTo('componentProfile'),
    profileBusinessEntityId: attr(),
    profileVersion: attr(),
    serverName: attr('string'),
    softwareVersion: attr('string'),
    nickname: attr('string'),
    tasks: hasMany('task'),
    type: attr('number'),
    runInstall: attr('boolean'),
    updates: attr('string'),
    installLocation: attr('string'),
    componentStatus: belongsTo('componentStatus'),
    in4mListenerPort: attr('number'),
    //all computed properties
    serviceType: Ember.computed('type', function () {
      if (this.type === 1) {
        return "UTG";
      } else if (this.type === 2) {
        return "Engine";
      } else {
        return "Unknown";
      }
    }),
    softwareVersionFromAws: Ember.computed('componentStatus.metadata', function () {
      var versionFromAws = this.get('componentStatus.metadata.version');

      if (versionFromAws) {
        return versionFromAws;
      } else {
        return this.softwareVersion;
      }
    }),
    scheduledTasks: Ember.computed('tasks', function () {
      var tasks = this.tasks;
      var numberOfScheduledTasks = 0;
      tasks.forEach(function (task) {
        //in c#, see TaskStatus.cs to see the Enum for these values (negative values have special meaning, thus can't use a simple less-than here)
        if (task.get('status') === 0 //requested
        || task.get('status') === 5 //accepted
        || task.get('status') === 10 //planned
        || task.get('status') === 15) //scheduled
          {
            numberOfScheduledTasks += 1;
          }
      });
      return numberOfScheduledTasks;
    }),
    failedToCheckIn: Ember.computed('componentStatus.machineStatus.nextPredictedUpdateAt', function () {
      var today = new Date();
      var nextPredictedDate = new Date(this.get('componentStatus.machineStatus.nextPredictedUpdateAt'));

      if (nextPredictedDate.getTime() < today.getTime()) {
        //less than today will always be accurate, rather than comparing to last communication. However, browser timezone could affect this logic. Eventually, we will want to investigate this further.
        return true;
      }

      return false;
    }),
    status: Ember.computed('componentStatus.status', function () {
      var status = this.get('componentStatus.status');

      if (this.failedToCheckIn) {
        return "Failed to check-in";
      }

      switch (status === null || status === void 0 ? void 0 : status.toString()) {
        case '0':
          return "Unknown";

        case '1':
          return "Running";

        case '2':
          return "Online";

        case '4':
          return "Offline";

        case '128':
          return "Stopped";

        case '32768':
          return "Failed";
      }

      return 'Unknown';
    }),
    machineStatus: Ember.computed('componentStatus.machineStatus.status', function () {
      if (this.failedToCheckIn) {
        return 'Failed to check in'; //if this machine failed to check-in, we can't really say it's running. So, this is the first field we'll check.
      }

      var status = this.get('componentStatus.machineStatus.status');

      if (status) {
        if (status.toString() != '1') {
          return 'Not Registered';
        } else {
          return 'Running';
        }
      }

      return 'Unknown';
    }),
    nextPredictedUpdateAt: Ember.computed('componentStatus.machineStatus.nextPredictedUpdateAt', function () {
      return this.get('componentStatus.machineStatus.nextPredictedUpdateAt');
    }),
    lastMachineStatusUpdatedAt: Ember.computed('componentStatus.machineStatus.lastUpdatedAt', function () {
      return this.get('componentStatus.machineStatus.lastUpdatedAt');
    }),
    isDuplicate: Ember.computed('componentStatus.machineStatus.isDuplicate', function () {
      return this.get('componentStatus.machineStatus.isDuplicate');
    }),
    parentName: Ember.computed('parentBusinessEntity', function () {
      return this.get('parentBusinessEntity.name');
    }),
    configVersion: Ember.computed('componentStatus.localConfigRevisionId', 'localConfigRevisionId', 'componentStatus.remoteConfigRevisionId', 'remoteConfigRevisionId', 'hasConfigChanges', function () {
      var configVersion = {};

      if (this.hasConfigChanges) {
        configVersion.status = "Uncommitted Changes";
        configVersion.class = "fa fa-exclamation-circle ucc";
        configVersion.popover = "The UTG Configuration in Shift4 Command Center is newer than what is currently saved on the UTG locally. Please schedule a task to update the UTG's local configuration to the latest Shift4 Command Center configuration.";
      } else {
        var localMachineRevisionId = this.get('componentStatus.localConfigRevisionId');
        var localServerRevisionId = this.localConfigRevisionId;
        var remoteMachineRevisionId = this.get('componentStatus.remoteConfigRevisionId');
        var remoteServerRevisionId = this.remoteConfigRevisionId;

        if (localMachineRevisionId > localServerRevisionId) {
          configVersion.status = "Locally modified";
          configVersion.class = "fa fa-exclamation-triangle lm";
          configVersion.popover = "This UTG's configuration was modified locally. Please schedule a task to update the Shift4 Command Center configuration version with the UTG's local configuration, or update the UTG with Shift4 Command Center's current configuration.";
        } else if (localMachineRevisionId === localServerRevisionId) {
          if (remoteMachineRevisionId > remoteServerRevisionId) {
            configVersion.status = "Locally newer";
            configVersion.class = "fa fa-exclamation-triangle lm";
            configVersion.popover = "UTG and Shift4 Command Center out of sync. Please schedule the \"Update Shift4 Command Center with UTG's local configuration\" task. If this issue persists, please contact Customer Support at 1 (702) 597-2480.";
          } else if (remoteMachineRevisionId < remoteServerRevisionId) {
            configVersion.status = "Pending";
            configVersion.class = "fa fa-clock-o pd";
            configVersion.popover = "A task has been scheduled to update the UTG configuration version.";
          } else if (remoteMachineRevisionId === remoteServerRevisionId) {
            configVersion.status = "Up to date";
            configVersion.class = "fa fa-check-circle-o upd";
            configVersion.popover = "";
          }
        } else if (localMachineRevisionId < localServerRevisionId) {
          if (remoteMachineRevisionId > remoteServerRevisionId) {
            configVersion.status = "Locally newer";
            configVersion.class = "fa fa-exclamation-triangle lm";
            configVersion.popover = "UTG and Shift4 Command Center out of sync. Please schedule the \"Update Shift4 Command Center with UTG's local configuration\" task. If this issue persists, please contact Customer Support at 1 (702) 597-2480.";
          } else if (remoteMachineRevisionId < remoteServerRevisionId || remoteMachineRevisionId === remoteServerRevisionId) {
            configVersion.status = "Pending";
            configVersion.class = "fa fa-clock-o pd";
            configVersion.popover = "A task has been scheduled to update the UTG configuration version.";
          }
        }
      }

      return configVersion;
    }),
    merchant: attr(),
    location: attr(),
    lane: attr(),
    fileId: attr(),
    componentSerialSlotAssignmentId: attr('number', {
      defaultValue: 0
    }),
    isAgentVersionLatest: Ember.computed('machine', function () {
      var _this = this;

      var currentAgentVersion = _this.get('machine.agentVersion');

      if (!currentAgentVersion) {
        return false;
      } else {
        var agentManifestLocation = _this.get('apiPath.agentManifestLocation');

        var latestAgentVersion = _this.get('session.latestAgentVersion');

        if (currentAgentVersion < latestAgentVersion) {
          return false;
        } else {
          return true;
        }
      }
    })
  });

  _exports.default = _default;
});
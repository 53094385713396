define("in4m-web/utils/validators", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validators;

  function validators() {
    $.validator.setDefaults({
      ignore: false,
      onsubmit: false,
      highlight: function highlight(element) {
        // support no form-group found
        // check where the form-group is at
        if ($(element).parent().hasClass('form-group')) {
          // input field with no label
          $(element).parent().addClass('has-error');
        } else if ($(element).parent().parent().hasClass('form-group')) {
          // input field with label
          $(element).parent().parent().addClass('has-error');
        } else if ($(element)[0].type == "checkbox") {
          $(element).closest('.checkbox').parent().addClass('has-error');
        } else {
          // edge case where no form-group is used and we don't want to highlight everything
          $(element).parent().addClass('has-error');
        } // check if the element is visible and if it is within a collapsible div


        if (!$(element).is(":visible") && $(element).closest('.collapse').length) {
          if ($('[data-target=#' + $(element).closest('.collapse')[0].id + ']').length) {
            // try to find the data-target for the collapsible and trigger the click
            $('[data-target=#' + $(element).closest('.collapse')[0].id + ']')[0].click();
          } else {
            // toggle it
            $(element).closest('.collapse').collapse('toggle');
          }
        }
      },
      unhighlight: function unhighlight(element) {
        var fieldsValid = true,
            fields = $('.form-control', $(element).closest('.form-group')).toArray();

        if (fields && fields.length > 1) {
          fields.some(function (fieldEl) {
            var $fieldEl = $(fieldEl);

            if (!$fieldEl.validate().check($fieldEl)) {
              fieldsValid = false;
              return true;
            }
          });
        }

        if (fieldsValid) {
          // check where the form-group is at
          if ($(element).parent().hasClass('form-group')) {
            // input field with no label
            $(element).parent().removeClass('has-error');
          } else if ($(element).parent().parent().hasClass('form-group')) {
            // input field with label
            $(element).parent().parent().removeClass('has-error');
          } else if ($(element)[0].type == "checkbox") {
            $(element).closest('.checkbox').parent().removeClass('has-error');
          } // edge case where no form-group is used and we don't want to highlight everything


          $(element).parent().removeClass('has-error');
        }
      },
      errorElement: 'span',
      errorClass: 'help-block',
      errorPlacement: function errorPlacement(error, element) {
        if (element.parent('.input-group').length || element.parent('.error-group').length) {
          error.insertAfter(element.parent());
        } else if ($(element)[0].type == "checkbox") {
          error.insertAfter(element.closest('.checkbox'));
        } else {
          error.insertAfter(element);
        }
      }
    });
    $.validator.addMethod("ipaddress", function (value) {
      var ipvalidateregex = "^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$";
      return value.match(ipvalidateregex);
    }, "Please input a valid IP address.");
    $.validator.addMethod("greaterThan", function (value, element) {
      return Number($(element).val()) > Number($('#' + $(element).data('greaterthan')).val());
    });
    $.validator.addMethod("greaterThanOrEqualTo", function (value, element) {
      return Number($(element).val()) >= Number($('#' + $(element).data('greaterthan')).val());
    });
    $.validator.addMethod("lessThan", function (value, element) {
      return Number($(element).val()) < Number($('#' + $(element).data('lessthan')).val());
    });
    $.validator.addMethod("lessThanOrEqualTo", function (value, element) {
      return Number($(element).val()) <= Number($('#' + $(element).data('lessthan')).val());
    }); //add require from group method

    jQuery.validator.addMethod("requireFromGroup", function (value, element) {
      var requiredClass = $(element).data('requiredClass');
      var $fields = $($('[data-required-class=' + requiredClass + ']'), element.form);
      var $fieldsFirst = $fields.eq(0);
      var validator = $fieldsFirst.data("valid_req_grp") ? $fieldsFirst.data("valid_req_grp") : $.extend({}, this);
      var isValid = $fields.filter(function () {
        return validator.elementValue(this) || validator.elementValue(this) == "on";
      }).length >= $(element).data('requiredCount'); // Store the cloned validator for future validation

      $fieldsFirst.data("valid_req_grp", validator); // If element isn't being validated, run each require_from_group field's validation rules

      if (!$(element).data("being_validated")) {
        $fields.data("being_validated", true);
        $fields.each(function () {
          validator.element(this);
        });
        $fields.data("being_validated", false);
      }

      return isValid;
    });
    $.validator.addClassRules("ipaddress", {
      ipaddress: true
    });
    $.validator.addClassRules("greaterThan", {
      greaterThan: true
    });
    $.validator.addClassRules("lessThan", {
      lessThan: true
    });
    $.validator.addClassRules("greaterThanOrEqualTo", {
      greaterThanOrEqualTo: true
    });
    $.validator.addClassRules("lessThanOrEqualTo", {
      lessThanOrEqualTo: true
    });
    $.validator.addClassRules("requireFromGroup", {
      requireFromGroup: true
    });
  }
});
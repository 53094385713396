define("in4m-web/routes/component/manage/telemetry", ["exports", "in4m-web/helpers/check-for-dirty-attributes", "in4m-web/helpers/roll-back-relationship-attributes"], function (_exports, _checkForDirtyAttributes, _rollBackRelationshipAttributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var componentConfig = this.modelFor('component.manage');
      var telemetry = componentConfig.get('config.coreGlobal.telemetry');
      telemetry.set('schema', this.utgComponentConfigRetrieveModel.modelWithTab(componentConfig, 'telemetry'));
      return telemetry;
    },
    actions: {
      didTransition: function didTransition() {
        this.navigation.set('allowTransition', false);
      },
      willTransition: function willTransition(transition) {
        if ((0, _checkForDirtyAttributes.checkForDirtyAttributes)(this.model().get('utgGlobal')) || (0, _checkForDirtyAttributes.checkForDirtyAttributes)(this.model().get('coreGlobal'))) {
          //custom dirty check since browser is converting integer to strings for input tagas and ember is checking for '===' instead of '=='
          $('#unsavedChangesModal').modal('show');
          transition.abort();
          this.set('transition', transition);
        } else {
          this.navigation.set('allowTransition', true);

          if (this.get('navigation.selectedBusinessEntity')) {
            this.session.set('selectedBusinessEntity', this.get('navigation.selectedBusinessEntity')); //setting the session's businessEntity to the selected businessEntity
          }
        }
      },
      allowTransition: function allowTransition(isAllowed) {
        $('#unsavedChangesModal').modal('hide');

        if (isAllowed) {
          if (this.transition) {
            this.model().get('utgGlobal').rollbackAttributes();
            this.model().get('coreGlobal').rollbackAttributes();
            (0, _rollBackRelationshipAttributes.rollBackRelationshipAttributes)(this.model().get('utgGlobal'));
            (0, _rollBackRelationshipAttributes.rollBackRelationshipAttributes)(this.model().get('coreGlobal'));
            this.navigation.set('allowTransition', true); //allow transition need to set to the original value since it is checking in the navigation service

            if (this.get('navigation.selectedBusinessEntity')) {
              this.session.set('selectedBusinessEntity', this.get('navigation.selectedBusinessEntity')); //setting the session's businessEntity to the selected businessEntity
            }

            this.transition.retry();
          }
        } else {
          $('#' + this.get('session.selectedBusinessEntity.id')).addClass('jstree-clicked'); //manually adding and removing css

          $('#' + this.get('navigation.selectedBusinessEntity.id')).removeClass('jstree-clicked');
          this.navigation.set('selectedBusinessEntity', null); //to make sure to reset the navigation's selected business entity to null so that session's selected business entity won't change

          this.transitionTo('component.manage.telemetry');
        }
      }
    }
  });

  _exports.default = _default;
});
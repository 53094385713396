define("in4m-web/components/time-input", ["exports", "in4m-web/templates/components/time-input"], function (_exports, _timeInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _timeInput.default,
    step: '',
    valueString: Ember.computed('value', function () {
      if (this.value) {
        var times = this.value.match(/.{1,2}/g); //step=1 determines time format needed in hhmmss (per html5 standard)

        if (this.step === 1) {
          return times[0] + ':' + times[1] + ':' + times[2];
        } else {
          return times[0] + ':' + times[1];
        }
      }
    }),
    actions: {
      valueChanged: function valueChanged(valueString) {
        if (valueString) {
          var times = valueString.split(':');
          var updatedTimeFormat;
          var hours = times[0] || '00';
          var minutes = times[1] || '00';

          if (this.step === 1) {
            var seconds = times[2] || '00';
            updatedTimeFormat = hours + minutes + seconds;
          } else {
            updatedTimeFormat = hours + minutes;
          }

          this.set('value', updatedTimeFormat);
        }
      }
    }
  });

  _exports.default = _default;
});
define("in4m-web/helpers/caption-for-api-interface-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.captionForApiInterfaceType = captionForApiInterfaceType;
  _exports.default = void 0;

  function captionForApiInterfaceType(params) {
    var cdType; // makes helper able to be used as a general javascript util and in a handlebars template

    if (Ember.isArray(params)) {
      cdType = params[0];
    } else {
      cdType = params;
    } // used for getting display name for api-interfaces


    var caption = '';

    switch (cdType.toString()) {
      case '30':
        caption = 'TCP/IP'; //1

        break;

      case '110':
        caption = 'TCP/IP SSL'; //3

        break;

      case '109':
        caption = 'UTG4Cloud'; //6

        break;

      case '137':
        caption = 'UTG4Cloud SSL'; //7

        break;

      case '111':
        caption = 'HTTP'; //2

        break;

      case '112':
        caption = 'HTTP SSL'; //4

        break;

      case '154':
        caption = 'REST'; //5

        break;

      case '114':
        caption = 'Micros API';
        break;

      case '115':
        caption = 'Simphony API';
        break;

      case '116':
        caption = 'Serial API - ASCII';
        break;

      case '117':
        caption = 'Serial API - Binary';
        break;

      case '120':
        caption = 'File API';
        break;

      case '148':
        caption = 'Oracle Payment Interface (OPI)'; //8

        break;

      case '158':
        caption = 'Opera'; //8

        break;

      case '121':
        caption = 'Tween API';
        break;
    }

    return caption;
  }

  var _default = Ember.Helper.helper(captionForApiInterfaceType);

  _exports.default = _default;
});
define("in4m-web/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    selectedRoute: 'dashboard',
    selectedBusinessEntityDidChange: function () {
      if (this.currentRouteName) {
        //to not trigger handleBusinessEntityChange before the route is loaded 
        if (this.session.selectedBusinessEntity.id) {
          this.send('handleBusinessEntityChange');
        }
      }
    }.observes('session.selectedBusinessEntity'),
    modelWatch: function () {
      var _this = this;

      if (this.get('model.error') == true) {
        $('#progressHudOverlay').hide();
        this.transitionToRoute('index');
        Ember.run.next(function () {
          $('#services-unavailable').modal().toggle();
          $('#services-unavailable').on('hide.bs.modal', function () {
            _this.send('logout');
          });
        });
      }
    }.observes('model'),
    currentYear: Ember.computed('moment', function () {
      return moment().format('YYYY');
    })
  });

  _exports.default = _default;
});
define("in4m-web/routes/component/manage/express", ["exports", "in4m-web/helpers/check-for-dirty-attributes"], function (_exports, _checkForDirtyAttributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var model = '';
      var componentConfig = this.modelFor('component.manage');

      if (componentConfig) {
        var in4mTuneUp = this.get('modelGenerator.tuneUp');
        var schema = in4mTuneUp.findBy('s4-tag', 'ExpressManager');
        componentConfig.get('config').eachRelationship(function (name, relationship) {
          if (componentConfig.get('config').get(name)) {
            //to check if relationship data exists
            if (schema.properties.cdType['s4-model-key'] === componentConfig.get('config').get(name).get('cdType')) {
              model = componentConfig.get('config').get(name);
            }
          }
        });
      }

      return model;
    },
    afterModel: function afterModel(model) {
      if (model) {
        var properties = model.schema.properties;

        for (var key in properties) {
          if (properties[key]['s4-password']) {
            model.set('verifyPassword', model.get(key.camelize())); //verify password is a special input element thats not present in the schema
          }
        }
      }
    },
    actions: {
      didTransition: function didTransition() {
        this.navigation.set('allowTransition', false);
      },
      willTransition: function willTransition(transition) {
        if ((0, _checkForDirtyAttributes.checkForDirtyAttributes)(this.model())) {
          //custom dirty check since browser is converting integer to strings for input tagas and ember is checking for '===' instead of '=='
          $('#unsavedChangesModal').modal('show');
          transition.abort();
          this.set('transition', transition);
        } else {
          this.navigation.set('allowTransition', true);

          if (this.get('navigation.selectedBusinessEntity')) {
            this.session.set('selectedBusinessEntity', this.get('navigation.selectedBusinessEntity')); //setting the session's businessEntity to the selected businessEntity
          }
        }
      },
      allowTransition: function allowTransition(isAllowed) {
        $('#unsavedChangesModal').modal('hide');

        if (isAllowed) {
          if (this.transition) {
            this.model().rollbackAttributes();
            this.navigation.set('allowTransition', true); //allow transition need to set to the original value since it is checking in the navigation service

            if (this.get('navigation.selectedBusinessEntity')) {
              this.session.set('selectedBusinessEntity', this.get('navigation.selectedBusinessEntity')); //setting the session's businessEntity to the selected businessEntity
            }

            this.transition.retry();
          }
        } else {
          $('#' + this.get('session.selectedBusinessEntity.id')).addClass('jstree-clicked'); //manually adding and removing css

          $('#' + this.get('navigation.selectedBusinessEntity.id')).removeClass('jstree-clicked');
          this.navigation.set('selectedBusinessEntity', null); //to make sure to reset the navigation's selected business entity to null so that session's selected business entity won't change

          this.transitionTo('component.manage.express');
        }
      }
    }
  });

  _exports.default = _default;
});
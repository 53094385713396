define("in4m-web/router", ["exports", "in4m-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  /*
      This is where we tell the Router to connect URL paths to Templates and Routes.
  
      The logic to load the data for the route's model is defined in /app/routes, via Route.Extend in each file
  
      Example syntax:
  
          this.route('route-name-maps-to-template-name',{path:'url-path-optional-if-same-as-route-name'}, function(){});
  
      In the below example, the name of the Template is 'dashboard'. And the path is '/dashboard'
  
          this.route('dashboard',{path:'dashboard'}, function(){});
  
      For more information, see: https://guides.emberjs.com/release/routing/defining-your-routes/#toc_basic-routes
   */

  Router.map(function () {
    this.route('error');
    this.route('dashboard', {
      path: 'dashboard'
    }, function () {});
    this.route('machine', {
      path: 'machines'
    }, function () {
      this.route('error');
      this.route('manage', {
        path: '/:machineID'
      }, function () {});
    });
    this.route('component', {
      path: 'services'
    }, function () {
      //nested routes are for nested content (E.g. a pop-up model on the same page), see: https://matthewlehner.net/ember-js-routing-and-views to learn about nested routes
      this.route('error');
      this.route('manage', {
        path: '/:componentID'
      }, function () {
        this.route('general', {
          path: '/'
        });
        this.route('api-interface', function () {
          this.route('new');
          this.route('detail', {
            path: '/:id'
          }, function () {
            this.route('component-certificate');
          });
        });
        this.route('devices', function () {
          this.route('device', {
            path: '/:deviceID'
          });
          this.route('new');
        });
        this.route('4go');
        this.route('express');
        this.route('mail-server');
        this.route('advanced');
        this.route('offline-mode');
        this.route('telemetry');
      });
    });
    this.route('installations', function () {
      //route for the installations tab
      //nested routes for nested content we want to display WITHIN the installation tab
      this.route('error');
      this.route('installation', {
        path: '/:installationID'
      });
      this.route('connectUTG'); //nested route for creating an installation package to connect an existing UTG

      this.route('installUTG'); //..for installing command center and a new UTG

      this.route('selectInstallType');
      this.route('downloadInstall', {
        path: 'download/:id'
      });
    });
    this.route('componentProfiles', function () {
      this.route('error');
      this.route('componentProfile', {
        path: '/:id'
      }, function () {
        this.route('general', {
          path: '/'
        });
        this.route('apiInterfaces', function () {
          this.route('apiInterface', {
            path: '/:apiInterface_id'
          }, function () {});
          this.route('new');
        });
        this.route('devices', function () {
          this.route('device', {
            path: '/:deviceID'
          });
          this.route('new');
        });
        this.route('4go');
        this.route('express');
        this.route('mailServer');
        this.route('advanced');
        this.route('offlineMode');
        this.route('micros');
      });
    });
    this.route('devices', function () {
      this.route('error');
    });
    this.route('componentDeviceForms', {
      path: 'forms'
    }, function () {
      this.route('error');
    });
    this.route('tasks', {
      path: 'tasks'
    }, function () {
      this.route('error');
    });
    this.route('users', {
      path: 'users'
    }, function () {
      this.route('error');
      this.route('new');
    });
  });
  var _default = Router;
  _exports.default = _default;
});
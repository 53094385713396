define("in4m-web/templates/components/models-table/expand-row-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jG2WyiX0",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[29,\"exists-in\",[[25,[\"_expandedRowIndexes\"]],[25,[\"index\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"a\"],[11,\"href\",\"#\"],[12,\"class\",[25,[\"classes\",\"collapseRow\"]]],[9],[7,\"i\"],[12,\"class\",[30,[[25,[\"icons\",\"collapse-row\"]]]]],[9],[10],[3,\"action\",[[24,0,[]],\"collapseRow\",[25,[\"index\"]]],[[\"bubbles\"],[false]]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"a\"],[11,\"href\",\"#\"],[12,\"class\",[25,[\"classes\",\"expandRow\"]]],[9],[7,\"i\"],[12,\"class\",[30,[[25,[\"icons\",\"expand-row\"]]]]],[9],[10],[3,\"action\",[[24,0,[]],\"expandRow\",[25,[\"index\"]]],[[\"bubbles\"],[false]]],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/components/models-table/expand-row-cell.hbs"
    }
  });

  _exports.default = _default;
});
define("in4m-web/utils/alerts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var stack_bar_bottom = {
    "dir1": "up",
    "dir2": "right",
    "spacing1": 0,
    "spacing2": 0
  };
  var _default = {
    _alert: function _alert(message, status, header) {
      if (PNotify.notices.length >= 2) {
        //change this to any number based on the limit to show number of notifications on screen
        PNotify.notices.splice(0, PNotify.notices.length - 1); //remove all notifications till the penultimate one
      }

      new PNotify({
        //adding a new notification to the PNotify List
        title: header ? header : "Notification",
        text: message,
        type: status,
        addclass: 'stack-bar-bottom',
        stack: stack_bar_bottom,
        width: "70%"
      });
    },
    _error: function _error(message) {
      this._alert(message, 'error');
    },
    _success: function _success(message, header) {
      this._alert(message, 'success', header);
    },
    _warn: function _warn(message) {
      this._alert(message, 'warning');
    },
    _info: function _info(message) {
      this._alert(message, 'info');
    }
  };
  _exports.default = _default;
});
define("in4m-web/helpers/number-of-applications", ["exports", "in4m-web/utils/local-to-apipermission"], function (_exports, _localToApipermission) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    session: Ember.inject.service('session'),
    compute: function compute(params, hash) {
      var noOfApplications;
      var applicationGroupId = params[0];

      switch (applicationGroupId) {
        case 2:
          var _this = this;

          var permissionMasks = _this.get('session.userObject.permissionMasks');

          if (permissionMasks) {
            permissionMasks.forEach(function (permission) {
              if (!_this.get('hasAccesstoVT4')) {
                if (permission.get('permission.key') === (0, _localToApipermission.default)('vt4Sales') && permission.get('isEnabled') === true) {
                  _this.set('hasAccesstoVT4', true);
                }

                if (permission.get('permission.key') === (0, _localToApipermission.default)('vt4Refunds') && permission.get('isEnabled') === true) {
                  _this.set('hasAccesstoVT4', true);
                }
              }
            });

            if (_this.get('hasAccesstoVT4')) {
              noOfApplications = this.get('session.businessEntityThemes.length') + 1;
            } else {
              noOfApplications = this.get('session.businessEntityThemes.length');
            }

            if (noOfApplications === 0) {
              noOfApplications = 1; // Once US20986 is compelted, removed line 28-30
            }
          }

          break;

        default:
          noOfApplications = 0;
          break;
      }

      return noOfApplications;
    }
  });

  _exports.default = _default;
});
define("in4m-web/adapters/component-profile-assignment-collection", ["exports", "ember-inflector", "in4m-web/adapters/application"], function (_exports, _emberInflector, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    createRecord: function createRecord(store, type, snapshot) {
      var payload = {};
      var array = [];
      snapshot.record.eachRelationship(function (name, relationship) {
        if (relationship.kind === 'hasMany') {
          snapshot.record.get(name).forEach(function (eachRecord) {
            var eachObjectInPayload = {}; //assuming there are no relationships, loop through attributes only in a bulk upload request

            eachRecord.eachAttribute(function (propertyName, meta) {
              if (propertyName === 'profileId') {
                eachObjectInPayload[propertyName] = eachRecord.get(propertyName).toString().split("-")[1];
              } else {
                eachObjectInPayload[propertyName] = eachRecord.get(propertyName);
              }
            });
            array.push(eachObjectInPayload);
          });
        } else {//write code for belongsTo relationship type if needed
        }
      });
      var id = snapshot.id;
      var url = this.buildURL(type.modelName, id, snapshot, 'createRecord');
      payload['data'] = array;
      return this.ajax(url, 'PATCH', payload);
    },
    host: Ember.computed('apiPath', function () {
      return this.get('apiPath.in4mApiUrl');
    }),
    namespace: Ember.computed('apiPath', function () {
      return this.get('apiPath.in4mApiPath');
    }),
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var url = [];
      var host = Ember.get(this, 'host');
      var prefix = this.urlPrefix();
      var path;

      if (requestType === 'createRecord') {
        if (modelName) {
          path = this.pathForType(modelName);

          if (path) {
            //TODO: services team need to come up with more elegant way to handle the existing component type. For now, hardcoding here since this is breaking
            url.push('componentProfileAssignments?installationtype=30');
          }
        }
      } else {
        return this._super(modelName, id, snapshot, requestType, query);
      }

      if (id) {
        url.push(encodeURIComponent(id));
      }

      if (prefix) {
        url.unshift(prefix);
      }

      url = url.join('/');

      if (!host && url && url.charAt(0) !== '/') {
        url = '/' + url;
      }

      return url;
    },
    pathForType: function pathForType(modelName) {
      var camelized = Ember.String.camelize(modelName);
      var pluralized = (0, _emberInflector.pluralize)(camelized);
      return pluralized;
    }
  });

  _exports.default = _default;
});
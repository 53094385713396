define("in4m-web/models/installation-manifest", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    machine: belongsTo('machine', {
      async: false
    }),
    agent: belongsTo('agent', {
      async: false
    }),
    credentials: belongsTo('installationCredentials', {
      async: false
    }),
    components: hasMany('components'),
    in4mConfig: null,
    verificationData: null,
    install: belongsTo('installation', {
      async: false
    }),
    tasks: hasMany('installationTask')
  });

  _exports.default = _default;
});
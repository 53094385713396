define("in4m-web/controllers/component/manage", ["exports", "in4m-web/helpers/check-duplicates-in-array"], function (_exports, _checkDuplicatesInArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    duplicateFieldName: '',
    popOverMessage: Ember.computed('duplicateFieldName', 'duplicateFieldType', function () {
      var msg = "Duplicate values were found for " + this.duplicateFieldName + " between two or more " + this.duplicateFieldType + ". This issue must be resolved prior to making additional configuration changes or scheduling additional tasks. Change the value of one of the duplicate fields to resolve the issue.";
      return msg;
    }),
    canEditSelectedComponent: function () {
      var selectedBusinessEntity = this.get('session.selectedBusinessEntity');
      var user = this.get('session.userObject');
      var hasPermissionToEditComponentOnSelectedBE = user.hasPermission('canEdit', 'in4mUtgServices', selectedBusinessEntity);
      return hasPermissionToEditComponentOnSelectedBE;
    }.property('model'),
    duplicatesFound: Ember.computed('model.component.id', function () {
      try {
        this.set('duplicateFieldName', '');
        var model = this.model;
        var apiInterfaces = model.get('config').get('apiInterfaceConfig').get('apiInterface');
        var hasDuplicateCombinationOfAPIPortsAndIp = (0, _checkDuplicatesInArray.checkDuplicatesInArray)(apiInterfaces, 'listenPort', 'hostAddress');

        if (hasDuplicateCombinationOfAPIPortsAndIp) {
          this.set('duplicateFieldName', 'Port ($ for hex)/Host Address');
          this.set('duplicateFieldType', 'API Interfaces');
          return true;
        } else {
          var hasDuplicateAPIInterfaceNames = (0, _checkDuplicatesInArray.checkDuplicatesInArray)(apiInterfaces, 'taskDesc');

          if (hasDuplicateAPIInterfaceNames) {
            this.set('duplicateFieldName', 'Description');
            this.set('duplicateFieldType', 'API Interfaces');
            return true;
          } else {
            var devices = model.get('config').get('deviceConfig').get('devices');
            var hasDuplicateDeviceNames = (0, _checkDuplicatesInArray.checkDuplicatesInArray)(devices, 'taskDesc');

            if (hasDuplicateDeviceNames) {
              this.set('duplicateFieldName', 'Device Name');
              this.set('duplicateFieldType', 'Devices');
              return true;
            } else {
              var hasDuplicateAPITIDs = (0, _checkDuplicatesInArray.checkDuplicatesInArray)(devices, 'terminalList');

              if (hasDuplicateAPITIDs) {
                this.set('duplicateFieldName', 'API Terminal ID');
                this.set('duplicateFieldType', 'Devices');
                return true;
              } else {
                var rs232Ports = [];
                devices.forEach(function (device) {
                  if (device.get('connection.deviceMode') === 'rs232') {
                    if (device.get('connection.portNumber') != 'Auto') {
                      rs232Ports.push(device.get('connection.portNumber'));
                    }
                  }
                });

                if (rs232Ports.uniq().length !== rs232Ports.length) {
                  this.set('duplicateFieldName', 'Port');
                  this.set('duplicateFieldType', 'Devices');
                  return true;
                }
              }
            }
          }
        }

        return false;
      } catch (error) {
        // even if an exception occurs, we want to return something so the UI will render
        return false;
      }
    })
  });

  _exports.default = _default;
});
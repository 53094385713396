define("in4m-web/components/wizard/wizard-step", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super();

      this.get('wizard.steps').addObject(this);
    },
    isCurrentStep: function () {
      return this.name == this.get('wizard.currentStep');
    }.property('wizard.currentStep')
  });

  _exports.default = _default;
});
define("in4m-web/serializers/component-profile/config", ["exports", "ember-data", "in4m-web/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      "utgGlobal": {
        embedded: 'always'
      },
      "apiInterfaceConfig": {
        embedded: 'always'
      },
      "deviceConfig": {
        embedded: 'always'
      },
      "mailServer": {
        embedded: 'always'
      }
    },
    normalize: function normalize(modelClass, resourceHash, prop) {
      if (!resourceHash.microsBridge) {
        resourceHash['microsBridge'] = {};
        resourceHash.microsBridge['installMicrosBridge'] = false;
        resourceHash.microsBridge['product'] = '';
        resourceHash.microsBridge['microsInstallLocation'] = 'c:\\micros';
        resourceHash.microsBridge['microsBridgeInstallLocation'] = 'c:\\shift4interface';
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});
define("in4m-web/templates/custom/device-gear", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IajVNm2i",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[29,\"device-can-push-form\",[[25,[\"record\",\"deviceTypeComputed\"]],[25,[\"formsLoaded\"]]],null]],null,{\"statements\":[[4,\"table-action-gear\",null,null,{\"statements\":[[4,\"has-permission\",null,[[\"permAction\",\"permKey\",\"targetBusinessEntity\",\"tagName\"],[\"canEdit\",\"in4mDevices\",[25,[\"session\",\"selectedBusinessEntity\"]],\"\"]],{\"statements\":[[4,\"if\",[[25,[\"canPushForms\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"li\"],[9],[7,\"a\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-arrow-circle-o-up\"],[9],[10],[0,\"  Push Forms\"],[10],[3,\"action\",[[24,0,[]],\"sendAction\",\"updateDeviceFirmwareForm\",[25,[\"record\"]],10],[[\"bubbles\"],[false]]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\\t\\t\"],[7,\"li\"],[9],[7,\"a\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-refresh\"],[9],[10],[0,\"  Update Firmware\"],[10],[3,\"action\",[[24,0,[]],\"sendAction\",\"updateDeviceFirmwareForm\",[25,[\"record\"]],11],[[\"bubbles\"],[false]]],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/custom/device-gear.hbs"
    }
  });

  _exports.default = _default;
});
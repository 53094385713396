define("in4m-web/controllers/dashboard", ["exports", "in4m-web/utils/alerts"], function (_exports, _alerts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    columns: [{
      "propertyName": "manifest.machine.nickname",
      "title": "Installation Name"
    }, {
      "template": "custom/installationType",
      "propertyName": "type",
      "title": "Install Type"
    }, {
      "propertyName": "parentBusinessEntity.name",
      "title": "Parent Account"
    }, {
      "template": "custom/installationDate",
      "propertyName": "lastUpdatedAt",
      "title": "Creation Date",
      "sortedBy": "lastUpdatedAt",
      "sortDirection": "desc",
      "sortPrecedence": "1"
    }],
    referenceDocumentUrl: config.referenceDocumentUrl,
    // Get the reference guide url by loading it from the global config variable (see config.js)
    recentInstallationsLoaded: false,
    setRecentInstallations: function () {
      var user = this.get('session.userObject');
      var parentBusinessEntityId = this.get('session.selectedBusinessEntity.id'); // check if the user has permissions to installations and get the list from the server in the background

      if (user) {
        if (user.hasPermission('canView', 'in4mUtgInstallations', this.get('session.selectedBusinessEntity'))) {
          var _this = this;

          _this.set('recentInstallationsLoaded', false);

          this.store.query('installation', {
            parentBusinessEntityId: parentBusinessEntityId
          }).then(function (installations) {
            _this.set('recentInstallationsLoaded', true);

            if (installations.length > 5) {
              installations = installations.slice(installations.get('length') - 5);
            }

            _this.set('recentInstallations', installations);
          }, function (error) {
            _this.get('errorhandler').handleError(error, 'Error retrieving the installations.');
          });
        }
      }
    }.observes('model'),
    actions: {
      scheduleTask: function scheduleTask() {
        _alerts.default._info('Schedule a Task by clicking on the gear icon in the table above.');

        this.transitionToRoute('component');
      }
    }
  });

  _exports.default = _default;
});
define("in4m-web/serializers/validation-request", ["exports", "ember-data", "in4m-web/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id) {
      payload.validationRequest = payload["resultWithMessage`1"];
      delete payload["resultWithMessage`1"];
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});
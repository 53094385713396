define("in4m-web/routes/machine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    permKey: 'in4mMachines',
    permAction: 'canView',
    model: function model(params) {
      $('#progressHudOverlay').show();

      var _this = this;

      var navigation = _this.get('navigation');

      var hasPermission = navigation.checkPermissions(_this.get('session.selectedBusinessEntity'));

      if (hasPermission) {
        var machines;
        return _this.store.query('machine', {
          parentBusinessEntityId: _this.get('session.selectedBusinessEntity.id')
        }).then(function (machines) {
          machines = machines;
          return _this.store.query('machine-status', {
            parentBusinessEntityId: _this.get('session.selectedBusinessEntity.id')
          }).then(function (machineStatuses) {
            $('#progressHudOverlay').hide();
            return machines;
          }, function (error) {
            $('#progressHudOverlay').hide();
            return _this.get('errorhandler').handleError(error, 'Error while making a request.');
          });
        }, function (error) {
          $('#progressHudOverlay').hide();
          return _this.get('errorhandler').handleError(error, 'Error while making a request.');
        });
      }
    },
    afterModel: function afterModel() {
      $('#progressHudOverlay').hide();
    },
    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting) {
        var pollingInterval = this.pollingInterval;

        if (pollingInterval) {
          Ember.run.cancel(pollingInterval);
        }

        this.set('pollingInterval', null);
      }
    }
  });

  _exports.default = _default;
});
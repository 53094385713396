define("in4m-web/templates/custom/date-formatter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1ERuriJ1",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[29,\"get\",[[25,[\"record\"]],[25,[\"column\",\"propertyName\"]]],null]],null,{\"statements\":[[0,\"\\t\"],[1,[29,\"date-formatter\",[[29,\"get\",[[25,[\"record\"]],[25,[\"column\",\"propertyName\"]]],null]],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/custom/date-formatter.hbs"
    }
  });

  _exports.default = _default;
});
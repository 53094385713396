define("in4m-web/serializers/component-profile/config/api-interface-config", ["exports", "ember-data", "in4m-web/serializers/application", "in4m-web/helpers/cd-type-to-model-type"], function (_exports, _emberData, _application, _cdTypeToModelType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    normalize: function normalize(modelClass, resourceHash, prop) {
      //set the polymorphic type on the apiInterfaces
      resourceHash.apiInterface.forEach(function (apiInterface) {
        apiInterface.type = 'api-interface/' + Ember.String.dasherize((0, _cdTypeToModelType.cdTypeToModelType)(apiInterface.cdType));
      });
      return this._super.apply(this, arguments);
    },
    attrs: {
      "apiInterfaces": {
        key: 'apiInterface',
        embedded: 'always'
      }
    }
  });

  _exports.default = _default;
});
define("in4m-web/mixins/error-substate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      handleBusinessEntityChange: function handleBusinessEntityChange() {
        this.transitionTo(this.routeName.replace('.error', '')); //bubble up action so the parent route refreshes

        return true;
      }
    }
  });

  _exports.default = _default;
});
define("in4m-web/models/component-device-form", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    isActive: attr(),
    deviceType: attr(),
    name: attr(),
    description: attr(),
    fileLocation: attr(),
    lastUpdatedAt: attr(),
    lastUpdatedBy: attr(),
    parentBusinessEntity: belongsTo('businessEntity', {
      async: false
    }),
    parentBusinessEntityId: attr()
  });

  _exports.default = _default;
});
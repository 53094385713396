define("in4m-web/templates/custom/device-keys", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B8Ot16fQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"li\"],[9],[0,\"\\n  \"],[7,\"a\"],[9],[1,[25,[\"record\",\"DeviceStatusDeviceKey0\"]],false],[0,\"...\"],[10],[0,\"\\n\"],[3,\"action\",[[24,0,[]],\"sendAction\",\"showDeviceKeysModal\",[25,[\"record\"]]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/custom/device-keys.hbs"
    }
  });

  _exports.default = _default;
});
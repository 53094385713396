define("in4m-web/helpers/get-id", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getId = getId;
  _exports.default = void 0;

  function getId(params
  /*, hash*/
  ) {
    var idFieldToVerify = params[0];

    if (idFieldToVerify) {
      return '#' + idFieldToVerify;
    }
  }

  var _default = Ember.Helper.helper(getId);

  _exports.default = _default;
});
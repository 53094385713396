define("in4m-web/templates/components/wizard/wizard-step", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3ECv83kv",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,[\"isCurrentStep\"]]],null,{\"statements\":[[0,\"\\t\"],[15,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/components/wizard/wizard-step.hbs"
    }
  });

  _exports.default = _default;
});
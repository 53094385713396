define("in4m-web/serializers/component", ["exports", "ember-data", "in4m-web/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      parentBusinessEntity: 'businessEntityId',
      profile: {
        key: 'profileId'
      },
      machine: {
        serialize: false,
        deserialize: 'records'
      },
      tasks: {
        embedded: 'always'
      }
    },
    normalize: function normalize(modelClass, resourceHash, prop) {
      if (resourceHash.profileBusinessEntityId) {
        resourceHash.profileId = resourceHash.profileBusinessEntityId + '-' + resourceHash.profileId;
      }

      if (resourceHash.tasks) {
        resourceHash.tasks.forEach(function (task) {
          delete task.component;
        });
      }

      return this._super.apply(this, arguments);
    },
    serialize: function serialize(payload, options) {
      var json = this._super.apply(this, arguments);

      if (json.profileId) {
        if (json.profileId.toString().indexOf('-') !== -1) {
          // strip the dasherized profileId
          json.profileId = json.profileId.toString().split("-")[1];
        }
      }

      if (!json.fileId) {
        json.fileId = 0;
      }

      return json;
    }
  });

  _exports.default = _default;
});
define("in4m-web/controllers/installations/install-utg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* See: https://api.emberjs.com/ember/release/classes/Observable/methods and 
  https://github.com/ember-cli/eslint-plugin-ember/blob/master/docs/rules/no-function-prototype-extensions.md */
  var _default = Ember.Controller.extend({
    installations: Ember.inject.controller('installations'),

    /* As soon as the model is loaded, load the set of available Agent
       and UTG versions to display in this modal. 
       
       Note: The name of these methods doesn't matter. We don't call these methods 
       directly. They are OBSERVERs. So they're called automatically when the 
       model is loaded.
    */
    loadAvailableUtgVersions: Ember.observer('model', function () {
      var _this = this;

      var utgManifestLocation = _this.get('apiPath.utgManifestLocation');

      Ember.$.get(utgManifestLocation).then(function (data) {
        _this.set('availableUtgVersions', data.product);
      });
    }),
    loadAvailableAgentVersions: Ember.observer('model', function () {
      var _this = this;

      var agentManifestLocation = _this.get('apiPath.agentManifestLocation'); //load as set in config.js


      Ember.$.get(agentManifestLocation).then(function (data) {
        _this.set('availableAgentVersions', data.product);
      });
    }),

    /*
    	As the user selects an agent and UTG version from the dropdowns, we 
    	store the download URLs of the selected versions here.
    */
    agentDownloadLocation: '',
    utgDownloadLocation: '',
    setEphemeral: Ember.observer('model', function () {
      var _this = this; // create the ephemeralUser right away for UI bindings


      var ephemeralUser = _this.store.createRecord('ephemeralUser', {
        applicationId: 4,
        createdBy: _this.get('session.userObject.id'),
        // set to selected BE
        parentBusinessEntity: _this.get('session.selectedBusinessEntity'),
        allowDuplicateSessions: true,
        // lifespan of 20 is multi use, the wizard sets the start/end date
        lifespan: _this.store.createRecord('lifespan', {
          lifeSpanType: 20,
          startDate: moment().format('MM/DD/YYYY'),
          endDate: moment().add(99, 'years').format('MM/DD/YYYY')
        })
      });

      _this.set('ephemeralUser', ephemeralUser); // check if we have already retrieved the ephemeral roles and then set the ephemeral user on the controller


      if (this.ephemeralRoles) {
        var ephemeralRoles = this.ephemeralRoles;
        var ephemeralRole = ephemeralRoles.get('firstObject'); // set ephemeralUser role

        ephemeralUser.get('roleMappings').addObject(_this.store.createRecord('roleMapping', {
          role: ephemeralRole.get('roleId'),
          parentBusinessEntity: _this.get('session.selectedBusinessEntity'),
          ephemeralUser: ephemeralUser
        }));
      } else {
        this.store.query('objectRoleMapping', {
          objectTypeId: 7,
          objectId: 1
        }).then(function (ephemeralRoles) {
          var ephemeralRole = ephemeralRoles.get('firstObject');

          _this.set('ephemeralRoles', ephemeralRoles); // set ephemeralUser role


          ephemeralUser.get('roleMappings').addObject(_this.store.createRecord('roleMapping', {
            role: ephemeralRole.get('roleId'),
            parentBusinessEntity: _this.get('session.selectedBusinessEntity'),
            ephemeralUser: ephemeralUser
          }));
        });
      }
    }),
    ephemeralUser: null,
    hasPermissionToEditServices: Ember.computed('session.selectedBusinessEntity', function () {
      var hasPermissionToEditServices = false;
      var selectedBusinessEntity = this.get('session.selectedBusinessEntity');
      var user = this.get('session.userObject');
      hasPermissionToEditServices = user.hasPermission('canEdit', 'in4mUtgServices', selectedBusinessEntity);
      return hasPermissionToEditServices;
    }),
    getProfiles: Ember.observer('model', function () {
      if (this.hasPermissionToEditServices) {
        var selectedBusinessEntity = this.get('session.selectedBusinessEntity.id');
        this.set('componentProfiles', this.store.query('componentProfile', {
          parentBusinessEntityId: selectedBusinessEntity
        }));
      }
    }),
    actions: {
      closeConnectUTG: function closeConnectUTG() {
        var _this = this; //to remove the stickiness of the profile name in the modal


        _this.set('selectedProfileId', '');

        $('#installationModal').modal('toggle').on('hidden.bs.modal', function () {
          _this.transitionToRoute('installations');
        });
      },
      createInstallation: function createInstallation() {
        if ($('#installUTG').valid()) {
          var _this = this;

          var installation = _this.get('model');

          var ephemeralUser = _this.get('ephemeralUser');

          $('#progressHudMessage').html('Creating Install User...');
          $('#progressHudOverlay').show();
          /* NOTE: If the components array is NULL or empty, the next line will throw an error.				
          However, this is a good thing. Because such a state indicates an error in earlier 
          logic. E.g. If we're in such a state, something is wrong with earlier logic. By 
          definition, the install-utg flow should have a UTG component in the components array. */

          var utgComponent = installation.get('manifest.components').firstObject;
          Ember.set(utgComponent, 'downloadLocation', _this.get('utgDownloadLocation')); //now set the target property on the array-item
          //first, we'll extract and set the agent download URL based on the version selected by the user

          installation.get('manifest.agent') //grab the agent section from installation.manifest.agent
          .setProperties({
            downloadLocation: //Set the download location for the agent...
            _this.get('agentDownloadLocation') // ...to the agent download location for the version picked by the user

          });
          ephemeralUser.save().then(function (ephemeralUser) {
            // set auth code from user into manifest
            installation.get('manifest.credentials').setProperties({
              applicationId: 4,
              sessionToken: ephemeralUser.get('userSessions.firstObject.sessionToken'),
              businessEntityId: ephemeralUser.get('parentBusinessEntity'),
              authenticationCode: ephemeralUser.get('authenticationCode')
            }); //add profileId and profileBusinessEntityId to the manifest object which is later used by the services agent

            _this.get('componentProfiles').find(function (item) {
              if (item.id == _this.get('selectedProfileId')) {
                installation.get('manifest.components').get('firstObject').setProperties({
                  profile: item,
                  profileBusinessEntityId: item.get('parentBusinessEntity.id')
                });
              }
            });

            if (!_this.get('selectedProfileId')) {
              installation.get('manifest').set('tasks', []);
            }

            $('#progressHudMessage').html('Creating Package...');
            installation.save().then(function () {
              _this.set('selectedProfileId', ''); // add installation to installations table because it will not update automatically since the model is a query result (TODO: don't use query as much)


              _this.get('installations').get('model').get('content').pushObject(installation.get('_internalModel'));

              _this.transitionToRoute('installations.downloadInstall', installation);
            }, function (error) {
              $('#progressHudOverlay').hide();

              _this.get('errorhandler').handleError(error, 'Error saving the installation manifest.');
            });
          }, function (error) {
            $('#progressHudOverlay').hide();

            _this.get('errorhandler').handleError(error, 'Error creating the Ephemeral User.');
          });
        }
      }
    }
  });

  _exports.default = _default;
});
define("in4m-web/routes/component-profiles/component-profile/micros", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var profile = this.modelFor('componentProfiles.componentProfile');
      return profile.get('config.microsBridge');
    }
  });

  _exports.default = _default;
});
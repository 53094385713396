define("in4m-web/services/session", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    sessionObject: null,
    userObject: null,
    sessionTimeoutInterval: null,
    selectedBusinessEntity: null,
    applicationGroups: null,
    businessEntityThemes: null,
    showChildren: false,
    latestAgentVersion: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('sessionObject', {});
    },
    setSessionObject: function setSessionObject(sessionObject) {
      this.set('sessionObject', sessionObject);
    },
    setUserObject: function setUserObject(userObject) {
      this.set('userObject', userObject);
    },
    setApplicationGroups: function setApplicationGroups(AG) {
      this.set('applicationGroups', AG);
    },
    setBusinessEntityThemes: function setBusinessEntityThemes(themes) {
      this.set('businessEntityThemes', themes);
    },
    setShowChildren: function setShowChildren(checked) {
      this.set('showChildren', checked);
    }
  });

  _exports.default = _default;
});
define("in4m-web/helpers/dasherize-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dasherizeHelper = dasherizeHelper;
  _exports.default = void 0;

  function dasherizeHelper(params
  /*, hash*/
  ) {
    return Ember.String.dasherize(params[0]);
  }

  var _default = Ember.Helper.helper(dasherizeHelper);

  _exports.default = _default;
});
define("in4m-web/helpers/check-for-dirty-attributes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.checkForDirtyAttributes = checkForDirtyAttributes;
  _exports.default = void 0;

  function checkForDirtyAttributes(params
  /*, hash*/
  ) {
    if (params) {
      var changedAttributes = params.changedAttributes();
      var changedAttributesInRelationships = false;

      for (var key in changedAttributes) {
        if (key !== 'useUTGGeneratedCertificate') {
          //a special case where useUTGGeneratedCertificate is not part of the json object
          if (changedAttributes[key][0] != changedAttributes[key][1]) {
            //custom check where 6 == "6" instead of default ember check 6 === 6
            return true;
          }
        }
      }

      params.eachRelationship(function (name, relationship) {
        if (params.get("".concat(name, "._internalModel._attributes"))) {
          for (var key in params.get("".concat(name, "._internalModel._attributes"))) {
            if (key !== 'useUTGGeneratedCertificate') {
              //a special case where useUTGGeneratedCertificate is not part of the json object
              if (params.get("".concat(name, "._internalModel._attributes"))[key] != params.get("".concat(name, "._internalModel._data"))[key]) {
                //custom check where 6 == "6" instead of default ember check 6 === 6
                changedAttributesInRelationships = true; //since eachRelationship loop does not break at this point, saving in a variable
              }
            }
          }
        }
      });
      return changedAttributesInRelationships;
    }
  }

  var _default = Ember.Helper.helper(checkForDirtyAttributes);

  _exports.default = _default;
});
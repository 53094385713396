define("in4m-web/routes/component-profiles/component-profile/devices/device", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.modelFor('componentProfiles/componentProfile/devices').findBy('id', params.deviceID);
    }
  });

  _exports.default = _default;
});
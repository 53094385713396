define("in4m-web/utils/local-to-apipermission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = localToAPIPermission;

  function localToAPIPermission(permKey) {
    // Translate local permission keys to match API permission keys
    switch (permKey) {
      case 'organizations':
        permKey = 'Organizations';
        break;

      case 'roles':
        permKey = 'Roles';
        break;

      case 'groups':
        permKey = 'Groups';
        break;

      case 'merchantSets':
        permKey = 'MerchantSets';
        break;

      case 'users':
        permKey = 'Users';
        break;

      case 'vt4Settings':
        permKey = 'VT4Settings';
        break;

      case 'vt4CampaignSettings':
        permKey = 'CampaignSettings';
        break;

      case 'vt4ShelfSettings':
        permKey = 'ShelfSettings';
        break;

      case 'vt4Sales':
        permKey = 'Sales';
        break;

      case 'vt4Refunds':
        permKey = 'Refunds';
        break;

      case 'vt4Reports':
        permKey = 'VT4Reports';
        break;

      case 'midLicenses':
        permKey = 'MIDLicenses';
        break;

      case '4tressReports':
        permKey = '4tressReports';
        break;

      case 'billingBillableProducts':
        permKey = 'BillableProducts';
        break;

      case 'billingBillableActivities':
        permKey = 'BillableActivities';
        break;

      case 'billingRateProfiles':
        permKey = 'RateProfiles';
        break;

      case 'billingRateProfileAssignments':
        permKey = 'RateProfileAssignments';
        break;

      case 'billingCompanies':
        permKey = 'Companies';
        break;

      case 'billingContacts':
        permKey = 'Contacts';
        break;

      case 'billingBillToAccounts':
        permKey = 'BillToAccounts';
        break;

      case 'billingPaymentMethods':
        permKey = 'PaymentMethods';
        break;

      case 'shift4User':
        permKey = 'Shift4User';
        break;

      case 'billingInvoices':
        permKey = 'Invoices';
        break;

      case 'billingCycles':
        permKey = 'BillingCycles';
        break;

      case 'billingCyclesPauseResume':
        permKey = 'BillingCyclesPauseResume';
        break;

      case 'billingCyclesRerun':
        permKey = 'BillingCyclesRerun';
        break;

      case 'fullOrganizationTree':
        permKey = 'FullOrganizationTree';
        break;

      case 'BETheme':
        permKey = 'BusinessEntityTheme';
        break;

      case 'accountTree':
        permKey = 'BusinessEntityTree';
        break;

      case 'in4mAgentRegistrations':
        permKey = 'In4m:AgentRegistrations';
        break;

      case 'in4mDeviceConfig':
        permKey = 'In4m:DeviceConfigurations';
        break;

      case 'in4mDevices':
        permKey = 'In4m:Devices';
        break;

      case 'in4mMachines':
        permKey = 'In4m:Machines';
        break;

      case 'in4mUtgProfiles':
        permKey = 'In4m:UtgConfigurations';
        break;

      case 'in4mUtgScheduleTasks':
        permKey = 'In4m:UtgServiceActions';
        break;

      case 'in4mUtgSetupPackages':
        permKey = 'In4m:UtgServiceGuidExport';
        break;

      case 'in4mUtgServices':
        permKey = 'In4m:UtgServices';
        break;

      case 'in4mAccountTree':
        permKey = 'In4m:AccountTree';
        break;

      case 'in4mUtgTelemetry':
        permKey = 'In4m:UtgTelemetry';
        break;

      case 'in4mUtgInstallations':
        permKey = 'In4m:UtgInstallations';
        break;

      case 'in4mDeviceForms':
        permKey = 'In4m:DeviceForms';
        break;
    }

    return permKey;
  }
});
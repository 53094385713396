define("in4m-web/models/component-profile/config/mail-server", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    serverName: attr(),
    utgGlobal: belongsTo('componentProfile/config/utgGlobal', {
      async: false
    }),
    alertList: attr('complex-array'),
    statusList: attr('complex-array'),
    sourceDomain: attr(),
    port: attr()
  });

  _exports.default = _default;
});
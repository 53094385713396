define("in4m-web/templates/custom/task-list-machine-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yiJ9qcCI",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",[\"machine.manage\",[25,[\"record\",\"component\",\"machineId\"]]],null,{\"statements\":[[1,[25,[\"record\",\"component\",\"machine\",\"dnsName\"]],false]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/custom/task-list-machine-link.hbs"
    }
  });

  _exports.default = _default;
});
define("in4m-web/components/form-generator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'form',
    name: 'tune-up',
    classNames: ['form-horizontal', 'newvalidation'],
    attributeBindings: ['name'],
    actions: {
      generateCertificate: function generateCertificate() {
        this.sendAction('generateCertificate');
      },
      loadCertificate: function loadCertificate() {
        this.sendAction('loadCertificate');
      }
    }
  });

  _exports.default = _default;
});
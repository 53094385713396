define("in4m-web/templates/components/checkbox-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZKVhBF1I",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\"],[9],[0,\"\\n\\t\"],[1,[29,\"input\",null,[[\"name\",\"disabled\",\"class\",\"type\",\"checked\",\"data-required-class\",\"data-required-count\",\"data-msg\"],[[25,[\"name\"]],[25,[\"disabled\"]],[25,[\"property\",\"s4-validations\",\"class\"]],\"checkbox\",[25,[\"checked\"]],[29,\"concat\",[[25,[\"property\",\"s4-validations\",\"requiredClass\"]],[29,\"guid-for\",[[25,[\"model\"]]],null]],null],[25,[\"property\",\"s4-validations\",\"requiredCount\"]],[25,[\"property\",\"s4-validations\",\"errorMessage\"]]]]],false],[0,\"\\n\\t\"],[1,[23,\"labelText\"],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/components/checkbox-type.hbs"
    }
  });

  _exports.default = _default;
});
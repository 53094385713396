define("in4m-web/helpers/utg-supports-device", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.utgSupportsDevice = utgSupportsDevice;
  _exports.default = void 0;

  function utgSupportsDevice(params) {
    var deviceType = params[0];
    var utgVersion = parseInt(params[1].split('.')[3]);
    var isPAX = typeof deviceType != 'undefined' && deviceType.toLowerCase().includes('pax');

    if (utgVersion < 3070 && isPAX) {
      return false;
    } else {
      return true;
    }
  }

  var _default = Ember.Helper.helper(utgSupportsDevice);

  _exports.default = _default;
});
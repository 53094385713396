define("in4m-web/templates/components/models-table/component-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fnsyEblW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[30,[[25,[\"classes\",\"tfooterWrapper\"]]]]],[9],[0,\"\\n\"],[0,\"  \"],[7,\"div\"],[12,\"class\",[30,[[29,\"if\",[[25,[\"useNumericPagination\"]],[25,[\"classes\",\"footerSummaryNumericPagination\"]],[25,[\"classes\",\"footerSummaryDefaultPagination\"]]],null],\" \",[25,[\"classes\",\"footerSummary\"]]]]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"dataTables_info\"],[11,\"role\",\"status\"],[11,\"aria-live\",\"polite\"],[9],[0,\"\\n      \"],[1,[23,\"summary\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"],[0,\"  \"],[10],[0,\"\\n\"],[0,\"\\n\"],[4,\"if\",[[25,[\"useNumericPagination\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[30,[[25,[\"classes\",\"paginationWrapper\"]],\" \",[25,[\"classes\",\"paginationWrapperNumeric\"]]]]],[9],[0,\"\\n      \"],[16,[25,[\"numericPaginationTemplate\"]],[]],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[30,[[25,[\"classes\",\"paginationWrapper\"]],\" \",[25,[\"classes\",\"paginationWrapperDefault\"]]]]],[9],[0,\"\\n      \"],[16,[25,[\"simplePaginationTemplate\"]],[]],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "in4m-web/templates/components/models-table/component-footer.hbs"
    }
  });

  _exports.default = _default;
});
define("in4m-web/helpers/class-name-for-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.className = className;
  _exports.default = void 0;

  function className(params
  /*, hash*/
  ) {
    var schemaProperty = params[0];
    var index = params[1]; // keep track of loop position

    var i = 0;
    var noofColumns;

    for (var key in schemaProperty.items.properties) {
      // check if current iteration in loop is equal to the index
      if (i == index) {
        // grab the s4-noof-columns for the property
        noofColumns = schemaProperty.items.properties[key]['s4-noof-columns'];
      }

      i++;
    }

    if (noofColumns) {
      var classes = ''; // columns

      var xs = noofColumns.xs;
      var sm = noofColumns.sm;
      var md = noofColumns.md;

      if (xs) {
        classes += ' col-xs-' + xs;
      }

      if (sm) {
        classes += ' col-sm-' + sm;
      }

      if (md) {
        classes += ' col-md-' + md;
      }

      return classes;
    } else {
      return 'col-xs-8 col-sm-6 col-md-4';
    }
  }

  var _default = Ember.Helper.helper(className);

  _exports.default = _default;
});
define("in4m-web/helpers/format-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatUrl = formatUrl;
  _exports.default = void 0;

  function formatUrl(params) {
    var url = params[0];

    if (url) {
      return url.replace('-', '&#8209;');
    }
  }

  var _default = Ember.Helper.helper(formatUrl);

  _exports.default = _default;
});
define("in4m-web/models/api-interface", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr; // api-interface is its own model because there may be other areas that could reuse this in the future
  // we could potentially have both service configs and profile configs share the api-interfaces

  var _default = Model.extend({
    cdType: attr() //apiInterfaceConfig: belongsTo('componentProfile/config/apiInterfaceConfig', {async:false})

  });

  _exports.default = _default;
});
define("in4m-web/components/date-range-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.TextField.extend({
    dateRanges: null,
    dateLimit: 0,
    dateLimitPeriod: "days",
    minDate: 0,
    minDatePeriod: "days",
    maxDate: 0,
    maxDatePeriod: "days",
    autoApply: true,
    format: 'MM/DD/YYYY',
    opens: 'left',
    drops: 'up',
    singleDatePicker: true,
    timePicker: true,
    autoUpdateInput: false,
    applyClass: 'btn-primary',
    didInsertElement: function didInsertElement() {
      var element = '#' + this.elementId;

      var _this = this;

      $('#datecalendar').click(function () {
        $(element).click();
      });
      var datePickerOptions = {
        autoApply: this.autoApply,
        opens: this.opens,
        drops: this.drops,
        startDate: this.startDate,
        endDate: this.endDate,
        minDate: moment().subtract(24, 'hours'),
        singleDatePicker: this.singleDatePicker,
        timePicker: this.timePicker,
        autoUpdateInput: this.autoUpdateInput,
        applyClass: this.applyClass
      };

      if (this.minDate) {
        datePickerOptions.minDate = moment().subtract(this.minDate, this.minDatePeriod).format('MM/DD/YYYY');
      }

      if (this.maxDate) {
        datePickerOptions.maxDate = moment().add(this.maxDate, this.maxDatePeriod).format('MM/DD/YYYY');
      }

      if (this.dateLimit) {
        datePickerOptions.dateLimit = _defineProperty({}, this.dateLimitPeriod, this.dateLimit);
      }

      if (this.dateRanges) {
        datePickerOptions.ranges = this.dateRanges;
      }

      $(element).daterangepicker(datePickerOptions); // set initial default value

      if (_this.get('singleDatePicker')) {
        $(element).val(this.endDate);
      } else {
        $(element).val(this.startDate + ' - ' + this.endDate);
      }

      $(element).on('apply.daterangepicker', function (ev, picker) {
        $('#daterangepickerDiv').removeClass('has-error'); //fix for removing error classes since jquery's key up events are not triggered

        $('#daterangepicker-error').hide(); // if single date picker only set endDate

        if (_this.get('singleDatePicker')) {
          _this.set('endDate', picker.endDate.format('MM/DD/YYYY h:mm:ss A'));

          $(this).val(picker.endDate.format(_this.get('format')));
        } else {
          _this.set('startDate', picker.startDate.format('MM/DD/YYYY h:mm:ss A'));

          _this.set('endDate', picker.endDate.format('MM/DD/YYYY h:mm:ss A'));

          $(this).val(picker.startDate.format(_this.get('format')) + ' - ' + picker.endDate.format(_this.get('format')));
        }
      });
      $(element).on('cancel.daterangepicker', function (ev, picker) {
        $(this).val(_this.get('startDate') + ' - ' + _this.get('endDate'));
      });
    }
  });

  _exports.default = _default;
});
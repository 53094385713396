define("in4m-web/controllers/component-profiles/component-profile/micros", ["exports", "in4m-web/utils/drop-down-list"], function (_exports, _dropDownList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    componentProfile: Ember.inject.controller('componentProfiles.componentProfile'),
    isMicrosInstalled: Ember.computed('model.installMicrosBridge', function () {
      if (this.get('model.installMicrosBridge')) {
        return true;
      }

      return false;
    }),
    dropdownListValues: Ember.computed('dropdownList', function () {
      return (0, _dropDownList.default)();
    })
  });

  _exports.default = _default;
});
define("in4m-web/helpers/is-equal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isEqual = isEqual;
  _exports.default = void 0;

  function isEqual(params
  /*, hash*/
  ) {
    if (params[0] == params[1]) {
      return true;
    }

    return false;
  }

  var _default = Ember.Helper.helper(isEqual);

  _exports.default = _default;
});
define("in4m-web/components/s4-radio-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'input',
    name: 'radioButton',
    attributeBindings: ['required', 'name', 'type', 'checked', 'disabled'],
    type: 'radio',
    checked: function () {
      return this.value == this.name;
    }.property('value', 'name'),
    click: function click() {
      this.set('name', this.value); // support for multiple radio buttons with the same name on the same page 

      if (this.dedupe) {
        this.set('propertyValue', this.value.replace(this.dedupe, ''));
      }
    }
  });

  _exports.default = _default;
});
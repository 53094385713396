define("in4m-web/controllers/component/manage/devices/index", ["exports", "in4m-web/utils/drop-down-list", "in4m-web/helpers/check-duplicates-in-array", "in4m-web/utils/scroll-to-validate", "in4m-web/helpers/remove-properties-from-model", "in4m-web/utils/alerts"], function (_exports, _dropDownList, _checkDuplicatesInArray, _scrollToValidate, _removePropertiesFromModel, _alerts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    component: Ember.inject.controller('component.manage'),
    selectedDevice: null,
    isEditDisabled: Ember.computed('model.firstObject', function () {
      var hasPermissionToEditComponentOnSelectedBE = this.get('component.canEditSelectedComponent');
      return !hasPermissionToEditComponentOnSelectedBE;
    }),
    customClasses: {
      'pageSizeWrapper': 'col-xs-12 col-sm-8 col-md-6'
    },
    columns: function () {
      return [{
        "propertyName": "taskDesc",
        "title": "Device Name"
      }, {
        "propertyName": "deviceType",
        "title": "Device Type"
      }, {
        "propertyName": "terminalList",
        "title": "TID"
      }, {
        "propertyName": "threadType",
        "title": "Thread Type"
      }, {
        "template": "custom/clone-device",
        "useFilter": false,
        "isHidden": this.get('isEditDisabled')
      }, {
        "template": "custom/delete-device",
        "useFilter": false,
        "templateForSortCell": 'custom/add-record',
        "isHidden": this.get('isEditDisabled')
      }];
    }.property('isEditDisabled'),
    selectedDeviceSchema: function () {
      if (this.get('selectedDevice')) {
        var supportedDevices = this.store.peekAll('componentDeviceSchema');
        var selectedDeviceSchema = supportedDevices.findBy('name', this.get('selectedDevice.deviceType')).get('schemaValue');
        return selectedDeviceSchema;
      }
    }.property('selectedDevice'),
    validations: function () {
      var selectedDeviceSchema = this.get('selectedDeviceSchema');
      var validations = {};

      function setValidations(validations, properties) {
        for (var key in properties) {
          if (properties[key].type !== 'object' && properties[key].type !== 'array') {
            validations[key] = {
              required: properties[key].required,
              maxLength: properties[key].maxLength,
              minLength: properties[key].minLength,
              minimum: properties[key].minimum,
              maximum: properties[key].maximum,
              disabled: properties[key].disabled
            };
          } else {
            if (properties[key].type !== 'array') {
              validations[key] = {};
              setValidations(validations[key], properties[key].properties);
            } else {
              validations[key] = {};
              setValidations(validations[key], properties[key].items.properties);
            }
          }
        }
      }

      if (selectedDeviceSchema) {
        setValidations(validations, selectedDeviceSchema.properties);
      }

      validations.deviceType = this.get('selectedDevice.name');
      return validations;
    }.property('selectedDeviceSchema'),
    dropdownListValues: Ember.computed('dropdownList', function () {
      return (0, _dropDownList.default)();
    }),
    reservedPortValues: Ember.computed('selectedDevice', function () {
      var _this = this;

      var reservedPortValues = [];
      var autoPortDeviceType;
      this.get('model').forEach(function (device) {
        //do not add port number from new device
        if (device.get('connection.deviceMode') === 'rs232' && !device.get('isNew')) {
          var portNumber = device.get('connection.portNumber');
          var deviceType = device.get('deviceType');

          if (portNumber) {
            reservedPortValues.push(portNumber);

            var selectedDeviceType = _this.get('selectedDevice.deviceType');

            if (portNumber == 'Auto' && selectedDeviceType && selectedDeviceType != deviceType) {
              reservedPortValues.pop();
            }
          }
        }
      });
      return reservedPortValues;
    }),
    actions: {
      displayDataChangedAction: function displayDataChangedAction(data) {
        if (data.get('selectedItems').length > 0) {
          this.transitionToRoute('component.manage.devices.device', data.get('selectedItems').get('firstObject').id);
        }
      },
      addRecord: function addRecord(record) {
        this.transitionToRoute('component.manage.devices.new');
      },
      showDeleteModal: function showDeleteModal(record) {
        this.set('selectedDevice', record);
        $('#deleteDeviceModal').modal('show');
      },
      dismissDeleteModal: function dismissDeleteModal(record) {
        $('#deleteDeviceModal').modal('hide');
      },
      deleteRecord: function deleteRecord() {
        var record = this.get('selectedDevice');

        var _this = this;

        if (record) {
          $('#progressHudMessage').html('Updating...');
          $('#progressHudOverlay').show();
          record.deleteRecord();

          _this.send('saveComponentConfig');
        }

        $('#deleteDeviceModal').modal('hide');
      },
      showCloneDeviceModal: function showCloneDeviceModal(record) {
        var _this = this;

        this.set('selectedDevice', record);

        var reservedPortValues = _this.get('reservedPortValues');

        var serializedRecord = record.serialize();
        var selectedDeviceWithNoIds = (0, _removePropertiesFromModel.removePropertiesFromModel)(serializedRecord, 'id');

        var component = _this.get('component').get('model');

        var deviceConfig = component.get('config.deviceConfig'); //get the next available port in the selected UTG devices.

        var nextAvailablePortValues = this.get('dropdownListValues.portNumbers').filter(function (item) {
          return reservedPortValues.indexOf(item) < 0;
        });

        var clonedDevice = _this.store.createRecord('componentDevice', {
          id: Ember.guidFor(selectedDeviceWithNoIds),
          deviceConfig: deviceConfig,
          amexKeyIndex: selectedDeviceWithNoIds.amexKeyIndex,
          beepVolume: selectedDeviceWithNoIds.beepVolume,
          bypassAmountOk: selectedDeviceWithNoIds.bypassAmountOk,
          cashback: selectedDeviceWithNoIds.cashback,
          cdType: selectedDeviceWithNoIds.cdType,
          connection: {
            deviceMode: selectedDeviceWithNoIds.connection.deviceMode,
            deviceModeType: selectedDeviceWithNoIds.connection.deviceModeType,
            //This is no longer handled from the component device schema.
            portNumber: nextAvailablePortValues[0],
            baudRate: selectedDeviceWithNoIds.connection.baudRate,
            ipAddress: selectedDeviceWithNoIds.connection.ipAddress,
            timeoutSecs: selectedDeviceWithNoIds.connection.timeoutSecs,
            port: selectedDeviceWithNoIds.connection.port
          },
          contactlessEnabled: selectedDeviceWithNoIds.contactlessEnabled,
          dccTimeoutSecs: selectedDeviceWithNoIds.dccTimeoutSecs,
          debitKeyIndex: selectedDeviceWithNoIds.debitKeyIndex,
          defaultLanguage: selectedDeviceWithNoIds.defaultLanguage,
          deviceType: selectedDeviceWithNoIds.deviceType,
          ebt: selectedDeviceWithNoIds.ebt,
          googleSecrets: selectedDeviceWithNoIds.googleSecrets,
          lineLength: selectedDeviceWithNoIds.lineLength,
          manualCardEntry: selectedDeviceWithNoIds.manualCardEntry,
          privateLabel: selectedDeviceWithNoIds.privateLabel,
          sourceSerialNumber: selectedDeviceWithNoIds.sourceSerialNumber,
          taskDesc: selectedDeviceWithNoIds.taskDesc,
          terminalList: selectedDeviceWithNoIds.terminalList,
          threadType: selectedDeviceWithNoIds.threadType,
          tip: selectedDeviceWithNoIds.tip,
          rebootTime: selectedDeviceWithNoIds.rebootTime
        });

        deviceConfig.get('devices').addObject(clonedDevice);

        _this.set('clonedDevice', clonedDevice);

        $('#cloneDeviceModal').modal('show');
      },
      cancelCloneDeviceModal: function cancelCloneDeviceModal() {
        //clearing the temporarily created record from the store and controller
        this.get('component').get('model').get('config.deviceConfig.devices').removeObject(this.get('clonedDevice'));
        this.get('clonedDevice').deleteRecord();
        this.set('clonedDevice', ''); //hide the modal and reset the form on the modal

        $('#cloneDeviceModal').modal('hide');
        $("#cloneDeviceForm").data('validator').resetForm();
      },
      cloneDevice: function cloneDevice() {
        var _this = this;

        if ($('form').valid()) {
          var model = this.get('component').get('model'); //check for device names duplicates

          var devices = model.get('config').get('deviceConfig').get('devices');
          var hasDuplicateDeviceNames = (0, _checkDuplicatesInArray.checkDuplicatesInArray)(devices, 'taskDesc');

          if (hasDuplicateDeviceNames) {
            _scrollToValidate.default.begin('custom-scroll', 'taskDesc', 'cloneDeviceForm', 'Device Name Must be Unique.');
          } else {
            var hasDuplicateAPITIDs = (0, _checkDuplicatesInArray.checkDuplicatesInArray)(devices, 'terminalList');

            if (hasDuplicateAPITIDs) {
              _scrollToValidate.default.begin('custom-scroll', 'terminalList', 'cloneDeviceForm', 'API Terminal ID Must be Unique.');
            } else {
              var rs232Ports = [];
              var utgStubDirectCombinationOfPortsAndIp = [];
              devices.forEach(function (device) {
                if (device.get('connection.deviceMode') === 'rs232') {
                  rs232Ports.push(device.get('connection.portNumber'));
                } else {
                  //create a unique field based on two properties
                  utgStubDirectCombinationOfPortsAndIp.push(device.get('connection.port') + '' + device.get('connection.ipAddress'));
                }
              });

              if (rs232Ports.uniq().length !== rs232Ports.length) {
                _scrollToValidate.default.begin('custom-scroll', 'portNumber', 'cloneDeviceForm', 'Port Must be Unique.');
              } else if (utgStubDirectCombinationOfPortsAndIp.uniq().length !== utgStubDirectCombinationOfPortsAndIp.length) {
                _scrollToValidate.default.begin('custom-scroll', 'port', 'cloneDeviceForm', 'Port Must be Unique per IP Address.');
              } else {
                $('#progressHudOverlay').show();
                devices.addObject(_this.get('clonedDevice'));
                model.save().then(function () {
                  _alerts.default._success('The UTG has been updated.');

                  $('#cloneDeviceModal').modal('hide');
                  $("#cloneDeviceForm").data('validator').resetForm();
                  $('#progressHudOverlay').hide();
                }, function (error) {
                  _alerts.default._error('Unable to update UTG.');

                  $('#progressHudOverlay').hide();
                });
              }
            }
          }
        }
      }
    }
  });

  _exports.default = _default;
});
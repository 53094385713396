define("in4m-web/helpers/application-description", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.applicationDescription = applicationDescription;
  _exports.default = void 0;

  function applicationDescription(params
  /*, hash*/
  ) {
    var appDescription;

    if (params) {
      switch (params[0]) {
        case 1:
          appDescription = 'Application Management';
          break;

        case 2:
          appDescription = 'Point of Sale';
          break;

        case 3:
          appDescription = 'Data Center Infrastructure Management';
          break;

        default:
          appDescription = 'Data Center Infrastructure Management';
          break;
      }
    }

    return appDescription;
  }

  var _default = Ember.Helper.helper(applicationDescription);

  _exports.default = _default;
});
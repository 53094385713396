define("in4m-web/helpers/if-or", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ifOr = ifOr;
  _exports.default = void 0;

  function ifOr(condition) {
    if (typeof condition === 'undefined' || condition.length === 0) {
      return false;
    } else if (condition.length === 1) {
      return condition[0];
    } else if (condition.length === 2) {
      return condition[0] || condition[1];
    } else if (condition.length === 3) {
      return condition[0] || condition[1] || condition[2];
    } else if (condition.length === 4) {
      return condition[0] || condition[1] || condition[2] || condition[3];
    } else if (condition.length === 5) {
      return condition[0] || condition[1] || condition[2] || condition[3] || condition[4];
    } // TODO: Try to refactor this to use array.every() to allow more params dynamically?
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/every

  }

  var _default = Ember.Helper.helper(ifOr);

  _exports.default = _default;
});
define("in4m-web/controllers/installations/download-install", ["exports", "in4m-web/utils/alerts"], function (_exports, _alerts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    downloadUrl: Ember.computed('apiPath', function () {
      var _this = this;

      var baseUrl = _this.get('apiPath.in4mApiUrl') + '/' + _this.get('apiPath.in4mPubPath');

      var downloadUrl = baseUrl + '/' + 'commandcentersetup.exe';
      return downloadUrl;
    }),
    verificationIdComputed: Ember.computed('model.verificationId', function () {
      var verificationId = this.get('model.verificationId');

      if (verificationId) {
        var verificationIdParts = verificationId.match(/.{1,4}/g);
      }

      if (verificationIdParts.length > 0) {
        //contains 8 characters all the time.
        return verificationIdParts[0] + ' ' + verificationIdParts[1];
      }
    }),
    actions: {
      download: function download() {
        _alerts.default._info('Your download should begin shortly.');

        window.location.replace(this.downloadUrl);
      }
    }
  });

  _exports.default = _default;
});
define("in4m-web/controllers/machine/index", ["exports", "in4m-web/utils/alerts"], function (_exports, _alerts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    showDuplicates: false,
    selectedMachines: null,
    userConfirmDelete: '',
    filteredMachines: Ember.computed('model', 'showDuplicates', function () {
      if (this.showDuplicates) {
        return this.get('model');
      }

      return this.get('model').filter(function (c) {
        return c.isDuplicate == false;
      });
    }),
    isCogHidden: Ember.computed('session.selectedBusinessEntity', function () {
      var hasPermissionToViewCog = true;
      var selectedBusinessEntity = this.get('session.selectedBusinessEntity');
      var user = this.get('session.userObject');
      var hasPermissionToEditServices = user.hasPermission('canEdit', 'in4mMachines', selectedBusinessEntity);

      if (hasPermissionToEditServices) {
        hasPermissionToViewCog = false;
      }

      return hasPermissionToViewCog;
    }),
    columns: function () {
      return [{
        "template": "custom/select-box",
        "useFilter": false
      }, {
        "template": "custom/machineGear",
        "isHidden": this.isCogHidden
      }, {
        "propertyName": "parentName",
        "title": "Parent Account"
      }, {
        "propertyName": "dnsName",
        "title": "Machine DNS Name",
        "routeName": "machine.manage" //specifying routeName adds a link and takes the user to the appropriate route

      }, {
        "propertyName": "utgNickname",
        "title": "UTG Nickname"
      }, {
        "propertyName": "realTimeMachineStatus",
        "title": "Machine Status"
      }, {
        "propertyName": "utgStatus",
        "title": "UTG Status"
      }, {
        "propertyName": "utgVersion",
        "title": "UTG Version"
      }, {
        "title": "Agent Version",
        "className": "columnMinWidth",
        "propertyName": "agentVersion"
      }, {
        "propertyName": "merchantName",
        "title": "Merchant"
      }, {
        "propertyName": "merchantFileId",
        "title": "File Id"
      }, {
        "propertyName": "utgLocationOrSerial",
        "title": "Location/Serial"
      }, {
        "propertyName": "utgLane",
        "title": "Lane"
      }, {
        "propertyName": "nickname",
        "title": "Machine Nickname"
      }, {
        "template": "custom/dateFormatter",
        "propertyName": "machineStatus.lastUpdatedAt",
        "title": "Last Known Communication",
        "sortedBy": "machineStatus.lastUpdatedAt"
      }, {
        "template": "custom/nextKnownCommunication",
        "title": "Next Predicted Communication",
        "sortedBy": "machineStatus.nextPredictedUpdateAt"
      }, {
        "template": "custom/displayInGigaBytes",
        "title": "Available Memory",
        "sortedBy": "machineStatus.availableMemory"
      }, {
        "propertyName": "machineStatus.cpuUsage",
        "title": "CPU Usage (%)"
      }, {
        "template": "custom/displaySecondsInDays",
        "title": "Machine Uptime (dd:hh:mm:ss)",
        "sortedBy": "machineStatus.uptime"
      }, {
        "propertyName": "operatingSystem",
        "title": "Operating System"
      }, {
        "propertyName": "isDuplicate",
        "title": "Possible Duplicate"
      }];
    }.property('isCogHidden'),
    actions: {
      showDeleteMachineWarningModal: function showDeleteMachineWarningModal(e) {
        this.set('userConfirmDelete', '');
        var id = parseInt(e.id);
        var selectedMachines = this.get('selectedMachines');
        var selectedMachineIds = selectedMachines.map(function (x) {
          return parseInt(x.id);
        });
        $('#deleteMachineWarningModal').modal('show');

        if (selectedMachineIds.includes(id)) {
          return true;
        }
      },
      dismissDeleteMachineModal: function dismissDeleteMachineModal() {
        $('#deleteMachineWarningModal').modal('hide');
        $('#deleteMachineConfirmationModal').modal('hide');
        this.get('selectedMachines').clear();
        this.set('userConfirmDelete', '');
      },
      displayDataChanged: function displayDataChanged(e) {
        this.set('selectedMachines', e.selectedItems);
      },
      deleteMachineAction: function deleteMachineAction() {
        var _this = this;

        var records = this.get('selectedMachines');
        var userConfirmDelete = this.get('userConfirmDelete').trim().toUpperCase();
        var validator = $('#deleteMachineForm').validate();

        if ('DELETE' !== userConfirmDelete) {
          validator.showErrors({
            'userConfirmDelete': 'Please enter DELETE into the textbox to confirm.'
          });
        } else {
          $("#deleteMachineForm").data('validator').resetForm();
          $('#progressHudOverlay').show();
          records.forEach(function (record) {
            record.destroyRecord().then(function () {
              $('#progressHudOverlay').hide();

              _alerts.default._success(record.nickname + ' was successfully deleted.', 'Machine(s) Deleted');
            }, function (error) {
              $('#progressHudOverlay').hide();

              _this.get('errorhandler').handleError(error, record.nickname + ' failed to delete.');
            });
          });

          _this.send('dismissDeleteMachineModal');
        }
      },
      exportMachines: function exportMachines() {
        var r = confirm("Export may take a few minutes to download ALL machines on this tab. Are you sure you want to continue?"); //Note: The data generated for export from the machines tab and components tab is now identical. Therefore, the code from here down is IDENTICAL in each.

        if (r == true) {
          var _this = this;

          _alerts.default._info('Download of export.csv should begin shortly.'); // build url


          var beId = this.get('session.selectedBusinessEntity.id');

          var baseUrl = _this.get('apiPath.in4mApiUrl') + '/' + _this.get('apiPath.in4mApiPath');

          var downloadUrl = baseUrl + "/businessEntities/".concat(beId, "/machinestatus/export");
          var authHeaderValue = 'Token token="' + this.get('session.sessionObject.accessToken') + '"'; //trigger Ajax request to load binary data

          var oReq = new XMLHttpRequest();
          oReq.open("GET", downloadUrl, true);
          oReq.setRequestHeader('Authorization', authHeaderValue); //When using setRequestHeader(), you must call it after calling open(), but before calling send(). If this method is called several times with the same header, the values are merged into one single request header. (See: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/setRequestHeader)

          oReq.responseType = "arraybuffer";
          /* For more information on "Receiving binary data using JavaScript typed arrays",
             See: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/Sending_and_Receiving_Binary_Data
          */

          oReq.onload = function (oEvent) {
            var arrayBuffer = oReq.response; // Note: not oReq.responseText

            if (arrayBuffer) {
              var byteArray = new Uint8Array(arrayBuffer);
              /* Package data as Blob for download. See:
              	- https://stackoverflow.com/questions/3665115/how-to-create-a-file-in-memory-for-user-to-download-but-not-through-server
              	- https://developer.mozilla.org/en-US/docs/Web/API/Blob)
              */

              var blob = new Blob([byteArray], {
                type: "application/octet-stream"
              }); //trigger save prompt

              saveAs(blob, "export.csv");
            }
          };

          oReq.send(null);
        }
      },
      machinesInfo: function machinesInfo() {
        $('#machinesInfoModal').modal('show');
      },
      showMachinesInfoDoc: function showMachinesInfoDoc() {
        var _this = this;

        window.open(_this.get('apiPath.referenceDocumentUrl'), '_blank');
      }
    }
  });

  _exports.default = _default;
});
define("in4m-web/serializers/component-config", ["exports", "ember-data", "in4m-web/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    unknownConfigs: [],
    attrs: {
      component: 'componentId',
      config: {
        embedded: 'always'
      }
    },
    normalize: function normalize(modelClass, resourceHash, prop) {
      if (prop === 'componentConfig') {
        var _this = this;

        var payload = resourceHash.config;

        if (payload) {
          if (!payload.deviceConfig) {
            payload.deviceConfig = {
              "id": Ember.guidFor(payload)
            };
          }

          _this.get('unknownConfigs').config = {};

          for (var key in payload) {
            _this.get('unknownConfigs').config[key] = {};

            for (var key2 in payload[key]) {
              //assuming the polymorphic items are one level deeper. TODO: This needs to be more dynamic
              if (Array.isArray(payload[key][key2])) {
                _this.get('unknownConfigs').config[key][key2] = [];
                payload[key][key2].forEach(function (item, index) {
                  if (item.cdType) {
                    var matchingRecordType = _this.get('modelGenerator').tuneUp.filterBy('properties.cdType.s4-model-key', item.cdType); //filtering the selected interface from the schema


                    if (matchingRecordType.length > 0) {
                      var modelName = matchingRecordType[0].properties.cdType["s4-model-name"];
                      item.type = modelName;
                    } else {
                      //if any of the configuration object is not present in the schema. Add it to the temporary unknownConfigs json object inorder to send back to the server while doing a POST request
                      //deviceConfig is not handled through the model generator/form generator, so exclude from adding to the unknownConfigs
                      if (key !== 'deviceConfig') {
                        _this.get('unknownConfigs').config[key][key2].push(item);

                        resourceHash.config[key][key2].splice(index, 1);
                      }
                    }
                  }
                });
              }
            }
          }
        }
      }

      return this._super.apply(this, arguments);
    },
    serialize: function serialize(snapshot, options) {
      var json = this._super.apply(this, arguments);

      var unknownConfigs = this.unknownConfigs; //get all the unknown configs that are added while normalizing the payload

      if (unknownConfigs) {
        var payload = unknownConfigs.config;

        for (var key in payload) {
          for (var key2 in payload[key]) {
            if (Array.isArray(payload[key][key2])) {
              payload[key][key2].forEach(function (unknownConfig) {
                if (unknownConfig.cdType) {
                  json.config[key][key2].push(unknownConfig);
                }
              });
            }
          }
        }
      }

      return json;
    }
  });

  _exports.default = _default;
});
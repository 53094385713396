define("in4m-web/templates/custom/select-box-all", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VhKp2Dcf",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[12,\"class\",[30,[\"fa \",[29,\"if\",[[29,\"is-equal\",[[25,[\"_selectedItems\",\"length\"]],0],null],\"fa-square-o\",\"fa-check-square-o\"],null]]]],[9],[0,\" (\"],[1,[25,[\"_selectedItems\",\"length\"]],false],[0,\")\"],[3,\"action\",[[24,0,[]],\"toggleAllSelection\"]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/custom/select-box-all.hbs"
    }
  });

  _exports.default = _default;
});
define("in4m-web/templates/custom/profile-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jlR3po/I",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"record\",\"ComponentProfileName\"]]],null,{\"statements\":[[0,\"  \"],[1,[25,[\"record\",\"ComponentProfileName\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  No Profile\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/custom/profile-name.hbs"
    }
  });

  _exports.default = _default;
});
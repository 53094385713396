define("in4m-web/components/schedule-tasks-modal", ["exports", "in4m-web/utils/instruction-values"], function (_exports, _instructionValues) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    casenumber: '',
    instructionValues: Ember.computed('instructionValues', function () {
      return (0, _instructionValues.default)();
    }),
    //returns true if at least one of the selected components has a profileId
    isUpdateCommandCenterHidden: Ember.computed('selectedComponents.[]', 'selectedComponent', function () {
      var selectedComponents = this.selectedComponents;

      if (selectedComponents) {
        return selectedComponents.find(function (item) {
          if (item.hasOwnProperty('ComponentProfileId')) {
            return !!item.ComponentProfileId;
          } else {
            return !!item.get('profile').get('id');
          }
        });
      }

      if (!this.selectedComponent) {
        return false;
      }

      if (this.selectedComponent.hasOwnProperty('ComponentProfileId')) {
        return !!this.selectedComponent.ComponentProfileId;
      } else {
        return !!this.selectedComponent.get('profile').get('id');
      }
    }),
    disableTaskDropdown: Ember.computed('selectedComponentType', function () {
      if (this.get('selectedComponentType')) {
        if (this.get('selectedComponentType') == '') {
          return true;
        } else {
          return false;
        }
      }

      return true;
    }),
    taskTypes: Ember.computed('selectedComponentType', function () {
      var taskTypes = [{
        "text": "Please select",
        "value": ''
      }];

      switch (this.get('selectedComponentType')) {
        case '1':
          // UTG
          taskTypes = taskTypes.concat([{
            "text": "Start/Stop",
            "value": 1
          }, {
            "text": "Configuration Changes",
            "value": 2
          }, {
            "text": "Deploy UTG Version",
            "value": 3
          }, {
            "text": "Miscellaneous",
            "value": 4
          }, {
            "text": "Set Offline",
            "value": 6
          }, {
            "text": "Upload Logs",
            "value": 15
          }]);
          break;

        case '2':
          // Agent
          taskTypes = taskTypes.concat([{
            "text": "Deploy Agent Version",
            "value": 5
          }, {
            "text": "Upload Logs",
            "value": 15
          }]);
          break;

        default:
          taskTypes = [{
            "text": "Please select",
            "value": ''
          }];
          break;
      }

      return taskTypes;
    }),
    currentUTGVersion: Ember.computed('selectedComponents.[]', function () {
      var selectedComponents = this.get('selectedComponents');

      if (selectedComponents && selectedComponents.length > 0) {
        // This control can still be called from service detail page which loads data using ember data. However, this computed property is not used on that page so we don't
        // need to look at alternative locations to get utg version
        return selectedComponents[0].UtgVersion;
      }
    }),
    currentAgentVersion: Ember.computed('selectedComponents.[]', function () {
      var selectedComponents = this.get('selectedComponents');

      if (selectedComponents && selectedComponents.length > 0) {
        // This control can still be called from service detail page which loads data using ember data. However, this computed property is not used on that page so we don't
        // need to look at alternative locations to get utg version
        var agentVersion = selectedComponents[0].AgentVersion;

        if (!agentVersion) {
          agentVersion = "Unknown";
        }

        return agentVersion;
      }
    }),
    availableUTGVersions: Ember.computed('selectedComponent', function () {
      var _this = this;

      var utgManifestLocation = this.get('apiPath.utgManifestLocation');
      Ember.$.get(utgManifestLocation).then(function (data) {
        _this.set('availableUTGVersions', data.product);
      });
    }),
    availableAgentVersions: Ember.computed('selectedComponent', function () {
      var _this = this;

      var agentManifestLocation = this.get('apiPath.agentManifestLocation');
      Ember.$.get(agentManifestLocation).then(function (data) {
        _this.set('availableAgentVersions', data.product);
      });
    }),
    subTaskTypes: Ember.computed('selectedTaskType', function () {
      var _this = this;

      var subTaskTypes = [];
      var availableUTGVersions = this.get('availableUTGVersions');
      var availableAgentVersions = this.get('availableAgentVersions');
      var changeUTGVersionSubTaskTypes = [];
      var changeAgentVersionSubTaskTypes = [];
      this.set('generalAvailability', false);

      switch (this.get('selectedTaskType')) {
        case '1':
          subTaskTypes = [{
            "text": "Start UTG",
            "status": 1,
            "taskStatus": 1,
            "info": " Starts the UTG as a service."
          }, {
            "text": "Stop UTG",
            "status": 2,
            "taskStatus": 2,
            "info": "Stops the UTG whether running as a service or in Stand Alone."
          }, {
            "text": "Restart UTG",
            "status": 3,
            "taskStatus": 3,
            "info": " Stops and then restarts the UTG."
          }];
          break;

        case '2':
          subTaskTypes = [{
            "text": "Update UTG",
            "status": 4,
            "taskStatus": 4,
            "info": "Stops the UTG, updates the UTG to the current Command Center configuration, and then restarts the UTG as a service."
          }, {
            "text": "Rollback UTG",
            "status": 5.1,
            "taskStatus": 5,
            "info": "Stops the UTG, rolls the configuration back to the previously installed configuration, and then restarts the UTG."
          }];

          if (!this.get('isUpdateCommandCenterHidden')) {
            subTaskTypes.push({
              "text": "Update Command Center",
              "status": 5.2,
              "taskStatus": 5,
              "info": "Updates the Command Center to the UTG’s current configuration."
            });
          }

          break;

        case '3':
          if (availableUTGVersions) {
            availableUTGVersions.forEach(function (availableUTGVersion, index) {
              var tempSubTaskType = {};
              var generalAvailability = availableUTGVersion.generalAvailability;
              tempSubTaskType["text"] = availableUTGVersion.version;
              tempSubTaskType["status"] = Number(8 + '.' + (index + 10));
              tempSubTaskType["taskStatus"] = 8;
              tempSubTaskType["generalAvailability"] = generalAvailability;
              changeUTGVersionSubTaskTypes.push(tempSubTaskType);
              _this.instructionValues[tempSubTaskType["status"]] = '{"downloadLocation":"' + availableUTGVersion.downloadLocation + '"}';

              if (generalAvailability && !_this.get('generalAvailability')) {
                _this.set('generalAvailability', tempSubTaskType["status"]);

                _this.sendAction('updateStatusInstructions', {
                  'selectedStatus': 8,
                  'instructions': '{"downloadLocation":"' + availableUTGVersion.downloadLocation + '"}'
                });
              }
            });
          }

          subTaskTypes = changeUTGVersionSubTaskTypes;
          break;

        case '4':
          subTaskTypes = [{
            "text": "Reset UTG Slot",
            "status": 7,
            "taskStatus": 7,
            "info": "The slot is what ties the UTG to the machine. If the UTG is moved to another machine (or if the IP address changes) the slot must be reactivated."
          }];
          break;

        case '5':
          if (availableAgentVersions) {
            availableAgentVersions.forEach(function (availableAgentVersion, index) {
              var tempSubTaskType = {};
              var generalAvailability = availableAgentVersion.generalAvailability;
              tempSubTaskType["text"] = availableAgentVersion.version;
              tempSubTaskType["status"] = Number(9 + '.' + (index + 10));
              tempSubTaskType["taskStatus"] = 9;
              tempSubTaskType["generalAvailability"] = generalAvailability;
              changeAgentVersionSubTaskTypes.push(tempSubTaskType);
              _this.instructionValues[tempSubTaskType["status"]] = '{"downloadLocation":"' + availableAgentVersion.downloadLocation + '"}';

              if (generalAvailability && !_this.get('generalAvailability')) {
                _this.set('generalAvailability', tempSubTaskType["status"]);

                _this.sendAction('updateStatusInstructions', {
                  'selectedStatus': 9,
                  'instructions': '{"downloadLocation":"' + availableAgentVersion.downloadLocation + '"}'
                });
              }
            });
          }

          subTaskTypes = changeAgentVersionSubTaskTypes;
          break;

        case '6':
          var instructions = ["1 HOUR", "4 HOURS", "8 HOURS", "12 HOURS", "16 HOURS", "20 HOURS", "24 HOURS"];
          instructions.forEach(function (instruction, index) {
            subTaskTypes.push({
              "text": instruction.toLowerCase(),
              "status": 13 + "." + index,
              "taskStatus": 13,
              'instructions': '{"OFFLINETIME":"' + instruction + '"}'
            });
            _this.instructionValues[subTaskTypes[index]["status"]] = '{"OFFLINETIME":"' + instructions[index] + '"}';
          });

          _this.set('generalAvailability', subTaskTypes[0]["status"]);

          _this.sendAction('updateStatusInstructions', {
            'selectedStatus': 13,
            'instructions': '{"OFFLINETIME":"' + instructions[0] + '"}'
          });

          break;

        case '15':
          // upload logs task
          _this.sendAction('updateStatusInstructions', {
            'selectedStatus': 15,
            'instructions': '{}'
          });

          break;

        default:
          subTaskTypes = [];
          break;
      }

      return subTaskTypes;
    }),
    actions: {
      changed: function changed(selectedValue) {
        // called when the task type changes
        var selectedStatus;
        var instructions;
        var subTaskTypes = this.get('subTaskTypes');

        var _this = this;

        subTaskTypes.filter(function (subTaskType) {
          if (subTaskType.status == selectedValue) {
            selectedStatus = subTaskType.taskStatus;
            instructions = _this.get('instructionValues')[selectedValue];
          }
        });

        _this.sendAction('updateStatusInstructions', {
          'selectedStatus': selectedStatus,
          'instructions': instructions
        });
      },
      closeModal: function closeModal() {
        // called when the user exits the modal
        this.sendAction('closeModal');
      },
      changeStatus: function changeStatus() {
        // called when the user saves the modal
        var taskType = this.get('selectedTaskType');

        if (taskType == 15) {
          // upload logs
          var fromDate = moment($('#fromdaterangepicker').val()).format(this.get('fmt'));
          var toDate = moment($('#todaterangepicker').val()).format(this.get('fmt'));
          var casenumber = $('#casenumber').val();
          var instructions = JSON.stringify({
            TargetDirectory: "",
            StartDate: fromDate,
            EndDate: toDate,
            Label: casenumber
          }); //this.sendAction('updateStatusInstructions', {'selectedStatus':taskType, 'instructions':instructions});
        }

        this.sendAction('changeStatus', {
          'selectedStatus': taskType,
          'instructions': instructions
        });
      }
    }
  });

  _exports.default = _default;
});
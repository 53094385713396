define("in4m-web/components/s4-computed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['col-xs-2', ' col-sm-3', ' col-md-3']
  });

  _exports.default = _default;
});
define("in4m-web/templates/components/form-generator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9n2GK46V",
    "block": "{\"symbols\":[\"value\",\"key\",\"s4group\",\"value\",\"key\"],\"statements\":[[1,[23,\"form-validator\"],false],[0,\"\\n\"],[7,\"fieldset\"],[12,\"disabled\",[23,\"isEditDisabled\"]],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"model\",\"schema\",\"s4-groups\"]]],null,{\"statements\":[[4,\"each\",[[25,[\"model\",\"schema\",\"s4-groups\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[1,[29,\"s4-group\",null,[[\"model\",\"s4group\"],[[25,[\"model\"]],[24,3,[]]]]],false],[0,\"\\n\"],[4,\"each\",[[29,\"-each-in\",[[25,[\"model\",\"schema\",\"properties\"]]],null]],null,{\"statements\":[[4,\"if\",[[24,4,[\"s4-display\"]]],null,{\"statements\":[[4,\"if\",[[29,\"is-equal\",[[24,3,[\"id\"]],[24,4,[\"s4-group-id\"]]],null]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\\t\\t\"],[1,[29,\"form-element\",null,[[\"name\",\"property\",\"model\",\"isEditDisabled\",\"tagName\",\"generateCertificate\",\"loadCertificate\"],[[24,5,[]],[24,4,[]],[25,[\"model\"]],[25,[\"isEditDisabled\"]],[25,[\"elementId\"]],\"generateCertificate\",\"loadCertificate\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[4,5]},null],[0,\"\\t\\t\\t\"],[7,\"hr\"],[11,\"class\",\"col-lg-12 col-md-12\"],[9],[10],[0,\"\\n\"]],\"parameters\":[3]},null]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[29,\"-each-in\",[[25,[\"model\",\"schema\",\"properties\"]]],null]],null,{\"statements\":[[4,\"if\",[[24,1,[\"s4-display\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[1,[29,\"form-element\",null,[[\"name\",\"property\",\"model\",\"isEditDisabled\",\"tagName\"],[[24,2,[]],[24,1,[]],[25,[\"model\"]],[25,[\"isEditDisabled\"]],[25,[\"elementId\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},null]],\"parameters\":[]}],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/components/form-generator.hbs"
    }
  });

  _exports.default = _default;
});
define("in4m-web/controllers/component-profiles/component-profile/api-interfaces/index", ["exports", "in4m-web/utils/drop-down-list"], function (_exports, _dropDownList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    selectedInterface: null,
    componentProfile: Ember.inject.controller('componentProfiles.componentProfile'),
    isEditDisabled: Ember.computed('model.firstObject', function () {
      var hasPermissionToEditProfileOnSelectedBE = this.get('componentProfile.canEditSelectedProfile');
      return !hasPermissionToEditProfileOnSelectedBE;
    }),
    customClasses: {
      'pageSizeWrapper': 'col-xs-12 col-sm-8 col-md-6'
    },
    columns: function () {
      return [{
        "propertyName": "taskDesc",
        "title": "Description"
      }, {
        "template": "custom/api-interface-type",
        "title": "Type",
        "sortedBy": "schema.properties.cdType.s4-caption",
        "filteredBy": "schema.properties.cdType.s4-caption"
      }, {
        "template": "custom/delete-api-interface",
        "useFilter": false,
        "templateForSortCell": 'custom/add-record',
        "isHidden": this.isEditDisabled
      }];
    }.property('isEditDisabled'),
    dropdownListValues: Ember.computed('dropdownList', function () {
      return (0, _dropDownList.default)();
    }),
    actions: {
      displayDataChangedAction: function displayDataChangedAction(data) {
        if (data.get('selectedItems').length > 0) {
          this.transitionToRoute('componentProfiles.componentProfile.apiInterfaces.apiInterface', data.get('selectedItems').get('firstObject').get('id'));
        }
      },
      addRecord: function addRecord(record) {
        this.transitionToRoute('componentProfiles.componentProfile.apiInterfaces.new');
      },
      showDeleteModal: function showDeleteModal(record) {
        this.set('selectedInterface', record);
        $('#deleteAPIModal').modal('show');
      },
      dismissDeleteModal: function dismissDeleteModal(record) {
        $('#deleteAPIModal').modal('hide');
      },
      deleteRecord: function deleteRecord() {
        var record = this.selectedInterface;

        var _this = this;

        if (record) {
          $('#progressHudMessage').html('Updating...');
          $('#progressHudOverlay').show();
          record.deleteRecord();

          _this.send('saveProfile');
        }

        $('#deleteAPIModal').modal('hide');
      }
    }
  });

  _exports.default = _default;
});
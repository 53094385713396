define("in4m-web/helpers/is-contains", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isEqual = isEqual;
  _exports.default = void 0;

  function isEqual(params
  /*, hash*/
  ) {
    var string1 = params[0];
    var string2 = params[1];

    if (string1 && string2) {
      if (string1.indexOf(string2) !== -1) {
        return true;
      }
    }

    return false;
  }

  var _default = Ember.Helper.helper(isEqual);

  _exports.default = _default;
});
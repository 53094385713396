define("in4m-web/routes/installations/download-install", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    renderTemplate: function renderTemplate() {
      this.render('installations/downloadInstall', {
        into: 'installations',
        outlet: 'modal'
      });
      this.render('installations/index', {
        into: 'installations',
        controller: 'installations/index',
        model: this.modelFor('installations')
      });
    },
    model: function model(params) {
      $('#progressHudMessage').html('Retrieving Install...');
      $('#progressHudOverlay').show();

      var _this = this;

      return this.store.findRecord('installation', params.id).then(function (installation) {
        $('#progressHudOverlay').hide();
        return installation;
      }, function (e) {
        console.log(e);
      });
    },
    afterModel: function afterModel(transition) {
      Ember.run.next(function () {
        $('#installationModal').modal('show');
        $('#progressHudMessage').html('Loading...');
        $('#progressHudOverlay').hide();
      });
    }
  });

  _exports.default = _default;
});
define("in4m-web/services/model-generator", ["exports", "ember-data", "in4m-web/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    apiPath: Ember.inject.service(),
    tuneUp: [],
    createModels: function createModels(componentType, componentVersion) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        window.Application.store = window.Application.__container__.lookup('service:store'); // NOTE: to simplify updating tune-up settings, temporarily switch from loading the config from the database to the config-schema.json file.
        // Toggle the useDatabaseSchema variable to enable/disable

        var useDatabaseSchema = true;
        var schemaURL = 'config-schema.json';

        if (useDatabaseSchema) {
          var schemaURL = _this.get('apiPath.in4mApiUrl') + '/' + _this.get('apiPath.in4mApiPath') + '/componentconfigschemas?componenttype=' + componentType + '&componentversion=' + componentVersion;
        }

        $.ajax({
          url: schemaURL,
          type: 'GET'
        }).then(function (response) {
          var embeddedRecords = {}; //json to store all the embedded record types

          var tuneUpItems = [];
          var polyMorphicBaseModel = window.Application.store.modelFor('polymorphicRecordType');

          if (useDatabaseSchema) {
            // if using config-schema.json then it is already parsed into json
            response = $.parseJSON(response.Schema);
          }

          for (var key in response.properties) {
            parseItem(response.properties[key], key, 'componentConfig', false);
          }

          function parseItem(schema, keyName, parentKeyName, s4hintType) {
            var modelAttributes = {};
            var subSchemas = [];
            var properties = schema.properties; //iterating over schema properties from each polymorphic item

            for (var key in properties) {
              var emberModelAttribute = key.camelize();
              var emberModelAttributeType = properties[key].type; //checking to see if any of the polymorphic item contains an object inside

              if (emberModelAttributeType === 'object') {
                // order object's properties
                properties[key].properties = orderKeys(properties[key].properties, 's4-order');

                if (properties[key]['s4-hint-type'] == 'tuneUpObject') {
                  tuneUpItems.pushObject(properties[key]);
                }

                var tempArr; //for each object in the schema, there is a need to create a serializer with embedded always

                if (embeddedRecords.hasOwnProperty(keyName)) {
                  //checking to see if the property already exists in the embeddedRecords json
                  tempArr = embeddedRecords[keyName];
                } else {
                  tempArr = [];
                }

                tempArr.pushObject(emberModelAttribute);
                embeddedRecords[keyName] = tempArr;
                modelAttributes[emberModelAttribute] = _emberData.default.belongsTo(emberModelAttribute, {
                  async: false
                }); //setting up a belongsTo relationship if the property type is an object
                //push all complex objects from schema into subSchemas array, which later loops through to create models

                var tempJson = {};
                tempJson.keyName = emberModelAttribute;

                if (properties[key].properties.cdType) {
                  //if there is a model name present in the schema
                  tempJson.keyName = properties[key].properties.cdType['s4-model-name'];
                }

                tempJson.schema = properties[key];
                tempJson.parentKeyName = keyName;
                tempJson.s4hintType = properties[key]['s4-hint-type'];
                subSchemas.pushObject(tempJson);
              } else if (emberModelAttributeType === 'array' && properties[key]['s4-hint-type'] == 'polyMorphicObject') {
                var tempArr; //for each object in the schema, there is a need to create a serializer with embedded always

                if (embeddedRecords.hasOwnProperty(keyName)) {
                  //checking to see if the property already exists in the embeddedRecords json
                  tempArr = embeddedRecords[keyName];
                } else {
                  tempArr = [];
                }

                tempArr.pushObject(emberModelAttribute);
                embeddedRecords[keyName] = tempArr; // reopen  the polymorphic record Type

                modelAttributes[emberModelAttribute] = _emberData.default.hasMany('polymorphicRecordType', {
                  polymorphic: true,
                  async: false
                }); //setting up a belongsTo relationship if the property type is an object

                var reopen = {};
                reopen[keyName] = _emberData.default.belongsTo(keyName, {
                  inverse: emberModelAttribute
                });
                polyMorphicBaseModel.reopen(reopen);
                properties[key].items.forEach(function (polyMorphicItem) {
                  tuneUpItems.pushObject(polyMorphicItem);
                  var tempJson = {};
                  tempJson.keyName = polyMorphicItem.properties.cdType['s4-model-name'];
                  tempJson.schema = polyMorphicItem;
                  tempJson.parentKeyName = key;
                  tempJson.s4hintType = properties[key]['s4-hint-type'];
                  subSchemas.pushObject(tempJson);
                });
                window.Application.unregister('model:' + emberModelAttribute.dasherize(), _emberData.default.Model.extend()); //model creation extending the polymorphic base model

                window.Application.register('model:' + emberModelAttribute.dasherize(), _emberData.default.Model.extend()); //model creation extending the polymorphic base model
              } else {
                if (emberModelAttributeType === 'array') {
                  properties[key].items.properties = orderKeys(properties[key].items.properties, 's4-order');
                }

                if (properties[key]['s4-complex-array']) {
                  modelAttributes[emberModelAttribute] = _emberData.default.attr('complex-array');
                } else {
                  //add all plain properties as ember model attributes
                  if (properties[key]['s4-validations']) {
                    modelAttributes[emberModelAttribute] = _emberData.default.attr(emberModelAttributeType == 'integer' ? 'number' : emberModelAttributeType, {
                      defaultValue: properties[key]['s4-validations'].defaultValue
                    });
                  } else {
                    modelAttributes[emberModelAttribute] = _emberData.default.attr(emberModelAttributeType == 'integer' ? 'number' : emberModelAttributeType);
                  }
                }
              }
            } //storing the schema in to the model


            schema.properties = orderKeys(schema.properties, 's4-order');
            modelAttributes.schema = schema; //this relationship is needed, removing it for now, but will remove the comments once there are no issues after all the testing.
            // if (parentKeyName) {
            // 	modelAttributes[parentKeyName] = DS.belongsTo(parentKeyName,{async:false});
            // }

            if (s4hintType == 'polyMorphicObject') {
              //since the schema contains different kind of objects- check is needed to ensure polymorphic objects are created extending polymorphcbasemodel
              //after the loop execution completes, create a model
              window.Application.unregister('model:' + keyName.dasherize(), polyMorphicBaseModel.extend(modelAttributes)); //model creation extending the polymorphic base model

              window.Application.register('model:' + keyName.dasherize(), polyMorphicBaseModel.extend(modelAttributes)); //model creation extending the polymorphic base model
            } else {
              //this is a one off since everything else for a config is set in the model generator
              if (keyName == 'config') {
                embeddedRecords['config'].push('deviceConfig');
                modelAttributes['deviceConfig'] = _emberData.default.belongsTo('componentProfile/config/deviceConfig', {
                  async: false
                });
              } //after the loop execution completes, create a model


              window.Application.unregister('model:' + keyName.dasherize(), _emberData.default.Model.extend(modelAttributes)); //model creation extending the polymorphic base model

              window.Application.register('model:' + keyName.dasherize(), _emberData.default.Model.extend(modelAttributes)); //model creation extending the polymorphic base model
            } //calling parseSchema again for all the object types


            subSchemas.forEach(function (subSchema) {
              parseItem(subSchema.schema, subSchema.keyName, subSchema.parentKeyName, subSchema.s4hintType);
            });
          }

          function orderKeys(obj, orderDirective) {
            var tempArr = [];

            for (var key in obj) {
              tempArr.push({
                'key': key,
                'value': obj[key]
              });
            } // sort values


            tempArr.sort(function (a, b) {
              // support sorting properties with no s4-order lower than those that do have an order defined
              if (isNaN(a.value[orderDirective] - b.value[orderDirective])) {
                return 1;
              } else {
                return a.value[orderDirective] - b.value[orderDirective];
              }
            });
            var tempJson = {}; // set values

            for (var i = 0; i < tempArr.length; i++) {
              tempJson[tempArr[i].key] = tempArr[i].value;
            }

            return tempJson;
          }

          _this.set('tuneUp', tuneUpItems); //storing all polymorphic objects for later use


          createSerializersForEmbeddedRecords(); //creating serializers here

          function createSerializersForEmbeddedRecords() {
            for (var key in embeddedRecords) {
              var attrs = {};
              var serializerAttributes = embeddedRecords[key];
              serializerAttributes.forEach(function (item) {
                attrs[item] = {
                  embedded: 'always'
                };
              });
              window.Application.unregister('serializer:' + key.dasherize(), _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
                attrs: attrs
              }));
              window.Application.register('serializer:' + key.dasherize(), _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
                attrs: attrs
              }));
            }
          }

          resolve(response);
        }, function (reason) {
          reject(reason);
        });
      });
    }
  });

  _exports.default = _default;
});
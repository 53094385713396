define("in4m-web/routes/component/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    permKey: 'in4mUtgServices',
    permAction: 'canView',
    showChildrenAllowed: true,
    //add showChildren to true wherever the show children checkbox needed on the side bar
    model: function model(params) {
      $('#progressHudOverlay').show();

      var _this = this;

      var navigation = _this.get('navigation');

      var hasPermission = navigation.checkPermissions(_this.get('session.selectedBusinessEntity'));

      var showChildren = _this.get('session.showChildren');

      if (hasPermission) {
        var beId = _this.get('session.selectedBusinessEntity.id');

        var restQuery = this.get('restQuery');
        return restQuery.queryComponents(beId).then(function (components) {
          $('#progressHudOverlay').hide();
          return components;
        }).catch(function (err) {
          $('#progressHudOverlay').hide();
          return _this.get('errorhandler').handleError(err, 'Error while making a request.');
        });
      }
    },
    afterModel: function afterModel() {
      $('#progressHudOverlay').hide();
    },
    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    },
    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting) {
        var indexController = this.controllerFor('component.index');
        var pollingInterval = indexController.pollingInterval;

        if (pollingInterval) {
          Ember.run.cancel(pollingInterval);
        }
      }
    }
  });

  _exports.default = _default;
});
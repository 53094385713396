define("in4m-web/controllers/tasks/index", ["exports", "in4m-web/utils/alerts"], function (_exports, _alerts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    filter: null,
    filterOptions: null,
    init: function init() {
      this._super.apply(this, arguments); // Add a default filter to only return tasks where the runafter is > today - 30 days


      var dateOffset = 1000 * 60 * 60 * 24 * 30; // 30 days ago. milliseconds * seconds * minutes * hours * days

      var targetDate = new Date();
      targetDate.setTime(targetDate.getTime() - dateOffset);
      targetDate = moment(targetDate).format('MM/DD/YYYY'); // date queries use LT and GT operators. However, the GT operator includes the selected date (greater than midnight) so in the UI we label it 'Greater than or equals'

      this.set('filterOptions', [{
        name: 'UTG Version',
        field: 'UtgVersion',
        type: 'text',
        supportsOperators: true,
        supportsMultiplePredicates: true,
        TypeHint: "Version",
        operatorOptions: [{
          key: "Is Equal to",
          value: "EQ"
        }, {
          key: "Is Not Equal to",
          value: "NEQ"
        }, {
          key: "Is Less than",
          value: "LT"
        }, {
          key: "Is Less than or Equal to",
          value: "LTE"
        }, {
          key: "Is Greater than",
          value: "GT"
        }, {
          key: "Is Greater than or Equal to",
          value: "GTE"
        }]
      }, {
        name: 'Agent Version',
        field: 'AgentVersion',
        type: 'text',
        supportsOperators: true,
        supportsMultiplePredicates: true,
        TypeHint: "Version",
        operatorOptions: [{
          key: "Is Equal to",
          value: "EQ"
        }, {
          key: "Is Not Equal to",
          value: "NEQ"
        }, {
          key: "Is Less than",
          value: "LT"
        }, {
          key: "Is Less than or Equal to",
          value: "LTE"
        }, {
          key: "Is Greater than",
          value: "GT"
        }, {
          key: "Is Greater than or Equal to",
          value: "GTE"
        }]
      }, {
        name: 'Merchant',
        field: 'ComponentMerchant',
        type: 'text'
      }, {
        name: 'UTG Nickname',
        field: 'ComponentNickname',
        type: 'text'
      }, {
        name: 'Profile',
        field: 'ComponentProfileName',
        type: 'text'
      }, {
        name: 'Profile Version',
        field: 'ComponentProfileVersion',
        type: 'numeric',
        TypeHint: "Integer"
      }, {
        name: 'Location/Serial',
        field: 'ComponentLocation',
        type: 'text'
      }, {
        name: 'Lane',
        field: 'ComponentLane',
        type: 'text'
      }, {
        name: 'File ID',
        field: 'ComponentFileId',
        type: 'text'
      }, {
        name: 'Machine Status',
        field: 'MachineStatus',
        type: 'text'
      }, {
        name: 'UTG Status',
        field: 'UtgStatus',
        type: 'text'
      }, {
        name: 'Last Known Communication',
        field: 'MachineStatusEntityLastKnownCommunication',
        type: 'date',
        supportsOperators: true,
        supportsMultiplePredicates: true,
        TypeHint: "DateTime",
        operatorOptions: [{
          key: "Is Less than",
          value: "LT"
        }, {
          key: "Is Greater than or Equal to",
          value: "GT"
        }]
      }, {
        name: 'Task Run Date',
        field: 'TaskRunAfter',
        type: 'date',
        supportsOperators: true,
        supportsMultiplePredicates: true,
        TypeHint: "DateTime",
        operatorOptions: [{
          key: "Is Less than",
          value: "LT"
        }, {
          key: "Is Greater than or Equal to",
          value: "GT"
        }],
        data: [{
          value: targetDate,
          operator: "GT"
        }],
        val1: targetDate,
        op1: "GT"
      }, {
        name: 'Task Type',
        field: 'TaskType',
        type: 'dropdown',
        supportsOperators: true,
        supportsMultiplePredicates: true,
        TypeHint: "Integer",
        options: [{
          key: "Choose Task Type...",
          value: "",
          default: true
        }, {
          key: "Start UTG",
          value: "1"
        }, {
          key: "Stop UTG",
          value: "2"
        }, {
          key: "Restart UTG",
          value: "3"
        }, {
          key: "Update UTG(s) to latest configuration",
          value: "4"
        }, {
          key: "Update Shift4 Command Center with UTG's local configuration or Revert UTG",
          value: "5"
        }, {
          key: "Retrieve latest component information",
          value: "6"
        }, {
          key: "Reset UTG Slot",
          value: "7"
        }, {
          key: "Change UTG Version",
          value: "8"
        }, {
          key: "Change Agent Version",
          value: "9"
        }, {
          key: "Push Forms",
          value: "10"
        }, {
          key: "Update Firmware",
          value: "11"
        }, {
          key: "Set Offline",
          value: "13"
        }, {
          key: "Upload Logs",
          value: "15"
        }],
        operatorOptions: [{
          key: "Is Equal to",
          value: "EQ"
        }, {
          key: "Is Not Equal to",
          value: "NEQ"
        }]
      }, {
        name: 'Task Status',
        field: 'TaskStatus',
        type: 'dropdown',
        supportsOperators: true,
        supportsMultiplePredicates: true,
        TypeHint: "Integer",
        options: [{
          key: "Choose Task Status...",
          value: "",
          default: true
        }, {
          key: "Rejected (-10)",
          value: "-10"
        }, {
          key: "Controller Failed (-5)",
          value: "-5"
        }, {
          key: "Requested (0)",
          value: "0"
        }, {
          key: "Accepted (5)",
          value: "5"
        }, {
          key: "Planned (10)",
          value: "10"
        }, {
          key: "Scheduled (15)",
          value: "15"
        }, {
          key: "Completed (20)",
          value: "20"
        }, {
          key: "Failed (30)",
          value: "30"
        }],
        operatorOptions: [{
          key: "Is Equal to",
          value: "EQ"
        }, {
          key: "Is Not Equal to",
          value: "NEQ"
        }, {
          key: "Is Less than",
          value: "LT"
        }, {
          key: "Is Less than or Equal to",
          value: "LTE"
        }, {
          key: "Is Greater than",
          value: "GT"
        }, {
          key: "Is Greater than or Equal to",
          value: "GTE"
        }]
      }]);
    },
    columns: function () {
      return [{
        "propertyName": "ComponentMerchant",
        "title": "Merchant"
      }, {
        "title": "Machine DNS Name",
        "propertyName": "MachineDnsName"
      }, {
        "template": "custom/serviceLink",
        "title": "UTG Nickname",
        "sortedBy": "ComponentNickname"
      }, {
        "propertyName": "TaskTypeString",
        "title": "Task Type",
        "className": "columnMinWidth"
      }, {
        "template": "custom/dateFormatter",
        "propertyName": "TaskRunAfter",
        "title": "Task Run Date",
        "className": "columnMinWidth"
      }, {
        "template": "custom/dateFormatter",
        "propertyName": "TaskLastUpdatedAt",
        "title": "Task Updated",
        "className": "columnMinWidth"
      }, {
        "propertyName": "TaskStatusString",
        "title": "Task State"
      }, {
        "propertyName": "TaskResultMessage",
        "title": "Result Message"
      }, {
        "title": "Result Details",
        "template": "custom/taskResultDetails"
      }, {
        "propertyName": "MachineStatus",
        "title": "Machine Status"
      }, {
        "propertyName": "UtgStatus",
        "title": "UTG Status"
      }, {
        "template": "custom/dateFormatter",
        "propertyName": "MachineStatusEntityLastKnownCommunication",
        "title": "Last Known Communication"
      }, {
        "template": "custom/dateFormatter",
        "propertyName": "MachineStatusEntityNextPredictedCommunication",
        "title": "Next Predicted Communication"
      }, {
        "propertyName": "UtgVersion",
        "title": "UTG Version"
      }, {
        "template": "custom/agentVersionTasks",
        "title": "Agent Version",
        "className": "columnMinWidth",
        "sortedBy": "AgentVersion",
        "propertyName": "AgentVersion"
      }, {
        "propertyName": "ComponentProfileName",
        "title": "Profile"
      }, {
        "propertyName": "ComponentProfileVersion",
        "title": "Profile Version"
      }, {
        "propertyName": "ComponentFileId",
        "title": "File Id"
      }, {
        "propertyName": "ComponentLocation",
        "title": "Location/Serial"
      }, {
        "propertyName": "ComponentLane",
        "title": "Lane"
      }, {
        "propertyName": "MachineNickname",
        "title": "Machine Nickname"
      }, {
        "propertyName": "ParentName",
        "title": "Parent Account"
      }];
    }.property('state'),
    actions: {
      //-----------------------------------------------------------------
      // Filter Modal
      //-----------------------------------------------------------------
      openFilterModal: function openFilterModal() {
        $('#searchFilterModal').modal('show');
      },
      cancelFilter: function cancelFilter() {
        $('#searchFilterModal').modal('hide');
        this.set('filter', {
          "Predicates": []
        });
      },
      applyFilter: function applyFilter(data) {
        var _this = this;

        console.log('applyFilter');
        console.log(JSON.stringify(data, null, 2));
        $('#searchFilterModal').modal('hide');
        var beId = this.get('session.selectedBusinessEntity.id');
        var restQuery = this.get('restQuery');
        restQuery.setTasksFilter(data); // call the rest-query service with our query parameters to get the filtered data from the rest api

        $('#progressHudOverlay').show();
        restQuery.queryTasks(beId).then(function (tasks) {
          $('#progressHudOverlay').hide();
        }).catch(function (err) {
          $('#progressHudOverlay').hide();
          return _this.get('errorhandler').handleError(err, 'Error while making a request.');
        });
      },
      //-----------------------------------------------------------------
      // Export
      //-----------------------------------------------------------------
      exportTasks: function exportTasks() {
        var r = confirm("Export may take a few minutes to download ALL data on this tab. Are you sure you want to continue?"); //Note: This export is slightly different from machine and service tab since this one adds a date range filter

        if (r == true) {
          var _this = this;

          _alerts.default._info('Download of tasks.csv should begin shortly.'); // query only for tasks where the runafter is > today - 30 days


          var dateOffset = 1000 * 60 * 60 * 24 * 30; // 30 days ago. milliseconds * seconds * minutes * hours * days

          var targetDate = new Date();
          targetDate.setTime(targetDate.getTime() - dateOffset); // build url

          var beId = this.get('session.selectedBusinessEntity.id');

          var baseUrl = _this.get('apiPath.in4mApiUrl') + '/' + _this.get('apiPath.in4mApiPath');

          var downloadUrl = baseUrl + "/businessentities/".concat(beId, "/tasks/query/csv");
          var authHeaderValue = 'Token token="' + this.get('session.sessionObject.accessToken') + '"'; //trigger Ajax request to load binary data

          var oReq = new XMLHttpRequest();
          oReq.open("POST", downloadUrl, true);
          oReq.setRequestHeader('Authorization', authHeaderValue); //When using setRequestHeader(), you must call it after calling open(), but before calling send(). If this method is called several times with the same header, the values are merged into one single request header. (See: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/setRequestHeader)

          oReq.responseType = "arraybuffer";
          oReq.setRequestHeader('Content-Type', 'application/json');
          oReq.setRequestHeader('Accept', 'application/json');
          /* For more information on "Receiving binary data using JavaScript typed arrays",
             See: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/Sending_and_Receiving_Binary_Data
          */

          oReq.onload = function (oEvent) {
            var arrayBuffer = oReq.response; // Note: not oReq.responseText

            if (arrayBuffer) {
              var byteArray = new Uint8Array(arrayBuffer);
              /* Package data as Blob for download. See:
              	- https://stackoverflow.com/questions/3665115/how-to-create-a-file-in-memory-for-user-to-download-but-not-through-server
              	- https://developer.mozilla.org/en-US/docs/Web/API/Blob)
              */

              var blob = new Blob([byteArray], {
                type: "application/octet-stream"
              }); //trigger save prompt

              saveAs(blob, "tasks.csv");
            }
          };

          var restQuery = this.get('restQuery'); // double stringify is required to ensure the data is encoded the way the api expects

          var encodedData = JSON.stringify(restQuery.tasksFilter).replace("\n", "");
          oReq.send(JSON.stringify(encodedData));
        }
      },
      tasksInfo: function tasksInfo() {
        $('#tasksInfoModal').modal('show');
      },
      showTasksInfoDoc: function showTasksInfoDoc() {
        var _this = this;

        window.open(_this.get('apiPath.referenceDocumentUrl'), '_blank');
      },
      copyRecord: function copyRecord(record) {
        // place the data on the users clipboard if the browser supports it
        if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
          navigator.clipboard.writeText(JSON.stringify(record, null, 2));

          _alerts.default._success('The task data has been copied to your clipboard.', 'Task Copied');
        } else {
          _alerts.default._error('This browser does not support copying data to the clipboard.', 'Copy Failed');
        }
      }
    }
  });

  _exports.default = _default;
});
define("in4m-web/routes/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      var _this = this;

      $('#progressHudOverlay').show();
      var beId = this.get('session.selectedBusinessEntity.id');
      var restUsers = this.get('restUsers'); //gets list of existing users to display in a table

      return restUsers.queryUsers(beId).then(function (users) {
        $('#progressHudOverlay').hide();
        return users;
      }).catch(function (err) {
        $('#progressHudOverlay').hide();
        return _this.get('errorhandler').handleError(err, 'Error while making a request.');
      });
    },
    afterModel: function afterModel() {
      $('#progressHudOverlay').hide();
    }
  });

  _exports.default = _default;
});
define("in4m-web/utils/instruction-values", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = instructionValues;

  function instructionValues() {
    var instructionValues = {
      '1': '{"ComponentRunningMode": 1}',
      '3': '{"ComponentRunningMode": 1}',
      '5.1': '{"Destination": 5}',
      '5.2': '{"Destination": 10}'
    };
    return instructionValues;
  }
});
define("in4m-web/helpers/pretty-json", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prettyJson = prettyJson;
  _exports.default = void 0;

  function prettyJson(json) {
    return JSON.stringify(json, null, 2);
  }

  var _default = Ember.Helper.helper(prettyJson);

  _exports.default = _default;
});
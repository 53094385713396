define("in4m-web/adapters/machine", ["exports", "ember-inflector", "in4m-web/adapters/application"], function (_exports, _emberInflector, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    host: Ember.computed('apiPath', function () {
      return this.get('apiPath.in4mApiUrl');
    }),
    namespace: Ember.computed('apiPath', function () {
      return this.get('apiPath.in4mApiPath');
    }),
    headers: Ember.computed('session.sessionObject', function () {
      if (this.get('session.sessionObject.accessToken')) {
        return {
          'clientId': 4,
          'Authorization': 'Token token="' + this.get('session.sessionObject.accessToken') + '"',
          'shift4-rest-request-envelope-version': 1,
          'shift4-rest-response-envelope-version': 1
        };
      } else {
        return {
          'clientId': 4,
          'Authorization': 'Token token="' + localStorage.getItem('accessToken') + '"',
          'shift4-rest-request-envelope-version': 1,
          'shift4-rest-response-envelope-version': 1
        };
      }
    }),
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var url = [];
      var host = Ember.get(this, 'host');
      var prefix = this.urlPrefix();
      var path = this.pathForType(modelName);

      if (requestType === 'query') {
        var parentBusinessEntityId = query.parentBusinessEntityId;
        delete query.parentBusinessEntityId;
        url.push('businessEntities/' + parentBusinessEntityId + '/' + path);
      } else if (requestType === 'updateRecord' || requestType === 'deleteRecord') {
        url.push('businessEntities/' + snapshot.record.get('parentBusinessEntity.id') + '/' + path + '/' + snapshot.record.get('permanentId'));
      } else if (requestType === 'findRecord') {
        url.push('businessEntities/' + this.get('session.selectedBusinessEntity.id') + '/' + path + '/' + id);
      }

      if (prefix) {
        url.unshift(prefix);
      }

      url = url.join('/');

      if (!host && url && url.charAt(0) !== '/') {
        url = '/' + url;
      }

      return url;
    },
    pathForType: function pathForType(modelName) {
      var camelized = Ember.String.camelize(modelName);
      return (0, _emberInflector.pluralize)(camelized);
    }
  });

  _exports.default = _default;
});
define("in4m-web/routes/component/manage/devices/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var _this = this;

      var component = this.modelFor('component.manage');
      var deviceConfig = component.get('config.deviceConfig');

      var newDevice = _this.store.createRecord('componentDevice', {
        deviceConfig: deviceConfig,
        // use the reported utg version from aws if we have it available
        utgVersion: component.component.softwareVersionFromAws || component.component.softwareVersion
      });

      deviceConfig.get('devices').addObject(newDevice);
      return newDevice;
    },
    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting) {
        var dirtyRecord = controller.model;

        if (dirtyRecord) {
          dirtyRecord.rollbackAttributes();
          controller.component.get('model.config.deviceConfig.devices').removeObject(dirtyRecord);
        }

        controller.set('selectedDeviceKey', ''); //resetting the selected interface on the controller to empty
      }
    }
  });

  _exports.default = _default;
});
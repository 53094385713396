define("in4m-web/models/component-status", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var hasMany = _emberData.default.hasMany;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    status: attr('number'),
    type: attr('number'),
    configDifference: attr('number'),
    localConfigRevisionId: attr('number'),
    remoteConfigRevisionId: attr('number'),
    machineStatus: belongsTo('machineStatus', {
      async: false
    }),
    component: belongsTo('component', {
      inverse: 'componentStatus',
      async: false
    }),
    deviceStatus: hasMany('deviceStatus'),
    metadata: attr()
  });

  _exports.default = _default;
});
define("in4m-web/components/devices-server-table", ["exports", "ember-models-table/components/models-table-server-paginated"], function (_exports, _modelsTableServerPaginated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _Ember = Ember,
      get = _Ember.get,
      set = _Ember.set,
      A = _Ember.A;

  var _default = _modelsTableServerPaginated.default.extend({
    store: Ember.inject.service(),
    didRender: function didRender() {
      $('.dropdown-toggle').dropdown();
    },
    _loadData: function _loadData() {
      var _this = this;

      $('#progressHudOverlay').show();
      var pageNumber = get(this, 'currentPageNumber');
      var pageSize = get(this, 'pageSize');
      var data = get(this, 'data');
      var parentBusinessEntityArr = [];
      var machinePermanentIds = {};
      var promises = [];
      var model = [];
      return _this.store.query('component', {
        businessentityid: _this.get('session.selectedBusinessEntity.id'),
        includealldescendants: false,
        pagenumber: pageNumber,
        pagesize: pageSize,
        sortColumn: "id"
      }).then(function (components) {
        components.forEach(function (eachComponent) {
          var parentBusinessEntity = eachComponent.get('parentBusinessEntity.id');
          var machinePermanentId = eachComponent.get('machine.permanentId');

          if (parentBusinessEntityArr.indexOf(parentBusinessEntity) === -1) {
            parentBusinessEntityArr.push(parentBusinessEntity);
          }

          if (machinePermanentIds.hasOwnProperty(parentBusinessEntity)) {
            var mpids = machinePermanentIds[parentBusinessEntity];
            mpids.push(machinePermanentId);
            machinePermanentIds[parentBusinessEntity] = mpids;
          } else {
            machinePermanentIds[parentBusinessEntity] = [machinePermanentId];
          }
        });
        var promises2 = [];
        parentBusinessEntityArr.forEach(function (parentBusinessEntityId) {
          promises2.push(_this.store.query('machineStatus', {
            parentBusinessEntityId: parentBusinessEntityId,
            machinePermanentIds: JSON.stringify(machinePermanentIds[parentBusinessEntityId])
          }).then(function (machineStatuses) {
            //do nothing
            machineStatuses.forEach(function (machineStatus) {
              var componentStatuses = machineStatus.get('componentStatus');
              componentStatuses.forEach(function (componentStatus) {
                var deviceStatuses = componentStatus.get('deviceStatus');

                if (deviceStatuses.get('length') > 0) {
                  deviceStatuses.forEach(function (eachDeviceStatus) {
                    promises.push(model.push(eachDeviceStatus));
                  });
                }
              });
            });
          }, function (error) {
            _this.get('errorhandler').handleError(error, 'Error while making a request');
          }));
        });
        return Ember.RSVP.all(promises2).then(function () {
          return Ember.RSVP.all(promises).then(function () {
            $('#progressHudOverlay').hide();
            set(_this, 'filteredContent', model);
          });
        });
      });
    },
    actions: {
      toggleAllSelection: function toggleAllSelection() {
        var selectedItems = get(this, '_selectedItems');
        var data = get(this, 'data'); //data.content will be undefined when the relationship objects are resolved

        var dataContent = data.content ? data.content : data;

        if (selectedItems.length === dataContent.length) {
          get(this, '_selectedItems').clear();
        } else {
          set(this, '_selectedItems', A(data.slice()));
        }

        this.userInteractionObserver();
      },
      gotoNext: function gotoNext() {
        if (!get(this, 'gotoForwardEnabled')) {
          return;
        }

        var currentPageNumber = get(this, 'currentPageNumber');
        this.incrementProperty('currentPageNumber');
        sessionStorage.setItem('devicePageNumber', this.get('currentPageNumber'));
      },
      gotoLast: function gotoLast() {
        if (!get(this, 'gotoForwardEnabled')) {
          return;
        }

        var pagesCount = get(this, 'pagesCount');
        set(this, 'currentPageNumber', pagesCount);
        sessionStorage.setItem('devicePageNumber', this.get('currentPageNumber'));
      }
    }
  });

  _exports.default = _default;
});
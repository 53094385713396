define("in4m-web/adapters/component-device-form", ["exports", "in4m-web/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    host: Ember.computed('apiPath', function () {
      return this.get('apiPath.in4mApiUrl');
    }),
    namespace: Ember.computed('apiPath', function () {
      return this.get('apiPath.in4mApiPath');
    }),
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var parentBusinessEntityId;

      if (query) {
        // parentBusinessEntityId is an internal only query param that we use for url building
        if (query.parentBusinessEntityId) {
          parentBusinessEntityId = query.parentBusinessEntityId;
          delete query.parentBusinessEntityId;
        }
      } else {
        //regardless of query, parentbusinessentity is needed for a deviceForm api call
        if (snapshot.record) {
          // if there is a record we are saving (updateRecord) then use that record's parent.
          parentBusinessEntityId = snapshot.record.get('parentBusinessEntityId');
        }
      } // fallback to selectedBusinessEntity
      // this solves issues where Ember tries to asynchronously retrieve the record 


      if (typeof parentBusinessEntityId === 'undefined') {
        parentBusinessEntityId = this.get('session.selectedBusinessEntity.id');
      }

      var url = [],
          host = Ember.get(this, 'host'),
          prefix = this.urlPrefix(),
          type = Ember.String.camelize(modelName); //updateRecord might have to send different businessEntityId since the deviceForm clicked can be from anywhere in the inheritance tree

      if (requestType === 'queryRecord') {
        url.push('businessentities/' + parentBusinessEntityId + '/' + this.pathForType(type) + '/' + parentBusinessEntityId);
      } else {
        url.push('businessentities/' + parentBusinessEntityId + '/' + this.pathForType(type));
      } // id present indicates we are retrieving a single record rather than a collection


      if (id) {
        if (id.toString().indexOf('-') === -1) {
          //fallback for possible missed id's that are not dasherized
          url.push(encodeURIComponent(id));
        } else {
          url.push(encodeURIComponent(id.toString().split("-")[1]));
        }
      }

      if (prefix) {
        url.unshift(prefix);
      }

      url = url.join('/');

      if (!host && url) {
        url = '/' + url;
      } // //one-off thing to have the params in the URL
      // if (requestType === 'deleteRecord') {
      // 	url = url + '?profileName=' + snapshot.record.get('name');
      // }


      return url;
    }
  });

  _exports.default = _default;
});
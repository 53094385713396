define("in4m-web/helpers/application-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    apiPath: Ember.inject.service('apiPath'),
    compute: function compute(params, hash) {
      var appUrl;

      switch (params[0]) {
        case 1:
          appUrl = this.get('apiPath.fourTressUrl');
          break;

        case 2:
          if (this.get('apiPath.iOS')) {
            appUrl = this.get('apiPath.vt4MobileUrl');
          } else {
            appUrl = this.get('apiPath.vt4WebUrl');
          }

          break;

        case 3:
          appUrl = this.get('apiPath.in4mUrl');
          break;

        default:
          appUrl = this.get('apiPath.in4mUrl');
          break;
      }

      return appUrl;
    }
  });

  _exports.default = _default;
});
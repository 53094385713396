define("in4m-web/models/business-entity-theme", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    businessEntity: belongsTo('businessEntity'),
    applicationGroupId: attr('number'),
    companyName: attr('string'),
    emailFrom: attr('string'),
    supportPhone: attr('string'),
    supportEmail: attr('string'),
    appName: attr('string'),
    domain: attr('string', {
      defaultValue: "4tress.4tresspos.com"
    }),
    primaryColor: attr('string'),
    secondaryColor: attr('string'),
    accentColor: attr('string'),
    baseImageUrl: attr('string'),
    gridImage: function () {
      return this.baseImageUrl + 'appGridLogo1.bin?' + Date.now();
    }.property('baseImageUrl')
  });

  _exports.default = _default;
});
define("in4m-web/helpers/port-hex", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.portHex = portHex;
  _exports.default = void 0;

  function portHex(params
  /*, hash*/
  ) {
    var port = parseInt(params[0]);
    return isNaN(port) ? '' : port.toString(16);
  }

  var _default = Ember.Helper.helper(portHex);

  _exports.default = _default;
});
define("in4m-web/overrides/check-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Checkbox.reopen({
    attributeBindings: ['data-automatron', 'data-required-class', 'data-required-count', 'data-msg']
  });

  _exports.default = _default;
});
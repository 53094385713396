define("in4m-web/adapters/component-certificate", ["exports", "in4m-web/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    host: Ember.computed('apiPath', function () {
      return this.get('apiPath.in4mApiUrl');
    }),
    namespace: Ember.computed('apiPath', function () {
      return this.get('apiPath.in4mApiPath');
    }),
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var url = [];
      var host = Ember.get(this, 'host');
      var prefix = this.urlPrefix();
      var path;

      if (requestType === 'createRecord') {
        path = this.pathForType(modelName);
        url.push('businessEntities/' + snapshot.record.get('parentBusinessEntity.id') + '/' + 'components' + '/' + snapshot.record.get('component.id') + '/' + path);
      } else if (requestType === 'query') {
        path = this.pathForType(modelName);
        url.push('businessEntities/' + query.businessEntityId + '/' + 'components' + '/' + query.componentId + '/' + path);
        delete query.businessEntityId;
        delete query.componentId;
      } else if (requestType === 'deleteRecord' || requestType === 'updateRecord') {
        path = this.pathForType(modelName);
        url.push('businessEntities/' + snapshot.record.get('parentBusinessEntity.id') + '/' + 'components' + '/' + snapshot.record.get('component.id') + '/' + path + '/' + snapshot.record.get('certificateGuid'));
      }

      if (!path) {
        //this check should be present if buildURL does not get into any of the two conditions specified above
        path = this.pathForType(modelName);

        if (path) {
          url.push(path);
        }
      }

      if (prefix) {
        url.unshift(prefix);
      }

      url = url.join('/');

      if (!host && url && url.charAt(0) !== '/') {
        url = '/' + url;
      }

      return url;
    }
  });

  _exports.default = _default;
});
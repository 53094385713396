define("in4m-web/models/component-profile/config", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    componentProfile: belongsTo('componentProfile', {
      async: false
    }),
    apiInterfaceConfig: belongsTo('componentProfile/config/apiInterfaceConfig', {
      async: false
    }),
    microsBridge: attr(),
    coreGlobal: attr(),
    deviceConfig: belongsTo('componentProfile/config/deviceConfig', {
      async: false
    }),
    expressManager: attr(),
    mailServer: belongsTo('componentProfile/config/mailServer'),
    proactorSupervisor: attr(),
    utgGlobal: belongsTo('componentProfile/config/utgGlobal'),
    webServer: attr(),
    hash: attr(),
    internallyManagedRevisionId: attr(),
    isApplied: attr(),
    isCommitted: attr(),
    isProtected: attr(),
    lastUpdatedAt: attr(),
    lastUpdatedBy: attr(),
    revisionId: attr()
  });

  _exports.default = _default;
});
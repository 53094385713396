define("in4m-web/services/navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Service responsible for handling navigation and business entity selection.
   * @module services/navigation
   * @default export
   */
  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service('-routing'),
    permKey: null,
    permAction: null,
    allowTransition: true,
    showChildrenAllowed: null,
    selectedBusinessEntity: null,
    determineSelectedBE: function determineSelectedBE() {
      var _this = this;

      var selectedBE;

      if (localStorage.selectedBusinessEntityId) {
        selectedBE = _this.get('store').peekRecord('businessEntity', localStorage.selectedBusinessEntityId);

        if (!selectedBE) {
          selectedBE = _this.get('session.userObject.parentBusinessEntity');
        }
      } else {
        selectedBE = _this.get('session.userObject.parentBusinessEntity');
      }

      return selectedBE;
    },
    setSelectedBE: function setSelectedBE() {
      var selectedBE = this.determineSelectedBE();
      this.selectBE(selectedBE);
    },
    selectBE: function selectBE(businessEntity) {
      // businessEntity may be a promise instead of an object, so we need to take the content of the promise instead.
      if (businessEntity.get('content')) {
        businessEntity = businessEntity.get('content');
      }

      this.set('selectedBusinessEntity', businessEntity);
      localStorage.setItem('selectedBusinessEntityId', businessEntity.get('id'));

      if (this.allowTransition) {
        //allow transition is set to false in few routes where transition needs to be aborted eg. 4go, express tabs etc
        var _this = this;

        _this.get('session').set('selectedBusinessEntity', businessEntity);

        _this.get('store').peekAll('businessEntity').filterBy('isSelected', true).forEach(function (BE) {
          BE.set('isSelected', false);
        });

        businessEntity.set('isSelected', true);
      } else {
        this.router.router.transitionTo('component'); //transition to component to make sure the willTransitionTo is called in routes where allowTransition is set to false
      }
    },

    /**
     * Helper function to check a user's permissions for a business entity given a specific navigation.
     * 
     * @param {*} businessEntity -- An ember data model object. For example, to access values, use businessEntity.get('id') or businessEntity.get('name')
     * @returns 
     */
    checkPermissions: function checkPermissions(businessEntity) {
      var user = this.get('session.userObject');
      var permKey = this.permKey; //the permission key, which corresponds to the permission key column in the database under dbo.Permission. The value will be something like 'In4m:UtgInstallations'

      var permAction = this.permAction; // a text string that describes the action, in the UI view, edit, etc. have action strings of 'canView', 'canEdit', etc. Not sure why this was done... But, this is the current structure of the action value

      if (!permKey) {
        //default
        return true;
      } else {
        if (businessEntity) {
          var hasPermission = user.hasPermission(permAction, permKey, businessEntity); //refers to the hasPermission function in the user-manifest.js model

          return hasPermission;
        } else {
          return false;
        }
      }
    }
  });

  _exports.default = _default;
});
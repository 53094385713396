define("in4m-web/components/search-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    filterValue: [],
    isMultiplePredicates: false,
    currentFilter: '',
    currentFilterIndex: 0,
    input1: null,
    input2: null,
    date: null,
    filterOutput: [],
    init: function init() {
      this._super.apply(this, arguments);

      console.log("init");
      this.date = moment().format('MM/DD/YYYY');
    },
    didInsertElement: function didInsertElement() {
      console.log('didinsert');
    },
    didUpdateAttrs: function didUpdateAttrs() {//window.alert("didUpdateAttrs");
    },
    didReceiveAttrs: function didReceiveAttrs() {
      //window.alert("didReceiveAttrs");
      this.filterValue = this.get('filterOptions');
      this.filterValue.forEach(function (filter) {
        if (filter.type == "bool") {
          if (!filter.data) {
            Ember.set(filter, 'data', true);
          }

          Ember.set(filter, 'queryOperator', 'AND');
          Ember.set(filter, 'isMultiplePredicates', false);
        } else {
          if (!filter.data) {
            Ember.set(filter, 'data', []);
          }

          Ember.set(filter, 'queryOperator', 'AND');
          Ember.set(filter, 'isMultiplePredicates', false);
          console.log(filter);
        }
      });
      this.currentFilter = this.filterValue[0].field;
      this.currentFilterIndex = 0;
    },
    actions: {
      applyFilter: function applyFilter() {
        // build the filter value as needed and bubble up to the containing controller
        // // parse filterValue & form JSON like
        // {
        //   "Predicates": [
        //     {
        //       "PropertyName": "ComponentMERCHant",
        //       "Operator": "EQ",
        //       "Value": "Retail"
        //     },
        //     {
        //        "Operator": "AND",
        //        "Value": [
        //         {
        //            "PropertyName": "ComponentMERCHant",
        //            "Operator": "EQ",
        //            "Value": "Retail"
        //          },
        //         {
        //            "PropertyName": "AGeNTVERsion",
        //            "Operator": "LT",
        //            "Value": "1.9",
        //            "TypeHint": "Version"
        //          }
        //       ]
        //     },
        var filters = this.filterValue;
        var req = {
          "Predicates": []
        };
        var arrFilters = req.Predicates;
        console.log(filters);
        filters.map(function (filter) {
          // checks to see if any filters have been set. if yes, generate request
          if (filter.data.length > 0) {
            generateReq(filter);
          }
        }); // for each item filterValue, build object to add to req JSON

        function generateReq(obj) {
          var filterObj = {};
          var arrData = obj.data; // checks if only one predicate, else multiple predicates

          if (arrData.length == 1) {
            Ember.set(filterObj, 'PropertyName', obj.field); // text fields passed to API as LIKE if Equal is chosen as operator

            if (obj.type == "text" && arrData[0].operator == "EQ") {
              Ember.set(filterObj, 'Operator', 'LIKE');
            } else {
              Ember.set(filterObj, 'Operator', arrData[0].operator);
            }

            Ember.set(filterObj, 'Value', arrData[0].value);
            Ember.set(filterObj, 'TypeHint', obj.TypeHint);
            arrFilters.push(filterObj);
          } else {
            Ember.set(filterObj, "Operator", obj.queryOperator);
            Ember.set(filterObj, "Value", []);
            arrData.forEach(function (data) {
              var tempObj = {};
              Ember.set(tempObj, 'PropertyName', obj.field);

              if (obj.type == "text" && data.operator == "EQ") {
                Ember.set(tempObj, 'Operator', 'LIKE');
              } else {
                Ember.set(tempObj, 'Operator', data.operator);
              }

              Ember.set(tempObj, 'Value', data.value);
              Ember.set(tempObj, 'TypeHint', obj.TypeHint);
              filterObj["Value"].push(tempObj);
            });
            arrFilters.push(filterObj);
          }

          ;
        }

        ;
        console.log(req);
        this.sendAction('onApplyFilter', req);
      },
      cancelFilter: function cancelFilter() {
        this.sendAction('onCancelFilter');
      },
      updateFilterOperatorValue: function updateFilterOperatorValue(filter, index, event) {
        var val;
        val = event.target.value;
        Ember.set(this.filterValue[index], 'queryOperator', val);
        console.log(JSON.stringify(this.filterValue, null, 2));
      },
      updateFilterValue: function updateFilterValue(filter, index, event) {
        var val;
        var op;
        var op1;
        var op2;
        var val1;
        var val2;
        var arr = [];
        var obj = {};
        var selectOp1;
        var selectOp2;
        var inputVal1;
        var inputVal2; // logic dependant on filter type

        switch (filter.type) {
          case 'text':
            selectOp1 = "#" + filter.field + "_op1";
            selectOp2 = "#" + filter.field + "_op2";
            inputVal1 = "#" + filter.field + "_val1";
            inputVal2 = "#" + filter.field + "_val2";

            if (filter.supportsOperators) {
              op1 = $(selectOp1).val();
              op2 = $(selectOp2).val();
            } else {
              op1 = "LIKE";
              op2 = "LIKE";
            }

            ;
            val1 = $(inputVal1).val();
            val2 = $(inputVal2).val();

            if (val1 != "") {
              Ember.set(obj, 'value', val1);
              Ember.set(obj, 'operator', op1);
              arr.push(obj);
            } //only set op2 and val2 if user wants multiple conditions


            if (filter.isMultiplePredicates) {
              if (val2 != "") {
                obj = {};
                Ember.set(obj, 'value', val2);
                Ember.set(obj, 'operator', op2);
                arr.push(obj);
              }

              ;
            }

            ;
            Ember.set(this.filterValue[index], 'data', arr);
            Ember.set(this.filterValue[index], 'val1', val1);
            Ember.set(this.filterValue[index], 'val2', val2);
            Ember.set(this.filterValue[index], 'op1', op1);
            Ember.set(this.filterValue[index], 'op2', op2);
            break;

          case 'bool':
            inputVal1 = "#" + filter.field + "_val1";
            op1 = "EQ";
            val1 = $(inputVal1).val();
            Ember.set(obj, 'value', val1);
            Ember.set(obj, 'operator', op1);
            arr.push(obj);
            Ember.set(this.filterValue[index], 'data', arr);
            Ember.set(this.filterValue[index], 'val1', val1);
            break;

          case 'date':
            selectOp1 = "#" + filter.field + "_op1";
            selectOp2 = "#" + filter.field + "_op2";
            inputVal1 = "#" + filter.field + "_val1";
            inputVal2 = "#" + filter.field + "_val2";

            if (filter.supportsOperators) {
              op1 = $(selectOp1).val();
              op2 = $(selectOp2).val();
            } else {
              op1 = "EQ";
              op2 = "EQ";
            }

            ;
            val1 = $(inputVal1).val();
            val2 = $(inputVal2).val();

            if (val1 != "") {
              Ember.set(obj, 'value', val1);
              Ember.set(obj, 'operator', op1);
              arr.push(obj);
            } //only set op2 and val2 if user wants multiple conditions


            if (filter.isMultiplePredicates) {
              if (val2 != "") {
                obj = {};
                Ember.set(obj, 'value', val2);
                Ember.set(obj, 'operator', op2);
                arr.push(obj);
              }

              ;
            }

            ;
            Ember.set(this.filterValue[index], 'data', arr);
            Ember.set(this.filterValue[index], 'val1', val1);
            Ember.set(this.filterValue[index], 'val2', val2);
            Ember.set(this.filterValue[index], 'op1', op1);
            Ember.set(this.filterValue[index], 'op2', op2);
            break;

          case 'dropdown':
            selectOp1 = "#" + filter.field + "_op1";
            selectOp2 = "#" + filter.field + "_op2";
            inputVal1 = "#" + filter.field + "_val1";
            inputVal2 = "#" + filter.field + "_val2";

            if (filter.supportsOperators) {
              op1 = $(selectOp1).val();
              op2 = $(selectOp2).val();
            } else {
              op1 = "EQ";
              op2 = "EQ";
            }

            ;
            val1 = $(inputVal1).val();
            val2 = $(inputVal2).val(); // if selected option is the default disabled option, ignore

            if (val1 != null && val1 != "") {
              Ember.set(obj, 'value', val1);
              Ember.set(obj, 'operator', op1);
              arr.push(obj);
            }

            ; // only set op2 and val2 if user wants multiple conditions, if selected option is the default disabled option, ignore

            if (filter.isMultiplePredicates && val2 != null && val2 != "") {
              obj = {};
              Ember.set(obj, 'value', val2);
              Ember.set(obj, 'operator', op2);
              arr.push(obj);
            }

            ;
            Ember.set(this.filterValue[index], 'data', arr); // set these values for UI

            Ember.set(this.filterValue[index], 'val1', val1);
            Ember.set(this.filterValue[index], 'val2', val2);
            Ember.set(this.filterValue[index], 'op1', op1);
            Ember.set(this.filterValue[index], 'op2', op2);
            break;

          case 'numeric':
            selectOp1 = "#" + filter.field + "_op1";
            selectOp2 = "#" + filter.field + "_op2";
            inputVal1 = "#" + filter.field + "_val1";
            inputVal2 = "#" + filter.field + "_val2";

            if (filter.supportsOperators) {
              op1 = $(selectOp1).val();
              op2 = $(selectOp2).val();
            } else {
              op1 = "EQ";
              op2 = "EQ";
            }

            ;
            val1 = $(inputVal1).val();
            val2 = $(inputVal2).val();

            if (val1 != "") {
              Ember.set(obj, 'value', val1);
              Ember.set(obj, 'operator', op1);
              arr.push(obj);
            } //only set op2 and val2 if user wants multiple conditions


            if (filter.isMultiplePredicates) {
              if (val2 != "") {
                obj = {};
                Ember.set(obj, 'value', val2);
                Ember.set(obj, 'operator', op2);
                arr.push(obj);
              }

              ;
            }

            ;
            Ember.set(this.filterValue[index], 'data', arr);
            Ember.set(this.filterValue[index], 'val1', val1);
            Ember.set(this.filterValue[index], 'val2', val2);
            Ember.set(this.filterValue[index], 'op1', op1);
            Ember.set(this.filterValue[index], 'op2', op2);
            break;
        }

        console.log(JSON.stringify(this.filterValue, null, 2));
      },
      resetFilter: function resetFilter() {
        this.set('filterValue', []);
        this.set('filterValue', this.get('filterOptions'));
        console.log(this.filterValue);
        var filterFields = this.filterValue;
        filterFields.forEach(function (filter) {
          var selectOp1 = "#" + filter.field + "_op1";
          var selectOp2 = "#" + filter.field + "_op2";
          var inputVal1 = "#" + filter.field + "_val1";
          var inputVal2 = "#" + filter.field + "_val2";
          $(inputVal1).val("");
          $(inputVal2).val("");
          $(selectOp1).val("EQ");
          $(selectOp2).val("EQ");

          if (filter.type == "bool" || filter.type == "dropdown") {
            Ember.set(filter, 'data', true);
            Ember.set(filter, 'queryOperator', 'AND');
            Ember.set(filter, 'isMultiplePredicates', false);
            Ember.set(filter, 'val1', filter.options[0].value);
            Ember.set(filter, 'val2', filter.options[0].value);
            Ember.set(filter, 'op1', filter.operatorOptions[0].value);
            Ember.set(filter, 'op2', filter.operatorOptions[0].value);
          } else {
            Ember.set(filter, 'data', []);
            Ember.set(filter, 'queryOperator', 'AND');
            Ember.set(filter, 'isMultiplePredicates', false);
            Ember.set(filter, 'val1', '');
            Ember.set(filter, 'val2', '');
            Ember.set(filter, 'op1', '');
            Ember.set(filter, 'op2', '');
            console.log(filter);
          }
        });
      },
      addMultiplePredicates: function addMultiplePredicates(filter, index, event) {
        console.log('addMultiplePredicates');
        Ember.set(filter, 'isMultiplePredicates', true);
      },
      showFilter: function showFilter(filter) {
        //dynamically displays filter input fields as user selects from filters
        console.log('showFilter');
        this.set('currentFilter', filter.field);
        var currentIndex = this.filterValue.findIndex(function (f) {
          return f.field == filter.field;
        });
        this.set('currentFilterIndex', currentIndex);
        this.set('input1', filter.field + "_val1");
        this.set('input2', filter.field + "_val2");
      }
    }
  });

  _exports.default = _default;
});
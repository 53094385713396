define("in4m-web/templates/custom/machine-gear", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vJggwtyV",
    "block": "{\"symbols\":[],\"statements\":[[4,\"table-action-gear\",null,[[\"record\"],[[25,[\"record\"]]]],{\"statements\":[[4,\"has-permission\",null,[[\"permAction\",\"permKey\",\"targetBusinessEntity\",\"tagName\"],[\"canEdit\",\"in4mMachines\",[25,[\"session\",\"selectedBusinessEntity\"]],\"\"]],{\"statements\":[[0,\"\\t\\t\"],[7,\"li\"],[9],[7,\"a\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-trash-o fa-lg\"],[9],[10],[0,\"  Delete selected machine(s)\"],[10],[3,\"action\",[[24,0,[]],\"sendAction\",\"showDeleteMachineWarningModal\",[25,[\"record\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/custom/machine-gear.hbs"
    }
  });

  _exports.default = _default;
});
define("in4m-web/components/be-tree-leaf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    navigation: Ember.inject.service(),
    router: Ember.inject.service('-routing'),
    didNavigate: Ember.on('init', Ember.observer('router.currentRouteName', function () {
      var businessEntity = this.businessEntity;
      var permissionsValid = this.navigation.checkPermissions(businessEntity);
      var currentRouteName = this.get('router.currentRouteName');
      var isDashboard = currentRouteName.indexOf('dashboard') !== -1;

      if (businessEntity) {
        if (permissionsValid || isDashboard) {
          $('#' + businessEntity.get('id')).removeClass('jstree-disabled');
        } else {
          $('#' + businessEntity.get('id')).addClass('jstree-disabled');
        }
      }
    })),
    actions: {
      selectBE: function selectBE(businessEntity) {
        this.navigation.selectBE(businessEntity);
      }
    }
  });

  _exports.default = _default;
});
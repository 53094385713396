define("in4m-web/models/machine-status", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    agentStatus: attr('string'),
    status: attr('string'),
    statusUpdateInterval: attr('number'),
    lastUpdatedAt: attr('string'),
    nextPredictedUpdateAt: attr('string'),
    isDuplicate: attr('boolean'),
    parentBusinessEntity: belongsTo('businessEntity'),
    componentStatus: hasMany('componentStatus'),
    availableMemory: attr('string'),
    cpuUsage: attr('string'),
    machine: belongsTo('machine'),
    dotNetVersion: attr('string'),
    uptime: attr('string'),
    hasTasks: attr('boolean'),
    isMachineRegistered: Ember.computed('status', function () {
      var status = this.status;

      if (status === '1') {
        return true;
      } else {
        return false;
      }
    })
  });

  _exports.default = _default;
});
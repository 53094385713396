define("in4m-web/components/push-forms", ["exports", "in4m-web/utils/instruction-values", "in4m-web/utils/pci-version"], function (_exports, _instructionValues, _pciVersion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    settings: [],
    instructionValues: Ember.computed('instructionValues', function () {
      return (0, _instructionValues.default)();
    }),
    versionMapping: Ember.computed('versionMapping', function () {
      return (0, _pciVersion.default)();
    }),
    columns: [{
      "propertyName": "name",
      "title": "Name"
    }, {
      "propertyName": "deviceType",
      "title": "Device Type"
    }, {
      "template": "custom/lastUpdated",
      "propertyName": "lastUpdatedAt",
      "title": "Upload Date"
    }],
    actions: {
      displayDataChanged: function displayDataChanged(settings) {
        this.set('settings', settings);

        if (settings.get('selectedItems').length > 0) {
          this.set('selectedForm', settings.get('selectedItems').get('firstObject'));
        }
      },
      closeModal: function closeModal() {
        this.get('settings.selectedItems').clear();
        this.sendAction('closeModal');
      },
      pushFormToDevice: function pushFormToDevice() {
        this.get('settings.selectedItems').clear();
        this.sendAction('pushFormToDevice');
      },
      deviceChanged: function deviceChanged() {
        var deviceMapping = this.versionMapping.device;
        var device = $("#device").val();
        var $modelPattern = $("#model-number-pattern");
        var $modelPatternText = $("#model-number-pattern-text");
        var patterns = deviceMapping[device].modelPatterns;
        var pciVersion = deviceMapping[device].pciVersion;

        if (pciVersion !== undefined) {
          $("#pci-version").val(pciVersion);
        } else {
          $("#pci-version").val("");
        }

        if (patterns.length === 0) {
          $modelPattern.addClass("hidden");
          $modelPatternText.removeClass("hidden");
        } else {
          $modelPatternText.addClass("hidden");
          $modelPattern.removeClass("hidden");
          $modelPattern.children().remove().end();
          $modelPattern.append('<option selected value="">Please select Model Pattern</option>');
          $.each(patterns, function (pattern) {
            $modelPattern.append($("<option />").val(this).text(this));
          });
        }
      },
      modelNumberPatternChanged: function modelNumberPatternChanged() {
        var $modelNumber = $("#model-number-pattern");
        var $pciVersion = $("#pci-version");
        var pciVersionMapping = this.versionMapping.pciVersion;
        var deviceMapping = this.versionMapping.device;
        var modelNumberPattern = $modelNumber.val();
        var device = $("#device").val();
        var pciVersion = pciVersionMapping[modelNumberPattern];

        if (pciVersion === undefined) {
          pciVersion = deviceMapping[device].pciVersion;
        }

        $pciVersion.val(pciVersion);
      }
    }
  });

  _exports.default = _default;
});
define("in4m-web/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;

  var _default = Model.extend({
    username: attr('string'),
    password: attr('string'),
    clerkNumber: attr('string'),
    firstName: attr('string'),
    lastLogon: attr('string'),
    expireDateTime: attr('string'),
    logonAttempt: attr('number'),
    userType: attr('number'),
    lastUpdateUserId: attr('number'),
    lastLogonAttempt: attr('string'),
    resetDateTime: attr('string'),
    saltAlgorithmVersion: attr('number'),
    salt: attr('string'),
    lastName: attr('string'),
    email: attr('string'),
    image: attr('number'),
    tempSecurityImageId: 0,
    imageSelected: attr('boolean'),
    isActive: attr('boolean'),
    isLocked: attr('boolean'),
    isReset: attr('boolean'),
    lastUpdateDateTime: attr('string')
  });

  _exports.default = _default;
});
define("in4m-web/helpers/fmt-currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fmtCurrency = fmtCurrency;
  _exports.default = void 0;

  function fmtCurrency(num) {
    num = +num;

    if (isNaN(num)) {
      return '0.00';
    }

    num = num.toFixed(2);
    return num.replace(/\B(?=(\d{3})+(?!\d)(?=\.))/g, ',');
  }

  var _default = Ember.Helper.helper(fmtCurrency);

  _exports.default = _default;
});
define("in4m-web/templates/installations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YUFzzG73",
    "block": "{\"symbols\":[],\"statements\":[[1,[23,\"outlet\"],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"modal fade\"],[11,\"id\",\"installationModal\"],[11,\"data-backdrop\",\"static\"],[11,\"data-keyboard\",\"false\"],[11,\"tabindex\",\"-1\"],[11,\"role\",\"dialog\"],[9],[0,\"\\n\\t\"],[7,\"div\"],[11,\"class\",\"modal-dialog\"],[11,\"role\",\"document\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\"],[11,\"class\",\"modal-content\"],[9],[0,\"\\n\\t\\t\\t\"],[1,[29,\"outlet\",[\"modal\"],null],false],[0,\" \"],[2,\" This outlet is named to allow writing nested content to it by name. See: https://discuss.emberjs.com/t/dealing-with-named-outlets/4493 \"],[0,\"\\n\\t\\t\"],[10],[0,\"\\n\\t\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/installations.hbs"
    }
  });

  _exports.default = _default;
});
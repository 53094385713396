define("in4m-web/helpers/icon-for-org-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.iconForOrgType = iconForOrgType;
  _exports.default = void 0;

  function iconForOrgType(options) {
    var iconContent = '';
    var BE = options[0];
    iconContent = '<b>?</b>';

    if (BE) {
      if (BE.get('isMerchant')) {
        iconContent = '<i style="font-size:20px;" class="fa fa-user"></i>';
      }

      if (BE.get('isOrganization')) {
        iconContent = '<i style="font-size:20px;" class="fa fa-globe"></i>';
      }

      if (BE.get('isResellerOrganization')) {
        iconContent = '<i style="font-size:20px;" class="fa fa-briefcase"></i>';
      }
    }

    return new Ember.Handlebars.SafeString(iconContent);
  }

  var _default = Ember.Helper.helper(iconForOrgType);

  _exports.default = _default;
});
define("in4m-web/models/user-manifest", ["exports", "ember-data", "in4m-web/utils/local-to-apipermission"], function (_exports, _emberData, _localToApipermission) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  /**
   * Represents a user manifest model.
   * @class
   * @extends Model
   */
  var _default = Model.extend({
    /**
     * The user ID.
     * @type {number}
     */
    userId: attr('number'),

    /**
     * The user's email address.
     * @type {string}
     */
    emailAddress: attr('string'),

    /**
     * Indicates if the email address is verified.
     * @type {boolean}
     */
    emailAddressVerified: attr('boolean'),

    /**
     * The user's first name.
     * @type {string}
     */
    firstName: attr('string'),

    /**
     * Indicates if the user is active.
     * @type {boolean}
     */
    isActive: attr('boolean'),

    /**
     * Indicates if the user is an initial user.
     * @type {boolean}
     */
    isInitialUser: attr('boolean'),

    /**
     * Indicates if the user is locked.
     * @type {boolean}
     */
    isLocked: attr('boolean'),

    /**
     * Indicates if the user is reset.
     * @type {boolean}
     */
    isReset: attr('boolean'),

    /**
     * Indicates if the user is a user password.
     * @type {boolean}
     */
    isUserPassword: attr('boolean'),

    /**
     * The last logon date of the user.
     * @type {string}
     */
    lastLogon: attr('string'),

    /**
     * The user's last name.
     * @type {string}
     */
    lastName: attr('string'),

    /**
     * The parent business entity of the user.
     * @type {belongsTo}
     */
    parentBusinessEntity: belongsTo('businessEntity'),

    /**
     * The business entity ID.
     * @type {string}
     */
    businessEntityId: attr('string'),

    /**
     * The image of the user.
     * @type {number}
     */
    image: attr('number'),

    /**
     * The temporary image of the user.
     * @type {number}
     */
    tempimage: 0,

    /**
     * Indicates if the image is selected.
     * @type {boolean}
     */
    imageSelected: attr('boolean'),

    /**
     * The username of the user.
     * @type {string}
     */
    username: attr('string'),

    /**
     * Sets the image of the user.
     * @returns {string} The image URL.
     */
    setImage: function () {
      var url = this.get('apiPath.ssoUrl') + '/images/security/';
      var image = '';
      var id = '';

      if (this.tempimage === 0) {
        id = this.image;
      } else {
        id = this.tempimage;
      }

      if (id === 0) {
        image = 'images/0.png';
      } else {
        image = (url + id + '.png').toString();
      }

      return image;
    }.property('image', 'tempimage'),

    /**
     * Checks if the user has a specific permission. For example, this is called when a user tries to access a specific tab / page or perform a specific action.
     * @param {string} action - The action to check. -- In the UI view, edit, etc. have action strings of 'canView', 'canEdit', etc. Not sure why this was done... But, this is the current structure of the action value
     * @param {string} permKey - The permission key, which corresponds to the permission key column in the database under dbo.Permission. The value will be something like 'In4m:UtgInstallations'
     * @param {object} targetBusinessEntity - The target business entity. -- An ember data model object. For example, to access values, we must use targetBusinessEntity.get('id') or targetBusinessEntity.get('name')
     * @returns {boolean} True if the user has the permission, false otherwise.
     */
    hasPermission: function hasPermission(action, permKey, targetBusinessEntity) {
      var hasPermission = false,
          validActions = ['canView', 'canEdit', 'isEnabled', //'canDelete',
      'canGrant'],
          isActionValid = false; // Translate local permission keys to match API permission keys

      permKey = (0, _localToApipermission.default)(permKey); // Verify the action argument is a valid action

      for (var i = 0; i < validActions.length; i++) {
        var validAction = validActions[i];

        if (validAction === action) {
          isActionValid = true;
        }
      }

      if (!isActionValid) {
        return false;
      } //check target business entity exists


      if (targetBusinessEntity) {
        //iterate over permissionMasks to determine if there is one with the above criteria and our BE
        var isInheritedPermission = false;
        this.permissionMasks.forEach(function (permissionMask) {
          //check if the permissionMask has the permission key and action we are looking for
          if (permissionMask.get('permission.key') != permKey || permissionMask.get(action) !== true) {
            return;
          }

          permissionMask.get('businessEntities').forEach(function (businessEntity) {
            if (typeof targetBusinessEntity.get('inheritancePool') == 'undefined') {
              console.log('inheritancePool is undefined. ', action, permKey, targetBusinessEntity);
            } else {
              var ancestors = targetBusinessEntity.get('inheritancePool'); //get the chain of parent business entities for the target business entity

              console.log('ancestors:', ancestors); //check if the permissions you have are inherited from an ancestor business entity

              isInheritedPermission = ancestors.includes(businessEntity.get('id')) || isInheritedPermission; //we must OR the result to ensure we don't lose the value of isInheritedPermission because ANY ancestor qualifies

              console.log('isInheritedPermission:', isInheritedPermission, '. Inherited from: businessEntity:', businessEntity.get('id'));
            }
          });

          if (permissionMask.get('permission.key') == permKey && permissionMask.get(action) === true && isInheritedPermission) {
            console.log('Found a matching permission mask.  Setting hasPermission to true.  action:', action, ', permKey:', permKey, ', targetBusinessEntity:', targetBusinessEntity.get('id'), ', isInheritedPermission:', isInheritedPermission);
            hasPermission = true;
          }
        });
      }

      return hasPermission;
    },

    /**
     * The permission masks of the user.
     * @type {hasMany}
     */
    permissionMasks: hasMany('permissionMask'),

    /**
     * Indicates if the user has access to machines.
     * @type {boolean}
     */
    hasAccessToMachines: false,

    /**
     * Indicates if the user has access to services.
     * @type {boolean}
     */
    hasAccessToServices: false,

    /**
     * Indicates if the user has access to installations.
     * @type {boolean}
     */
    hasAccessToInstallations: false,

    /**
     * Indicates if the user has access to profiles.
     * @type {boolean}
     */
    hasAccessToProfiles: false,

    /**
     * Indicates if the user has access to devices.
     * @type {boolean}
     */
    hasAccessToDevices: false,

    /**
     * Indicates if the user has access to users.
     * @type {boolean}
     */
    hasAccessToUsers: false,

    /**
     * Checks if the user has access to specific resources.
     */
    hasAccessCheck: function hasAccessCheck() {
      var _this = this;

      this.permissionMasks.forEach(function (permission) {
        //check for machines
        if (permission.get('permission.key') === (0, _localToApipermission.default)('in4mMachines') && permission.get('canView') === true) {
          _this.set('hasAccessToMachines', true);
        } //check for machines


        if (permission.get('permission.key') === (0, _localToApipermission.default)('in4mUtgServices') && permission.get('canView') === true) {
          _this.set('hasAccessToServices', true);
        } //check for machines


        if (permission.get('permission.key') === (0, _localToApipermission.default)('in4mUtgInstallations') && permission.get('canView') === true) {
          _this.set('hasAccessToInstallations', true);
        } //check for machines


        if (permission.get('permission.key') === (0, _localToApipermission.default)('in4mUtgProfiles') && permission.get('canView') === true) {
          _this.set('hasAccessToProfiles', true);
        } //check for devices


        if (permission.get('permission.key') === (0, _localToApipermission.default)('in4mDevices') && permission.get('canView') === true) {
          _this.set('hasAccessToDevices', true);
        } //check for forms


        if (permission.get('permission.key') === (0, _localToApipermission.default)('in4mDeviceForms') && permission.get('canView') === true) {
          _this.set('hasAccessToDeviceForms', true);
        } //check for users


        if (permission.get('permission.key') === (0, _localToApipermission.default)('users') && permission.get('canView') === true) {
          _this.set('hasAccessToUsers', true);
        }
      });
    }
  });

  _exports.default = _default;
});
define("in4m-web/models/agent", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;

  var _default = Model.extend({
    installLocation: attr(),
    downloadLocation: attr(),
    createNewUser: attr(),
    newMachine: attr(),
    registerAgent: attr(),
    registerComponents: attr(),
    loadMasterKey: attr()
  });

  _exports.default = _default;
});
define("in4m-web/components/unit-price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend({
    didInsertElement: function didInsertElement() {
      Ember.run.schedule('afterRender', this, function () {
        if (this.value) {
          this.set('value', new Big(this.value).toFixed(2));
        } else {
          if (this.min) {
            this.set('value', new Big(this.min).toFixed(2));
          } else {
            this.set('value', new Big(0).toFixed(2));
          }
        }
      });
    },
    keyPress: function keyPress(event) {
      if (event.which !== 8 && event.which !== 0 && (event.which < 46 || event.which > 57)) {
        return false;
      }
    },
    focusOut: function focusOut() {
      if (this.value) {
        this.set('value', new Big(this.value).toFixed(2));
      } else {
        this.set('value', new Big(0).toFixed(2));
      }
    }
  });

  _exports.default = _default;
});
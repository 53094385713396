define("in4m-web/routes/component-profiles/component-profile", ["exports", "in4m-web/utils/alerts", "in4m-web/helpers/check-duplicates-in-array", "in4m-web/utils/scroll-to-validate"], function (_exports, _alerts, _checkDuplicatesInArray, _scrollToValidate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      var _this = this;

      return _this.store.peekRecord('componentProfile', params.id);
    },
    actions: {
      saveProfile: function saveProfile() {
        var _this = this;

        var profile = this.currentModel;

        if (profile.name.trim().length == 0) {
          profile.set('name', profile.changedAttributes().name[0]);
        } //check for apiInterface names duplicates


        var apiInterfaces = profile.get('config').get('apiInterfaceConfig').get('apiInterfaces'); //check duplicates for a combination of port number and ipAddressType

        var hasDuplicateCombinationOfAPIPortsAndIp = (0, _checkDuplicatesInArray.checkDuplicatesInArray)(apiInterfaces, 'listenPort', 'ipAddressType');

        if (hasDuplicateCombinationOfAPIPortsAndIp) {
          _scrollToValidate.default.begin('custom-scroll', 'listenPort', 'apiInterface', 'Port Must be Unique per IP Address.');
        } else {
          var hasDuplicateAPIInterfaceNames = (0, _checkDuplicatesInArray.checkDuplicatesInArray)(apiInterfaces, 'taskDesc');

          if (hasDuplicateAPIInterfaceNames) {
            _scrollToValidate.default.begin('custom-scroll', 'taskDesc', 'apiInterface', 'API Interface description must be unique.');
          } else {
            //check for device names duplicates
            var devices = profile.get('config').get('deviceConfig').get('devices');
            var hasDuplicateDeviceNames = (0, _checkDuplicatesInArray.checkDuplicatesInArray)(devices, 'taskDesc');

            if (hasDuplicateDeviceNames) {
              _scrollToValidate.default.begin('custom-scroll', 'taskDesc', 'device', 'Device Name Must be Unique.');
            } else {
              var rs232Ports = [];
              var nonAutoPortDeviceTypes = [];
              var autoPortDeviceTypes = [];
              var hasDevicesWithAutoPortAndOthers = false;
              devices.forEach(function (device) {
                if (device.get('connection.deviceMode') === 'rs232') {
                  if (device.get('connection.portNumber') != 'Auto') {
                    rs232Ports.push(device.get('connection.portNumber'));
                    nonAutoPortDeviceTypes.push(device.get('deviceType'));

                    if (autoPortDeviceTypes.indexOf(device.get('deviceType')) != -1) {
                      hasDevicesWithAutoPortAndOthers = true;
                    }
                  } else {
                    autoPortDeviceTypes.push(device.get('deviceType'));

                    if (nonAutoPortDeviceTypes.indexOf(device.get('deviceType')) != -1) {
                      hasDevicesWithAutoPortAndOthers = true;
                    }
                  }
                }
              });

              if (hasDevicesWithAutoPortAndOthers) {
                _scrollToValidate.default.begin('custom-scroll', 'portNumber', 'deviceSelection', 'If using more than one of the same device type, cannot use the Auto COM port option. Please modify device configuration.');
              } else {
                if (rs232Ports.uniq().length !== rs232Ports.length) {
                  _scrollToValidate.default.begin('custom-scroll', 'portNumber', 'device', 'Port Must be Unique.');
                } else {
                  var profileName = profile.get('name'); // validate whatever form is on the page the user is on if we have a tab with duplicate forms this will need to be more robust

                  if ($('form').length) {
                    if ($('form').valid()) {
                      saveProfile();
                    }
                  } else {
                    saveProfile();
                  }
                }
              }
            }
          }
        }

        function saveProfile() {
          $('#progressHudMessage').html('Updating Profile...');
          $('#progressHudOverlay').show();

          _this.get('currentModel').save().then(function () {
            _alerts.default._success(profileName + ' has been successfully updated.');

            $('#progressHudOverlay').hide();
          }, function (e) {
            _alerts.default._error('There was a problem updating your profile.');

            $('#progressHudOverlay').hide();
          });
        }
      }
    }
  });

  _exports.default = _default;
});
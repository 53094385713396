define("in4m-web/models/api-interface/oracle-payment-api", ["exports", "ember-data", "in4m-web/models/api-interface"], function (_exports, _emberData, _apiInterface) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _apiInterface.default.extend({
    taskDesc: attr('', {
      defaultValue: 'Oracle'
    }),
    cdType: attr('', {
      defaultValue: 148
    }),
    useUTGGeneratedCertificate: attr('', {
      defaultValue: true
    }),
    spinEnabled: attr('', {
      defaultValue: false
    }),
    spinFloor: attr('', {
      defaultValue: 0.00
    }),
    suppressCompletionReceipts: attr('', {
      defaultValue: false
    }),
    printTipLineSale: attr('', {
      defaultValue: false
    }),
    printTipLineAuth: attr('', {
      defaultValue: false
    }),
    receiptColumns: attr('', {
      defaultValue: 30
    }),
    //1 for machineIp and 2 for loopback
    ipAddressType: attr('', {
      defaultValue: 1
    }),
    listenPort: attr('', {
      defaultValue: 16448
    })
  });

  _exports.default = _default;
});
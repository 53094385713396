define("in4m-web/templates/components/error-substate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9S/8wOuo",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"panel-body\"],[9],[0,\"\\n\\t\"],[7,\"div\"],[11,\"class\",\"block\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\"],[11,\"class\",\"centered\"],[9],[0,\"\\n\\t\\t\\t\\tYou do not have permission to view \"],[1,[23,\"tabName\"],false],[0,\" for \"],[1,[25,[\"session\",\"selectedBusinessEntity\",\"name\"]],false],[0,\".\\n\\t\\t\"],[10],[0,\"\\n\\t\"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/components/error-substate.hbs"
    }
  });

  _exports.default = _default;
});
define("in4m-web/components/shift4-server-table", ["exports", "ember-models-table/components/models-table-server-paginated"], function (_exports, _modelsTableServerPaginated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
      set = Ember.set,
      A = Ember.A;

  var _default = _modelsTableServerPaginated.default.extend({
    didRender: function didRender() {
      $('.dropdown-toggle').dropdown();
    },
    _loadData: function _loadData() {
      var _this2 = this;

      $('#progressHudOverlay').show();
      var data = get(this, 'data');
      var currentPageNumber = get(this, 'currentPageNumber');
      var pageSize = get(this, 'pageSize');
      var columns = get(this, 'processedColumns');
      var sortProperties = get(this, 'sortProperties');
      var filterString = get(this, 'filterString');

      if (!get(data, 'query')) {
        $('#progressHudOverlay').hide();
        Ember.Logger.warn('You must use http://emberjs.com/api/data/classes/DS.Store.html#method_query for loading data');
        return;
      }

      var query = Object.assign({}, get(data, 'query'));
      var store = get(data, 'store');
      var modelName = get(data, 'type.modelName'); // Add pagination information

      query[get(this, 'filterQueryParameters.page')] = currentPageNumber;
      query[get(this, 'filterQueryParameters.pageSize')] = pageSize; // Add sorting information

      if (!sortProperties || sortProperties.length == 0) {
        sortProperties = ['id:asc'];
      }

      var sort = sortProperties && get(sortProperties, 'length') ? sortProperties[0] : null;

      if (sort) {
        var sortBy = sort.split(':')[0];
        var sortDirection = sort.split(':')[1].toUpperCase();
        query = this.sortingWrapper(query, sortBy, sortDirection);
      } else {
        delete query[[get(this, 'filterQueryParameters.sort')]];
        delete query[[get(this, 'filterQueryParameters.sortDirection')]];
      } // Add global filter


      if (filterString) {
        query[get(this, 'filterQueryParameters.globalFilter')] = filterString;
      } else {
        delete query[get(this, 'filterQueryParameters.globalFilter')];
      } // Add per-column filter


      columns.forEach(function (column) {
        var filter = get(column, 'filterString');

        var filterTitle = _this2.getCustomFilterTitle(column);

        if (filter) {
          query[filterTitle] = filter;
        } else {
          delete query[filterTitle];
        }
      });
      set(this, 'isLoading', true);

      var _this = this;

      store.query(modelName, query).then(function (newData) {
        $('#progressHudOverlay').hide();
        set(_this2, 'filteredContent', newData);

        _this.sendAction('filterApplied', newData);

        set(_this2, 'isLoading', false);
      });
    },
    actions: {
      toggleAllSelection: function toggleAllSelection() {
        var selectedItems = get(this, '_selectedItems');
        var data = get(this, 'data'); //data.content will be undefined when the relationship objects are resolved

        var dataContent = data.content ? data.content : data;

        if (selectedItems.length === dataContent.length) {
          get(this, '_selectedItems').clear();
        } else {
          set(this, '_selectedItems', A(data.slice()));
        }

        this.userInteractionObserver();
      },
      gotoNext: function gotoNext() {
        if (!get(this, 'gotoForwardEnabled')) {
          return;
        }

        var currentPageNumber = get(this, 'currentPageNumber');
        this.incrementProperty('currentPageNumber');
        sessionStorage.setItem('pageNumber', this.get('currentPageNumber'));
      },
      gotoLast: function gotoLast() {
        if (!get(this, 'gotoForwardEnabled')) {
          return;
        }

        var pagesCount = get(this, 'pagesCount');
        set(this, 'currentPageNumber', pagesCount);
        sessionStorage.setItem('pageNumber', this.get('currentPageNumber'));
      }
    }
  });

  _exports.default = _default;
});
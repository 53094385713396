define("in4m-web/helpers/cd-type-to-model-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cdTypeToModelType = cdTypeToModelType;
  _exports.default = void 0;

  function cdTypeToModelType(params) {
    var cdType; // makes helper able to be used as a general javascript util and in a handlebars template

    if (Ember.isArray(params)) {
      cdType = params[0];
    } else {
      cdType = params;
    } // Translate cdTypes in the UTG config to our ember model types for polymorphism


    var modelType = ''; //to avoid console error for undefined cdType

    if (!cdType) {
      return modelType;
    }

    switch (cdType.toString()) {
      case '1028':
        modelType = 'UtgGlobal';
        break;

      case '1':
        modelType = 'CoreGlobal';
        break;

      case '124':
        modelType = 'ProactorSupervisor';
        break;

      case '126':
        modelType = 'ExpressManager';
        break;

      case '3':
        modelType = 'MailServer';
        break;

      case '2':
        modelType = 'WebServer';
        break;

      case '30':
        modelType = 'TcpApi';
        break;

      case '110':
        modelType = 'TcpSSL';
        break;

      case '109':
        modelType = 'CloudApi';
        break;

      case '137':
        modelType = 'CloudSSL';
        break;

      case '111':
        modelType = 'HttpApi';
        break;

      case '112':
        modelType = 'HttpSSL';
        break;

      case '114':
        modelType = 'MicrosApi';
        break;

      case '115':
        modelType = 'SimphonyApi';
        break;

      case '116':
        modelType = 'AsciiApi';
        break;

      case '117':
        modelType = 'BinaryApi';
        break;

      case '120':
        modelType = 'FileApi';
        break;

      case '148':
        modelType = 'OraclePaymentApi';
        break;

      case '121':
        modelType = 'TweenApi';
        break;

      case '125':
        modelType = 'UtgDevice';
        break;

      case '154':
        modelType = 'rest';
        break;

      case '158':
        modelType = 'opera';
        break;
    }

    return modelType;
  }

  var _default = Ember.Helper.helper(cdTypeToModelType);

  _exports.default = _default;
});
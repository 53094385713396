define("in4m-web/mixins/check-for-id", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    normalize: function normalize(modelClass, resourceHash, prop) {
      if (resourceHash) {
        if (!resourceHash.hasOwnProperty('id')) {
          //changed the check logic from resource.id to resource.hasOwnProperty('id') because when id=0 case, if condition fails
          resourceHash.id = Ember.guidFor(resourceHash);
        }
      }

      return this._super(modelClass, resourceHash);
    }
  });

  _exports.default = _default;
});
define("in4m-web/components/shift4-table", ["exports", "ember-models-table/components/models-table"], function (_exports, _modelsTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modelsTable.default.extend({
    init: function init() {
      this._super.apply(this, arguments); // extend the available selection of page sizes


      this.pageSizeValues = [10, 25, 50, 250, 500, 1000];
    },
    didRender: function didRender() {
      $('.dropdown-toggle').dropdown();
    },
    actions: {
      toggleAllSelection: function toggleAllSelection() {
        var selectedItems = Ember.get(this, '_selectedItems'); // if we have any items selected, then toggling clears

        if (selectedItems.length > 0) {
          Ember.get(this, '_selectedItems').clear(); // force the table to update. Otherwise it might not render items when on first page

          this.forceUpdateArrangedContent();
        } else {
          // adds all current visible rows to our selectedItems list
          Ember.set(this, '_selectedItems', Ember.get(this, 'visibleContent'));
        }

        this.userInteractionObserver();
      }
    }
  });

  _exports.default = _default;
});
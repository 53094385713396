define("in4m-web/adapters/component-profile-assignment", ["exports", "in4m-web/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    host: Ember.computed('apiPath', function () {
      return this.get('apiPath.in4mApiUrl');
    }),
    namespace: Ember.computed('apiPath', function () {
      return this.get('apiPath.in4mApiPath');
    }),
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var url = [],
          host = Ember.get(this, 'host'),
          prefix = this.urlPrefix(),
          type = Ember.String.camelize(modelName); //updateRecord might have to send different businessEntityId since the profile clicked can be from anywhere in the inheritance tree

      if (requestType === 'deleteRecord') {
        url.push(this.pathForType(type) + '?' + 'componentId=' + snapshot.record.get('componentId') + '&' + 'componentBusinessEntityId=' + snapshot.record.get('componentBusinessEntityId'));
      } else {
        url.push(this.pathForType(type));
      }

      if (prefix) {
        url.unshift(prefix);
      }

      url = url.join('/');

      if (!host && url) {
        url = '/' + url;
      }

      return url;
    }
  });

  _exports.default = _default;
});
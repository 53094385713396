define("in4m-web/routes/component-profiles/component-profile/api-interfaces/api-interface/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      willTransition: function willTransition(transition) {
        //this is needed so that a clean model state is avaiable if the user selects on another tab.
        this.currentModel.rollbackAttributes();
      }
    }
  });

  _exports.default = _default;
});
define("in4m-web/controllers/component/manage/advanced", ["exports", "in4m-web/utils/drop-down-list"], function (_exports, _dropDownList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    indexController: Ember.inject.controller('component.manage.api-interface.index'),
    dropdownListValues: Ember.computed('dropdownList', function () {
      return (0, _dropDownList.default)();
    })
  });

  _exports.default = _default;
});
define("in4m-web/templates/component-device/ebt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iuo2l2Ga",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n\\t\"],[7,\"div\"],[11,\"class\",\"col-xs-offset-4 col-xs-8 checkbox\"],[9],[0,\"\\n\\t\\t\"],[7,\"label\"],[9],[0,\"\\n\\t\\t\\t\"],[1,[29,\"input\",null,[[\"type\",\"name\",\"checked\"],[\"checkbox\",\"contactlessEnabled\",[25,[\"model\",\"ebt\",\"cashEnabled\"]]]]],false],[0,\"\\n\\t\\t\\tEBT Cash Enabled\\n\\t\\t\"],[10],[0,\"\\n\\t\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n\\t\"],[7,\"div\"],[11,\"class\",\"col-xs-offset-4 col-xs-8 checkbox\"],[9],[0,\"\\n\\t\\t\"],[7,\"label\"],[9],[0,\"\\n\\t\\t\\t\"],[1,[29,\"input\",null,[[\"type\",\"name\",\"checked\"],[\"checkbox\",\"bypassAmountOk\",[25,[\"model\",\"ebt\",\"foodEnabled\"]]]]],false],[0,\"\\n\\t\\t\\tEBT Food Enabled\\n\\t\\t\"],[10],[0,\"\\n\\t\"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/component-device/ebt.hbs"
    }
  });

  _exports.default = _default;
});
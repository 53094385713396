define("in4m-web/routes/installations/connect-utg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    renderTemplate: function renderTemplate() {
      //we're going to render to templates when hitting this route
      //the modal for connectUtg
      this.render('installations/connectUTG', {
        into: 'installations',
        //<-- Inject the handlebars connect-utg.hbs template into the installations.hbs
        outlet: 'modal' //specifically, the named outlet, 'modal'. For a better understanding, Google "ember named outlets". Also, see: https://discuss.emberjs.com/t/dealing-with-named-outlets/4493

      }); //and the installation page

      this.render('installations/index', {
        into: 'installations',
        controller: 'installations/index',
        model: this.modelFor('installations') //for the installations/index template, we'll grab the model from the 'installation' route and render the installations page

      });
    },
    model: function model() {
      var install = this.store.createRecord('installation', {
        type: 30,
        parentBusinessEntity: this.get('session.selectedBusinessEntity'),
        manifest: this.store.createRecord('installationManifest', {
          install: install,
          machine: this.store.createRecord('machine', {
            nickname: null,
            createKeyContainer: true,
            parentBusinessEntity: this.get('session.selectedBusinessEntity')
          }),
          agent: this.store.createRecord('agent', {
            installLocation: 'c:\\shift4\\Shift4 Command Center\\agent',
            createNewUser: true,
            newMachine: true,
            registerAgent: true,
            registerComponents: true,
            loadMasterKey: true
          }),
          credentials: this.store.createRecord('installationCredentials', {}),
          components: [this.store.createRecord('component', {
            installLocation: "c:\\shift4\\utg2",
            type: 1,
            parentBusinessEntity: this.get('session.selectedBusinessEntity')
          })],
          tasks: [this.store.createRecord('installationTask', {
            type: 5,
            instructions: {
              destination: 10
            }
          })]
        })
      });
      return install;
    },
    afterModel: function afterModel(transition) {
      Ember.run.next(function () {
        $('#installationModal').modal('show');
      });
    }
  });

  _exports.default = _default;
});
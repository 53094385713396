define("in4m-web/helpers/has-permission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasPermission = hasPermission;
  _exports.default = void 0;

  function hasPermission(params) {
    var permAction = params[0];
    var permKey = params[1];
    var session = params[2];
    var businessEntity = session.get('selectedBusinessEntity');
    var user = session.get('userObject');

    if (user) {
      return user.hasPermission(permAction, permKey, businessEntity);
    }
  }

  var _default = Ember.Helper.helper(hasPermission);

  _exports.default = _default;
});
define("in4m-web/utils/pci-version", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = versionMapping;

  function versionMapping() {
    var mapping = {
      "pciVersion": {
        "IPP320-31Txxxxx": 4,
        "IPP320-11Txxxxx": 3,
        "IPP350-31Txxxxx": 4,
        "IPP350-11Txxxxx": 3,
        "ISC250-31Txxxxx": 4,
        "ISC250-01Txxxxx": 3,
        "IUP250LE (with 13_23 =0)": 4,
        "IUP250-11Txxxxx (with 13_23=1)": 4,
        "IUP250-01Txxxxxx": 3
      },
      "device": {
        "ipp320": {
          modelPatterns: ["IPP320-31Txxxxx", "IPP320-11Txxxxx"]
        },
        "ipp350": {
          modelPatterns: ["IPP350-31Txxxxx", "IPP350-11Txxxxx"]
        },
        "isc250": {
          modelPatterns: ["ISC250-31Txxxxx", "ISC250-01Txxxxx"]
        },
        "iup250": {
          modelPatterns: ["IUP250LE (with 13_23 =0)", "IUP250-11Txxxxx (with 13_23=1)", "IUP250-01Txxxxxx"]
        },
        "iwl220": {
          modelPatterns: [],
          pciVersion: "3"
        },
        "iwl250": {
          modelPatterns: [],
          pciVersion: "3"
        },
        "iwl252": {
          modelPatterns: [],
          pciVersion: "3"
        },
        "iwl228": {
          modelPatterns: [],
          pciVersion: "3"
        },
        "iwl258": {
          modelPatterns: [],
          pciVersion: "3"
        },
        "isc480": {
          modelPatterns: [],
          pciVersion: "4"
        },
        "icm122": {
          modelPatterns: [],
          pciVersion: "3"
        },
        "imp322": {
          modelPatterns: [],
          pciVersion: "3"
        },
        "imp352": {
          modelPatterns: [],
          pciVersion: "3"
        },
        "imp657": {
          modelPatterns: [],
          pciVersion: "4"
        },
        "imp627": {
          modelPatterns: [],
          pciVersion: "4"
        }
      }
    };
    return mapping;
  }
});
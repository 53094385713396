define("in4m-web/templates/components/time-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iHONpgLS",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"input\",null,[[\"type\",\"value\",\"focus-out\",\"class\",\"placeholder\",\"step\"],[\"time\",[25,[\"valueString\"]],\"valueChanged\",\"form-control\",[25,[\"placeholder\"]],[25,[\"step\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/components/time-input.hbs"
    }
  });

  _exports.default = _default;
});
define("in4m-web/helpers/class-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.className = className;
  _exports.default = void 0;

  function className(params
  /*, hash*/
  ) {
    var schemaProperty = params[0];
    var defaultClass = params[1];
    var noofColumns = schemaProperty['s4-noof-columns'];
    var offset = schemaProperty['s4-columns-offset'];
    var classes = defaultClass;

    if (noofColumns) {
      classes = ''; // columns

      var xs = noofColumns.xs;
      var sm = noofColumns.sm;
      var md = noofColumns.md;

      if (xs) {
        classes += ' col-xs-' + xs;
      }

      if (sm) {
        classes += ' col-sm-' + sm;
      }

      if (md) {
        classes += ' col-md-' + md;
      }
    }

    if (offset) {
      // offset 
      var xsOffset = offset.xs;
      var smOffset = offset.sm;
      var mdOffset = offset.md;

      if (xsOffset) {
        classes += ' col-xs-offset-' + xsOffset;
      }

      if (smOffset) {
        classes += ' col-sm-offset-' + smOffset;
      }

      if (mdOffset) {
        classes += ' col-md-offset-' + mdOffset;
      }
    }

    if (!offset && !noofColumns && !defaultClass) {
      classes = 'col-xs-8 col-sm-6 col-md-4';
    }

    return classes;
  }

  var _default = Ember.Helper.helper(className);

  _exports.default = _default;
});
define("in4m-web/components/s4-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['section-subheader'],
    collapsed: true,
    actions: {
      toggleCollapsed: function toggleCollapsed() {
        if (this.collapsed) {
          this.set('collapsed', false);
        } else {
          this.set('collapsed', true);
        }
      }
    }
  });

  _exports.default = _default;
});
define("in4m-web/controllers/component-profiles/component-profile/4go", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    componentProfile: Ember.inject.controller('componentProfiles.componentProfile'),
    editDisabled: function () {
      return !this.get('model.enabled');
    }.property('model.enabled'),
    actions: {
      addBroadcastList: function addBroadcastList() {
        this.get('model.broadcastList').addObject(Ember.Object.create({
          key: ''
        }));
      },
      removeBroadcastList: function removeBroadcastList(listItem) {
        this.get('model.broadcastList').removeObject(listItem);
      }
    }
  });

  _exports.default = _default;
});
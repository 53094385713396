define("in4m-web/templates/components/models-table/simple-pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wviZTP2U",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"btn-toolbar pull-right\"],[11,\"role\",\"toolbar\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"btn-group\"],[11,\"role\",\"group\"],[9],[0,\"\\n    \"],[7,\"a\"],[11,\"href\",\"#\"],[12,\"class\",[30,[[29,\"if\",[[25,[\"gotoBackEnabled\"]],\"enabled\",\"disabled\"],null],\" btn btn-default\"]]],[9],[0,\"\\n      \"],[7,\"span\"],[12,\"class\",[30,[[25,[\"icons\",\"nav-first\"]]]]],[9],[10],[0,\"\\n    \"],[3,\"action\",[[24,0,[]],\"gotoFirst\"]],[10],[0,\"\\n    \"],[7,\"a\"],[11,\"href\",\"#\"],[12,\"class\",[30,[[29,\"if\",[[25,[\"gotoBackEnabled\"]],\"enabled\",\"disabled\"],null],\" btn btn-default\"]]],[9],[0,\"\\n      \"],[7,\"span\"],[12,\"class\",[30,[[25,[\"icons\",\"nav-prev\"]]]]],[9],[10],[0,\"\\n    \"],[3,\"action\",[[24,0,[]],\"gotoPrev\"]],[10],[0,\"\\n    \"],[7,\"a\"],[11,\"href\",\"#\"],[12,\"class\",[30,[[29,\"if\",[[25,[\"gotoForwardEnabled\"]],\"enabled\",\"disabled\"],null],\" btn btn-default\"]]],[9],[0,\"\\n      \"],[7,\"span\"],[12,\"class\",[30,[[25,[\"icons\",\"nav-next\"]]]]],[9],[10],[0,\"\\n    \"],[3,\"action\",[[24,0,[]],\"gotoNext\"]],[10],[0,\"\\n    \"],[7,\"a\"],[11,\"href\",\"#\"],[12,\"class\",[30,[[29,\"if\",[[25,[\"gotoForwardEnabled\"]],\"enabled\",\"disabled\"],null],\" btn btn-default\"]]],[9],[0,\"\\n      \"],[7,\"span\"],[12,\"class\",[30,[[25,[\"icons\",\"nav-last\"]]]]],[9],[10],[0,\"\\n    \"],[3,\"action\",[[24,0,[]],\"gotoLast\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/components/models-table/simple-pagination.hbs"
    }
  });

  _exports.default = _default;
});
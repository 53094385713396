define("in4m-web/helpers/roll-back-relationship-attributes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rollBackRelationshipAttributes = rollBackRelationshipAttributes;
  _exports.default = void 0;

  function rollBackRelationshipAttributes(params
  /*, hash*/
  ) {
    var model = params;
    model.eachRelationship(function (name, relationship) {
      if (model.get("".concat(name, "._internalModel._attributes"))) {
        for (var key in model.get("".concat(name, "._internalModel._attributes"))) {
          model.get(name).set(key, model.get("".concat(name, "._internalModel._data"))[key]);
        }
      }
    });
  }

  var _default = Ember.Helper.helper(rollBackRelationshipAttributes);

  _exports.default = _default;
});
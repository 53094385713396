define("in4m-web/models/business-entity", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    type: attr('number'),
    subType: attr('number'),
    name: attr('string'),
    isRoot: attr('boolean'),
    isActive: attr('boolean'),
    users: hasMany('userManifest'),
    parentBusinessEntity: belongsTo("businessEntity", {
      inverse: 'children'
    }),
    children: hasMany('businessEntity', {
      inverse: 'parentBusinessEntity'
    }),
    lastUpdateDateTime: attr('date'),
    isSelected: false,
    parentBusinessEntityLoaded: function () {
      if (this._internalModel.__recordData._relationships.initializedRelationships.parentBusinessEntity) {
        if (this._internalModel.__recordData._relationships.initializedRelationships.parentBusinessEntity.inverseRecordData.__data !== null) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }.property('parentBusinessEntity', 'parentBusinessEntity.isLoaded'),
    nthChildLevel: function () {
      //used for determining how many levels deep the BE is in the context of it's parent
      if (this.parentBusinessEntityLoaded) {
        //check if parentBusinessEntity has a childLevel param
        return this.get('parentBusinessEntity.nthChildLevel') + 1;
      } else {
        return 0;
      }
    }.property('parentBusinessEntity.nthChildLevel', 'parentBusinessEntity'),
    inheritancePool: function () {
      //a list of all the BE's this BE can inherit permissions from in the known jsTreeType
      if (this.parentBusinessEntityLoaded) {
        var parentPool = this.get('parentBusinessEntity.inheritancePool'); //avoid binding to parentPool

        var inheritancePool = [];
        inheritancePool.push(this.id);
        return inheritancePool.concat(parentPool);
      } else {
        // this must be the top-most businessEntity, and thus only has this one id in it's pool
        return [this.id];
      }
    }.property('parentBusinessEntity.inheritancePool', 'parentBusinessEntity'),
    jsTreeType: function () {
      if (this.isMerchant) {
        return "merchant";
      } else if (this.isResellerOrganization) {
        return "reseller";
      } else {
        return "organization";
      }
    }.property('isMerchant'),
    isMerchant: function () {
      return this.type == 1;
    }.property('type'),
    isOrganization: function () {
      //used for generally checking if a business entity is an organization
      return this.type == 2;
    }.property('type'),
    isStandardOrganization: function () {
      //used for explicitly checking if an organization is standard and not of another type
      return this.isOrganization && this.subType == 1;
    }.property('isOrganization', 'subType'),
    isResellerOrganization: function () {
      //used for explicitly checking if an organization is a reseller
      return this.isOrganization && this.subType == 3;
    }.property('isOrganization', 'subType'),
    isRootOrganization: function () {
      //used for explicitly checking if an organization is the root 
      return this.isOrganization && this.subType == 2;
    }.property('isOrganization', 'subType'),
    beType: function () {
      if (this.isResellerOrganization) {
        return 1;
      } else if (this.isOrganization) {
        return 2;
      } else if (this.isMerchant) {
        return 3;
      }
    }.property('type', 'subType'),
    canManageChildren: attr('boolean'),
    sortProperties: ['type:desc', 'name:asc'],
    sortedChildren: Ember.computed.sort('children', 'sortProperties'),
    radio: function () {
      return false;
    }.property(),
    components: hasMany('component'),
    permissionMasks: hasMany('permissionMask')
  });

  _exports.default = _default;
});
define("in4m-web/templates/components/be-tree-leaf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xr3z/DGg",
    "block": "{\"symbols\":[\"childOrg\"],\"statements\":[[7,\"ul\"],[9],[0,\"\\n\\t\"],[7,\"li\"],[12,\"data-jstree\",[30,[\"{ \\n\\t\\t\\\"type\\\" : \",[25,[\"businessEntity\",\"jsTreeType\"]],\",\\n\\t\\t\\\"selected\\\": \",[25,[\"businessEntity\",\"isSelected\"]],\" \\n\\t}\"]]],[9],[0,\"\\n\\t\\t\"],[7,\"a\"],[11,\"href\",\"#\"],[12,\"id\",[25,[\"businessEntity\",\"id\"]]],[11,\"data-remove-class\",\"sidebar-left-opened\"],[11,\"data-target\",\"html\"],[9],[0,\"\\n\\t\\t\\t\"],[1,[25,[\"businessEntity\",\"name\"]],false],[0,\"\\n\\t\\t\"],[3,\"action\",[[24,0,[]],\"selectBE\",[25,[\"businessEntity\"]]]],[10],[0,\"                                  \\n\"],[4,\"each\",[[25,[\"businessEntity\",\"sortedChildren\"]]],null,{\"statements\":[[4,\"unless\",[[24,1,[\"isMerchant\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[1,[29,\"be-tree-leaf\",null,[[\"businessEntity\",\"selectBE\"],[[24,1,[]],[25,[\"selectBE\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"\\t\"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/components/be-tree-leaf.hbs"
    }
  });

  _exports.default = _default;
});
define("in4m-web/services/rest-users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    users: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('users', []);
    },
    //adds new user
    addUsers: function addUsers(beId, user) {
      var _this = this;

      var url = "".concat(_this.get('apiPath.coreApiUrl'), "/").concat(_this.get('apiPath.coreSecurityApiPath'), "/businessentities/").concat(beId, "/users");

      var accessToken = _this.get('session.sessionObject.accessToken');

      return new Promise(function (resolve, reject) {
        $.ajax({
          url: url,
          method: 'POST',
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify(user),
          headers: {
            'Authorization': "Token token=\"".concat(accessToken, "\""),
            "ClientId": 4
          }
        }).done(function (data) {
          return resolve(data);
        }).fail(function (jqXHR, textStatus, errorThrown) {
          return reject({
            error: errorThrown,
            details: jqXHR.responseJSON
          });
        });
      });
    },
    addRole: function addRole(userId, roleId, requestBody) {
      var _this = this;

      var url = "".concat(_this.get('apiPath.coreApiUrl'), "/").concat(_this.get('apiPath.coreSecurityApiPath'), "/users/").concat(userId, "/roles/").concat(roleId, "/businessentities");

      var accessToken = _this.get('session.sessionObject.accessToken');

      return new Promise(function (resolve, reject) {
        $.ajax({
          url: url,
          method: 'POST',
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify(requestBody),
          headers: {
            'Authorization': "Token token=\"".concat(accessToken, "\""),
            "ClientId": 4
          }
        }).done(function (data) {
          return resolve(data);
        }).fail(function (jqXHR, textStatus, errorThrown) {
          if (jqXHR.status != 201) {
            console.log("fail");
          }

          console.log(textStatus + ' ' + errorThrown);
          return reject(errorThrown);
        });
      });
    },
    //get existing users
    queryUsers: function queryUsers(beId) {
      var _this = this;

      var url = "".concat(_this.get('apiPath.coreApiUrl'), "/").concat(_this.get('apiPath.coreSecurityApiPath'), "/businessentities/").concat(beId, "/users?fullDetail=false");

      var accessToken = _this.get('session.sessionObject.accessToken');

      return this.queryEntities(url, accessToken, 'users');
    },
    queryEntities: function queryEntities(url, accessToken, entityType) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        $.ajax({
          url: url,
          method: 'GET',
          dataType: 'json',
          contentType: 'application/json',
          headers: {
            'Authorization': "Token token=\"".concat(accessToken, "\"")
          }
        }).done(function (data) {
          _this.set(entityType, _this.mapEntity(data));

          return resolve(data);
        }).fail(function (jqXHR, textStatus, errorThrown) {
          return reject(errorThrown);
        });
      });
    },
    mapEntity: function mapEntity(data) {
      return data;
    }
  });

  _exports.default = _default;
});
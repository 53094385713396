define("in4m-web/templates/component/manage/api-interface/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5K7YYKbY",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"model\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[29,\"shift4-table\",null,[[\"data\",\"columns\",\"customClasses\",\"simplePaginationTemplate\",\"showColumnsDropdown\",\"multipleColumnsSorting\",\"globalFilterUsed\",\"filteringIgnoreCase\",\"useFilteringByColumns\",\"sendDisplayDataChangedAction\",\"displayDataChangedAction\",\"addRecord\",\"deleteRecord\",\"showDeleteModal\",\"dismissDeleteModal\"],[[25,[\"filteredTypes\"]],[25,[\"columns\"]],[25,[\"customClasses\"]],\"custom/custom-pagination\",false,false,false,true,false,true,\"displayDataChangedAction\",\"addRecord\",\"deleteRecord\",\"showDeleteModal\",\"dismissDeleteModal\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"footer\"],[11,\"class\",\"panel-footer\"],[11,\"style\",\"background-color:white;margin-top:10px\"],[9],[0,\"\\n\\t\"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\"],[11,\"style\",\"float:right\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"button\"],[11,\"class\",\"btn btn-default\"],[11,\"type\",\"reset\"],[9],[0,\"Back\"],[3,\"action\",[[24,0,[]],\"back\"]],[10],[0,\"\\n\\t\\t\"],[10],[0,\"\\n\\t\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[1,[23,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/component/manage/api-interface/index.hbs"
    }
  });

  _exports.default = _default;
});
define("in4m-web/routes/machine/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return {
        Username: '',
        BusinessEntityId: this.get('session.selectedBusinessEntity.id'),
        FirstName: '',
        LastName: '',
        Email: '',
        VerifyEmail: '',
        UserType: 0,
        // standard user
        IsActive: true,
        RoleId: '10002' // default to read only

      };
    }
  });

  _exports.default = _default;
});
define("in4m-web/helpers/less-than", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lessThan = lessThan;
  _exports.default = void 0;

  function lessThan(params
  /*, hash*/
  ) {
    if (params[0] < params[1]) {
      return true;
    }

    return false;
  }

  var _default = Ember.Helper.helper(lessThan);

  _exports.default = _default;
});
define("in4m-web/routes/component-profiles/component-profile/devices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      $('#progressHudOverlay').show();

      var _this = this;

      var profile = this.modelFor('componentProfiles.componentProfile');
      return this.store.findAll('componentDeviceSchema').then(function () {
        $('#progressHudOverlay').hide();
        return profile.get('config.deviceConfig.devices');
      }, function (error) {
        $('#progressHudOverlay').show();
        return _this.get('errorhandler').handleError(error, 'Error while making a request.');
      });
    }
  });

  _exports.default = _default;
});
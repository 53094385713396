define("in4m-web/components/range-slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['mt-sm'],
    attributeBindings: ['disabledOrUndefined:disabled'],
    slider: null,
    start: undefined,
    step: undefined,
    margin: undefined,
    limit: undefined,
    pips: undefined,
    animate: true,
    snap: false,
    connect: true,
    disabled: false,
    orientation: 'horizontal',
    direction: 'ltr',
    behaviour: 'tap',
    tooltips: true,
    min: 0,
    max: 100,
    schemaFormat: null,
    format: function () {
      var formatFromSchema = this.schemaFormat;
      return {
        to: function to(value) {
          var displayValue;
          formatFromSchema.filter(function (eachFormat) {
            if (eachFormat.value == value) {
              displayValue = eachFormat.displayName;
            }
          });
          return displayValue;
        },
        from: function from(value) {
          var actualValue = value;
          formatFromSchema.filter(function (eachFormat) {
            if (eachFormat.displayName == value) {
              actualValue = eachFormat.value;
            }
          });
          return actualValue;
        }
      };
    }.property('schemaFormat'),
    setup: Ember.on('didInsertElement', function () {
      var _this = this;

      var element = this.get('element');
      var slider = element.noUiSlider;
      var properties = this.getProperties('start', 'step', 'margin', 'limit', 'range', 'connect', 'orientation', 'direction', 'behaviour', 'animate', 'snap', 'pips', 'format', 'tooltips');
      var sliderEvents = Ember.A(['change', 'set', 'slide', 'update', 'start', 'end']); // We first check if the element has a slider already created

      if (slider && slider.destroy) {
        slider.destroy();
      }

      try {
        slider = noUiSlider.create(element, properties, true);
      } catch (err) {
        warn("[ember-cli-nouislider]: ".concat(err));
      }

      this.slider = slider;
      sliderEvents.forEach(function (event) {
        if (!Ember.isEmpty(_this.get("on-".concat(event)))) {
          slider.on(event, function () {
            Ember.run(_this, function () {
              var val = this.slider.get();
              this.sendAction("on-".concat(event), val);
            });
          });
        }
      });
      /** DEPRECATED AND WILL BE REMOVED BEFORE 1.0 **/

      slider.on('change', function () {
        Ember.run(_this, function () {
          var val = this.slider.get();
          this.updateModel("change", val);
        });
      });

      if (!Ember.isEmpty(this.slide)) {
        slider.on('slide', function () {
          Ember.run(_this, function () {
            var val = this.slider.get();
            this.updateModel('slide', val);
          });
        });
      }
    }),
    update: Ember.on('didUpdateAttrs', function () {
      var slider = this.slider;
      var properties = this.getProperties('margin', 'limit', 'step', 'range', 'animate', 'snap', 'start');

      if (slider) {
        slider.updateOptions(properties);
      }
    }),
    teardown: Ember.on('willDestroyElement', function () {
      var slider = this.slider;
      slider.off('change');
      slider.off('slide');
      slider.off('set');
      slider.off('update');
      slider.off('start');
      slider.off('end');
      slider.destroy();
    }),
    setVal: Ember.observer('start', function () {
      var slider = this.slider;

      if (slider) {
        var val = this.start;
        slider.set(val);
      }
    }),
    // disabled can't be just `false` - this leads to an attribute of disabled="false"
    disabledOrUndefined: Ember.computed('disabled', function () {
      if (this.disabled) {
        return true;
      }
    }),
    updateModel: function updateModel(action, value) {
      this.send('changedAction', value);
    },
    actions: {
      changedAction: function changedAction(value) {
        var formatFromSchema = this.schemaFormat;
        var actualValue = value;
        formatFromSchema.filter(function (eachFormat) {
          if (eachFormat.displayName == value) {
            actualValue = eachFormat.value;
          }
        });
        this.model.set(this.camelizedName, actualValue);
      }
    }
  });

  _exports.default = _default;
});
define("in4m-web/utils/default-profile-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = defaultProfileConfig;

  function defaultProfileConfig() {
    var defaultProfileConfig = {
      "config": {
        "microsBridge": {
          "installMicrosBridge": false,
          "product": "",
          "microsInstallLocation": "c:\\micros",
          "microsBridgeInstallLocation": "c:\\shift4interface"
        },
        "utgGlobal": {
          "cdType": 1028,
          "taskDesc": "UtgGlobals",
          "description": "UTGGlobals",
          "maxTransmits": 4,
          "maxCircuitElements": 2,
          "apiSerialNumber": "",
          "apiPassword": "",
          "returnRealTranId": false,
          "maxThreads": 90,
          "configLastChanged": "2018-03-30T11:05:39-07:00",
          "importOverride": false,
          "transactionCacheEnabled": true,
          "lowDiskSpaceThresholdMB": 2.00,
          "convertErrorsToReferrals": false,
          "sendAlertMessagesToShift4": true,
          "corruptDataMarkTrackDataFix1": false,
          "utgId": 1,
          "offline": {
            "MaxInterfaceofflineHours": 12,
            "timeout": 30,
            "standinEnabled": true,
            "standinFloor": 50,
            "returnVoidErrorIfOffline": false,
            "declineAllNonBankCards": false,
            "transactionStoragePeriod": 60,
            "maxUploadAttempts": 3
          },
          "4Go": {
            "enabled": false,
            "fauxGenerationMethod": "SEQVAL",
            "normalRetentionTimeMinutes": 5,
            "offlineRetentionTimeMinutes": 30,
            "updates": true,
            "updateAddress": "",
            "updatePort": 17450,
            "broadcastList": []
          },
          "utgUpdates": true,
          "importManager": {
            "directory": "",
            "minutes": 5
          },
          "workerThreads": 20,
          "discretionaryData": "NOTSET@194",
          "remoteShutoff": false,
          "simulateWebServerIfSSL": false,
          "signature": {
            "timeout1": 60,
            "timeout2": 90,
            "compressEnabled": true,
            "compressSize": 4096
          },
          "useABSRegistry": false,
          "absFileName": "",
          "absDatabaseName": "UTGConfig",
          "multiDataCenterAware": false,
          "dataCenter": "",
          "lastTraceFileWipe": 0.00,
          "pinPadPushSlotCount": 3,
          "cloudTimeoutOverride": 0
        },
        "coreGlobal": {
          "cdType": 1,
          "taskDesc": "CoreGlobals",
          "description": "CoreGlobals",
          "odsEnabled": false,
          "serialNumber": "0000000001",
          "stationName": "Default",
          "programRegKey": "UTG2",
          "statusStartTime": "1899-12-30T00:00:00-07:00",
          "statusStartTimeText": "",
          "mainProduct": "UTG2",
          "mainProductText": "Universal Transaction Gateway®",
          "runningAsService": false,
          "revisionEx": "04.05.00",
          "fileRevision": "4.7.0.2300",
          "trace": {
            "enabled": true,
            "directory": "C:\\Shift4\\UTG2\\Trace",
            "suffix": "",
            "maxBytesEachFile": 20971520,
            "maxFiles": 16,
            "maxBlocks": 250,
            "highCapacityEnabled": true,
            "filesPerZip": 10,
            "daysCutoff": 7,
            "verbose": true,
            "verboseOverride": false,
            "rawStream": false,
            "sqlTraceLevel": "Queries"
          },
          "splunk": {
            "enabled": false,
            "directory": "C:\\Shift4\\Splunk",
            "suffix": "",
            "maxBytesEachFile": 1100000,
            "maxFiles": 16,
            "maxBlocks": 300,
            "highCapacityEnabled": false,
            "filesPerZip": 12,
            "daysCutoff": 30,
            "unmaskAccessToken": false
          },
          "statusTableWidth": 1024,
          "overrideEncryption": false,
          "startVisible": true,
          "colorEnabled": true,
          "mainWindowRow": 0,
          "mainWindowCol": 0,
          "mainWindowHigh": 0,
          "mainWindowWide": 0,
          "nagleEnabled": true,
          "socketBufferSendSize": 8192,
          "socketBufferRecvSize": 8192,
          "telemetry": {
            "hostAddress": "127.0.0.1",
            "listenPort": 18028
          },
          "puppet": {
            "hostAddress": "127.0.0.1",
            "listenPort": 19000
          },
          "muse": {
            "hostAddress": "127.0.0.1",
            "listenPort": 19500
          },
          "environment": "Production",
          "memoryMonitor": {
            "enabled": false,
            "frequency": "H24",
            "destination": ""
          },
          "coreUseABSRegistry": false,
          "installationPath": "C:\\Shift4\\UTG2"
        },
        "expressManager": {
          "taskDesc": "Express",
          "cdType": 126,
          "threadType": 1072,
          "ipAddressType": 1,
          "modemName": "",
          "portNumber": "NoCom",
          "baudRate": "Dynamic",
          "parity": "N",
          "dataBits": "8",
          "stopBits": "1",
          "flowControl": true,
          "ecEnabled": false,
          "dial9": "",
          "phoneRoute1": "",
          "phoneRoute2": "",
          "routeServerName": "ns.virtualleasedline.net",
          "routeServerPort": 26880,
          "routeCode": "A",
          "maxScore": 10,
          "socksProxy": {
            "enabled": false,
            "version": "4",
            "host": "",
            "port": 8989,
            "user": "",
            "password": ""
          }
        },
        "apiInterfaceConfig": {
          "apiInterface": [{
            "taskDesc": "HttpApi",
            "cdType": 111,
            "threadType": 1062,
            "spinEnabled": false,
            "spinFloor": 0.00,
            "printTipLineAuth": false,
            "printTipLineSale": false,
            "listenPort": 16448
          }]
        },
        "deviceConfig": {
          "device": [{
            "taskDesc": "Station1",
            "cdType": 125,
            "threadType": 1078,
            "deviceType": "Ingenico iPP320 RBA",
            "coordinatesFile": "",
            "googleSecrets": "",
            "manualCardEntry": {
              "enabled": true,
              "cvvPrompt": 0,
              "streetPrompt": 0,
              "zipPrompt": 0,
              "minimumDigits": 9,
              "maximumDigits": 32,
              "binRangeOverrides": []
            },
            "privateLabel": {
              "quickCreditApplication": {
                "ssnPrompt": true,
                "ssnLast4Prompt": false,
                "dateofBirthPrompt": false,
                "zipCodePrompt": true,
                "incomePrompt": false,
                "signaturePrompt": false
              },
              "preapprovedApplication": {
                "ssnPrompt": true,
                "ssnLast4Prompt": false,
                "dateofBirthPrompt": true,
                "zipCodePrompt": true,
                "incomePrompt": true,
                "signaturePrompt": true
              },
              "prescreenApplication": {
                "ssnPrompt": false,
                "ssnLast4Prompt": false,
                "dateofBirthPrompt": false,
                "zipCodePrompt": true,
                "incomePrompt": false,
                "signaturePrompt": false
              },
              "prescreenAcceptance": {
                "ssnPrompt": false,
                "ssnLast4Prompt": true,
                "dateofBirthPrompt": true,
                "zipCodePrompt": true,
                "incomePrompt": true,
                "signaturePrompt": false
              },
              "instantCreditApplication": {
                "ssnPrompt": true,
                "ssnLast4Prompt": false,
                "dateofBirthPrompt": true,
                "zipCodePrompt": true,
                "incomePrompt": true,
                "signaturePrompt": false
              },
              "accountLookup": {
                "ssnPrompt": true,
                "ssnLast4Prompt": false,
                "dateofBirthPrompt": false,
                "zipCodePrompt": true
              },
              "promoText": {
                "displayAllDurations": true,
                "percentage12Enabled": true,
                "percentage12": 8.7911,
                "percentage18Enabled": false,
                "percentage18": 0,
                "percentage24Enabled": true,
                "percentage24": 4.614,
                "percentage30Enabled": true,
                "percentage30": 3.7807,
                "percentage36Enabled": true,
                "percentage36": 3.2262,
                "percentage48Enabled": true,
                "percentage48": 2.5358,
                "percentage60Enabled": true,
                "percentage60": 2.1242
              }
            },
            "cashback": {
              "enabled": false,
              "amount1": 20.00,
              "amount2": 40.00,
              "amount3": 60.00,
              "amount4": 80.00,
              "maximumAmount": 200.00
            },
            "ebt": {
              "cashEnabled": false,
              "foodEnabled": false
            },
            "tip": {
              "enabled": true,
              "presetMode": "Percent",
              "percent1": 15,
              "percent2": 18,
              "percent3": 0,
              "percent4": 0,
              "amount1": 20.00,
              "amount2": 40.00,
              "amount3": 0.00,
              "amount4": 0.00
            },
            "connection": {
              "deviceMode": "rs232",
              "portNumber": "COM1",
              "baudRate": 115200,
              "timeoutSecs": 60
            },
            "contactlessEnabled": true,
            "lineLength": 20,
            "beepVolume": 25,
            "debitKeyIndex": 0,
            "amexKeyIndex": 0,
            "bypassAmountOk": false,
            "defaultLanguage": "eng",
            "dccTimeoutSecs": 3
          }]
        }
      }
    };
    return defaultProfileConfig;
  }
});
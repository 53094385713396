define("in4m-web/helpers/check-duplicates-in-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.checkDuplicatesInArray = checkDuplicatesInArray;
  _exports.default = void 0;

  function checkDuplicatesInArray(param1, param2, param3) {
    var hasDuplicates = false;
    var array = param1;
    var property1ToCheck = param2;
    var property2ToCheck = param3;
    var tempArray = []; //this case is for combination of two properties in the array object

    if (property2ToCheck) {
      array.forEach(function (item) {
        var property1Val = item.get(property1ToCheck);
        var property2Val = item.get(property2ToCheck); //create a unique value based on two properties

        var uniqueVal = property1Val + '' + property2Val;
        tempArray.push(uniqueVal);
      });
    } //case for checking single property duplicate values in an array object
    else if (param1 && param2) {
        array.forEach(function (item) {
          tempArray.push(item.get(property1ToCheck));
        });
      }

    if (tempArray.uniq().length !== array.length) {
      hasDuplicates = true;
    }

    return hasDuplicates;
  }

  var _default = Ember.Helper.helper(checkDuplicatesInArray);

  _exports.default = _default;
});
define("in4m-web/controllers/component/manage/general", ["exports", "in4m-web/utils/alerts"], function (_exports, _alerts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    selectedTask: '',
    selectedStatus: '',
    selectedDate: '',
    selectedTimeZone: '',
    runImmediately: false,
    casenumber: '',
    //case number is a field used only on the task creation form for uploading logs. We use this free-form field as part of the directory name for the upload
    fromdaterangepicker: '',
    todaterangepicker: '',
    initializeDatePicker: false,
    fmt: 'MM/DD/YYYY h:mm:ss A',
    canEditServices: Ember.computed('model', function () {
      var selectedBusinessEntity = this.get('session.selectedBusinessEntity');
      var user = this.get('session.userObject');
      var hasPermissionToEditServices = user.hasPermission('canEdit', 'in4mUtgServices', selectedBusinessEntity);
      return hasPermissionToEditServices;
    }),
    canViewDevices: Ember.computed('model', function () {
      var selectedBusinessEntity = this.get('session.selectedBusinessEntity');
      var user = this.get('session.userObject');
      var hasPermissionToViewServices = user.hasPermission('canView', 'in4mDevices', selectedBusinessEntity);
      return hasPermissionToViewServices;
    }),
    isEditDisabled: Ember.computed('model', function () {
      var selectedBusinessEntity = this.get('session.selectedBusinessEntity');
      var user = this.get('session.userObject');
      var hasPermissionToScheduleTasks = user.hasPermission('isEnabled', 'in4mUtgScheduleTasks', selectedBusinessEntity);
      return !hasPermissionToScheduleTasks;
    }),
    customClasses: {
      'pageSizeWrapper': 'col-xs-12 col-sm-8 col-md-6'
    },
    customMessageForDevices: {
      "noDataToShow": "No Devices"
    },
    customMessageForTasks: {
      "noDataToShow": "No Tasks Scheduled"
    },
    columns: function () {
      return [{
        "propertyName": "taskType",
        "title": "Type"
      }, {
        "template": "custom/dasherizedColumn",
        //not naming it specifically to version related since this template can be used elsewhere
        "propertyName": "version",
        "title": "Version"
      }, {
        "template": "custom/dateFormatter",
        "propertyName": "runAfter",
        "title": "Date",
        "className": "columnMinWidth",
        "sortDirection": "desc",
        "sortPrecedence": 1
      }, {
        "propertyName": "state",
        "title": "State"
      }, {
        "propertyName": "resultMessage",
        "title": "Result Message"
      }, {
        "title": "Result Details",
        "template": "custom/taskResultDetails"
      }, {
        "template": "custom/delete-task",
        "useFilter": false,
        "templateForSortCell": 'custom/add-record',
        "isHidden": this.isEditDisabled
      }];
    }.property('isEditDisabled'),
    deviceColumns: [{
      "propertyName": "serviceName",
      "title": "Service Name"
    }, {
      "propertyName": "machineName",
      "title": "Machine Name"
    }, // {
    // 	"propertyName": "deviceStatusComputed",
    // 	"title": "Status"
    // },
    {
      "propertyName": "deviceFirmwareComputed",
      "title": "Device Firmware"
    }, {
      "template": "custom/deviceKeys",
      "propertyName": "deviceyKeysComputed",
      "title": "Device Keys",
      "disableSorting": true
    }, {
      "propertyName": "deviceSerialNumber",
      "title": "Device Serial"
    }, {
      "propertyName": "deviceTypeComputed",
      "title": "Device Type"
    }, {
      "propertyName": "apiTid",
      "title": "Terminal ID"
    }, {
      "propertyName": "devicePciVersion",
      "title": "PCI Version"
    }],
    actions: {
      updateStatusInstructions: function updateStatusInstructions(value) {
        this.set('selectedStatus', value.selectedStatus);
        this.set('instructions', value.instructions);
      },
      refreshTable: function refreshTable(component) {
        var _this = this;

        if (component) {
          $('#progressHudOverlay').show();
          return this.store.queryRecord('component', {
            businessEntityId: component.get('parentBusinessEntity.id'),
            machinePermanentId: component.get('machine.permanentId'),
            componentPermanentId: component.get('permanentId')
          }).then(function () {
            $('#progressHudOverlay').hide();
          }, function (error) {
            $('#progressHudOverlay').hide();

            _this.get('errorhandler').handleError(error, 'Error while making a request.');
          });
        }
      },
      addRecord: function addRecord(record) {
        this.set('initializeDatePicker', true);
        $('#scheduleTaskModal').modal('show');
      },
      showDeleteModal: function showDeleteModal(record) {
        this.set('selectedTask', record);
        $('#deleteAPIModal').modal('show');
      },
      dismissDeleteModal: function dismissDeleteModal(record) {
        $('#deleteAPIModal').modal('hide');
      },
      showDeviceKeysModal: function showDeviceKeysModal(record) {
        this.set('selectedRecord', record);
        $('#deviceKeysModal').modal('show');
      },
      deleteRecord: function deleteRecord() {
        var record = this.selectedTask;

        var _this = this;

        if (record) {
          $('#progressHudMessage').html('Updating...');
          $('#progressHudOverlay').show();
          record.destroyRecord().then(function () {
            $('#progressHudOverlay').hide();

            _alerts.default._success('The UTG has been updated.');
          }, function (error) {
            _this.get('errorhandler').handleError(error, 'Failed to delete task.');
          });
        }

        $('#deleteAPIModal').modal('hide');
      },
      selectedDate: function selectedDate(date) {
        var formattedDate = date.format(this.fmt);
        $('#daterangepicker').val(formattedDate);
        this.set('selectedDate', formattedDate);
      },
      fromdaterangepicker: function fromdaterangepicker(date) {
        var formattedDate = date.format(this.fmt);
        $('#fromdaterangepicker').val(formattedDate);
        this.set('fromdaterangepicker', formattedDate);
      },
      todaterangepicker: function todaterangepicker(date) {
        var formattedDate = date.format(this.fmt);
        $('#todaterangepicker').val(formattedDate);
        this.set('todaterangepicker', formattedDate);
      },
      closeModal: function closeModal() {
        $('#daterangepicker').val(''); //resetting the daterangepicker value to default value

        $('#fromdaterangepicker').val('');
        $('#todaterangepicker').val('');
        this.set('selectedStatus', '');
        this.set('selectedDate', '');
        this.set('selectedTimeZone', '');
        this.set('fromdaterangepicker', '');
        this.set('todaterangepicker', '');
        this.set('casenumber', ''); //for more information about this field, see case number comment above

        this.set('runImmediately', false);
        this.set('initializeDatePicker', false);
        this.set('selectedComponentType', '');
        this.set('selectedTaskType', '');
        this.set('instructions', '');
        $("#scheduleTasks").data('validator').resetForm(); //resetting the form to remove error classes if any on modal close

        $('#scheduleTaskModal').modal('hide');
      },
      changeStatus: function changeStatus(value) {
        if ($('#scheduleTasks').valid()) {
          $('#progressHudMessage').html('Updating...');
          $('#progressHudOverlay').show();

          var _this = this;

          var model = _this.get('model');

          var runAfter;

          if (!_this.get('runImmediately')) {
            //set runAfter only if run immediately not checked
            runAfter = moment(_this.get('selectedDate')).tz(_this.get('selectedTimeZone')).utc().format(_this.get('fmt'));
          } else {
            runAfter = moment().toISOString(); //setting runAfter to current date and time to avoid sorting on null record
          }

          var selectedStatus = _this.get('selectedStatus');

          var instructions = _this.get('instructions');

          if (value && value.selectedStatus == '15') {
            // override instructions for upload log and trace
            instructions = value.instructions;
          }

          if (_this.get('selectedStatus')) {
            var task = _this.store.createRecord('task', {
              componentId: model.id,
              type: parseInt(selectedStatus),
              businessEntityId: model.get('parentBusinessEntity').get('id'),
              machinePermanentId: model.get('machine.permanentId'),
              runAfter: runAfter,
              instructions: instructions
            });

            task.save().then(function (task) {
              if (selectedStatus == 4) {
                model.set('hasConfigChanges', false); //adding this to make sure the component has no config changes when user updates UTG to latest configuration
              }

              task.set('component', model);

              _alerts.default._success('Task scheduled.');

              $('#progressHudOverlay').hide();

              _this.send('closeModal');
            }, function (error) {
              _this.send('closeModal');

              return _this.get('errorhandler').handleError(error, 'Failed to schedule task.');
            });
          }
        }
      },
      updateComponent: function updateComponent() {
        if ($('#generalForm').valid()) {
          var _this = this;

          $('#progressHudMessage').html('Updating...');
          $('#progressHudOverlay').show();
          this.model.save().then(function () {
            $('#progressHudOverlay').hide();

            _alerts.default._success('Component has been updated.');
          }, function (error) {
            $('#progressHudOverlay').hide();

            _this.get('errorhandler').handleError(error, 'Unable to update component.');
          });
        }
      },
      copyRecord: function copyRecord(record) {
        // Place data in temp object. Straight copying of the data will not work as ember data will not do deep copy to avoid circular references.
        // This gives us the data we need in a simpler format
        var task = {};
        task.id = record.id;
        task.type = record.type;
        task.taskType = record.taskType;
        task.runAfter = record.runAfter;
        task.status = record.status;
        task.state = record.state;
        task.taskGroupId = record.taskGroupId;
        task.instructions = record.instructions;
        task.lastUpdatedAt = record.lastUpdatedAt;
        task.lastUpdatedBy = record.lastUpdatedBy;
        task.version = record.version;
        task.businessEntityId = record.businessEntityId;
        task.resultMessage = record.resultMessage;
        task.resultDetails = record.resultDetails;
        task.isDeletable = record.isDeletable;
        task.nextPredictedUpdateAt = record.component.nextPredictedUpdateAt;
        task.lastMachineStatusUpdatedAt = record.component.lastMachineStatusUpdatedAt;
        task.utgStatus = record.component.status;
        task.machineStatus = record.component.machineStatus;
        task.isAgentVersionLatest = record.component.isAgentVersionLatest;
        task.softwareVersionFromAws = record.component.softwareVersionFromAws; // throw in anything else we can get from the component

        task.component = record.component; // place the data on the users clipboard if the browser supports it

        if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
          navigator.clipboard.writeText(JSON.stringify(task, null, 2));

          _alerts.default._success('The task data has been copied to your clipboard.', 'Task Copied');
        } else {
          _alerts.default._error('This browser does not support copying data to the clipboard.', 'Copy Failed');
        }
      }
    }
  });

  _exports.default = _default;
});
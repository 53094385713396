define("in4m-web/routes/installations/install-utg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    renderTemplate: function renderTemplate() {
      this.render('installations/installUTG', {
        into: 'installations',
        outlet: 'modal'
      });
      this.render('installations/index', {
        into: 'installations',
        controller: 'installations/index',
        model: this.modelFor('installations')
      });
    },
    model: function model() {
      var install = this.store.createRecord('installation', {
        type: 10,
        parentBusinessEntity: this.get('session.selectedBusinessEntity'),
        manifest: this.store.createRecord('installationManifest', {
          install: install,
          machine: this.store.createRecord('machine', {
            nickname: null,
            createKeyContainer: true,
            parentBusinessEntity: this.get('session.selectedBusinessEntity')
          }),
          agent: this.store.createRecord('agent', {
            installLocation: 'c:\\shift4\\Shift4 Command Center\\agent',
            createNewUser: true,
            newMachine: true,
            registerAgent: true,
            registerComponents: true,
            loadMasterKey: true
          }),
          credentials: this.store.createRecord('installationCredentials', {}),
          components: [this.store.createRecord('component', {
            installLocation: 'c:\\shift4\\',
            runInstall: true,
            type: 1,
            parentBusinessEntity: this.get('session.selectedBusinessEntity')
          })],
          tasks: [this.store.createRecord('installationTask', {
            type: 5,
            instructions: {
              destination: 6
            }
          })]
        })
      });
      return install;
    },
    afterModel: function afterModel(transition) {
      Ember.run.next(function () {
        $('#installationModal').modal('show');
      });
    }
  });

  _exports.default = _default;
});
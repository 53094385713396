define("in4m-web/controllers/installations/select-install-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      installNewUTG: function installNewUTG() {
        this.transitionToRoute('installations.installUTG');
      },
      connectUTG: function connectUTG() {
        this.transitionToRoute('installations.connectUTG');
      }
    }
  });

  _exports.default = _default;
});
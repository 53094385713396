define("in4m-web/controllers/component/manage/devices/device", ["exports", "in4m-web/utils/drop-down-list"], function (_exports, _dropDownList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    component: Ember.inject.controller('component.manage'),
    supportedDevices: function () {
      var supportedDevices = this.store.peekAll('componentDeviceSchema');
      return supportedDevices;
    }.property('model'),
    deviceSchema: function () {
      if (this.model) {
        return this.supportedDevices.findBy('name', this.get('model.deviceType')).get('schemaValue');
      } else {
        this.transitionToRoute('component.manage.devices');
      }
    }.property('supportedDevices'),
    selectedDeviceSchema: function () {
      return this.deviceSchema;
    }.property('deviceSchema'),
    validations: function () {
      var selectedDeviceSchema = this.deviceSchema;
      var validations = {};

      function setValidations(validations, properties) {
        for (var key in properties) {
          if (properties[key].type != 'object' && properties[key].type != 'array') {
            validations[key] = {
              required: properties[key].required,
              maxLength: properties[key].maxLength,
              minLength: properties[key].minLength,
              minimum: properties[key].minimum,
              maximum: properties[key].maximum,
              disabled: properties[key].disabled
            };
          } else {
            if (properties[key].type != 'array') {
              validations[key] = {};
              setValidations(validations[key], properties[key].properties);
            } else {
              validations[key] = {};
              setValidations(validations[key], properties[key].items.properties);
            }
          }
        }
      }

      if (selectedDeviceSchema) {
        setValidations(validations, selectedDeviceSchema.properties);
      }

      validations.deviceType = this.get('selectedDevice.name');
      return validations;
    }.property('deviceSchema'),
    dropdownListValues: Ember.computed('dropdownList', function () {
      return (0, _dropDownList.default)();
    }),
    reservedPortValues: Ember.computed('model.[]', function () {
      var _this2 = this;

      var _this = this;

      var reservedPortValues = [];
      var component = this.get('component').get('model');
      var devices = component.get('config').get('deviceConfig').get('devices');
      devices.forEach(function (device) {
        if (device.get('connection.deviceMode') === 'rs232') {
          var portNumber = device.get('connection.portNumber');
          var deviceType = device.get('deviceType');

          if (portNumber) {
            reservedPortValues.push(portNumber);

            var selectedDeviceType = _this.get('model.deviceType');

            if (portNumber == 'Auto' && selectedDeviceType && selectedDeviceType != deviceType) {
              reservedPortValues.pop();
            }
          }
        }
      }); //remove the current port value from the reservedPorts list.

      reservedPortValues = reservedPortValues.filter(function (port) {
        return port != _this2.get('model.connection.portNumber');
      });
      return reservedPortValues;
    }),
    resetTip: Ember.observer('model.tip.enabled', function () {
      if (!this.get('model.tip.enabled')) {
        this.set('model.tip.presetMode', 'Disabled');
      }
    }),
    actions: {
      toggleCollapsed: function toggleCollapsed(section) {
        this.set(section + 'Collapsed', !this.get(section + 'Collapsed'));
      },
      addBinRangeOverride: function addBinRangeOverride() {
        // TODO: possibly make bin range management a component so it can be reused in services
        this.get('model.manualCardEntry.binRangeOverrides').addObject({
          bypassCVV: false,
          bypassStreet: false,
          bypassExpDate: false,
          bypassLuhn: false,
          bypassPostalCode: false,
          lower: null,
          upper: null,
          minLength: null,
          maxLength: null
        });
      },
      removeBinRangeOverride: function removeBinRangeOverride(binRange) {
        this.get('model.manualCardEntry.binRangeOverrides').removeObject(binRange);
      },
      back: function back() {
        history.back();
      }
    }
  });

  _exports.default = _default;
});
define("in4m-web/helpers/object-at", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.objectAt = objectAt;
  _exports.default = void 0;

  function objectAt(params
  /*, hash*/
  ) {
    var array = params[0];
    var index = params[1];
    return array.objectAt(index);
  }

  var _default = Ember.Helper.helper(objectAt);

  _exports.default = _default;
});
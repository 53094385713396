define("in4m-web/routes/installations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    permKey: 'in4mUtgInstallations',
    permAction: 'canView',
    model: function model() {
      var _this = this;

      var navigation = _this.get('navigation');

      var hasPermission = navigation.checkPermissions(_this.get('session.selectedBusinessEntity'));

      if (hasPermission) {
        var parentBusinessEntityId = this.get('session.selectedBusinessEntity.id');
        $('#progressHudOverlay').show();
        return this.store.query('installation', {
          parentBusinessEntityId: parentBusinessEntityId
        }).then(function (installations) {
          $('#progressHudOverlay').hide();
          return installations;
        }, function (error) {
          $('#progressHudOverlay').hide();

          _this.get('errorhandler').handleError(error, 'Error retrieving the installations.');
        });
      }
    },
    actions: {
      closeModal: function closeModal() {
        var _this = this;

        $('#installationModal').off('hidden.bs.modal');
        $('#installationModal').on('hidden.bs.modal', function (e) {
          _this.transitionTo('installations.index');
        });
        $('#installationModal').modal('hide');
      },
      willTransition: function willTransition(transition) {
        this.set('previousRoute', this.routeName);
      },
      back: function back() {
        var _this = this; // go back to previous route


        if (this.previousRoute) {
          if (this.previousRoute.indexOf('selectInstallType') != -1) {
            this.transitionTo(this.previousRoute);
          } else {
            // go back to dashboard
            $('#installationModal').off('hidden.bs.modal');
            $('#installationModal').on('hidden.bs.modal', function (e) {
              _this.transitionTo('dashboard');
            });
            $('#installationModal').modal('hide');
          }
        } else {
          // go back to dashboard
          $('#installationModal').off('hidden.bs.modal');
          $('#installationModal').on('hidden.bs.modal', function (e) {
            _this.transitionTo('dashboard');
          });
          $('#installationModal').modal('hide');
        }
      }
    }
  });

  _exports.default = _default;
});
define("in4m-web/services/refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    logout: Ember.inject.service(),
    refresh: function refresh() {
      var _this = this;

      _this.get('store').findRecord('accessToken', _this.get('session.sessionObject.accessToken'), {
        reload: true
      }).then(function (data) {
        _this.get('session').setSessionObject(data);

        return _this.get('store').query('userManifest', {
          accesstoken: data.get('accessToken')
        }).then(function (loggedInUser) {
          //this api call is needed in here because core API does not extend the session with only get accessToken call
          var userObject = loggedInUser.get('firstObject');

          _this.get('session').setUserObject(userObject);

          $('#inactivityModal').modal('hide');
          _this.get('session').sessionTimeoutInterval = setInterval(function () {
            if (_this.get('session.sessionObject')) {
              if (Big(Big((_this.get('session.sessionObject.expires').getTime() - Date.now()) / 1000).toFixed(0)) <= 300) {
                clearInterval(_this.get('session.sessionTimeoutInterval'));
                $('#inactivityModal').on('show.bs.modal', function () {
                  clearInterval(_this.get('session.sessionTimeoutInterval'));
                });
                $('#inactivityModal').modal('show');
              }
            }
          }, 1000);
        }, function () {
          return _this.get('logout').logout();
        });
      }, function () {
        return _this.get('logout').logout();
      });
    }
  });

  _exports.default = _default;
});
define("in4m-web/serializers/permission-mask", ["exports", "in4m-web/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      applicationGroup: 'applicationGroupId',
      permission: 'permissionId',
      businessEntities: 'roleBusinessEntityIds'
    },
    normalize: function normalize(modelClass, resourceHash, prop) {
      if (prop === 'permissionMask') {
        resourceHash.id = resourceHash.permissionId + '-' + resourceHash.levelMask;
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});
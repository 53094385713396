define("in4m-web/serializers/machine", ["exports", "ember-data", "in4m-web/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      parentBusinessEntity: 'businessEntityId',
      //changing to make sure everywhere businessEntityId would be referred as parentBusinessEntity
      components: {
        embedded: 'always'
      }
    },
    normalize: function normalize(modelClass, resourceHash) {
      // We should not rely on getting permanentId from the machineStatus all the time, instead we should just use the machine.permanentId
      resourceHash.machineStatus = resourceHash.permanentId;

      if (resourceHash.components) {
        resourceHash.components.forEach(function (component) {
          delete component.machine;
        });
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});
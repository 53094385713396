define("in4m-web/components/schedule-tasks-certificate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      closeScheduleTasksModal: function closeScheduleTasksModal() {
        this.sendAction('closeScheduleTasksModal');
      },
      scheduleTask: function scheduleTask() {
        this.sendAction('scheduleTask');
      }
    }
  });

  _exports.default = _default;
});
define("in4m-web/adapters/permission-mask", ["exports", "in4m-web/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    buildURL: function buildURL(type, id, snapshot) {
      var url = this.host + '/' + this.namespace + '/users/' + this.get('session.userObject.userId') + '/permissionMasks';
      return url;
    }
  });

  _exports.default = _default;
});
define("in4m-web/serializers/application", ["exports", "ember-data", "in4m-web/mixins/check-for-underscore", "in4m-web/mixins/check-for-id"], function (_exports, _emberData, _checkForUnderscore, _checkForId) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_checkForId.default, _checkForUnderscore.default, {
    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      var data = this.serialize(record, options);

      for (var attr in data) {
        hash[attr] = data[attr];
      }
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.response) {
        delete payload.response;
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});
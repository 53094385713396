define("in4m-web/routes/component/manage/api-interface/detail/component-certificate", ["exports", "in4m-web/helpers/check-for-dirty-attributes", "in4m-web/utils/uuid"], function (_exports, _checkForDirtyAttributes, _uuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      var _this = this;

      return Ember.RSVP.hash({
        componentCertificate: this.store.createRecord('componentCertificate', {
          certificateGuid: (0, _uuid.default)(),
          parentBusinessEntity: _this.get('session').get('selectedBusinessEntity'),
          component: _this.modelFor('component.manage').get('component')
        }),
        selectedInterface: _this.modelFor('component.manage.api-interface.detail')
      });
    },
    setupController: function setupController(controller, models) {
      controller.set('componentCertificate', models.componentCertificate);
      controller.set('selectedInterface', models.selectedInterface);
    }
  });

  _exports.default = _default;
});
define("in4m-web/models/task", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    componentId: attr('number'),
    component: belongsTo('component', {
      async: false
    }),
    hash: attr('string'),
    instructions: attr('string'),
    lastUpdatedAt: attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    lastUpdatedBy: attr('number', {
      defaultValue: 1
    }),
    runAfter: attr('string'),
    signature: attr('string'),
    status: attr('number', {
      defaultValue: 0
    }),
    taskGroupId: attr('string'),
    type: attr('number'),
    businessEntityId: attr('number'),
    machinePermanentId: attr('string'),
    version: attr('string'),
    resultMessage: attr('string'),
    resultDetails: attr('string'),
    taskType: Ember.computed('status', 'instructions', function () {
      var type = this.type;
      var instructions;

      if (type === 1) {
        instructions = JSON.parse(this.instructions);

        if (instructions) {
          if (instructions.ComponentRunningMode == 1) {
            return "Start UTG as a service";
          } else if (instructions.ComponentRunningMode == 2) {
            return "Start UTG as stand alone";
          }
        }
      } else if (type === 2) {
        return "Stop UTG";
      } else if (type === 3) {
        return "Restart UTG";
      } else if (type === 4) {
        return "Update UTG(s) to latest configuration";
      } else if (type === 5) {
        instructions = JSON.parse(this.instructions);

        if (instructions) {
          if (instructions.Destination == 5) {
            return "Revert UTG to last successfully applied configuration";
          } else if (instructions.Destination == 10) {
            return "Update Shift4 Command Center with UTG's local configuration";
          }
        }
      } else if (type === 6) {
        return "Retrieve latest component information";
      } else if (type === 7) {
        return "Reset UTG Slot";
      } else if (type === 8) {
        return "Change UTG Version";
      } else if (type === 9) {
        return "Change Agent Version";
      } else if (type === 10) {
        return "Push Forms";
      } else if (type === 11) {
        return "Update Firmware";
      } else if (type === 13) {
        return "Set Offline";
      } else if (type === 15) {
        return "Upload Logs";
      } else {
        return "Unknown";
      }
    }),
    state: Ember.computed('status', function () {
      var status = this.status;

      if (status === -10) {
        return "Rejected";
      } else if (status === -5) {
        return "Controller Failed";
      } else if (status >= 0 && status < 15) {
        return "Scheduled";
      } else if (status === 15) {
        return "Running";
      } else if (status === 20) {
        return "Completed";
      } else if (status === 30) {
        return "Failed";
      } else {
        return "Unknown";
      }
    }),
    isDeletable: Ember.computed('status', function () {
      if (this.status < 15) {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});
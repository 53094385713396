define("in4m-web/templates/components/s4-computed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K0PodZiD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\"],[11,\"class\",\"form-control-static\"],[9],[1,[23,\"port\"],false],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/components/s4-computed.hbs"
    }
  });

  _exports.default = _default;
});
define("in4m-web/helpers/convert-to-giga-bytes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.convertToGigaBytes = convertToGigaBytes;
  _exports.default = void 0;

  function convertToGigaBytes(params
  /*, hash*/
  ) {
    //TODO: this helper can be expanded to convert to any thing from any value such as MB -> GB or MB -> KB.
    var data = params[0];
    var unit = params[1]; //Bytes, KB, MB, GB

    if (data) {
      var gigaBytes;

      switch (unit) {
        case 'bytes':
          gigaBytes = data / (1024 * 1024 * 1024);
          break;

        case 'KB':
          gigaBytes = data / (1024 * 1024);
          break;

        case 'MB':
          gigaBytes = data / 1024;
          break;

        case 'GB':
          gigaBytes = data;
          break;
      }

      var places = Math.pow(10, 2);
      return Math.round(gigaBytes * places) / places;
    }
  }

  var _default = Ember.Helper.helper(convertToGigaBytes);

  _exports.default = _default;
});
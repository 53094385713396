define("in4m-web/components/advanced-template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      save: function save() {
        this.sendAction('save');
      }
    }
  });

  _exports.default = _default;
});
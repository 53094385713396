define("in4m-web/adapters/validation-request", ["exports", "ember-inflector", "in4m-web/adapters/application"], function (_exports, _emberInflector, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    host: Ember.computed('apiPath', function () {
      return this.get('apiPath.in4mApiUrl');
    }),
    namespace: Ember.computed('apiPath', function () {
      return this.get('apiPath.in4mApiPath');
    }),
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var url = this.host + '/' + this.namespace + '/businessEntities/' + snapshot.record.businessEntityId + '/components/' + snapshot.record.componentId + '/' + 'componentCertificates' + '/' + snapshot.record.certificateGuid + '/' + this.pathForType(modelName);
      return url;
    },
    pathForType: function pathForType(modelName) {
      var camelized = Ember.String.camelize(modelName);
      var pluralized = (0, _emberInflector.pluralize)(camelized);
      return pluralized;
    }
  });

  _exports.default = _default;
});
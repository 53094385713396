define("in4m-web/templates/components/table-action-gear", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L/1GBdG/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"a\"],[11,\"class\",\"dropdown-toggle pull-right\"],[11,\"data-toggle\",\"dropdown\"],[11,\"aria-haspopup\",\"true\"],[11,\"aria-expanded\",\"true\"],[9],[0,\"\\n\\t\"],[7,\"i\"],[11,\"class\",\"fa fa-cog fa-lg\"],[11,\"style\",\"color:gray\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"ul\"],[11,\"class\",\"dropdown-menu\"],[11,\"style\",\"\"],[9],[0,\"\\n\\t\"],[15,1],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/components/table-action-gear.hbs"
    }
  });

  _exports.default = _default;
});
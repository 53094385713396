define("in4m-web/utils/component-device-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = componentDeviceTypes;

  function componentDeviceTypes() {
    var componentDeviceTypes = {
      // Ingenico
      '1': 'iPP320CAN',
      '2': 'iCM122RBA',
      '3': 'iMP352RBA',
      '4': 'iMP627RBA',
      '5': 'iMP657RBA',
      '6': 'iPP320RBA',
      '7': 'iPP350RBA',
      '8': 'iSC250RBA',
      '9': 'iSC350RBA',
      '10': 'iSC480RBA',
      '11': 'iWL250RBA',
      '12': 'iWL252RBA',
      '13': 'iWL258RBA',
      '14': 'iUP250RBA',
      '15': 'iUC285RBA',
      '16': 'Lane3000',
      '24': 'Lane3600',
      '17': 'Lane5000',
      '18': 'Lane7000',
      '19': 'Lane8000',
      '20': 'Link2500',
      '21': 'Move5000',
      // Verifone
      '22': 'Mx915P2PE',
      '23': 'Mx925P2PE'
    };
    return componentDeviceTypes;
  }
});
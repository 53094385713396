define("in4m-web/models/ephemeral-user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    authenticationCode: attr('string'),
    parentBusinessEntity: belongsTo('businessEntity'),
    applicationId: attr('number'),
    createdBy: attr('string'),
    allowDuplicateSessions: attr('boolean'),
    lifespan: belongsTo('lifespan', {
      async: false
    }),
    roleMappings: hasMany('roleMapping'),
    userSessions: hasMany('userSession'),
    isRevoked: attr('boolean'),
    lastUpdatedAt: attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    lastUpdatedBy: attr('number', {
      defaultValue: 1
    }),
    //currently no relationship is set here, but need to do it in future,
    parentName: function () {
      return this.get('parentBusinessEntity.name');
    }.property('parentBusinessEntity')
  });

  _exports.default = _default;
});
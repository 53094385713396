define("in4m-web/routes/installations/installation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    renderTemplate: function renderTemplate() {
      this.render('installations/installation', {
        into: 'installations',
        outlet: 'modal'
      });
      this.render('installations/index', {
        into: 'installations',
        controller: 'installations/index',
        model: this.modelFor('installations')
      });
    },
    model: function model(params) {
      return this.store.peekRecord('installation', params.installationID);
    },
    afterModel: function afterModel() {
      Ember.run.next(function () {
        $('#installationModal').modal('show');
      });
    }
  });

  _exports.default = _default;
});
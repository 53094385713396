define("in4m-web/controllers/installations/index", ["exports", "in4m-web/utils/alerts"], function (_exports, _alerts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    columns: [{
      "template": "custom/installGear"
    }, {
      "propertyName": "manifest.machine.nickname",
      "title": "Installation Name"
    }, {
      "template": "custom/installationType",
      "propertyName": "type",
      "title": "Install Type"
    }, {
      "propertyName": "verificationId",
      "title": "Verification ID"
    }, {
      "propertyName": "parentBusinessEntity.name",
      "title": "Parent Account"
    }, {
      "template": "custom/installationDate",
      "propertyName": "lastUpdatedAt",
      "title": "Creation Date",
      "sortedBy": "lastUpdatedAt",
      "sortDirection": "desc",
      "sortPrecedence": "1"
    }],
    installToDeactivate: null,
    actions: {
      newInstall: function newInstall() {
        this.transitionToRoute('installations.selectInstallType');
        Ember.run.next(function () {
          $('#installationModal').modal('show');
        });
      },
      installationsInfo: function installationsInfo() {
        $('#installationsInfoModal').modal('show');
      },
      showInstallationsInfoDoc: function showInstallationsInfoDoc() {
        var _this = this;

        window.open(_this.get('apiPath.referenceDocumentUrl'), '_blank');
      },
      deactivateInstall: function deactivateInstall() {
        $('#progressHudMessage').html('Deactivating...');
        $('#progressHudOverlay').show();
        $('#deactivateInstallModal').modal('toggle'); // get the install the user clicked on earlier

        var record = this.installToDeactivate;
        record.deleteRecord();
        record.save().then(function () {
          $('#progressHudOverlay').hide();
          $('#progressHudMessage').html('Loading...');

          _alerts.default._info('The installation has been deleted.');
        });
      },
      deactivateInstallConfirm: function deactivateInstallConfirm(record) {
        // store the record they clicked on in the controller
        this.set('installToDeactivate', record); // show confirmation

        $('#deactivateInstallModal').modal('toggle');
      },
      deactivateInstallCancel: function deactivateInstallCancel() {
        // clear record
        this.set('installToDeactivate', null); // hide modal

        $('#deactivateInstallModal').modal('toggle');
      },
      downloadInstall: function downloadInstall(install) {
        var _this = this;

        _alerts.default._info('Download Request sent. Your download should begin shortly.'); // build url


        var baseUrl = _this.get('apiPath.in4mApiUrl') + '/' + _this.get('apiPath.in4mPubPath');

        var downloadUrl = baseUrl + '/' + 'commandcentersetup.exe';
        window.location.replace(downloadUrl);
      },
      downloadInstallAsPackage: function downloadInstallAsPackage(install) {
        var _this = this;

        _alerts.default._info('Download of commandcenter.zip should begin shortly.'); // build url


        var baseUrl = _this.get('apiPath.in4mApiUrl') + '/' + _this.get('apiPath.in4mApiPath');

        var downloadUrl = baseUrl + '/installations/packagezip?verificationId=' + install.verificationId; //trigger Ajax request to load binary data

        var oReq = new XMLHttpRequest();
        oReq.open("GET", downloadUrl, true);
        oReq.responseType = "arraybuffer";
        /* For more information on "Receiving binary data using JavaScript typed arrays", 
           See: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/Sending_and_Receiving_Binary_Data
        */

        oReq.onload = function (oEvent) {
          var arrayBuffer = oReq.response; // Note: not oReq.responseText

          if (arrayBuffer) {
            var byteArray = new Uint8Array(arrayBuffer);
            /* Package data as Blob for download. See:
            	- https://stackoverflow.com/questions/3665115/how-to-create-a-file-in-memory-for-user-to-download-but-not-through-server
            	- https://developer.mozilla.org/en-US/docs/Web/API/Blob)
            */

            var blob = new Blob([byteArray], {
              type: "application/octet-stream"
            }); //trigger save prompt

            saveAs(blob, "commandcentersetup.zip");
          }
        };

        oReq.send(null);
      }
    }
  });

  _exports.default = _default;
});
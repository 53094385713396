define("in4m-web/templates/components/models-table/global-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IIZIhEHM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[30,[[25,[\"classes\",\"globalFilterWrapper\"]]]]],[9],[0,\"\\n\\t\"],[7,\"div\"],[11,\"class\",\"dataTables_filter\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"label\"],[9],[0,\"\\n\\t\\t\"],[1,[29,\"input\",null,[[\"type\",\"value\",\"class\",\"enter\",\"placeholder\"],[\"text\",[25,[\"filterString\"]],[29,\"concat\",[[25,[\"classes\",\"input\"]],\" filterString\"],null],\"\",[25,[\"messages\",\"searchPlaceholder\"]]]]],false],[0,\"\\n\\t\\t\\t\"],[10],[0,\"\\n\"],[0,\"\\t\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/components/models-table/global-filter.hbs"
    }
  });

  _exports.default = _default;
});
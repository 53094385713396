define("in4m-web/models/permission-mask", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    applicationGroup: belongsTo('applicationGroup'),
    permission: belongsTo('permission'),
    levelMask: attr('number'),
    businessEntities: hasMany('businessEntity'),
    canView: function () {
      if (16 & this.levelMask) {
        return true;
      } else {
        return false;
      }
    }.property('levelMask'),
    canEdit: function () {
      if (8 & this.levelMask) {
        return true;
      } else {
        return false;
      }
    }.property('levelMask'),
    canDelete: function () {
      if (4 & this.levelMask) {
        return true;
      } else {
        return false;
      }
    }.property('levelMask'),
    isEnabled: function () {
      if (2 & this.levelMask) {
        return true;
      } else {
        return false;
      }
    }.property('levelMask'),
    canGrant: function () {
      if (1 & this.levelMask) {
        return true;
      } else {
        return false;
      }
    }.property('levelMask')
  });

  _exports.default = _default;
});
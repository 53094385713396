define("in4m-web/routes/machine/manage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      $('#progressHudOverlay').show();
      return this.store.peekRecord('machine', params.machineID);
    },
    afterModel: function afterModel() {
      $('#progressHudOverlay').hide();
    },
    actions: {
      back: function back() {
        this.transitionTo('machine');
      },
      handleBusinessEntityChange: function handleBusinessEntityChange() {
        this.transitionTo('machine');
        return true;
      }
    }
  });

  _exports.default = _default;
});
define("in4m-web/helpers/installation-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.installationType = installationType;
  _exports.default = void 0;

  function installationType(params
  /*, hash*/
  ) {
    var installationType = params[0];
    var installationTypeDesc;

    if (installationType) {
      switch (installationType) {
        case 10:
          installationTypeDesc = 'Install and Connect a New UTG';
          break;

        case 20:
          installationTypeDesc = 'Upgrade Existing UTG';
          break;

        case 30:
          installationTypeDesc = 'Connect Existing UTG to Shift4 Command Center';
          break;

        case 40:
          installationTypeDesc = 'Reinstall Existing UTG';
          break;
      }
    }

    return installationTypeDesc;
  }

  var _default = Ember.Helper.helper(installationType);

  _exports.default = _default;
});
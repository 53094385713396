define("in4m-web/routes/tasks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    permKey: 'in4mMachines',
    permAction: 'canView',
    urlDateFormat: function urlDateFormat(date) {
      var year = date.getFullYear();
      var month = date.getUTCMonth() + 1; // 0-11 why? who knows

      var monthString = month > 9 ? month : '0' + month;
      var day = date.getUTCDate(); // 1-31

      var dayString = day > 9 ? day : '0' + day;
      return year + '-' + monthString + '-' + dayString;
    },
    model: function model(params) {
      $('#progressHudOverlay').show();

      var _this = this;

      var navigation = _this.get('navigation');

      var hasPermission = navigation.checkPermissions(_this.get('session.selectedBusinessEntity'));

      if (hasPermission) {
        // Add a default filter to only return tasks where the runafter is > today - 30 days
        var dateOffset = 1000 * 60 * 60 * 24 * 30; // 30 days ago. milliseconds * seconds * minutes * hours * days

        var targetDate = new Date();
        targetDate.setTime(targetDate.getTime() - dateOffset);
        var filter = {
          Predicates: [{
            PropertyName: "TaskRunAfter",
            Value: targetDate,
            Operator: "GT",
            TypeHint: "DateTime"
          }]
        };

        var beId = _this.get('session.selectedBusinessEntity.id');

        var restQuery = this.get('restQuery');
        restQuery.setTasksFilter(filter);
        return restQuery.queryTasks(beId).then(function (tasks) {
          $('#progressHudOverlay').hide();
          return tasks;
        }).catch(function (err) {
          $('#progressHudOverlay').hide();
          return _this.get('errorhandler').handleError(err, 'Error while making a request.');
        });
      }
    },
    afterModel: function afterModel() {
      $('#progressHudOverlay').hide();
    }
  });

  _exports.default = _default;
});
define("in4m-web/controllers/devices/index", ["exports", "in4m-web/utils/alerts"], function (_exports, _alerts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Controller.extend({
    filter: null,
    filterOptions: null,
    selectedRecord: null,
    selectedDevices: null,
    selectedDate: '',
    selectedTimeZone: '',
    runImmediately: false,
    initializeDatePicker: false,
    formsLoaded: false,
    selectedForm: '',
    fmt: 'MM/DD/YYYY h:mm:ss A',
    canPushForms: false,
    deviceIncompatabilityFound: false,
    drops: 'down',
    customMessages: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('customMessages', {
        "tableSummary": ""
      }), this.set('filterOptions', [{
        name: 'UTG Version',
        field: 'UtgVersion',
        type: 'text',
        supportsOperators: true,
        supportsMultiplePredicates: true,
        TypeHint: "Version",
        operatorOptions: [{
          key: "Is Equal to",
          value: "EQ"
        }, {
          key: "Is Not Equal to",
          value: "NEQ"
        }, {
          key: "Is Less than",
          value: "LT"
        }, {
          key: "Is Less than or Equal to",
          value: "LTE"
        }, {
          key: "Is Greater than",
          value: "GT"
        }, {
          key: "Is Greater than or Equal to",
          value: "GTE"
        }]
      }, {
        name: 'Agent Version',
        field: 'AgentVersion',
        type: 'text',
        supportsOperators: true,
        supportsMultiplePredicates: true,
        TypeHint: "Version",
        operatorOptions: [{
          key: "Is Equal to",
          value: "EQ"
        }, {
          key: "Is Not Equal to",
          value: "NEQ"
        }, {
          key: "Is Less than",
          value: "LT"
        }, {
          key: "Is Less than or Equal to",
          value: "LTE"
        }, {
          key: "Is Greater than",
          value: "GT"
        }, {
          key: "Is Greater than or Equal to",
          value: "GTE"
        }]
      }, {
        name: 'Merchant',
        field: 'ComponentMerchant',
        type: 'text'
      }, {
        name: 'UTG Nickname',
        field: 'ComponentNickname',
        type: 'text'
      }, {
        name: 'Profile',
        field: 'ComponentProfileName',
        type: 'text'
      }, {
        name: 'Profile Version',
        field: 'ComponentProfileVersion',
        type: 'numeric',
        TypeHint: "Integer"
      }, {
        name: 'Location/Serial',
        field: 'ComponentLocation',
        type: 'text'
      }, {
        name: 'Lane',
        field: 'ComponentLane',
        type: 'text'
      }, {
        name: 'File ID',
        field: 'ComponentFileId',
        type: 'text'
      }, {
        name: 'Machine Status',
        field: 'MachineStatus',
        type: 'text'
      }, {
        name: 'UTG Status',
        field: 'UtgStatus',
        type: 'text'
      }, {
        name: 'Firmware Version',
        field: 'DeviceStatusFirmwareVersion',
        supportsOperators: true,
        supportsMultiplePredicates: true,
        type: 'text',
        operatorOptions: [{
          key: "Is Equal to",
          value: "EQ"
        }, {
          key: "Is Not Equal to",
          value: "NEQ"
        }, {
          key: "Is Less than",
          value: "LT"
        }, {
          key: "Is Less than or Equal to",
          value: "LTE"
        }, {
          key: "Is Greater than",
          value: "GT"
        }, {
          key: "Is Greater than or Equal to",
          value: "GTE"
        }]
      }, {
        name: 'PCI Version',
        field: 'DeviceStatusDevicePciVersion',
        supportsOperators: true,
        supportsMultiplePredicates: true,
        type: 'text',
        operatorOptions: [{
          key: "Is Equal to",
          value: "EQ"
        }, {
          key: "Is Not Equal to",
          value: "NEQ"
        }, {
          key: "Is Less than",
          value: "LT"
        }, {
          key: "Is Less than or Equal to",
          value: "LTE"
        }, {
          key: "Is Greater than",
          value: "GT"
        }, {
          key: "Is Greater than or Equal to",
          value: "GTE"
        }]
      }, {
        name: 'Device Type',
        field: 'DeviceConfigDeviceType',
        type: 'text'
      }, {
        name: 'Device Serial',
        field: 'DeviceStatusDeviceSerialNumber',
        type: 'text'
      }, {
        name: 'Terminal ID',
        field: 'DeviceConfigApiTerminalId',
        type: 'text'
      }]);
    },
    isCogHidden: Ember.computed('session.selectedBusinessEntity', function () {
      var hasPermissionToViewCog = true;
      var selectedBusinessEntity = this.get('session.selectedBusinessEntity');
      var user = this.get('session.userObject');
      return !user.hasPermission('canEdit', 'in4mDevices', selectedBusinessEntity);
    }),
    columns: function () {
      return [{
        "template": "custom/select-box",
        "useFilter": false,
        "templateForSortCell": 'custom/select-box-all'
      }, {
        "template": "custom/deviceGear",
        "isHidden": this.isCogHidden
      }, {
        "propertyName": "ComponentMerchant",
        "title": "Merchant"
      }, {
        "propertyName": "ComponentNickname",
        "title": "UTG Nickname",
        "template": "custom/serviceLink"
      }, {
        "propertyName": "DeviceStatusDeviceSerialNumber",
        "title": "Device Serial"
      }, {
        "propertyName": "DeviceConfigDeviceType",
        "title": "Device Configured"
      }, {
        "propertyName": "DeviceStatusDeviceType",
        "title": "Device Reported"
      }, {
        "propertyName": "DeviceConfigApiTerminalId",
        "title": "Terminal ID"
      }, {
        "propertyName": "DeviceStatusDevicePciVersion",
        "title": "PCI Version"
      }, {
        "propertyName": "DeviceStatusFirmwareVersion",
        "title": "Firmware Version"
      }, // Commented out until further work is done to return accurate RBA/UPP versions
      // {
      // 	"propertyName": "DeviceStatusRBAVersion",
      // 	"title": "RBA Version"
      // },
      {
        "template": "custom/deviceKeys",
        "propertyName": "record",
        "title": "Device Keys",
        "disableSorting": true
      }, {
        "propertyName": "MachineDnsName",
        "title": "Machine DNS Name"
      }, {
        "propertyName": "UtgStatus",
        "title": "UTG Status"
      }, {
        "propertyName": "UtgVersion",
        "title": "UTG Version"
      }, {
        "title": "Agent Version",
        "className": "columnMinWidth",
        "propertyName": "AgentVersion"
      }, {
        "propertyName": "ComponentProfileName",
        "title": "Profile"
      }, {
        "propertyName": "ComponentProfileVersion",
        "title": "Profile Version"
      }, {
        "propertyName": "ComponentLocation",
        "title": "Location/Serial"
      }, {
        "propertyName": "ComponentLane",
        "title": "Lane"
      }, {
        "propertyName": "ComponentFileId",
        "title": "File ID"
      }, {
        "propertyName": "MachineStatus",
        "title": "Machine Status"
      }, {
        "template": "custom/dateFormatter",
        "propertyName": "MachineStatusEntityLastKnownCommunication",
        "title": "Last Known Communication",
        "sortedBy": "MachineStatusEntityLastKnownCommunication"
      }, {
        "template": "custom/dateFormatter",
        "propertyName": "MachineStatusEntityNextPredictedCommunication",
        "title": "Next Predicted Communication",
        "sortedBy": "MachineStatusEntityNextPredictedCommunication"
      }, {
        "propertyName": "MachineNickname",
        "title": "Machine Nickname"
      }, {
        "title": "Details",
        "template": "custom/taskResultDetails"
      }];
    }.property('isCogHidden'),
    getForms: function () {
      var _this = this;

      _this.set('formsLoaded', false);

      var selectedBusinessEntity = this.get('session.selectedBusinessEntity.id');
      return _this.store.query('componentDeviceForm', {
        parentBusinessEntityId: selectedBusinessEntity
      }).then(function (forms) {
        _this.set('forms', forms);

        _this.set('formsLoaded', true);

        if (forms.get('length') > 0) {
          if (forms.get('length') > 5) {
            _this.set('drops', 'up');
          } else {
            _this.set('drops', 'down');
          }

          _this.set('canPushForms', true);
        } else {
          _this.set('canPushForms', false);
        }
      }, function (error) {
        _this.set('formsLoaded', false);
      });
    }.observes('model'),
    actions: {
      //-----------------------------------------------------------------
      // Filter Modal
      //-----------------------------------------------------------------
      openFilterModal: function openFilterModal() {
        $('#searchFilterModal').modal('show');
      },
      cancelFilter: function cancelFilter() {
        $('#searchFilterModal').modal('hide');
        this.set('filter', {
          "Predicates": []
        });
      },
      applyFilter: function applyFilter(data) {
        var _this = this;

        console.log('applyFilter');
        console.log(JSON.stringify(data, null, 2));
        $('#searchFilterModal').modal('hide');
        var beId = this.get('session.selectedBusinessEntity.id');
        var restQuery = this.get('restQuery');
        restQuery.setDevicesFilter(data); // call the rest-query service with our query parameters to get the filtered data from the rest api

        $('#progressHudOverlay').show();
        restQuery.queryDevices(beId).then(function (devices) {
          $('#progressHudOverlay').hide();
        }).catch(function (err) {
          $('#progressHudOverlay').hide();
          return _this.get('errorhandler').handleError(err, 'Error while making a request.');
        });
      },
      //-----------------------------------------------------------------
      // Export
      //-----------------------------------------------------------------
      exportDevices: function exportDevices() {
        var r = confirm("Export may take a few minutes to download ALL devices on this tab. Are you sure you want to continue?");

        if (r == true) {
          var _this = this;

          _alerts.default._info('Download of devices.csv should begin shortly.'); // build url


          var beId = this.get('session.selectedBusinessEntity.id');

          var baseUrl = _this.get('apiPath.in4mApiUrl') + '/' + _this.get('apiPath.in4mApiPath');

          var downloadUrl = baseUrl + "/businessentities/".concat(beId, "/devices/query/csv");
          var authHeaderValue = 'Token token="' + this.get('session.sessionObject.accessToken') + '"'; //trigger Ajax request to load binary data

          var oReq = new XMLHttpRequest();
          oReq.open("POST", downloadUrl, true);
          oReq.setRequestHeader('Authorization', authHeaderValue); //When using setRequestHeader(), you must call it after calling open(), but before calling send(). If this method is called several times with the same header, the values are merged into one single request header. (See: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/setRequestHeader)

          oReq.responseType = "arraybuffer";
          oReq.setRequestHeader('Content-Type', 'application/json');
          oReq.setRequestHeader('Accept', 'application/json');
          /* For more information on "Receiving binary data using JavaScript typed arrays",
             See: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/Sending_and_Receiving_Binary_Data
          */

          oReq.onload = function (oEvent) {
            var arrayBuffer = oReq.response; // Note: not oReq.responseText

            if (arrayBuffer) {
              var byteArray = new Uint8Array(arrayBuffer);
              /* Package data as Blob for download. See:
              	- https://stackoverflow.com/questions/3665115/how-to-create-a-file-in-memory-for-user-to-download-but-not-through-server
              	- https://developer.mozilla.org/en-US/docs/Web/API/Blob)
              */

              var blob = new Blob([byteArray], {
                type: "application/octet-stream"
              }); //trigger save prompt

              saveAs(blob, "devices.csv");
            }
          };

          var restQuery = this.get('restQuery'); // double stringify is required to ensure the data is encoded the way the api expects

          var encodedData = JSON.stringify(restQuery.devicesFilter).replace("\n", "");
          oReq.send(JSON.stringify(encodedData));
        }
      },
      filterApplied: function filterApplied(filteredData) {
        this.set('model', filteredData);
      },
      displayDataChanged: function displayDataChanged(settings) {
        this.set('selectedDevices', settings.selectedItems);

        var distinctDeviceTypes = _toConsumableArray(new Set(this.get('selectedDevices').map(function (device) {
          return device.DeviceStatusDeviceType;
        })));

        if (distinctDeviceTypes.length > 1) {
          this.set('deviceIncompatabilityFound', true);
        } else {
          this.set('deviceIncompatabilityFound', false);
        }
      },
      applyColumnHide: function applyColumnHide(processedColumns) {
        var hiddenColumnCount = 0;
        processedColumns.forEach(function (column, index, array) {
          var visible = column.get('mayBeHidden');
          column.set('isHidden', !visible);

          if (!visible) {
            hiddenColumnCount = hiddenColumnCount + 1;
          }

          if (index === array.length - 1) {
            //sending column visibility action only after looping through all objects
            var firstColumn = processedColumns[0]; //

            var secondColumn = processedColumns[1];

            if (hiddenColumnCount === array.length - 2) {
              //to make sure first (checkbox) and second (gear icon) columns are hidden when the rest of the columns are hidden
              firstColumn.set('isHidden', true);
              secondColumn.set('isHidden', true);
            } else {
              firstColumn.set('isHidden', false);
              secondColumn.set('isHidden', false);
            }
          }
        });
      },
      closeModal: function closeModal() {
        $('#pushFormsModal').modal('hide');
        this.set('deviceIncompatabilityFound', false);
        this.set('selectedForm', '');
        this.set('selectedDate', '');
        this.set('selectedTimeZone', '');
        this.set('runImmediately', false);
        this.set('instructions', '');
        this.set('initializeDatePicker', false);
        $("#pushForms").data('validator').resetForm(); //resetting the form to remove error classes if any on modal close
      },
      showDeviceKeysModal: function showDeviceKeysModal(record) {
        this.set('selectedRecord', record);
        $('#deviceKeysModal').modal('show');
      },
      selectedDate: function selectedDate(date) {
        var formattedDate = date.format(this.fmt);
        this.set('selectedDate', formattedDate);
      },
      updateStatusInstructions: function updateStatusInstructions(value) {
        this.set('selectedStatus', value.selectedStatus);
        this.set('instructions', value.instructions);
      },
      updateDeviceFirmwareForm: function updateDeviceFirmwareForm(record, taskType) {
        if (this.selectedDevices !== null && this.selectedDevices.length === 0) {
          var tempArray = [];
          tempArray.pushObject(record);
          this.set('selectedDevices', tempArray);
        }

        var deviceIncompatabilityFound = this.get('selectedDevices').some(function (device) {
          var softwareVersion = device.UtgVersion;
          softwareVersion = parseInt(softwareVersion.split(".")[3]);

          if (softwareVersion < 3018) {
            return true;
          }
        });
        var devicePciVersions = this.selectedDevices.map(function (record) {
          return record.DeviceStatusDevicePciVersion;
        });
        var uniquePciVersions = devicePciVersions.filter(function (item, i, ar) {
          return ar.indexOf(item) === i;
        });
        var pciVersionIncompatible = false;

        if (uniquePciVersions.length > 1) {
          pciVersionIncompatible = true;
        }

        if (this.get('deviceIncompatabilityFound') || deviceIncompatabilityFound || pciVersionIncompatible) {
          $('#incompatibleDevicesModal').modal('show');
        } else {
          this.set('taskType', taskType);
          this.set('initializeDatePicker', true);
          this.set('devicePciVersion', record.DeviceStatusDevicePciVersion);
          $('#device').val('');
          $('#model-number-pattern').val('');
          $('#pci-version').val('');
          $('#pushFormsModal').modal('show');
        }
      },
      pushFormToDevice: function pushFormToDevice() {
        if ($('form').valid()) {
          var createRecord = function createRecord(devices) {
            if (devices.length > 0) {
              devices.forEach(function (selectedDevice) {
                var pciVersion = selectedDevice.DeviceStatusDevicePciVersion;

                if (pciVersion == "0") {
                  pciVersion = $("#pci-version").val();
                }

                var task = _this.store.createRecord('task', {
                  type: _this.get('taskType'),
                  componentId: selectedDevice.ComponentId,
                  businessEntityId: selectedDevice.BusinessEntityId,
                  machinePermanentId: selectedDevice.MachinePermanentId,
                  runAfter: runAfter,
                  //TODO: remove the hardcoded RBA in future
                  instructions: "{\"deviceType\": \"".concat(selectedDevice.DeviceStatusDeviceType, "RBA\",\"apiTid\":\"").concat(selectedDevice.DeviceConfigApiTerminalId, "\",\"formBusinessEntityId\":\"").concat(selectedForm.get('parentBusinessEntityId'), "\",\"formId\":\"").concat(selectedForm.get('id').toString().split("-")[1], "\",\"pciVersion\":\"").concat(pciVersion, "\",\"terminalName\":\"").concat(selectedDevice.DeviceStatusDeviceType, "\"}")
                });

                task.save().then(function (task) {
                  noOfUpdatesSucceeded = noOfUpdatesSucceeded + 1;
                  showAlert(noOfUpdatesSucceeded, noOfUpdatesFailed, devices);
                }, function () {
                  noOfUpdatesFailed = noOfUpdatesFailed + 1;
                  showAlert(noOfUpdatesSucceeded, noOfUpdatesFailed, devices);
                });
              });
            }
          };

          var showAlert = function showAlert(noOfUpdatesSucceeded, noOfUpdatesFailed, devices) {
            //passing filtered components instead of selected components
            if (noOfUpdatesSucceeded + noOfUpdatesFailed === devices.length) {
              //checking components length instead of all selected components length because schedule tasks can be done for only components that have hasConfigChanges or all selected components
              if (noOfUpdatesSucceeded > 0) {
                _alerts.default._success('Task scheduled for ' + noOfUpdatesSucceeded + ' Device(s).');
              }

              if (noOfUpdatesFailed > 0) {
                _alerts.default._error('Failed to schedule task for ' + noOfUpdatesFailed + ' Device(s).');
              }

              $('#progressHudOverlay').hide();

              _this.send('closeModal');
            }
          };

          var _this = this;

          var noOfUpdatesSucceeded = 0;
          var noOfUpdatesFailed = 0;
          var runAfter;

          if (!_this.get('runImmediately')) {
            //set runAfter only if run immediately not checked
            runAfter = moment(_this.get('selectedDate')).tz(_this.get('selectedTimeZone')).utc().format(_this.get('fmt'));
          } else {
            runAfter = moment().toISOString(); //setting runAfter to current date and time to avoid sorting on null record
          }

          var selectedForm = this.get('selectedForm');
          var selectedDevices = this.get('selectedDevices');

          if (selectedForm) {
            $('#progressHudOverlay').show();
            createRecord(_this.get('selectedDevices'));
          } else {
            _alerts.default._warn('Please select a form to push.');
          }
        }
      },
      copyRecord: function copyRecord(record) {
        // place the data on the users clipboard if the browser supports it
        if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
          navigator.clipboard.writeText(JSON.stringify(record, null, 2));

          _alerts.default._success('The record has been copied to your clipboard.', 'Record Copied');
        } else {
          _alerts.default._error('This browser does not support copying data to the clipboard.', 'Copy Failed');
        }
      }
    }
  });

  _exports.default = _default;
});
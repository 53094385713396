define("in4m-web/templates/components/models-table/numeric-pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M+2rGf1X",
    "block": "{\"symbols\":[\"page\"],\"statements\":[[7,\"div\"],[11,\"class\",\"btn-toolbar pull-right\"],[11,\"role\",\"toolbar\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"btn-group\"],[11,\"role\",\"group\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"visiblePageNumbers\"]]],null,{\"statements\":[[4,\"if\",[[24,1,[\"isLink\"]]],null,{\"statements\":[[0,\"        \"],[7,\"button\"],[12,\"class\",[30,[[29,\"if\",[[24,1,[\"isActive\"]],\"active\"],null],\" \",[25,[\"classes\",\"buttonDefault\"]]]]],[11,\"type\",\"button\"],[9],[1,[24,1,[\"label\"]],false],[3,\"action\",[[24,0,[]],\"gotoCustomPage\",[24,1,[\"label\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"button\"],[11,\"disabled\",\"disabled\"],[12,\"class\",[30,[[25,[\"classes\",\"buttonDefault\"]]]]],[11,\"type\",\"button\"],[9],[1,[24,1,[\"label\"]],false],[3,\"action\",[[24,0,[]],\"gotoCustomPage\",[24,1,[\"label\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/components/models-table/numeric-pagination.hbs"
    }
  });

  _exports.default = _default;
});
define("in4m-web/models/installation", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    parentBusinessEntity: belongsTo('businessEntity'),
    type: attr('number'),
    isExistingInstallation: function () {
      return this.type == 30;
    }.property('type'),
    isNewInstallation: function () {
      return this.type == 10;
    }.property('type'),
    isReinstallation: function () {
      return this.type == 40;
    }.property('type'),
    permanentId: attr(),
    manifest: belongsTo('installationManifest', {
      inverse: 'install',
      async: false
    }),
    lastUpdatedAt: attr(),
    lastUpdatedBy: attr(),
    internalManifestRepresentation: attr(),
    verificationId: attr()
  });

  _exports.default = _default;
});
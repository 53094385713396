define("in4m-web/helpers/application-check-permission", ["exports", "in4m-web/utils/local-to-apipermission"], function (_exports, _localToApipermission) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    session: Ember.inject.service('session'),
    compute: function compute(params, hash) {
      var applicationGroupId = params[0];
      var permissionMasks = this.get('session.userObject.permissionMasks');

      var _this = this;

      if (permissionMasks) {
        switch (applicationGroupId) {
          case 1:
            // 4tress - All in4m users have access to 4tress app
            _this.set('hasAccesstoApp', true);

            break;

          case 2:
            //VT4 - hasAccessToVT4Shelf or hasAccessToVT4Campaigns or hasAccessToReports
            _this.set('hasAccesstoApp', false);

            _this.set('vt4Permissions', ['vt4Sales', 'vt4Refunds']);

            permissionMasks.forEach(function (permission) {
              if (!_this.get('hasAccesstoApp')) {
                hasPermission(_this.get('vt4Permissions'), permission);
              }
            });
            break;

          case 3:
            // In4m
            _this.set('hasAccesstoApp', false);

            _this.set('in4mPermissions', ['in4mUtgServices', 'in4mUtgConfig', 'in4mDeviceConfig', 'in4mDevices']);

            permissionMasks.forEach(function (permission) {
              if (!_this.get('hasAccesstoApp')) {
                hasPermission(_this.get('in4mPermissions'), permission);
              }
            });
            break;

          default:
            _this.set('hasAccesstoApp', false);

            break;
        }

        return this.hasAccesstoApp;
      }

      function hasPermission(appPermissions, permission) {
        appPermissions.forEach(function (eachPermission) {
          if (!_this.get('hasAccesstoApp')) {
            if (permission.get('permission.key') == (0, _localToApipermission.default)(eachPermission)) {
              if (permission.get('canView') === true || permission.get('canEdit') === true || permission.get('isEnabled') === true) {
                _this.set('hasAccesstoApp', true);
              }
            }
          }
        });
      }
    }
  });

  _exports.default = _default;
});
define("in4m-web/models/component-profile/config/device-config", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    config: belongsTo('componentProfile/config', {
      async: false
    }),
    devices: hasMany('componentDevice', {
      async: false
    })
  });

  _exports.default = _default;
});
define("in4m-web/templates/custom/schedule-task-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u+ZvtLcK",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"date-formatter-with-zone\",[[25,[\"record\",\"runAfter\"]]],null],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/custom/schedule-task-date.hbs"
    }
  });

  _exports.default = _default;
});
define("in4m-web/components/form-validator", ["exports", "in4m-web/utils/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    didInsertElement: function didInsertElement() {
      // validators() lives in utilities. It appears to modify the default jquery validator object so that the behavior attached belows those rules.
      // to change validator logic, look in /utilities/validators.js
      (0, _validators.default)(); // TODO: this should probably live somewhere else. It is not related to validation since it is just blocking default copy paste behavior if it finds an input with the
      // specified class

      $('input.disablecopypaste').bind('cut copy paste', function (e) {
        e.preventDefault();
      }); // TODO: we should try to find a native ember component for validation rather than using jquery for all of this.
      // This component adds a jquery validate object to each item inside of a form and attaches a keyup handler

      $('form').each(function () {
        $('#' + this.id).validate({
          focusInvalid: false,
          onkeyup: function onkeyup(element, event) {
            if (event.which === 9 && this.elementValue(element) === '') {
              return;
            }

            if (element.name in this.submitted || element == this.lastElement) {
              this.element(element);
            }
          }
        });
      });
    }
  });

  _exports.default = _default;
});
define("in4m-web/controllers/component/manage/api-interface", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apiInterfaces: Ember.computed('modelGenerator.tuneUp', function () {
      var in4mTuneUp = this.get('modelGenerator.tuneUp');
      var apiInterfaces = in4mTuneUp.filterBy('s4-tag', 'apiInterface'); //filtering based on the s4-tag

      return apiInterfaces;
    })
  });

  _exports.default = _default;
});
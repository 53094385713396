define("in4m-web/adapters/task", ["exports", "ember-data", "ember-inflector", "in4m-web/adapters/application"], function (_exports, _emberData, _emberInflector, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var emberData = _emberData.default;

  var _default = _application.default.extend({
    host: Ember.computed('apiPath', function () {
      return this.get('apiPath.in4mApiUrl');
    }),
    namespace: Ember.computed('apiPath', function () {
      return this.get('apiPath.in4mApiPath');
    }),
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var url;

      if (requestType === 'deleteRecord') {
        url = this.host + '/' + this.namespace + '/businessEntities/' + snapshot.record.get('component.parentBusinessEntity.id') + '/machines/' + snapshot.record.get('component.machine.permanentId') + '/' + this.pathForType(modelName) + '/' + snapshot.record.get('id');
      } else if (requestType === 'createRecord') {
        url = this.host + '/' + this.namespace + '/businessEntities/' + snapshot.record.get('businessEntityId') + '/machines/' + snapshot.record.get('machinePermanentId') + '/' + this.pathForType(modelName);
      } else {
        // query which should get all tasks for the specified business entity
        url = this.host + '/' + this.namespace + '/businessEntities/' + query.businessEntityId + '/tasks';
      }

      return url;
    },
    pathForType: function pathForType(modelName) {
      var camelized = Ember.String.camelize(modelName);
      var pluralized = (0, _emberInflector.pluralize)(camelized);
      return pluralized;
    }
  });

  _exports.default = _default;
});
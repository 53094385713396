define("in4m-web/controllers/component-profiles/component-profile/offline-mode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    componentProfile: Ember.inject.controller('componentProfiles.componentProfile')
  });

  _exports.default = _default;
});
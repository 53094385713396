define("in4m-web/templates/components/models-table/header-rows-grouped", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YW6W/5bX",
    "block": "{\"symbols\":[\"row\",\"cell\"],\"statements\":[[4,\"each\",[[25,[\"groupedHeaders\"]]],null,{\"statements\":[[0,\"  \"],[7,\"tr\"],[9],[0,\"\\n\"],[4,\"each\",[[24,1,[]]],null,{\"statements\":[[0,\"      \"],[7,\"th\"],[12,\"colspan\",[30,[[24,2,[\"colspan\"]]]]],[12,\"rowspan\",[30,[[24,2,[\"rowspan\"]]]]],[9],[1,[24,2,[\"title\"]],false],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/components/models-table/header-rows-grouped.hbs"
    }
  });

  _exports.default = _default;
});
define("in4m-web/helpers/date-formatter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateFormatter = dateFormatter;
  _exports.default = void 0;

  function dateFormatter(params
  /*, hash*/
  ) {
    var dateString = params[0];

    if (dateString) {
      var result = new Date(dateString).toLocaleString();
      result = result.replace(',', '');
      return result;
    }
  }

  var _default = Ember.Helper.helper(dateFormatter);

  _exports.default = _default;
});
define("in4m-web/overrides/text-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.reopen({
    attributeBindings: ['required', 'min', 'max', 'maxLength', 'equalTo', 'data-msg', 'data-automatron', 'data-placement', 'data-toggle', 'data-original-title', 'maxLength', 'minLength']
  });

  _exports.default = _default;
});
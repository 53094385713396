define("in4m-web/controllers/component-profiles/component-profile/devices/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    componentProfile: Ember.inject.controller('componentProfiles.componentProfile'),
    selectedDevice: null,
    isEditDisabled: Ember.computed('model.firstObject', function () {
      var hasPermissionToEditProfileOnSelectedBE = this.get('componentProfile.canEditSelectedProfile');
      return !hasPermissionToEditProfileOnSelectedBE;
    }),
    customClasses: {
      'pageSizeWrapper': 'col-xs-12 col-sm-8 col-md-6'
    },
    columns: function () {
      return [{
        "propertyName": "taskDesc",
        "title": "Description"
      }, {
        "propertyName": "deviceType",
        "title": "Device Type"
      }, {
        "template": "custom/delete-device",
        "useFilter": false,
        "templateForSortCell": 'custom/add-record',
        "isHidden": this.isEditDisabled
      }];
    }.property('isEditDisabled'),
    actions: {
      displayDataChangedAction: function displayDataChangedAction(data) {
        if (data.get('selectedItems').length > 0) {
          this.transitionToRoute('componentProfiles.componentProfile.devices.device', data.get('selectedItems').get('firstObject').id);
        }
      },
      addRecord: function addRecord(record) {
        this.transitionToRoute('componentProfiles.componentProfile.devices.new');
      },
      showDeleteModal: function showDeleteModal(record) {
        this.set('selectedDevice', record);
        $('#deleteDeviceModal').modal('show');
      },
      dismissDeleteModal: function dismissDeleteModal(record) {
        $('#deleteDeviceModal').modal('hide');
      },
      deleteRecord: function deleteRecord() {
        var record = this.selectedDevice;

        var _this = this;

        if (record) {
          $('#progressHudMessage').html('Updating...');
          $('#progressHudOverlay').show();
          record.deleteRecord();

          _this.send('saveProfile');
        }

        $('#deleteDeviceModal').modal('hide');
      }
    }
  });

  _exports.default = _default;
});
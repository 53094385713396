define("in4m-web/components/numeric-only", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend({
    decimalPlaces: 0,
    // default
    attributeBindings: ['minlength', 'equalTo', 'data-msg', 'data-automatron', 'data-placement', 'data-toggle', 'data-original-title', 'data-greaterThan', 'data-lessThan', 'maxLength'],
    didInsertElement: function didInsertElement() {
      // initial format of decimalPlaces fields
      if (this.decimalPlaces) {
        Ember.run.schedule('afterRender', this, function () {
          if (this.value) {
            this.set('value', new Big(this.value).toFixed(this.decimalPlaces));
          } else {
            if (this.min) {
              this.set('value', new Big(this.min).toFixed(this.decimalPlaces));
            } else {
              this.set('value', new Big(0).toFixed(this.decimalPlaces));
            }
          }
        });
      }
    },
    keyPress: function keyPress(event) {
      if (this.decimalPlaces) {
        // Firefox: 0 is needed for arrow keys and shift to highlight. 8 is for backspace.
        if (event.which === 46 || event.which === 110 || event.which === 190 || event.which === 0 || event.which === 8 || event.which >= 48 && event.which <= 57) {
          return;
        } else {
          event.preventDefault();
        }
      } else {
        // Firefox: 0 is needed for arrow keys and shift to highlight. 8 is for backspace.
        if (event.which === 0 || event.which === 8 || event.which >= 48 && event.which <= 57) {
          return;
        } else {
          event.preventDefault();
        }
      }
    },
    input: function input(event) {
      //fix for entering number by highlighting the input field when reaches the maxlimit
      // if maxLength is not defined it defaults to -1
      if (event.target.maxLength !== -1) {
        if (event.target.value.length <= event.target.maxLength) {
          return true;
        } else {
          var str = event.target.value;
          str = str.substring(0, str.length - 1);
          event.target.value = str;
        }
      }
    },
    focusOut: function focusOut(event) {
      // fix issue with focusing out of a blank field causing errors
      // check how many numeric places we need to enforce
      if (!isNaN(parseInt(event.target.value))) {
        if (this.decimalPlaces) {
          this.set('value', new Big(event.target.value).toFixed(this.decimalPlaces));
        } else {
          this.set('value', parseInt(event.target.value));
        }
      } else {
        this.set('value', null);
      }
    }
  });

  _exports.default = _default;
});
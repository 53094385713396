define("in4m-web/services/errorhandler", ["exports", "in4m-web/utils/alerts"], function (_exports, _alerts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    logout: Ember.inject.service(),
    didFail: false,
    handleError: function handleError(jqXHR, message, isLogout) {
      this.set('didFail', true);
      $('#progressHudOverlay').hide();

      if (jqXHR.errors) {
        //adapter errors
        if (jqXHR.errors[0].status == 401) {
          return Ember.run.next(function () {
            $('#sessionExpiredModal').modal('show');
          });
        } else if (jqXHR.errors[0].status == 403) {
          if (isLogout) {
            //a special case where user need to logout for 403 error
            return Ember.run.next(function () {
              $('#noPermissionModal').modal('show');
            });
          } else {
            _alerts.default._error('You do not have permission to access this area.');
          }
        } else if (jqXHR.errors[0].status == 500) {
          _alerts.default._error('There was a problem trying to access the server. Please try again.');
        } else {
          _alerts.default._error(message);
        }
      } else {
        if (jqXHR.status == 401) {
          return Ember.run.next(function () {
            $('#sessionExpiredModal').modal('show');
          });
        } else if (jqXHR.status == 403) {
          if (isLogout) {
            //a special case where user need to logout for 403 error
            return Ember.run.next(function () {
              $('#noPermissionModal').modal('show');
            });
          } else {
            _alerts.default._error('You do not have permission to access this area.');
          }
        } else if (jqXHR.status == 500) {
          _alerts.default._error('There was a problem trying to access the server. Please try again.');
        } else {
          _alerts.default._error(message);
        }
      }
    }
  });

  _exports.default = _default;
});
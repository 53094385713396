define("in4m-web/components/form-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // classNames:['form-group'], This should be defined as needed in the template to prevent overuse
    property: '',
    name: '',
    didInsertElement: function didInsertElement() {
      $('[data-toggle="tooltip"]').tooltip();
    },
    isHidden: function () {
      var _this = this;

      return Ember.Object.extend({
        value: Ember.computed("model.".concat(this.get("property.s4-dependent.parentProperty")), function () {
          //observing model with keys from json schema
          if (_this.get('property.s4-dependent.parentProperty')) {
            var modelValue = this.model.get(_this.get('property.s4-dependent.parentProperty'));

            var schemaValue = _this.get('property.s4-dependent.hiddenWhen');

            if (schemaValue) {
              //added this condition to check when there is schema values for properties that does not come under this category
              if (modelValue == schemaValue) {
                //condition for checkboxes sincce there is only value from json schema true or false
                return true;
              } else if (Array.isArray(schemaValue)) {
                //condition for radio buttons since there are an array of values from json schema
                if (schemaValue.indexOf(modelValue) != -1) {
                  return true;
                }
              }
            }

            return false;
          }

          return false;
        })
      }).create({
        model: this.model
      });
    }.property("model"),
    isControlsHidden: function () {
      var _this = this;

      return Ember.Object.extend({
        value: Ember.computed("model.".concat(this.get("property.s4-dependent-control.parentProperty")), function () {
          //observing model with keys from json schema
          if (_this.get('property.s4-dependent-control.parentProperty')) {
            var modelValue = this.model.get(_this.get('property.s4-dependent-control.parentProperty'));

            var schemaValue = _this.get('property.s4-dependent-control.hiddenWhen');

            var tempObject = {};

            if (modelValue == schemaValue) {
              //condition for checkboxes sincce there is only value from json schema true or false	
              _this.get('property.s4-dependent-control.controlsHidden').forEach(function (control, index) {
                tempObject[control] = true;
              });

              return tempObject;
            } else if (Array.isArray(schemaValue)) {
              //condition for radio buttons since there are an array of values from json schema
              if (schemaValue.indexOf(modelValue) != -1) {
                _this.get('property.s4-dependent-control.controlsHidden').forEach(function (control, index) {
                  tempObject[control] = true;
                });
              } else if (schemaValue.indexOf(modelValue.toString()) != -1) {
                //this check is for initial load if the model value is integer and dropdown value is string
                _this.get('property.s4-dependent-control.controlsHidden').forEach(function (control, index) {
                  tempObject[control] = true;
                });
              }

              return tempObject;
            }

            return false;
          }

          return false;
        })
      }).create({
        model: this.model
      });
    }.property("model"),
    isdisabled: function () {
      if (this.get('property.s4-computed.s4-dependent')) {
        //check if there's a dependency in the computed property
        var funcFromSchema = this.get('property.s4-computed').funcDefinition.body;
        var certificateFile = this.model.get(this.get('property.s4-computed.s4-dependent'));
        var myFunc = new Function("x", funcFromSchema);
        var useUTGGenereatedCert = myFunc(certificateFile); //function returns false if there's a value for the passed in property and returns true if there's none

        this.model.set(this.camelizedName, useUTGGenereatedCert); //changing the model property's value
      }

      var _this = this;

      return Ember.Object.extend({
        value: Ember.computed("model.".concat(this.get("property.s4-dependent.parentProperty")), function () {
          //observing model with keys from json schema
          if (_this.get('property.s4-dependent.parentProperty')) {
            var modelValue = this.model.get(_this.get('property.s4-dependent.parentProperty'));

            var schemaValue = _this.get('property.s4-dependent.readOnlyWhen'); // check if we need to reset the value when it gets disabled 


            if (_this.get('property.s4-dependent.reset')) {
              //reset the attributes only if they have reset enabled in json schema
              if (modelValue == schemaValue) {
                this.model.set(_this.get('camelizedName'), '');
              } else {
                if (Object.keys(this.model.changedAttributes()).length > 0) {
                  //check to see if any attributes are changed
                  var changedAttributes = this.model.changedAttributes()[_this.get('camelizedName')]; //get the current property changedAttributes


                  if (changedAttributes) {
                    this.model.set(_this.get('camelizedName'), changedAttributes[0]); //reset to the original value
                  }
                }
              }
            } // set default values for the given state


            if (_this.get('property.s4-dependent.defaultValue')) {
              // check if schema value is a list of values
              if (Array.isArray(schemaValue)) {
                // check if modelValue is in the list, if it's not set the defaultvalue
                if (schemaValue.indexOf(modelValue) != -1) {
                  if (typeof _this.get('property.s4-dependent.defaultValue')[modelValue] != "undefined") {
                    this.model.set(_this.get('camelizedName'), _this.get('property.s4-dependent.defaultValue')[modelValue]);
                  }
                }
              } else {
                // non list scenarios
                if (typeof _this.get('property.s4-dependent.defaultValue')[modelValue] != "undefined") {
                  this.model.set(_this.get('camelizedName'), _this.get('property.s4-dependent.defaultValue')[modelValue]);
                }
              }
            } // TODO: Clean this up, do not rely on the isDisabled computed property, setup a observer
            // resolve isDisabled


            if (modelValue == schemaValue) {
              //condition for checkboxes sincce there is only value from json schema true or false
              return true;
            } else if (Array.isArray(schemaValue)) {
              //condition for radio buttons since there are an array of values from json schema
              if (schemaValue.indexOf(modelValue) != -1) {
                return true;
              }
            }

            return false;
          }

          if (_this.get('property.s4-disabled')) {
            return true;
          }

          return false;
        })
      }).create({
        model: this.model
      });
    }.property("model"),
    computedPort: function () {
      var _this = this;

      return Ember.Object.extend({
        value: Ember.computed("model.".concat(this.camelizedName), function () {
          //observing model with keys from json schema
          if (_this.get('property.s4-computed')) {
            var funcFromSchema = _this.get('property.s4-computed').funcDefinition.body;

            var portNumber = this.model.get(_this.get('camelizedName'));
            var myFunc = new Function("x", funcFromSchema);
            var hexValue = myFunc(portNumber);

            if (hexValue != 'NaN') {
              return hexValue;
            } else {
              return '';
            }
          }

          return '';
        })
      }).create({
        model: this.model
      });
    }.property("model"),
    disabled: Ember.computed.alias("isdisabled.value"),
    computedProperty: Ember.computed.alias("computedPort.value"),
    hidden: Ember.computed.alias("isHidden.value"),
    controlsHidden: Ember.computed.alias("isControlsHidden.value"),
    labelText: Ember.computed('property', function () {
      var caption = this.property.hasOwnProperty('s4-caption');

      if (caption) {
        return this.get('property.s4-caption');
      }
    }),
    camelizedName: Ember.computed('name', function () {
      return this.name.camelize();
    }),
    actions: {
      generateCertificate: function generateCertificate() {
        this.sendAction('generateCertificate');
      },
      loadCertificate: function loadCertificate() {
        this.sendAction('loadCertificate');
      }
    }
  });

  _exports.default = _default;
});
define("in4m-web/adapters/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTAdapter.extend({
    host: Ember.computed('apiPath', function () {
      return this.get('apiPath.coreApiUrl');
    }),
    namespace: Ember.computed('apiPath', function () {
      return this.get('apiPath.coreSecurityApiPath');
    }),
    headers: Ember.computed('session.sessionObject', function () {
      if (this.get('session.sessionObject.accessToken')) {
        return {
          'clientId': 4,
          'Authorization': 'Token token="' + this.get('session.sessionObject.accessToken') + '"',
          'shift4-rest-request-envelope-version': 1,
          'shift4-rest-response-envelope-version': 1
        };
      } else {
        return {
          'clientId': 4,
          'Authorization': 'Token token="' + localStorage.getItem('accessToken') + '"',
          'shift4-rest-request-envelope-version': 1,
          'shift4-rest-response-envelope-version': 1
        };
      }
    }),
    ajaxOptions: function ajaxOptions(url, type, options) {
      //overriding put requests to post
      var hash = options || {};
      hash.url = url;
      hash.type = type;
      hash.dataType = 'json';
      hash.context = this;

      if (hash.data && type !== 'GET') {
        hash.contentType = 'application/json; charset=utf-8';
        hash.data = JSON.stringify(hash.data);
      }

      var headers = this.headers;

      if (headers !== undefined) {
        hash.beforeSend = function (xhr) {
          if (type === 'PUT' || type === 'DELETE' || type === 'PATCH') {
            //adding http override header for put, delete, patch request types
            xhr.setRequestHeader('X-HTTP-Method-Override', type);
          }

          Object.keys(headers).forEach(function (key) {
            return xhr.setRequestHeader(key, headers[key]);
          });
        };
      }

      if (type === 'PUT' || type === 'DELETE' || type === 'PATCH') {
        //changing the type to post for put, delete, patch request types
        hash.type = 'POST';
      }

      return hash;
    },
    isSuccess: function isSuccess(status, headers, payload) {
      if (this.get('headers.shift4-auth-extend') !== false) {
        //checking for exact false condition instead of checking for property present
        var _this = this;

        if (_this.get('session.sessionTimeoutInterval')) {
          clearInterval(_this.get('session.sessionTimeoutInterval'));
          _this.get('session').sessionTimeoutInterval = setInterval(function () {
            if (_this.get('session.sessionObject')) {
              if (Big(Big((_this.get('session.sessionObject.expires').getTime() - Date.now()) / 1000).toFixed(0)) <= 300) {
                $('#inactivityModal').on('show.bs.modal', function () {
                  clearInterval(_this.get('session.sessionTimeoutInterval'));
                });
                $('#inactivityModal').modal('show');
              }
            }
          }, 1000);
          Ember.run.later(function () {
            _this.set('session.sessionObject.expires', new Date(Date.now() + 3600000), false);
          });
        }
      }

      return status >= 200 && status < 300 || status === 304;
    }
  });

  _exports.default = _default;
});
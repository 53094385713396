define("in4m-web/controllers/users/index", ["exports", "in4m-web/utils/alerts"], function (_exports, _alerts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    canEditUsers: Ember.computed('model', function () {
      var selectedBusinessEntity = this.get('session.selectedBusinessEntity');
      var user = this.get('session.userObject');
      var hasPermissionToEditUsers = user.hasPermission('canEdit', 'users', selectedBusinessEntity);
      return hasPermissionToEditUsers;
    }),
    init: function init() {
      this._super.apply(this, arguments);
    },
    actions: {},
    //define data-table columns and map model to columns
    columns: Ember.computed('isCogHidden', function () {
      return [{
        "propertyName": "Username",
        "title": "User Name",
        "routeName": "",
        "routeProperty": "Id"
      }, {
        "propertyName": "FirstName",
        "title": "First Name",
        "routeName": "",
        "routeProperty": "Id"
      }, {
        'title': 'Last Name',
        'propertyName': 'LastName'
      }, {
        'title': 'Email',
        'propertyName': 'Email'
      }, {
        'title': 'Active',
        'propertyName': 'IsActive'
      }];
    })
  });

  _exports.default = _default;
});
define("in4m-web/templates/components/s4-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yhHNku0/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\"],[11,\"class\",\"col-xs-4 col-sm-4 col-md-4 control-label\"],[11,\"for\",\"verifyPassword\"],[9],[1,[25,[\"property\",\"s4-validations\",\"verifyPasswordMessage\"]],false],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"col-xs-8 col-sm-6 col-md-4\"],[9],[0,\"\\n\\t\"],[1,[29,\"input\",null,[[\"id\",\"name\",\"disabled\",\"class\",\"type\",\"required\",\"equalTo\",\"value\",\"minlength\",\"maxlength\",\"data-msg\"],[\"verifyPassword\",\"verifyPassword\",[25,[\"property\",\"s4-disabled\"]],\"form-control disablecopypaste\",\"password\",[25,[\"property\",\"s4-validations\",\"required\"]],[29,\"get-id\",[[25,[\"name\"]]],null],[25,[\"model\",\"verifyPassword\"]],[25,[\"property\",\"s4-validations\",\"min\"]],[25,[\"property\",\"s4-validations\",\"max\"]],[25,[\"property\",\"s4-validations\",\"errorMessage\"]]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/components/s4-password.hbs"
    }
  });

  _exports.default = _default;
});
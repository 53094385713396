define("in4m-web/models/component-device", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;

  var _default = Model.extend({
    //deviceConfig: belongsTo('componentProfile/config/deviceConfig',{async:false}),
    amexKeyIndex: attr(),
    beepVolume: attr(),
    bypassAmountOk: attr(),
    cashback: attr(),
    cdType: attr(),
    connection: attr(),
    contactlessEnabled: attr(),
    dccTimeoutSecs: attr(),
    debitKeyIndex: attr(),
    defaultLanguage: attr(),
    deviceType: attr(),
    ebt: attr(),
    googleSecrets: attr(),
    lineLength: attr(),
    manualCardEntry: attr(),
    privateLabel: attr(),
    sourceSerialNumber: attr(),
    taskDesc: attr(),
    terminalList: attr(),
    threadType: attr(),
    tip: attr(),
    rebootTime: attr('', {
      defaultValue: '000000'
    })
  });

  _exports.default = _default;
});
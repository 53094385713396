define("in4m-web/serializers/api-interface", ["exports", "ember-data", "in4m-web/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {}
  });

  _exports.default = _default;
});
define("in4m-web/helpers/schema-check-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.schemaCheckField = schemaCheckField;
  _exports.default = void 0;

  function schemaCheckField(params) {
    var schema = params[0];
    var field = params[1];

    if (typeof schema == 'undefined') {
      return true;
    }

    return typeof schema[field] != 'undefined';
  }

  var _default = Ember.Helper.helper(schemaCheckField);

  _exports.default = _default;
});
define("in4m-web/templates/custom/display-in-giga-bytes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mKGCjYKa",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"convert-to-giga-bytes\",[[25,[\"record\",\"machineStatus\",\"availableMemory\"]],\"MB\"],null],false],[0,\" GB\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/custom/display-in-giga-bytes.hbs"
    }
  });

  _exports.default = _default;
});
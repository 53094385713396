define("in4m-web/components/table-action-gear", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNameBindings: ['gearDisabled:disabled:dropdown'],
    //record gets passed only from componentProfiles table
    gearDisabled: Ember.computed('record', function () {
      var currentRecord = this.record;

      if (currentRecord) {
        var currentRecordParentBusinessEntity = currentRecord.get('parentBusinessEntity');

        if (currentRecordParentBusinessEntity) {
          var user = this.get('session.userObject');
          var hasPermissionToEditProfileOnSelectedBE = user.hasPermission('canEdit', 'in4mUtgProfiles', currentRecordParentBusinessEntity);
          return !hasPermissionToEditProfileOnSelectedBE;
        }
      }
    })
  });

  _exports.default = _default;
});
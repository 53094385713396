define("in4m-web/serializers/role-mapping", ["exports", "ember-data", "in4m-web/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import Ember from "ember"; 
  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      parentBusinessEntity: {
        key: 'businessEntityId'
      },
      ephemeralUser: {
        key: 'ephemeralUserId'
      },
      role: {
        key: 'roleId'
      } // user: {key: 'userId'}

    }
  });

  _exports.default = _default;
});
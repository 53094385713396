define("in4m-web/components/has-permission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    hasPermission: function () {
      var user = this.get('session.userObject');
      return user.hasPermission(this.permAction, this.permKey, this.targetBusinessEntity);
    }.property('permAction', 'permKey', 'targetBusinessEntity')
  });

  _exports.default = _default;
});
define("in4m-web/controllers/dashboard/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    columns: [{
      "propertyName": "permanentId",
      "title": "Serial"
    }, {
      "propertyName": "status",
      "title": "Date"
    }, {
      "propertyName": "lastUpdatedAt",
      "title": "Slot"
    }, {
      "propertyName": "lastUpdatedBy",
      "title": "Business Entity"
    }, {
      "propertyName": "lastUpdatedBy",
      "title": "Machine"
    }]
  });

  _exports.default = _default;
});
define("in4m-web/services/logout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    apiPath: Ember.inject.service(),
    logout: function logout() {
      delete localStorage.accessToken;
      delete localStorage.selectedBusinessEntityId;
      delete sessionStorage.pageSize;
      delete sessionStorage.pageNumber;
      var logoutForm = '<form id="logoutForm" method="post" action="' + this.get('apiPath.ssoUrl') + '/logout?clientid=4&redirectToReferer=true"><input type="hidden" value="logout" name="logout"></form>';
      $('body').append(logoutForm);
      $('#logoutForm').submit();
    }
  });

  _exports.default = _default;
});
define("in4m-web/adapters/device-status", ["exports", "in4m-web/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    host: Ember.computed('apiPath', function () {
      return this.get('apiPath.in4mApiUrl');
    }),
    namespace: Ember.computed('apiPath', function () {
      return this.get('apiPath.in4mApiPath');
    }),
    headers: Ember.computed('session.sessionObject', function () {
      if (this.get('session.sessionObject.accessToken')) {
        return {
          'clientId': 4,
          'Authorization': 'Token token="' + this.get('session.sessionObject.accessToken') + '"',
          'shift4-rest-request-envelope-version': 1,
          'shift4-rest-response-envelope-version': 1,
          'shift4-auth-extend': false
        };
      } else {
        return {
          'clientId': 4,
          'Authorization': 'Token token="' + localStorage.getItem('accessToken') + '"',
          'shift4-rest-request-envelope-version': 1,
          'shift4-rest-response-envelope-version': 1,
          'shift4-auth-extend': false
        };
      }
    }),
    findRecord: function findRecord(store, type, id, snapshot) {
      // get device status potentially gets called thousands of times even though we have already queried for it
      // simply skip these if already on the components tab
      if (window.location.hash === '#/devices') {
        return null;
      }
    },
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var parentBusinessEntityId;

      if (requestType === 'findRecord') {
        parentBusinessEntityId = snapshot.record.get('machine.parentBusinessEntity.id');
      } else if (requestType === 'query') {
        parentBusinessEntityId = query.parentBusinessEntityId;
        delete query.parentBusinessEntityId;
      } else {
        parentBusinessEntityId = this.get('session.userObject.parentBusinessEntity.id');
      }

      var url = [];
      var host = Ember.get(this, 'host');
      var prefix = this.urlPrefix();
      var path;

      if (modelName) {
        path = this.pathForType(modelName);

        if (path) {
          url.push('businessEntities/' + parentBusinessEntityId + '/' + path);
        }
      }

      if (id) {
        url.push(encodeURIComponent(id));
      }

      if (prefix) {
        url.unshift(prefix);
      }

      url = url.join('/');

      if (!host && url && url.charAt(0) !== '/') {
        url = '/' + url;
      }

      return url;
    },
    pathForType: function pathForType(modelName) {
      var camelized = Ember.String.camelize(modelName);
      return camelized;
    }
  });

  _exports.default = _default;
});
define("in4m-web/models/api-interface/cloud-api", ["exports", "ember-data", "in4m-web/models/api-interface"], function (_exports, _emberData, _apiInterface) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _apiInterface.default.extend({
    taskDesc: attr('', {
      defaultValue: 'Cloud'
    }),
    threadType: attr(''),
    spinEnabled: attr('', {
      defaultValue: false
    }),
    spinFloor: attr('', {
      defaultValue: 0.00
    }),
    cdType: attr('', {
      defaultValue: 109
    }),
    //1 for machineIp and 2 for loopback
    ipAddressType: attr('', {
      defaultValue: 1
    }),
    listenPort: attr('', {
      defaultValue: '13107'
    }),
    transportFormat: attr('', {
      defaultValue: 'Http'
    })
  });

  _exports.default = _default;
});
define("in4m-web/models/permission", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    applicationGroup: belongsTo('applicationGroup'),
    key: attr('string'),
    name: attr('string'),
    description: attr('string'),
    canView: attr(),
    canEdit: attr(),
    isEnabled: attr(),
    canDelete: attr(),
    canGrant: attr(),
    showCanView: function () {
      return this.canView !== null;
    }.property('canView'),
    showCanEdit: function () {
      return this.canEdit !== null;
    }.property('canEdit'),
    showisEnabled: function () {
      return this.isEnabled !== null;
    }.property('isEnabled'),
    showCanDelete: function () {
      return this.canDelete !== null;
    }.property('canDelete'),
    showCanGrant: function () {
      return this.canGrant !== null;
    }.property('canGrant')
  });

  _exports.default = _default;
});
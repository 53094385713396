define("in4m-web/templates/custom/agent-version", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ncPrQFZi",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,[\"record\",\"AgentVersion\"]],false],[0,\"\\n\"],[4,\"unless\",[[25,[\"record\",\"isAgentVersionLatest\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[29,\"bootstrap-tool-tip\",null,[[\"class\",\"aria-hidden\",\"data-toggle\",\"data-placement\",\"title\"],[\"fa fa-exclamation-triangle lm\",\"true\",\"tooltip\",\"top\",\"The Agent version you are currently using is out of date. Please schedule a task for the Agent to 'Upgrade Agent to Latest Version.'\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/custom/agent-version.hbs"
    }
  });

  _exports.default = _default;
});
define("in4m-web/helpers/active-class", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.activeClass = activeClass;
  _exports.default = void 0;

  function activeClass(params
  /*, hash*/
  ) {
    return params[0] == params[1] ? 'active' : '';
  }

  var _default = Ember.Helper.helper(activeClass);

  _exports.default = _default;
});
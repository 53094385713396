define("in4m-web/controllers/component-profiles/component-profile/devices/device", ["exports", "in4m-web/utils/drop-down-list"], function (_exports, _dropDownList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    componentProfile: Ember.inject.controller('componentProfiles.componentProfile'),
    // isProfiles true is needed since the partials we use are shared by the components and componentProfiles, 
    // inorder to show some fields based on the model type, we need the isProfiles property on componentProfiles page
    isProfiles: true,
    supportedDevices: function () {
      var supportedDevices = this.store.peekAll('componentDeviceSchema');
      return supportedDevices;
    }.property('model'),
    deviceSchema: function () {
      return this.supportedDevices.findBy('name', this.get('model.deviceType')).get('schemaValue');
    }.property('supportedDevices'),
    selectedDeviceSchema: function () {
      return this.deviceSchema;
    }.property('deviceSchema'),
    validations: function () {
      var selectedDeviceSchema = this.deviceSchema;
      var validations = {};

      function setValidations(validations, properties) {
        for (var key in properties) {
          if (properties[key].type != 'object' && properties[key].type != 'array') {
            validations[key] = {
              required: properties[key].required,
              maxLength: properties[key].maxLength,
              minLength: properties[key].minLength,
              minimum: properties[key].minimum,
              maximum: properties[key].maximum,
              disabled: properties[key].disabled
            };
          } else {
            if (properties[key].type != 'array') {
              validations[key] = {};
              setValidations(validations[key], properties[key].properties);
            } else {
              validations[key] = {};
              setValidations(validations[key], properties[key].items.properties);
            }
          }
        }
      }

      if (selectedDeviceSchema) {
        setValidations(validations, selectedDeviceSchema.properties);
      }

      validations.deviceType = this.get('selectedDevice.name');
      return validations;
    }.property('deviceSchema'),
    dropdownListValues: Ember.computed('dropdownList', function () {
      return (0, _dropDownList.default)();
    }),
    actions: {
      toggleCollapsed: function toggleCollapsed(section) {
        this.set(section + 'Collapsed', !this.get(section + 'Collapsed'));
      },
      addBinRangeOverride: function addBinRangeOverride() {
        // TODO: possibly make bin range management a component so it can be reused in services
        this.get('model.manualCardEntry.binRangeOverrides').addObject({
          bypassCVV: false,
          bypassStreet: false,
          bypassExpDate: false,
          bypassLuhn: false,
          bypassPostalCode: false,
          lower: null,
          upper: null,
          minLength: null,
          maxLength: null
        });
      },
      removeBinRangeOverride: function removeBinRangeOverride(binRange) {
        this.get('model.manualCardEntry.binRangeOverrides').removeObject(binRange);
      }
    }
  });

  _exports.default = _default;
});
define("in4m-web/components/shift4-wizard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    initialStepSetup: function () {
      this.set('currentStep', this.get('steps.firstObject.name'));
    }.observes('steps.[]'),
    steps: Ember.A([]),
    currentStep: null,
    currentStepNumber: function () {//debugger;
    }.property('currentStep'),
    actions: {
      transitionToStep: function transitionToStep(stepName) {
        this.set('currentStep', stepName);
      }
    }
  });

  _exports.default = _default;
});
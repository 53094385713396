define("in4m-web/templates/component-profiles/component-profile/devices/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GOJ/TFNi",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"shift4-table\",null,[[\"data\",\"columns\",\"customClasses\",\"simplePaginationTemplate\",\"showColumnsDropdown\",\"multipleColumnsSorting\",\"globalFilterUsed\",\"filteringIgnoreCase\",\"useFilteringByColumns\",\"sendDisplayDataChangedAction\",\"displayDataChangedAction\",\"addRecord\",\"deleteRecord\",\"showDeleteModal\",\"dismissDeleteModal\",\"selectedDevice\"],[[25,[\"model\"]],[25,[\"columns\"]],[25,[\"customClasses\"]],\"custom/custom-pagination\",false,false,false,true,false,true,\"displayDataChangedAction\",\"addRecord\",\"deleteRecord\",\"showDeleteModal\",\"dismissDeleteModal\",[25,[\"selectedDevice\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/component-profiles/component-profile/devices/index.hbs"
    }
  });

  _exports.default = _default;
});
define("in4m-web/templates/custom/cd-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8LILIYHN",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"api-interface-type\",[[25,[\"record\",\"cdType\"]]],null],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/custom/cd-type.hbs"
    }
  });

  _exports.default = _default;
});
define("in4m-web/initializers/in4m-initializer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    // application.inject('route', 'foo', 'service:foo');
    window.Application = application; //injecting session service

    application.inject('route', 'session', 'service:session');
    application.inject('controller', 'session', 'service:session');
    application.inject('helper', 'session', 'service:session');
    application.inject('component', 'session', 'service:session');
    application.inject('adapter', 'session', 'service:session');
    application.inject('model', 'session', 'service:session');
    application.inject('service:rest-query', 'session', 'service:session');
    application.inject('service:rest-users', 'session', 'service:session'); //injecting clock service

    application.inject('route', 'clock', 'service:clock');
    application.inject('controller', 'clock', 'service:clock');
    application.inject('component', 'clock', 'service:clock');
    application.inject('adapter', 'clock', 'service:clock'); //injecting errorHandler service

    application.inject('route', 'errorhandler', 'service:errorhandler');
    application.inject('controller', 'errorhandler', 'service:errorhandler');
    application.inject('component', 'errorhandler', 'service:errorhandler');
    application.inject('adapter', 'errorhandler', 'service:errorhandler'); //injecting logout service

    application.inject('route', 'logout', 'service:logout'); //injecting refresh service

    application.inject('route', 'refreshSession', 'service:refresh'); //injecting navigation service

    application.inject('route', 'navigation', 'service:navigation');
    application.inject('controller', 'navigation', 'service:navigation');
    application.inject('component', 'navigation', 'service:navigation'); //injecting api-path service

    application.inject('route', 'apiPath', 'service:api-path');
    application.inject('controller', 'apiPath', 'service:api-path');
    application.inject('component', 'apiPath', 'service:api-path');
    application.inject('adapter', 'apiPath', 'service:api-path');
    application.inject('model', 'apiPath', 'service:api-path');
    application.inject('service:rest-query', 'apiPath', 'service:api-path');
    application.inject('service:rest-users', 'apiPath', 'service:api-path'); //injecting model-generator service

    application.inject('route', 'modelGenerator', 'service:model-generator');
    application.inject('controller', 'modelGenerator', 'service:model-generator');
    application.inject('component', 'modelGenerator', 'service:model-generator');
    application.inject('adapter', 'modelGenerator', 'service:model-generator');
    application.inject('serializer', 'modelGenerator', 'service:model-generator');
    application.inject('model', 'modelGenerator', 'service:model-generator');
    application.inject('transform', 'modelGenerator', 'service:model-generator'); //injecting model-for-tab service

    application.inject('route', 'utgComponentConfigRetrieveModel', 'service:utg-component-config-retrieve-model');
    application.inject('controller', 'utgComponentConfigRetrieveModel', 'service:utg-component-config-retrieve-model');
    application.inject('component', 'utgComponentConfigRetrieveModel', 'service:utg-component-config-retrieve-model');
    application.inject('adapter', 'utgComponentConfigRetrieveModel', 'service:utg-component-config-retrieve-model');
    application.inject('serializer', 'utgComponentConfigRetrieveModel', 'service:utg-component-config-retrieve-model');
    application.inject('model', 'utgComponentConfigRetrieveModel', 'service:utg-component-config-retrieve-model'); //injecting rest-query service

    application.inject('route', 'restQuery', 'service:rest-query');
    application.inject('controller', 'restQuery', 'service:rest-query');
    application.inject('component', 'restQuery', 'service:rest-query'); //injecting rest-users service

    application.inject('route', 'restUsers', 'service:rest-users');
    application.inject('controller', 'restUsers', 'service:rest-users');
    application.inject('component', 'restUsers', 'service:rest-users');
  }

  var _default = {
    name: 'in4m-initializer',
    initialize: initialize
  };
  _exports.default = _default;
});
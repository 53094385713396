define("in4m-web/serializers/installation-manifest", ["exports", "ember-data", "in4m-web/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      credentials: {
        embedded: 'always'
      },
      machine: {
        embedded: 'always'
      },
      agent: {
        embedded: 'always'
      },
      components: {
        embedded: 'always'
      },
      tasks: {
        embedded: 'always'
      }
    },
    serialize: function serialize(payload) {
      var json = this._super.apply(this, arguments);

      if (json.components[0].profileId) {
        if (json.components[0].profileId.toString().indexOf('-') !== -1) {
          // strip the dasherized profileId
          json.components[0].profileId = json.components[0].profileId.toString().split("-")[1];
        }
      }

      return json;
    }
  });

  _exports.default = _default;
});
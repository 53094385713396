define("in4m-web/serializers/component-profile/config/device-config", ["exports", "ember-data", "in4m-web/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      "devices": {
        key: 'device',
        embedded: 'always'
      }
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      console.log(payload);

      if (payload.response) {
        delete payload.response;
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});
define("in4m-web/adapters/business-entity-theme", ["exports", "ember-inflector", "in4m-web/adapters/application"], function (_exports, _emberInflector, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      if (requestType === 'query') {
        var parentBusinessEntityId = query.parentBusinessEntityId;
        delete query.parentBusinessEntityId;
        var url = [];
        var host = this.host;
        var prefix = this.urlPrefix();
        var path;

        if (modelName) {
          path = this.pathForType(modelName);

          if (path) {
            url.push('businessentities' + '/' + parentBusinessEntityId + '/' + path);
          }
        }

        if (id) {
          url.push(encodeURIComponent(id));
        }

        if (prefix) {
          url.unshift(prefix);
        }

        url = url.join('/');

        if (!host && url && url.charAt(0) !== '/') {
          url = '/' + url;
        }

        return url;
      } else {
        return this._super(modelName, id, snapshot, requestType, query);
      }
    },
    pathForType: function pathForType(modelName) {
      var camelized = Ember.String.camelize(modelName);
      var pluralized = (0, _emberInflector.pluralize)(camelized);
      return pluralized;
    }
  });

  _exports.default = _default;
});
define("in4m-web/templates/component/manage/4go", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IF8FifxF",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"form-generator\",null,[[\"model\",\"isEditDisabled\"],[[25,[\"model\"]],[25,[\"indexController\",\"isEditDisabled\"]]]]],false],[0,\"\\n\"],[7,\"footer\"],[11,\"class\",\"panel-footer\"],[11,\"style\",\"background-color:white\"],[9],[0,\"\\n\\t\"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\"],[11,\"style\",\"float:right\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"button\"],[11,\"class\",\"btn btn-default\"],[11,\"type\",\"reset\"],[9],[0,\"Back\"],[3,\"action\",[[24,0,[]],\"back\"]],[10],[0,\"\\n\"],[4,\"unless\",[[25,[\"indexController\",\"isEditDisabled\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"button\"],[11,\"class\",\"btn btn-primary\"],[9],[0,\"Apply\"],[3,\"action\",[[24,0,[]],\"saveComponentConfig\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\"],[10],[0,\"\\n\\t\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/component/manage/4go.hbs"
    }
  });

  _exports.default = _default;
});
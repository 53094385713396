define("in4m-web/helpers/device-can-push-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deviceCanPushForm = deviceCanPushForm;
  _exports.default = void 0;

  function deviceCanPushForm(params) {
    var deviceType = params[0];
    var formsLoaded = params[1];
    var isPAX = deviceType && typeof deviceType != 'undefined' && (deviceType.toLowerCase().includes('pax') || deviceType.toLowerCase().includes('s300'));
    return formsLoaded && !isPAX;
  }

  var _default = Ember.Helper.helper(deviceCanPushForm);

  _exports.default = _default;
});
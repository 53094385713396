define("in4m-web/helpers/leaf-padding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.leafPadding = leafPadding;
  _exports.default = void 0;

  function leafPadding(params
  /*, hash*/
  ) {
    var childOrg = params[0];
    return Ember.String.htmlSafe('padding-left:' + childOrg.get('nthChildLevel') * 30 + 'px');
  }

  var _default = Ember.Helper.helper(leafPadding);

  _exports.default = _default;
});
define("in4m-web/helpers/unique-name-id", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.uniqueNameId = uniqueNameId;
  _exports.default = void 0;

  function uniqueNameId(params
  /*, hash*/
  ) {
    var nameString = params[0];
    var indexToAppend = params[1];
    return nameString + indexToAppend;
  }

  var _default = Ember.Helper.helper(uniqueNameId);

  _exports.default = _default;
});
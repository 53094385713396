define("in4m-web/routes/component/manage/api-interface/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting) {
        var dirtyRecord = controller.selectedInterfaceModel;

        if (dirtyRecord) {
          dirtyRecord.rollbackAttributes();
        }

        controller.set('selectedInterface', ''); //resetting the selected interface on the controller to empty
      }
    }
  });

  _exports.default = _default;
});
define("in4m-web/components/s4-ip-address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend({
    keyPress: function keyPress(event) {
      // Firefox: 0 is needed for arrow keys and shift to highlight. 8 is for backspace.
      if (event.which === 0 || event.which === 8 || (event.which === 46 || event.which >= 48 && event.which <= 57) && event.target.value.length !== event.target.maxLength) {
        return;
      } else {
        event.preventDefault();
      }
    }
  });

  _exports.default = _default;
});
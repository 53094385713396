define("in4m-web/templates/custom/service-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MNVwf76d",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",[\"component.manage\",[25,[\"record\",\"ComponentId\"]]],null,{\"statements\":[[1,[25,[\"record\",\"ComponentNickname\"]],false]],\"parameters\":[]},null],[0,\" \"],[7,\"a\"],[12,\"href\",[30,[\"/react/#/be/\",[25,[\"record\",\"BusinessEntityId\"]],\"/utgs/\",[25,[\"record\",\"ComponentId\"]]]]],[9],[0,\"Configure\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/custom/service-link.hbs"
    }
  });

  _exports.default = _default;
});
define("in4m-web/routes/component-profiles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    permKey: 'in4mUtgProfiles',
    permAction: 'canView',
    model: function model() {
      var _this = this;

      var navigation = _this.get('navigation');

      var hasPermission = navigation.checkPermissions(_this.get('session.selectedBusinessEntity'));

      if (hasPermission) {
        $('#progressHudOverlay').show();
        var parentBusinessEntityId = this.get('session.selectedBusinessEntity.id');
        return this.store.query('componentProfile', {
          parentBusinessEntityId: parentBusinessEntityId
        }).then(function (componentProfiles) {
          return componentProfiles;
        }, function (error) {
          $('#progressHudOverlay').hide();
          return _this.get('errorhandler').handleError(error, 'Error while making a request.');
        });
      }
    },
    afterModel: function afterModel() {
      $('#progressHudOverlay').hide();
    }
  });

  _exports.default = _default;
});
define("in4m-web/templates/custom/profile-created-at", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7BkqXuwx",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"date-formatter\",[[29,\"concat\",[[25,[\"record\",\"createdAt\"]],\"Z\"],null]],null],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/custom/profile-created-at.hbs"
    }
  });

  _exports.default = _default;
});
define("in4m-web/models/device-status", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    parentBusinessEntityId: attr(),
    machinePermanentId: attr(),
    deviceKeys: attr(),
    deviceSerialNumber: attr(),
    devicePciVersion: attr(),
    machineName: attr(),
    terminalId: attr(),
    deviceHealthStats: attr(),
    apiTid: attr(),
    componentStatus: belongsTo('componentStatus', {
      async: false
    }),
    component: belongsTo('component', {
      async: true
    }),
    serviceName: Ember.computed('component', function () {
      return this.get('component.nickname');
    }),
    deviceyKeysComputed: Ember.computed('deviceKeys', function () {
      var deviceKeys = this.get('deviceKeys');

      if (deviceKeys != null) {
        return Object.values(this.get('deviceKeys'));
      } else {
        return [];
      }
    }),
    // deviceStatusComputed:computed('deviceHealthStats', function() {
    // 	return this.get('deviceHealthStats.penStatus');
    // }),
    deviceTypeComputed: Ember.computed('deviceHealthStats', function () {
      var terminalName = this.get('deviceHealthStats.terminalName');
      var unitModel = this.get('deviceHealthStats.unitModel');
      return terminalName ? terminalName : unitModel;
    }),
    deviceFirmwareComputed: Ember.computed('deviceHealthStats', function () {
      return this.get('deviceHealthStats.applicationVersion');
    })
  });

  _exports.default = _default;
});
define("in4m-web/controllers/installations/connect-utg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* See: https://api.emberjs.com/ember/release/classes/Observable/methods and 
  https://github.com/ember-cli/eslint-plugin-ember/blob/master/docs/rules/no-function-prototype-extensions.md */
  var _default = Ember.Controller.extend({
    installations: Ember.inject.controller('installations'),
    pushConfig: false,

    /* As soon as the model is loaded, load the set of available Agent versions 
       to display in this modal. 
       
       Note: The name of the method doesn't matter. We don't call this method 
       directly. It is an OBSERVER. So it's called automatically when the model is loaded.
    */
    loadAvailableAgentVersions: Ember.observer('model', function () {
      var _this = this;

      var agentManifestLocation = _this.get('apiPath.agentManifestLocation'); //load as set in config.js


      Ember.$.get(agentManifestLocation).then(function (data) {
        _this.set('availableAgentVersions', data.product);
      });
    }),

    /*
    	As the user selects an agent version from the dropdown, we will 
    	store the download URL of the selected version here.
    */
    agentDownloadLocation: '',
    setEphemeral: Ember.observer('model', function () {
      var _this = this; // create the ephemeralUser right away for UI bindings


      var ephemeralUser = _this.store.createRecord('ephemeralUser', {
        applicationId: 4,
        createdBy: _this.get('session.userObject.id'),
        // set to selected BE
        parentBusinessEntity: _this.get('session.selectedBusinessEntity'),
        allowDuplicateSessions: true,
        // lifespan of 20 is multi use, the wizard sets the start/end date
        lifespan: _this.store.createRecord('lifespan', {
          lifeSpanType: 20,
          startDate: moment().format('MM/DD/YYYY'),
          endDate: moment().add(99, 'years').format('MM/DD/YYYY')
        })
      });

      _this.set('ephemeralUser', ephemeralUser); // check if we have already retrieved the ephemeral roles and then set the ephemeral user on the controller


      if (this.ephemeralRoles) {
        var ephemeralRoles = this.ephemeralRoles;
        var ephemeralRole = ephemeralRoles.get('firstObject'); // set ephemeralUser role

        ephemeralUser.get('roleMappings').addObject(_this.store.createRecord('roleMapping', {
          role: ephemeralRole.get('roleId'),
          parentBusinessEntity: _this.get('session.selectedBusinessEntity'),
          ephemeralUser: ephemeralUser
        }));
      } else {
        this.store.query('objectRoleMapping', {
          objectTypeId: 7,
          objectId: 1
        }).then(function (ephemeralRoles) {
          var ephemeralRole = ephemeralRoles.get('firstObject');

          _this.set('ephemeralRoles', ephemeralRoles); // set ephemeralUser role


          ephemeralUser.get('roleMappings').addObject(_this.store.createRecord('roleMapping', {
            role: ephemeralRole.get('roleId'),
            parentBusinessEntity: _this.get('session.selectedBusinessEntity'),
            ephemeralUser: ephemeralUser
          }));
        });
      }
    }),
    ephemeralUser: null,
    selectedMachineId: '',
    clearSelectedMachine: Ember.observer('model.manifest.agent.newMachine', function () {
      // clears the selected machine property when the user toggles back to "new"
      if (!this.get('mode.manifest.agent.newMachine')) {
        this.set('selectedMachineId', '');
      }
    }),
    actions: {
      closeConnectUTG: function closeConnectUTG() {
        var _this = this;

        $('#installationModal').modal('toggle').on('hidden.bs.modal', function () {
          _this.transitionToRoute('installations');
        });
      },

      /**
       * Creates an installation after modal submittal by the user
       */
      createInstallation: function createInstallation() {
        if ($('#connectUTG').valid()) {
          var _this = this;

          var installation = _this.get('model');

          var ephemeralUser = _this.get('ephemeralUser');

          console.log(_this.agentDownloadLocation);
          $('#connectUtgModal').modal('toggle');
          $('#progressHudMessage').html('Creating Install User...');
          $('#progressHudOverlay').show();
          installation.get('manifest.agent') //grab the agent section from installation.manifest.agent
          .setProperties({
            downloadLocation: //Set the download location for the agent...
            _this.get('agentDownloadLocation') // ...to the agent download location for the version picked by the user

          });
          ephemeralUser.save().then(function (ephemeralUser) {
            // set auth code from user into manifest
            installation.get('manifest.credentials').setProperties({
              applicationId: 4,
              sessionToken: ephemeralUser.get('userSessions.firstObject.sessionToken'),
              businessEntityId: ephemeralUser.get('parentBusinessEntity'),
              authenticationCode: ephemeralUser.get('authenticationCode')
            });
            $('#progressHudMessage').html('Creating Package...');
            installation.save().then(function () {
              // add installation to installations table because it will not update automatically since the model is a query result (TODO: don't use query as much)
              _this.get('installations').get('model.content').pushObject(installation.get('_internalModel'));

              _this.transitionToRoute('installations.downloadInstall', installation);
            }, function (error) {
              $('#progressHudOverlay').hide();

              _this.get('errorhandler').handleError(error, 'Error creating the installation.');
            });
          }, function (error) {
            $('#progressHudOverlay').hide();

            _this.get('errorhandler').handleError(error, 'Error creating the Ephemeral User.');
          });
        }
      }
    }
  });

  _exports.default = _default;
});
define("in4m-web/models/machine", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    agentVersion: attr('string'),
    permanentId: attr('string'),
    createKeyContainer: attr('boolean', {
      default: true
    }),
    serverName: attr('string'),
    nickname: attr('string'),
    dnsName: attr('string'),
    machineStatus: belongsTo('machineStatus'),
    lastUpdatedAt: attr('Date'),
    lastUpdatedBy: attr('number'),
    status: attr('string'),
    ipAddress: attr('string'),
    operatingSystem: attr('string'),
    parentBusinessEntity: belongsTo('businessEntity'),
    components: hasMany('component'),
    parentName: Ember.computed('parentBusinessEntity', function () {
      return this.get('parentBusinessEntity.name');
    }),
    failedToCheckIn: Ember.computed('machineStatus.nextPredictedUpdateAt', function () {
      var today = new Date();
      var nextPredictedDate = new Date(this.get('machineStatus.nextPredictedUpdateAt'));

      if (nextPredictedDate.getTime() < today.getTime()) {
        return true;
      }

      return false;
    }),
    realTimeMachineStatus: Ember.computed('machineStatus.status', function () {
      var status = this.get('machineStatus.status');

      if (this.failedToCheckIn) {
        return 'Failed to check-in';
      }

      if (status != '1') {
        return 'Not Registered';
      } else {
        return 'Running';
      }
    }),
    utgStatus: Ember.computed('machineStatus.componentStatus', function () {
      var componentStatus = this.get('machineStatus.componentStatus');

      if (componentStatus) {
        if (componentStatus.length > 0) {
          var _thisComponent$status;

          //UTG and Machine are really one - to - one.Thus we care about the first record only.
          var thisComponent = componentStatus.firstObject; //ember makes the first object in an array available at this special property name, rather than by index == 0

          var status = (_thisComponent$status = thisComponent.status) === null || _thisComponent$status === void 0 ? void 0 : _thisComponent$status.toString();

          if (this.failedToCheckIn) {
            return "Failed to check-in";
          } //handle standard cases


          switch (status) {
            case '0':
              return "Unknown";

            case '1':
              return "Running";

            case '2':
              return "Online";

            case '4':
              return "Offline";

            case '128':
              return "Stopped";

            case '32768':
              return "Failed";

            default:
              return "Unknown";
          }
        }
      }

      return "Unknown";
    }),
    merchantName: Ember.computed('components', function () {
      //grabbing field from the UTG (machine.components[0])
      var components = this.get('components');

      if (components) {
        if (components.length > 0) {
          //UTG and Machine are really one - to - one.Thus we care about the first record only.
          var thisComponent = components.firstObject; //ember makes the first object in an array available at this special property name, rather than by index == 0

          if (thisComponent) {
            return thisComponent.merchant;
          }
        }
      }

      return '';
    }),
    merchantFileId: Ember.computed('components', function () {
      //grabbing field from the UTG (machine.components[0]), fileId is a unique identifier given to the merchant when onboarding with Shift4... in the past this class property was also used for MID
      var components = this.get('components');

      if (components) {
        if (components.length > 0) {
          //UTG and Machine are really one - to - one.Thus we care about the first record only.
          var thisComponent = components.firstObject; //ember makes the first object in an array available at this special property name, rather than by index == 0

          if (thisComponent) {
            return thisComponent.fileId;
          }
        }
      }

      return '';
    }),
    utgNickname: Ember.computed('components', function () {
      //components currently == UTG
      var components = this.get('components');

      if (components) {
        if (components.length > 0) {
          var thisComponent = components.firstObject; //ember makes the first object in an array available at this special property name, rather than by index == 0

          if (thisComponent) {
            return thisComponent.nickname;
          }
        }
      }

      return 'No UTG';
    }),
    utgLocationOrSerial: Ember.computed('components', function () {
      //grabbing field from the UTG (machine.components[0]), currently we expect this to be the Shift4 Serial number
      var components = this.get('components');

      if (components) {
        if (components.length > 0) {
          //UTG and Machine are really one - to - one.Thus we care about the first record only.
          var thisComponent = components.firstObject; //ember makes the first object in an array available at this special property name, rather than by index == 0

          if (thisComponent) {
            return thisComponent.location;
          }
        }
      }

      return '';
    }),
    utgLane: Ember.computed('components', function () {
      //grabbing field from the UTG (machine.components[0])
      var components = this.get('components');

      if (components) {
        if (components.length > 0) {
          //UTG and Machine are really one - to - one.Thus we care about the first record only.
          var thisComponent = components.firstObject; //ember makes the first object in an array available at this special property name, rather than by index == 0

          if (thisComponent) {
            return thisComponent.lane;
          }
        }
      }

      return '';
    }),
    utgVersion: Ember.computed('components', function () {
      //grabbing field from the UTG (machine.components[0])
      var components = this.get('components');

      if (components) {
        if (components.length > 0) {
          //UTG and Machine are really one - to - one.Thus we care about the first record only.
          var thisComponent = components.firstObject; //ember makes the first object in an array available at this special property name, rather than by index == 0

          if (thisComponent) {
            // use the reported utg version from aws if we have it available
            return thisComponent.softwareVersionFromAws || thisComponent.softwareVersion;
          }
        }
      }

      return '';
    }),
    isAgentVersionLatest: Ember.computed('agentVersion', function () {
      var _this = this;

      var currentAgentVersion = _this.get('agentVersion');

      if (!currentAgentVersion) {
        return false;
      } else {
        var agentManifestLocation = this.get('apiPath.agentManifestLocation');

        var latestAgentVersion = _this.get('session.latestAgentVersion');

        if (currentAgentVersion < latestAgentVersion) {
          return false;
        } else {
          return true;
        }
      }
    }),
    agentInstallationDirectory: attr(),
    isDuplicate: Ember.computed('machineStatus.isDuplicate', function () {
      return this.get('machineStatus.isDuplicate');
    })
  });

  _exports.default = _default;
});
define("in4m-web/helpers/check-permissions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    navigation: Ember.inject.service('navigation'),
    compute: function compute(params, hash) {
      var businessEntity = params[0];
      var navigation = this.navigation;
      var permissionsValid = navigation.checkPermissions(businessEntity);

      if (permissionsValid) {
        return true;
      } else {
        return false;
      }
    }
  });

  _exports.default = _default;
});
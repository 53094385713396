define("in4m-web/utils/scroll-to-validate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    //utility that scrolls to the element and highlights the error message
    begin: function begin(outerScroll, scrollToElement, formName, errorMessage) {
      //this check is needed to make sure, the scroll happens if the scrollToElement is on the present screen
      if ($("#" + scrollToElement).offset()) {
        $('.' + outerScroll).animate({
          scrollTop: $("#" + scrollToElement).offset().top - 200
        }, 100);
      }

      var validator = $('#' + formName).validate();
      var errors = {};
      errors[scrollToElement] = errorMessage;

      if (validator) {
        validator.showErrors(errors);
      }
    }
  };
  _exports.default = _default;
});
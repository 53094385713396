define("in4m-web/templates/components/shift4-wizard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uvPf4J1W",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"wizard\"],[9],[0,\"\\n\\t\"],[15,1,[[29,\"hash\",null,[[\"step\",\"transitionToStep\",\"currentStep\",\"currentStepNumber\"],[[29,\"component\",[\"wizard/wizard-step\"],[[\"wizard\"],[[24,0,[]]]]],[29,\"action\",[[24,0,[]],\"transitionToStep\"],null],[24,0,[\"currentStep\"]],[24,0,[\"currentStepNumber\"]]]]]]],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/components/shift4-wizard.hbs"
    }
  });

  _exports.default = _default;
});
define("in4m-web/models/component-certificate", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    certificateFormat: attr({
      defaultValue: 1
    }),
    commonName: attr(),
    email: attr(),
    countryCode: attr({
      defaultValue: 'US'
    }),
    editPartyName: attr(),
    endDate: attr(),
    friendlyName: attr(),
    isClientAuthentication: attr({
      defaultValue: true
    }),
    isKeyEmbedded: attr(),
    isServerAuthentication: attr({
      defaultValue: true
    }),
    isSignedCert: attr(),
    certificateIssuanceType: attr({
      defaultValue: 2
    }),
    keySize: attr({
      defaultValue: 4096
    }),
    locality: attr({
      defaultValue: 'LAS VEGAS'
    }),
    organization: attr(),
    organizationUnit: attr(),
    password: attr(),
    registeredId: attr(),
    serialNumber: attr(),
    startDate: attr(),
    stateOrProvince: attr({
      defaultValue: 'NV'
    }),
    parentBusinessEntity: belongsTo('businessEntity'),
    certificateGuid: attr(),
    component: belongsTo('component', {
      async: false
    }),
    createdAt: attr(),
    lastUpdatedAt: attr(),
    lastUpdatedBy: attr(),
    certificateFilePath: attr(),
    keyFilePath: attr(),
    hashAlgorithmName: attr(),
    filename: attr(),
    useCaCert: attr(),
    caCertFilenameWithoutExtension: attr({
      defaultValue: 'ca-cert'
    }),
    isCaCertKeyEmbedded: attr({
      defaultValue: false
    }),
    caCertPassword: attr(),
    caCertFormat: attr({
      defaultValue: 'pfx'
    }),
    sanEmails: attr(),
    sanIpAddresses: attr(),
    sanDnsNames: attr()
  });

  _exports.default = _default;
});
define("in4m-web/templates/change-parent-be-leaf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8B/7BAV9",
    "block": "{\"symbols\":[\"childOrg\"],\"statements\":[[7,\"tr\"],[9],[0,\"\\n\\t\"],[7,\"td\"],[12,\"style\",[29,\"leaf-padding\",[[25,[\"childOrg\"]]],null]],[9],[0,\"\\n\\t\\t\"],[1,[29,\"icon-for-org-type\",[[25,[\"childOrg\"]]],null],false],[0,\"\\n\\t\\t\"],[1,[25,[\"childOrg\",\"name\"]],false],[0,\"\\n\\t\"],[10],[0,\"\\n\\t\"],[7,\"td\"],[11,\"class\",\"checkbox-icon\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"childOrg\",\"radio\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"i\"],[11,\"style\",\"font-size:28px;color:#136ef6\"],[11,\"class\",\"icon icon-check\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"i\"],[11,\"style\",\"font-size:28px;color:#ccc\"],[11,\"class\",\"icon-check\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\"],[10],[0,\"\\n\"],[3,\"action\",[[24,0,[]],\"selectParent\",[25,[\"childOrg\"]]]],[10],[0,\"\\n\"],[4,\"each\",[[25,[\"childOrg\",\"children\"]]],null,{\"statements\":[[0,\"\\t\"],[16,\"change-parent-be-leaf\",[1]],[0,\"\\t\\n\"]],\"parameters\":[1]},null]],\"hasEval\":true}",
    "meta": {
      "moduleName": "in4m-web/templates/change-parent-be-leaf.hbs"
    }
  });

  _exports.default = _default;
});
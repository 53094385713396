define("in4m-web/helpers/guid-for", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.guidFor = guidFor;
  _exports.default = void 0;

  function guidFor(params
  /*, hash*/
  ) {
    return Ember.guidFor(params[0]);
  }

  var _default = Ember.Helper.helper(guidFor);

  _exports.default = _default;
});
define("in4m-web/templates/custom/configuration-version", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9/yJjQUV",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,[\"record\",\"configVersion\",\"status\"]],false],[0,\"\\n\"],[1,[29,\"bootstrap-tool-tip\",null,[[\"class\",\"aria-hidden\",\"data-toggle\",\"data-placement\",\"title\"],[[25,[\"record\",\"configVersion\",\"class\"]],\"true\",\"tooltip\",\"top\",[25,[\"record\",\"configVersion\",\"popover\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/custom/configuration-version.hbs"
    }
  });

  _exports.default = _default;
});
define("in4m-web/templates/custom/next-known-communication-tasks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oAcu0KpG",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"record\",\"component\",\"failedToCheckIn\"]]],null,{\"statements\":[[0,\"\\tFailed to check in\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[1,[29,\"date-formatter\",[[25,[\"record\",\"component\",\"nextPredictedUpdateAt\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/custom/next-known-communication-tasks.hbs"
    }
  });

  _exports.default = _default;
});
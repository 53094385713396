define("in4m-web/templates/users/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JqQx03Cc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"class\",\"panel\"],[9],[0,\"\\n  \"],[7,\"header\"],[11,\"class\",\"panel-heading\"],[9],[0,\"\\n    \"],[7,\"h2\"],[11,\"class\",\"panel-title\"],[9],[0,\"Users\"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"panel-actions\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"canEditUsers\"]]],null,{\"statements\":[[4,\"link-to\",[\"users.new\"],[[\"tagName\"],[\"li\"]],{\"statements\":[[0,\"          \"],[7,\"a\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-plus fa-lg\"],[9],[10],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"panel-body horizontalScroll\"],[9],[0,\"\\n    \"],[1,[29,\"shift4-table\",null,[[\"showPageSize\",\"data\",\"columns\",\"simplePaginationTemplate\",\"multipleColumnsSorting\",\"globalFilterUsed\",\"filteringIgnoreCase\",\"useFilteringByColumns\",\"showColumnsDropdown\",\"elementId\"],[true,[25,[\"model\"]],[25,[\"columns\"]],\"custom/custom-pagination\",false,false,true,false,false,\"usersTable\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/users/index.hbs"
    }
  });

  _exports.default = _default;
});
define("in4m-web/adapters/component-device-schema", ["exports", "in4m-web/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    host: Ember.computed('apiPath', function () {
      return this.get('apiPath.in4mApiUrl');
    }),
    namespace: Ember.computed('apiPath', function () {
      return this.get('apiPath.in4mApiPath');
    }),
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var url = [],
          host = Ember.get(this, 'host'),
          prefix = this.urlPrefix();
      url.push('componentDevices'); // id present indicates we are retrieving a single record rather than a collection

      if (id) {
        // individual roles are retrieved without organizational context
        url.push(encodeURIComponent(id));
      }

      if (prefix) {
        url.unshift(prefix);
      }

      url = url.join('/');

      if (!host && url) {
        url = '/' + url;
      }

      return url;
    }
  });

  _exports.default = _default;
});
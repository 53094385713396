define("in4m-web/templates/components/models-table/page-size", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SBRXF6oO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[30,[[25,[\"classes\",\"pageSizeWrapper\"]]]]],[9],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[30,[[25,[\"classes\",\"pageSizeSelectWrapper\"]]]]],[9],[0,\"\\n  \"],[7,\"label\"],[11,\"style\",\"padding-right:10px\"],[9],[0,\"\\n  \\t\"],[1,[29,\"models-select\",null,[[\"options\",\"value\",\"class\"],[[25,[\"pageSizeValues\"]],[25,[\"pageSize\"]],[29,\"concat\",[[25,[\"classes\",\"input\"]],\" changePageSize select2-container\"],null]]]],false],[0,\"\\n  \\t records per page\\n  \"],[10],[0,\" \\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/components/models-table/page-size.hbs"
    }
  });

  _exports.default = _default;
});
define("in4m-web/components/s4-control", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // ['col-xs-2','col-sm-2', 'col-md-2', 'form-control-static'],
    classNameBindings: ['getClassNames'],
    getClassNames: Ember.computed('property', function () {
      var noofCoulmns = this.property['s4-control-noof-columns'];

      if (noofCoulmns) {
        var xs = noofCoulmns.xs;
        var sm = noofCoulmns.sm;
        var md = noofCoulmns.md;
        return 'col-xs-' + xs + ' col-sm-' + sm + ' col-md-' + md + ' form-control-static';
      } else {
        return 'col-xs-2 col-sm-2 col-md-2 form-control-static';
      }
    }),
    didRender: function didRender() {
      $('[data-toggle="tooltip"]').tooltip();
    }
  });

  _exports.default = _default;
});
define("in4m-web/models/component-profile/config/utg-global", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Model$extend;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend((_Model$extend = {
    "4Go": belongsTo('componentProfile/config/utgGlobal/4go', {
      async: false
    }),
    offline: belongsTo('componentProfile/config/utgGlobal/offline', {
      async: false
    }),
    config: belongsTo('componentProfile/config', {
      async: false
    }),
    utgId: attr(),
    maxTransmits: attr(),
    maxCircuitElements: attr(),
    workerThreads: attr(),
    cloudTimeoutOverride: attr(),
    stationName: attr(),
    installationPath: attr(),
    signature: attr(),
    convertErrorsToReferrals: attr(),
    sendAlertMessagesToShift4: attr(),
    transactionCacheEnabled: attr(),
    lowDiskSpaceThresholdMB: attr(),
    maxThreads: attr(),
    corruptDataMarkTrackDataFix1: attr()
  }, _defineProperty(_Model$extend, "offline", attr()), _defineProperty(_Model$extend, "cdType", attr()), _defineProperty(_Model$extend, "pinPadPushSlotCount", attr()), _defineProperty(_Model$extend, "importManager", attr()), _Model$extend));

  _exports.default = _default;
});
define("in4m-web/components/side-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'aside',
    applyClass: true,
    classNames: ['sidebar-left'],
    elementId: "sidebar-left",
    watchCheckbox: Ember.observer('isChecked', function () {
      this.session.setShowChildren(this.isChecked);
    }),
    dataComputed: Ember.computed('businessEntity', function () {
      var businessEntity = this.get('businessEntity');

      if (businessEntity) {
        var eachNodeObject = {};
        eachNodeObject['data'] = businessEntity;
        eachNodeObject['id'] = businessEntity.get('id');
        eachNodeObject['text'] = businessEntity.get('name');
        eachNodeObject['type'] = businessEntity.get('jsTreeType');
        eachNodeObject['state'] = {};
        eachNodeObject.state['opened'] = true;
        eachNodeObject.state['selected'] = true;
        eachNodeObject['children'] = this.getChildrenData(businessEntity.get('sortedChildren'));
        return [eachNodeObject];
      }
    }),
    getChildrenData: function getChildrenData(sortedChildren) {
      var _this = this;

      var childrenData = [];
      sortedChildren.forEach(function (eachSortedChildren) {
        if (!eachSortedChildren.get('isMerchant')) {
          var eachSubNodeObject = {};
          eachSubNodeObject['data'] = eachSortedChildren;
          eachSubNodeObject['id'] = eachSortedChildren.get('id');
          eachSubNodeObject['text'] = eachSortedChildren.get('name');
          eachSubNodeObject['type'] = eachSortedChildren.get('jsTreeType');
          childrenData.push(eachSubNodeObject);

          if (eachSortedChildren.get('sortedChildren').get('length') > 0) {
            eachSubNodeObject['children'] = _this.getChildrenData(eachSortedChildren.get('sortedChildren'));
          }
        }
      });
      return childrenData;
    },
    typesOptions: {
      'default': {
        'icon': 'fa fa-globe'
      },
      'organization': {
        'icon': 'fa fa-globe'
      },
      'merchant': {
        'icon': 'fa fa-user'
      },
      'reseller': {
        'icon': 'fa fa-briefcase'
      }
    },
    plugins: "state, search, types",
    themes: Ember.computed(function () {
      return {
        name: "default-dark",
        responsive: true
      };
    }),
    searchOptions: Ember.computed(function () {
      return {
        show_only_matches: true
      };
    }),
    searchTerm: "",
    didInsertElement: function didInsertElement() {
      $(function () {
        $('.scrollbar-outer').scrollbar();
        $(".nav-parent a").click(function () {
          toggleNav();
        }); //mobile view

        $("#TopNavSideBarToggle").click(function () {
          toggleNav();
        });

        function toggleNav() {
          if ($('.nav-expanded')[0]) {
            collapseNav();
          } else {
            expandNav();
          }
        }

        expandNav();

        function expandNav() {
          $(".nav-parent").addClass("nav-expanded");
          $(".in4m-right").css("visibility", "visible");
          $("html").removeClass("sidebar-left-collapsed");
          $("html").addClass("sidebar-left-opened");
          $(".be-tree").hide();
          $(".be-tree").css("display", "inline-block");
          enableSideBarResize();
        }

        function collapseNav() {
          if (!$("html").hasClass(".sidebar-left-collapsed")) {
            $("html").addClass("sidebar-left-collapsed");
            $("html").removeClass("sidebar-left-opened");
            $(".in4m-right").css("visibility", "hidden");
            $(".nav-parent").removeClass("nav-expanded");
            $(".be-tree").hide();
            disableSideBarResize();
          }
        }

        function disableSideBarResize() {
          $("html.fixed .content-body").css('margin-left', '');
          $("html.fixed .page-header").css('left', '');
          $("html.fixed .inner-toolbar").css('left', '');
          $('#sidebar-left').css('width', '');
          $('#sidebar-resizer').hide();
        }

        function enableSideBarResize() {
          $('#sidebar-resizer').show();
          var maxWidth = $(window).width() / 2;
          var sideBarWidth = $('#sidebar-left').width();
          $("html.fixed .content-body").css('margin-left', sideBarWidth);
          $("html.fixed .page-header").css('left', sideBarWidth);
          $("html.fixed .inner-toolbar").css('left', sideBarWidth);
          $('#sidebar-left').resizable({
            maxWidth: maxWidth,
            minWidth: 350,
            handles: {
              'e': '#sidebar-resizer'
            },
            resize: function resize(event, ui) {
              $(window).resize();
              var sideBarWidth = ui.size.width + 'px';
              $("html.fixed .content-body").css('margin-left', sideBarWidth);
              $("html.fixed .nav-header").css('left', sideBarWidth);
              $("html.fixed .page-header").css('left', sideBarWidth);
              $("html.fixed .inner-toolbar").css('left', sideBarWidth);
            }
          });
        }
      });
    },
    actions: {
      eventDidSelectNode: function eventDidSelectNode(node) {
        if (node.action == 'select_node') {
          this.navigation.selectBE(node.node.data);
        }
      },
      openAll: function openAll(isChecked) {
        if (isChecked) {
          this.get('jstreeActionReceiver').send('openAll');
        } else {
          this.get('jstreeActionReceiver').send('closeAll');
        }
      }
    }
  });

  _exports.default = _default;
});
define("in4m-web/controllers/component/manage/api-interface/new", ["exports", "in4m-web/utils/alerts", "in4m-web/utils/scroll-to-validate", "in4m-web/helpers/check-duplicates-in-array"], function (_exports, _alerts, _scrollToValidate, _checkDuplicatesInArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    selectedInterface: '',
    apiInterfaceController: Ember.inject.controller('component.manage.api-interface'),
    filteredAPIInterfaces: Ember.computed('apiInterfaceController', function () {
      return this.get('apiInterfaceController.apiInterfaces').filterBy('s4-canAddFromUI', true);
    }),
    selectedInterfaceModel: Ember.computed('selectedInterface', function () {
      var _this = this;

      this.model.get('config').get('apiInterfaceConfig').get('apiInterface').forEach(function (item) {
        if (item.get('isNew')) {
          //rollback attributes for model which is in New state
          item.rollbackAttributes();
        }
      });
      var selectedInterface = parseInt(_this.get('selectedInterface'));

      if (selectedInterface) {
        var selectedInterfaceRecord = this.apiInterfaceController.get('apiInterfaces').filterBy('properties.cdType.s4-model-key', selectedInterface); //filtering the selected interface from the schema

        var modelName = selectedInterfaceRecord[0].properties.cdType["s4-model-name"];
        var componentConfig = this.model;

        var newInterface = _this.store.createRecord(modelName, {
          type: modelName,
          cdType: selectedInterface,
          apiInterfaceConfig: componentConfig.get('config').get('apiInterfaceConfig')
        });

        return newInterface;
      }
    }),
    actions: {
      cancel: function cancel(record) {
        record.rollbackAttributes();
        this.set('selectedInterface', '');
      },
      back: function back() {
        history.back();
      },
      addInterface: function addInterface() {
        if ($('#newApiInterfaceForm').valid()) {
          var _this = this;

          var model = this.model;
          var apiInterfaces = model.get('config').get('apiInterfaceConfig').get('apiInterface'); //check duplicates for a combination of port number and ipAddressType

          var hasDuplicateCombinationOfAPIPortsAndIp = (0, _checkDuplicatesInArray.checkDuplicatesInArray)(apiInterfaces, 'listenPort', 'hostAddress');

          if (hasDuplicateCombinationOfAPIPortsAndIp) {
            _scrollToValidate.default.begin('custom-scroll', 'listenPort', 'newApiInterfaceForm', 'Port Must be Unique per IP Address.');
          } else {
            var hasDuplicateAPIInterfaceNames = (0, _checkDuplicatesInArray.checkDuplicatesInArray)(apiInterfaces, 'taskDesc');

            if (hasDuplicateAPIInterfaceNames) {
              _scrollToValidate.default.begin('custom-scroll', 'taskDesc' + Ember.guidFor(this.get('selectedInterfaceModel')), 'newApiInterfaceForm', 'API Interface description must be unique.');
            } else {
              $('#progressHudOverlay').show();
              model.save().then(function () {
                $('#progressHudOverlay').hide();

                _this.get('selectedInterfaceModel').rollbackAttributes();

                _alerts.default._success('The UTG has been updated.');

                _this.transitionToRoute('component.manage.api-interface');
              }, function (error) {
                $('#progressHudOverlay').hide();

                _this.get('errorhandler').handleError(error, 'Unable to update UTG.');
              });
            }
          }
        }
      }
    }
  });

  _exports.default = _default;
});
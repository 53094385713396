define("in4m-web/controllers/component-profiles/component-profile/mail-server", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    componentProfile: Ember.inject.controller('componentProfiles.componentProfile'),
    actions: {
      addAlertList: function addAlertList() {
        this.get('model.alertList').addObject(Ember.Object.create({
          key: ''
        }));
      },
      removeAlertList: function removeAlertList(listItem) {
        this.get('model.alertList').removeObject(listItem);
      },
      addStatusList: function addStatusList() {
        this.get('model.statusList').addObject(Ember.Object.create({
          key: ''
        }));
      },
      removeStatusList: function removeStatusList(listItem) {
        this.get('model.statusList').removeObject(listItem);
      }
    }
  });

  _exports.default = _default;
});
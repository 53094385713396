define("in4m-web/controllers/component-profiles/component-profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    canEditSelectedProfile: function () {
      var selectedBusinessEntity = this.model.get('parentBusinessEntity');
      var user = this.get('session.userObject');
      var hasPermissionToEditProfileOnSelectedBE = user.hasPermission('canEdit', 'in4mUtgProfiles', selectedBusinessEntity);
      return hasPermissionToEditProfileOnSelectedBE;
    }.property('model')
  });

  _exports.default = _default;
});
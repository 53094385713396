define("in4m-web/serializers/business-entity", ["exports", "ember-data", "in4m-web/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.response) {
        delete payload.response;
      }

      if (payload.businessEntitySummary) {
        payload.businessEntities = payload.businessEntitySummary;
        delete payload.businessEntitySummary;
      }

      return this._super.apply(this, arguments);
    },
    attrs: {
      //children:{embedded:'always'},
      //permissionMasks: {embedded:'always'},
      parentBusinessEntity: 'parentId',
      subType: 'businessEntitySubTypeId',
      type: 'businessEntityTypeId'
    }
  });

  _exports.default = _default;
});
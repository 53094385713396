define("in4m-web/templates/custom/install-gear", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Th5dteE6",
    "block": "{\"symbols\":[],\"statements\":[[4,\"table-action-gear\",null,null,{\"statements\":[[4,\"if\",[[29,\"has-permission\",[\"canView\",\"in4mUtgInstallations\",[25,[\"session\"]]],null]],null,{\"statements\":[[4,\"link-to\",[\"installations.installation\",[25,[\"record\",\"id\"]]],[[\"tagName\"],[\"li\"]],{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"a\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-info-circle solid\"],[9],[10],[0,\"  View Install\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"if\",[[29,\"has-permission\",[\"canEdit\",\"in4mUtgInstallations\",[25,[\"session\"]]],null]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"li\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"a\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-sign-out\"],[9],[10],[0,\"  Deactivate Install\"],[10],[0,\"\\n\\t\\t\"],[3,\"action\",[[24,0,[]],\"sendAction\",\"deactivateInstallConfirm\",[25,[\"record\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[29,\"has-permission\",[\"canView\",\"in4mUtgInstallations\",[25,[\"session\"]]],null]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"li\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"a\"],[9],[7,\"i\"],[11,\"class\",\"bts bt-download\"],[9],[10],[0,\"  Download Installer\"],[10],[0,\"\\n\\t\\t\"],[3,\"action\",[[24,0,[]],\"sendAction\",\"downloadInstall\",[25,[\"record\"]]],[[\"bubbles\"],[false]]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[29,\"has-permission\",[\"canView\",\"in4mUtgInstallations\",[25,[\"session\"]]],null]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"li\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"a\"],[9],[7,\"i\"],[11,\"class\",\"bts bt-download\"],[9],[10],[0,\"  Download Install Package\"],[10],[0,\"\\n\\t\\t\"],[3,\"action\",[[24,0,[]],\"sendAction\",\"downloadInstallAsPackage\",[25,[\"record\"]]],[[\"bubbles\"],[false]]],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/custom/install-gear.hbs"
    }
  });

  _exports.default = _default;
});
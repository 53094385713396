define("in4m-web/routes/devices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    permKey: 'in4mDevices',
    permAction: 'canView',
    model: function model() {
      var _this = this;

      $('#progressHudOverlay').show(); // var parentBusinessEntityId = _this.get('session.selectedBusinessEntity.id');
      // var model = [];
      // var promises = [];
      // var parentBusinessEntityArr = [];
      // var machinePermanentIds = {};
      // return _this.store.query('component',{businessentityid: parentBusinessEntityId, includealldescendants:false, deviceStatusOnly:false /* We want to bring back more data than device status so that we can give a richer user experience to users doing bulk operations on devices. */ }).then(function(component) {
      // 	return _this.store.query('deviceStatus', {parentBusinessEntityId : parentBusinessEntityId}).then(function(deviceStatuses){
      // 		$('#progressHudOverlay').hide();
      // 		return deviceStatuses;
      // 	},function(error){
      // 		$('#progressHudOverlay').hide();
      // 		return _this.get('errorhandler').handleError(error,'Error while making a request.');
      // 	});
      // },function(error){
      // 	$('#progressHudOverlay').hide();
      // 	return _this.get('errorhandler').handleError(error,'Error while making a request.');
      // });

      var beId = _this.get('session.selectedBusinessEntity.id');

      var restQuery = this.get('restQuery');
      return restQuery.queryDevices(beId).then(function (devices) {
        $('#progressHudOverlay').hide();
        return devices;
      }).catch(function (err) {
        $('#progressHudOverlay').hide();
        return _this.get('errorhandler').handleError(err, 'Error while making a request.');
      });
    },
    afterModel: function afterModel() {
      $('#progressHudOverlay').hide();
    },
    actions: {
      handleBusinessEntityChange: function handleBusinessEntityChange() {
        this.refresh();
        return true;
      }
    }
  });

  _exports.default = _default;
});
define("in4m-web/adapters/ephemeral-user", ["exports", "in4m-web/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    host: Ember.computed('apiPath', function () {
      return this.get('apiPath.coreApiUrl');
    }),
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var ephemeralUserId;

      if (query) {
        // ephemeralUserId is an internal only query param that we use for url building
        if (query.ephemeralUserId) {
          ephemeralUserId = query.ephemeralUserId;
          delete query.ephemeralUserId;
        }
      } //regardless of query, parentbusinessentity is needed for an ephemeral api call


      var parentBusinessEntityId = this.get('session.selectedBusinessEntity.id');
      var url = [],
          host = Ember.get(this, 'host'),
          prefix = this.urlPrefix(),
          type = Ember.String.camelize(modelName);

      if (requestType === 'queryRecord') {
        url.push('businessentities/' + parentBusinessEntityId + '/' + this.pathForType(type) + '/' + ephemeralUserId);
      } else {
        url.push('businessentities/' + parentBusinessEntityId + '/' + this.pathForType(type));
      } // id present indicates we are retrieving a single record rather than a collection


      if (id) {
        // individual roles are retrieved without organizational context
        url.push(encodeURIComponent(id));
      }

      if (prefix) {
        url.unshift(prefix);
      }

      url = url.join('/');

      if (!host && url) {
        url = '/' + url;
      }

      return url;
    }
  });

  _exports.default = _default;
});
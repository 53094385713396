define("in4m-web/helpers/date-formatter-with-zone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateFormatterWithZone = dateFormatterWithZone;
  _exports.default = void 0;

  function dateFormatterWithZone(params
  /*, hash*/
  ) {
    var dateString = params[0];

    if (!dateString) {
      //runImmediately
      dateString = new Date();
    } else {
      var localDate = moment.utc(dateString).local().format('MM/DD/YYYY');
      var localTime = moment.utc(dateString).local().format('h:mm A');
      var localTimeZone = moment().tz(moment.tz.guess()).format('z');
      var differenceFromUTC = moment().tz(moment.tz.guess()).format('Z');
      return localDate + ' ' + localTime + ' ' + localTimeZone + ' UTC' + differenceFromUTC;
    }
  }

  var _default = Ember.Helper.helper(dateFormatterWithZone);

  _exports.default = _default;
});
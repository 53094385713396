define("in4m-web/controllers/component-profiles/component-profile/devices/new", ["exports", "in4m-web/utils/drop-down-list", "in4m-web/helpers/check-duplicates-in-array", "in4m-web/utils/scroll-to-validate", "in4m-web/utils/alerts"], function (_exports, _dropDownList, _checkDuplicatesInArray, _scrollToValidate, _alerts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    componentProfile: Ember.inject.controller('component-profiles.component-profile'),
    // isProfiles true is needed since the partials we use are shared by the components and componentProfiles, 
    // inorder to show some fields based on the model type, we need the isProfiles property on componentProfiles page
    isProfiles: true,
    dropdownListValues: Ember.computed('dropdownList', function () {
      return (0, _dropDownList.default)();
    }),
    supportedDevices: function () {
      var supportedDevices = this.store.peekAll('componentDeviceSchema');
      return supportedDevices;
    }.property('model'),
    selectedDeviceKey: null,
    selectedDevice: function () {
      var selectedDevice;

      if (this.get('selectedDeviceKey')) {
        selectedDevice = this.get('supportedDevices').findBy('key', this.get('selectedDeviceKey'));
      }

      return selectedDevice;
    }.property('supportedDevices', 'selectedDeviceKey'),
    selectedDeviceSchema: function () {
      var selectedDevice = this.get('selectedDevice');

      if (selectedDevice) {
        var selectedDeviceSchema = selectedDevice.get('schemaValue');
        return selectedDeviceSchema;
      } else {
        return false;
      }
    }.property('selectedDeviceKey', 'selectedDevice'),
    validations: function () {
      var selectedDeviceSchema = this.get('selectedDeviceSchema');
      var validations = {};

      function setValidations(validations, properties) {
        for (var key in properties) {
          if (properties[key].type != 'object' && properties[key].type != 'array') {
            validations[key] = {
              required: properties[key].required,
              maxLength: properties[key].maxLength,
              minLength: properties[key].minLength,
              minimum: properties[key].minimum,
              maximum: properties[key].maximum,
              disabled: properties[key].disabled
            };
          } else {
            if (properties[key].type != 'array') {
              validations[key] = {};
              setValidations(validations[key], properties[key].properties);
            } else {
              validations[key] = {};
              setValidations(validations[key], properties[key].items.properties);
            }
          }
        }
      }

      if (selectedDeviceSchema) {
        setValidations(validations, selectedDeviceSchema.properties);
      }

      validations.deviceType = this.get('selectedDevice.name');
      return validations;
    }.property('selectedDeviceSchema'),
    setDefaults: function () {
      // used for setting the defaults on the model
      var selectedDeviceSchema = this.get('selectedDeviceSchema');
      var defaultDevice = {};

      function setDefaults(defaultDevice, properties) {
        for (var key in properties) {
          if (properties[key].type != 'object') {
            if (properties[key].type != 'array') {
              defaultDevice[key] = properties[key].default;
            } else {
              defaultDevice[key] = [];
            }
          } else {
            defaultDevice[key] = {};
            setDefaults(defaultDevice[key], properties[key].properties);
          }
        }
      } // build out an object containing the default values from the JSON Schema


      if (selectedDeviceSchema) {
        setDefaults(defaultDevice, selectedDeviceSchema.properties);
        defaultDevice.deviceType = this.get('selectedDevice.name');
      }

      this.model.setProperties(defaultDevice);
    }.observes('selectedDeviceSchema'),
    actions: {
      toggleCollapsed: function toggleCollapsed(section) {
        this.set(section + 'Collapsed', !this.get(section + 'Collapsed'));
      },
      addBinRangeOverride: function addBinRangeOverride() {
        // TODO: possibly make bin range management a component so it can be reused in services
        this.get('model.manualCardEntry.binRangeOverrides').addObject({
          bypassCVV: false,
          bypassStreet: false,
          bypassExpDate: false,
          bypassLuhn: false,
          bypassPostalCode: false,
          lower: null,
          upper: null,
          minLength: null,
          maxLength: null
        });
      },
      removeBinRangeOverride: function removeBinRangeOverride(binRange) {
        this.get('model.manualCardEntry.binRangeOverrides').removeObject(binRange);
      },
      saveProfile: function saveProfile() {
        var _this = this;

        if ($('form').valid()) {
          var model = this.get('componentProfile').get('model'); //check for device names duplicates

          var devices = model.get('config').get('deviceConfig').get('devices');
          var hasDuplicateDeviceNames = (0, _checkDuplicatesInArray.checkDuplicatesInArray)(devices, 'taskDesc');

          if (hasDuplicateDeviceNames) {
            _scrollToValidate.default.begin('custom-scroll', 'taskDesc', 'deviceSelection', 'Device Name Must be Unique.');
          } else {
            var rs232Ports = [];
            var nonAutoPortDeviceTypes = [];
            var autoPortDeviceTypes = [];
            var hasDevicesWithAutoPortAndOthers = false;
            devices.forEach(function (device) {
              if (device.get('connection.deviceMode') === 'rs232') {
                if (device.get('connection.portNumber') != 'Auto') {
                  rs232Ports.push(device.get('connection.portNumber'));
                  nonAutoPortDeviceTypes.push(device.get('deviceType'));

                  if (autoPortDeviceTypes.indexOf(device.get('deviceType')) != -1) {
                    hasDevicesWithAutoPortAndOthers = true;
                  }
                } else {
                  autoPortDeviceTypes.push(device.get('deviceType'));

                  if (nonAutoPortDeviceTypes.indexOf(device.get('deviceType')) != -1) {
                    hasDevicesWithAutoPortAndOthers = true;
                  }
                }
              }
            });

            if (hasDevicesWithAutoPortAndOthers) {
              _scrollToValidate.default.begin('custom-scroll', 'portNumber', 'deviceSelection', 'If using more than one of the same device type, cannot use the Auto COM port option. Please modify device configuration.');
            } else {
              if (rs232Ports.uniq().length !== rs232Ports.length) {
                _scrollToValidate.default.begin('custom-scroll', 'portNumber', 'deviceSelection', 'Port Must be Unique.');
              } else {
                var profileName = model.get('name');
                $('#progressHudOverlay').show();
                model.save().then(function () {
                  _alerts.default._success(profileName + ' has been successfully updated.');

                  _this.transitionToRoute('componentProfiles.componentProfile.devices');

                  $('#progressHudOverlay').hide();
                }, function (e) {
                  _alerts.default._error('There was a problem updating your profile.');

                  $('#progressHudOverlay').hide();
                });
              }
            }
          }
        }
      }
    }
  });

  _exports.default = _default;
});
define("in4m-web/templates/components/s4-control", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6wMLmNuT",
    "block": "{\"symbols\":[\"control\"],\"statements\":[[4,\"each\",[[25,[\"property\",\"s4-controls\"]]],null,{\"statements\":[[4,\"if\",[[29,\"is-equal\",[[24,1,[\"type\"]],\"info\"],null]],null,{\"statements\":[[4,\"unless\",[[29,\"get\",[[25,[\"controlsHidden\"]],[24,1,[\"id\"]]],null]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"i\"],[12,\"class\",[24,1,[\"class\"]]],[11,\"aria-hidden\",\"true\"],[11,\"data-toggle\",\"tooltip\"],[11,\"data-placement\",\"top\"],[12,\"title\",[24,1,[\"text\"]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[29,\"is-equal\",[[24,1,[\"type\"]],\"msg\"],null]],null,{\"statements\":[[4,\"unless\",[[29,\"get\",[[25,[\"controlsHidden\"]],[24,1,[\"id\"]]],null]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"p\"],[9],[1,[24,1,[\"text\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/components/s4-control.hbs"
    }
  });

  _exports.default = _default;
});
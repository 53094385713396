define("in4m-web/templates/custom/custom-pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TA76XxOX",
    "block": "{\"symbols\":[\"page\"],\"statements\":[[7,\"div\"],[11,\"class\",\"btn-toolbar pull-right\"],[11,\"role\",\"toolbar\"],[9],[0,\"\\n\\t\"],[7,\"div\"],[11,\"class\",\"btn-group\"],[11,\"role\",\"group\"],[9],[0,\"\\n\\t\\t\"],[7,\"a\"],[11,\"href\",\"#\"],[12,\"class\",[30,[[29,\"if\",[[25,[\"gotoBackEnabled\"]],\"enabled\",\"disabled\"],null],\" btn btn-default\"]]],[9],[0,\"\\n\\t      \"],[7,\"span\"],[12,\"class\",[30,[[25,[\"icons\",\"nav-first\"]]]]],[9],[10],[0,\"\\n\\t    \"],[3,\"action\",[[24,0,[]],\"gotoFirst\"]],[10],[0,\"\\n\\t    \"],[7,\"a\"],[11,\"href\",\"#\"],[12,\"class\",[30,[[29,\"if\",[[25,[\"gotoBackEnabled\"]],\"enabled\",\"disabled\"],null],\" btn btn-default\"]]],[9],[0,\"\\n\\t      \"],[7,\"span\"],[12,\"class\",[30,[[25,[\"icons\",\"nav-prev\"]]]]],[9],[10],[0,\"\\n\\t    \"],[3,\"action\",[[24,0,[]],\"gotoPrev\"]],[10],[0,\"\\n\"],[4,\"each\",[[25,[\"visiblePageNumbers\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"button\"],[12,\"class\",[30,[[29,\"if\",[[24,1,[\"isActive\"]],\"active\"],null],\" \",[25,[\"classes\",\"buttonDefault\"]]]]],[11,\"type\",\"button\"],[9],[1,[24,1,[\"label\"]],false],[3,\"action\",[[24,0,[]],\"gotoCustomPage\",[24,1,[\"label\"]]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t    \"],[7,\"a\"],[11,\"href\",\"#\"],[12,\"class\",[30,[[29,\"if\",[[25,[\"gotoForwardEnabled\"]],\"enabled\",\"disabled\"],null],\" btn btn-default\"]]],[9],[0,\"\\n\\t      \"],[7,\"span\"],[12,\"class\",[30,[[25,[\"icons\",\"nav-next\"]]]]],[9],[10],[0,\"\\n\\t    \"],[3,\"action\",[[24,0,[]],\"gotoNext\"]],[10],[0,\"\\n\\t    \"],[7,\"a\"],[11,\"href\",\"#\"],[12,\"class\",[30,[[29,\"if\",[[25,[\"gotoForwardEnabled\"]],\"enabled\",\"disabled\"],null],\" btn btn-default\"]]],[9],[0,\"\\n\\t      \"],[7,\"span\"],[12,\"class\",[30,[[25,[\"icons\",\"nav-last\"]]]]],[9],[10],[0,\"\\n\\t    \"],[3,\"action\",[[24,0,[]],\"gotoLast\"]],[10],[0,\"\\n\\t\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/custom/custom-pagination.hbs"
    }
  });

  _exports.default = _default;
});
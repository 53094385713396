define("in4m-web/components/theme-default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    willRender: function willRender() {
      moment.tz.add([//adding the supported timezones, may have to change this to add dynamically in next release
      'America/Los_Angeles|PST PDT|80 70|0101|1Lzm0 1zb0 Op0|15e6', 'America/Denver|MST MDT|70 60|0101|1zb0 0p0 1zb0|26e5', 'America/Chicago|CST CDT|60 50|0101|1zb0 0p0 1zb0|92e5', 'America/New_York|EST EDT|50 40|0101|1Lz50 1zb0 Op0|21e6']);
    },
    didInsertElement: function didInsertElement() {
      // Data Tables - Config
      (function ($) {
        if ($.isFunction($.fn.dataTable)) {
          $.extend(true, $.fn.dataTable.defaults, {
            sDom: "<'row datatables-header form-inline'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>r><'table-responsive't><'row datatables-footer'<'col-sm-12 col-md-6'i><'col-sm-12 col-md-6'p>>",
            oLanguage: {
              sLengthMenu: '_MENU_ records per page',
              sProcessing: '<i class="fa fa-spinner fa-spin"></i> Loading'
            },
            fnInitComplete: function fnInitComplete(settings, json) {
              // select 2
              if ($.isFunction($.fn.select2)) {
                $('.dataTables_length select', settings.nTableWrapper).select2({
                  theme: 'bootstrap',
                  minimumResultsForSearch: -1
                });
              }

              var options = $('table', settings.nTableWrapper).data('plugin-options') || {}; // search

              var $search = $('.dataTables_filter input', settings.nTableWrapper);
              $search.attr({
                placeholder: typeof options.searchPlaceholder !== 'undefined' ? options.searchPlaceholder : 'Search'
              }).addClass('form-control');

              if ($.isFunction($.fn.placeholder)) {
                $search.placeholder();
              }
            }
          });
        }
      }).apply(this, [jQuery]); // Notifications - Config

      (function ($) {
        'use strict'; // use font awesome icons if available

        if (typeof PNotify != 'undefined') {
          PNotify.prototype.options.styling = "fontawesome";
          $.extend(true, PNotify.prototype.options, {
            shadow: false,
            stack: {
              spacing1: 15,
              spacing2: 15
            }
          });
          $.extend(PNotify.styling.fontawesome, {
            // classes
            container: "notification",
            notice: "notification-warning",
            info: "notification-info",
            success: "notification-success",
            error: "notification-danger",
            // icons
            notice_icon: "fa fa-exclamation",
            info_icon: "fa fa-info",
            success_icon: "fa fa-check",
            error_icon: "fa fa-times"
          });
        }
      }).apply(this, [jQuery]); //this is to make sure the table popover come on top/bottom based on the table height

      (function ($) {
        'use strict';

        $(document).on("shown.bs.dropdown", ".dropdown", function () {
          var ul = $(this).children(".dropdown-menu");
          var table = $(this).closest('.table');
          var ulTop;
          ulTop = ul.offset().top - table.offset().top;
          var spaceUp = ulTop - ul.height() - table.scrollTop();
          var spaceDown = table.scrollTop() + table.height() - (ulTop + ul.height());

          if (spaceDown < 0 && (spaceUp >= 0 || spaceUp > spaceDown)) {
            $(this).addClass("dropup");
          }
        }).on("hidden.bs.dropdown", ".dropdown", function () {
          $(this).removeClass("dropup");
        });
      }).apply(this, [jQuery]);
    }
  });

  _exports.default = _default;
});
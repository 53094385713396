define("in4m-web/templates/component/manage/advanced", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VHwi7bR9",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"advanced-template\",null,[[\"model\",\"canEdit\",\"dropdownListValues\",\"save\"],[[25,[\"model\"]],[29,\"unless\",[[25,[\"indexController\",\"isEditDisabled\"]],true],null],[25,[\"dropdownListValues\"]],\"saveComponentConfig\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/component/manage/advanced.hbs"
    }
  });

  _exports.default = _default;
});